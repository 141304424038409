import React from "react";
import { Label } from "react-bootstrap";
import "./status-idle.scss";

const StatusIdle = ({ showModal, deviceUUID }) => {
  return (
    <div onClick={() => showModal(deviceUUID)} className="device__status--idle">
      <span className="ion-ios-pint device__alert-icon" />
      <span className="device__text--idle">
        <Label bsStyle="primary">Start a new session</Label>
      </span>
      <span className="ion-ios-arrow-right device__chevron" />
    </div>
  );
};

export default StatusIdle;

export const isBrewable = recipe => {
  let error = false;
  if (!recipe.boiling.length) {
    error = true;
  } else if (!recipe.fermenting.length) {
    error = true;
  } else if (!recipe.mashing.length) {
    error = true;
  } else if (!recipe.mashing[0].steps.length) {
    error = true;
  } else {
    recipe.fermenting.forEach(stage => {
      stage.steps.forEach(step => {
        if (step.duration === null || step.temperature === null) {
          error = true;
        }
      });
    });
  }

  if (error) {
    return false;
  } else {
    return true;
  }
};

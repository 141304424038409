import React from "react";

import "./status-needs-cleaning.scss";
class StatusNeedsCleaning extends React.Component {
  state = {};
  render() {
    const { showModal, deviceUUID } = this.props;
    return (
      <div
        onClick={() => showModal(deviceUUID)}
        className="device__status--needs-cleaning"
      >
        <span className="ion-waterdrop device__alert-icon" />
        <span className="device__alert-text"> Device needs cleaning </span>
        <span className="ion-ios-arrow-right device__chevron" />{" "}
      </div>
    );
  }
}

export default StatusNeedsCleaning;

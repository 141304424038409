import React from "react";

class DeviceToggle extends React.Component {
  handleClick = e => {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    return <div onClick={this.handleClick}>{this.props.children}</div>;
  }
}

export default DeviceToggle;

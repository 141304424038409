import React from "react";
import { Row, Table } from "react-bootstrap";

export const SubOverview = ({ selectedYear, selectedMonth }) => {
  let newLines = {
    whiteSpace: "pre-wrap",
    textAlign: "center"
  };
  let selectionYear = {
    color: "#000"
  };
  let selectionMonth = {
    color: "#000"
  };
  if (selectedYear == true) {
    selectionYear.color = "rgb(253, 204, 0)";
    selectionYear.fontFamily = "Gotham-Bold";
  }
  if (selectedMonth == true) {
    selectionMonth.color = "rgb(253, 204, 0)";
    selectionMonth.fontFamily = "Gotham-Bold";
  }

  return (
    <Row
      style={{
        display: "flex",
        padding: 10,
        flexDirection: "column",
        justifySelf: "center",
        justifyContent: "center"
      }}
    >
      <Table hover className="compare-table">
        <tbody style={{ pointerEvents: "none" }}>
          <tr>
            <td className="hidden-xs hidden-sm hidden-md">
              <h3 style={{ textAlign: "center" }}>Pricing</h3>
            </td>

            <td style={newLines}>
              <p style={selectionMonth} className="normal-text">
                € 8.50
                {"\n"}
                <span className="normal-text" style={{ fontStyle: "italic" }}>
                  (Annually € 102)
                </span>
                {"\n"}
              </p>
            </td>

            <td className="last-column " style={newLines}>
              <p
                style={selectionYear}
                className="normal-text hidden-xs hidden-sm hidden-md"
              >
                {"       "}€ 89
                {"\n"}
                <span className="normal-text" style={{ fontStyle: "italic" }}>
                  (15% discount)
                </span>
              </p>
              <p
                style={selectionYear}
                className="normal-text hidden-lg hidden-xl"
              >
                € 89
                {"\n"}
                <span className="normal-text" style={{ fontStyle: "italic" }}>
                  (15% discount)
                </span>
              </p>
            </td>
          </tr>
          <tr>
            <td className="hidden-xs hidden-sm hidden-md">
              <h3 style={{ textAlign: "center" }}>First month free</h3>
            </td>

            <td style={newLines}>
              <p
                style={selectionMonth}
                className="normal-text hidden-xs hidden-sm hidden-md"
              >
                Yes
              </p>
              <p
                style={selectionMonth}
                className="normal-text hidden-lg hidden-xl"
              >
                First Month Free
              </p>
            </td>
            <td style={newLines} className="last-column ">
              <p
                style={selectionYear}
                className="normal-text hidden-xs  hidden-sm hidden-md"
              >
                {"       "}Yes
              </p>
              <p
                style={selectionYear}
                className="normal-text hidden-lg hidden-xl"
              >
                First Month Free
              </p>
            </td>
          </tr>
          <tr>
            <td className="hidden-xs hidden-sm hidden-md">
              <h3 style={{ textAlign: "center" }}>
                Access to the full library
              </h3>
            </td>

            <td style={newLines}>
              <p
                style={selectionMonth}
                className="normal-text hidden-xs hidden-sm hidden-md"
              >
                Yes
              </p>
              <p
                style={selectionMonth}
                className="normal-text hidden-lg hidden-xl"
              >
                Full Library Access
              </p>
            </td>
            <td style={newLines} className="last-column">
              <p
                style={selectionYear}
                className="normal-text hidden-xs hidden-sm hidden-md"
              >
                {"       "}Yes
              </p>
              <p
                style={selectionYear}
                className="normal-text hidden-lg hidden-xl"
              >
                Full Library Access
              </p>
            </td>
          </tr>
          <tr>
            <td className="hidden-xs hidden-sm hidden-md col-lg-3">
              <h3 style={{ marginTop: 0, textAlign: "center" }}>
                Brew on your Computer, Mobile Phone and Tablet
              </h3>
            </td>

            <td className="col-lg-4" style={newLines}>
              <p
                style={selectionMonth}
                className="normal-text hidden-xs hidden-sm hidden-md hidden-sm hidden-md"
              >
                Yes
              </p>
              <p
                style={selectionMonth}
                className="normal-text hidden-lg hidden-xl"
              >
                Brew on Desktop, Mobile Phone and Tablet
              </p>
            </td>
            <td
              className="col-lg-5 desktop-column last-column"
              style={newLines}
            >
              <p
                style={selectionYear}
                className="normal-text hidden-xs hidden-sm hidden-md "
              >
                {"       "}Yes
              </p>
              <p
                style={selectionYear}
                className="normal-text hidden-lg hidden-xl"
              >
                Brew on Desktop, Mobile Phone and Tablet
              </p>
            </td>
          </tr>
        </tbody>
      </Table>
    </Row>
  );
};

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormGroup, FormControl, Button, Alert } from "react-bootstrap";
import { post } from "@common/services/request";
import { apiEndpoints } from "@common/constants/urls";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import "./reset-password-page.scss";

class ResetPasswordPage extends Component {
  state = {
    errorMessage: null,
    resetRequested: false,
    isLoading: false,
    email: ""
  };

  handleChange = e => {
    this.setState({ email: e.target.value });
  };

  resetPassword = e => {
    e.preventDefault();
    const customHeaders = {
      "Content-Type": "application/json",
      Authorization: "TOKEN " + process.env.API_TOKEN,
      client: "Breweryportal",
      version: process.env.VERSION || 0
    };

    this.setState({
      isLoading: true,
      errorMessage: null
    });

    post(apiEndpoints.recover, { email: this.state.email }, customHeaders)
      .then(() => {
        this.setState({
          errorMessage: null,
          resetRequested: true,
          isLoading: false
        });
      })
      .catch(err => {
        if (typeof err === "string")
          this.setState({ errorMessage: err, isLoading: false });
        else if (err.then)
          err.then(msg => {
            this.setState({
              isLoading: false,
              errorMessage: msg.error_message || msg.detail
            });
          });
      });
  };

  render() {
    return (
      <main className="container-fluid content--auth center auth-bg">
        <section className="col-md-4 col-sm-7 col-xs-12 reset-password">
          <div>
            <h1 className="reset-password__title">Reset password</h1>
          </div>
          <h4 className="reset-password__subtitle">
            Forgot your password?
            <br />
            Request a reset and check your mailbox for instructions.
          </h4>

          {!this.state.resetRequested && (
            <form onSubmit={this.resetPassword}>
              <FormGroup
                controlId="resetPasswordForm"
                className="reset-password__form"
              >
                <FormControl
                  name="email"
                  type="email"
                  value={this.state.email}
                  placeholder="Enter your email"
                  onChange={this.handleChange}
                  required
                  disabled={this.state.resetRequested}
                />
                <ButtonWithLoad
                  bsStyle="primary"
                  type="submit"
                  className="reset-password__form-button"
                  disabled={this.state.resetRequested}
                  isLoading={this.state.isLoading}
                >
                  Reset
                </ButtonWithLoad>
              </FormGroup>
              <div style={{ height: "40px" }} />
            </form>
          )}

          {this.state.errorMessage && (
            <Alert bsStyle="danger" className="reset-password__message">
              {this.state.errorMessage}
            </Alert>
          )}

          {this.state.resetRequested && (
            <Alert bsStyle="success" className="reset-password__message">
              Reset password request sent, please check your email inbox.
              <div className="reset-password__message-button">
                <Link to="/auth/signin/">
                  <Button bsStyle="success" type="button">
                    OK
                  </Button>
                </Link>
              </div>
            </Alert>
          )}
        </section>
      </main>
    );
  }
}

export default ResetPasswordPage;

import React from "react";
import "./status-session-starting.scss";

const StatusSessionStarting = () => {
  return (
    <div className="status-starting flex items-center">
      <i className="ion-load-c spin status-starting__spinner" />
      <div>Starting session</div>
    </div>
  );
};

export default StatusSessionStarting;

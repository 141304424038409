import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import './table.scss';

/**
 * <GenericTable headers={['Name', 'Amount', 'Duration', 'IBU']}
 * data={[{name: 'beer', amount: 100, duration: 10, ibu: 5, country: 'nl' }]}
 * filter={['name', 'amount', 'duration', 'ibu']}
 * />
 * @param props.headers the headers to be displayed in this table
 * @param props.data an array of object you'd wish to display
 * @param props.filter the fields of the object you'd wish to show
 */
class GenericTable extends React.Component {
  state = {};

  renderTable() {
    // If data is empty show no data message
    if (this.props.data.length <= 0 || this.props.data === undefined) {
      return (
        <tbody>
          <tr>
            <td>No Data Found</td>
          </tr>
        </tbody>
      );
    } else if (this.props.filter === undefined) {
      // If no filters are defined show all elements in object
      return (
        <tbody>
          {this.props.data.map((object, index) => (
            <tr key={index}>
              {Object.keys(object).map(element => (
                <td key={element}>{object[element]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    } else {
      // If filter is defined, show only the intended data
      return (
        <tbody>
          {this.props.data.map((object, index) => (
            <tr key={index}>
              {this.props.filter.map(
                element =>
                  element === "index" ? (
                    <td key={element}>{index + 1}</td>
                  ) : (
                    <td key={element}>{object[element]}</td>
                  )
              )}
            </tr>
          ))}
        </tbody>
      );
    }
  }

  render() {
    return (
      <Table className="generic-table" responsive>
        <thead>
          <tr>
            {this.props.headers.map(header => <th key={header}>{header}</th>)}
          </tr>
        </thead>
        {this.renderTable()}
      </Table>
    );
  }
}

GenericTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default connect(undefined, undefined)(GenericTable);

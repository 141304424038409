const PROCESS_STATES = {
  IDLE_STATE: 0,

  MANUAL_CONTROL_STATE: 5,

  // brewing states

  // step 2: rinse system
  PREPARE_RINSE_STATE: 6,
  PUMP_PRIMING_STATE: 10,
  CHECK_FLOW_STATE: 11,
  CHECK_WATER_STATE: 8,
  HEATUP_RINSE_STATE: 12,
  CLEAN_BALL_VALVE_STATE: 13,
  RINSE_BOILING_PATH_STATE: 14,
  RINSE_MASHING_PATH_STATE: 15,
  RINSE_COOL_STATE: 18,
  RINSE_DONE_STATE: 16,

  //step 3: manual preparation
  CHECK_KEG_STATE: 7,
  FILL_MACHINE_STATE: 17,

  //step 5: mashing
  MASHBED_HYDRATE_HEATING_STATE: 20, //deprecated
  MASHBED_HYDRATE_FLOW_STATE: 21, //deprecated
  MASHBED_HYDRATE_STATE: 22, //deprecated
  MASHBED_HYDRATE_SETTLE_STATE: 23, //deprecated
  MASH_IN_STATE: 24,
  MASHING_HEATUP_STATE: 30,
  MASHING_MAINTAIN_STATE: 31,
  MASHING_REST_STATE: 32, //deprecated

  //step 6: lautering
  SPARGING_STATE: 39,
  LAUTERING_STATE: 40,
  REPLACE_MASH_STATE: 43,

  //step 7: boiling and flavouring
  BOILING_HEATUP_STATE: 50,
  BOILING_MAINTAIN_STATE: 51,
  SECONDARY_LAUTERING_STATE: 52,

  //step 8: Filtering and cooling
  CONNECT_WATER_STATE: 59,
  COOL_WORT_STATE: 60,
  FILTER_COLD_CRASH_STATE: 61, //deprecated

  BREWING_DONE_STATE: 70,
  BREWING_FAILED_STATE: 71,

  // fermentation states

  //step 1: pitching
  PITCH_COOLING_STATE: 74,
  PREPARE_FERMENTATION_STATE: 75,

  //step 2: fermentation
  FERMENTATION_TEMP_CONTROL_STATE: 80,
  PLACE_AIRLOCK_STATE: 76,
  REMOVE_AIRLOCK_STATE: 77,
  PLACE_TRUB_CONTAINER_STATE: 78,
  REMOVE_TRUB_STATE: 81,
  FERMENTATION_ADD_INGREDIENT_STATE: 82,
  FERMENTATION_REMOVE_INGREDIENT_STATE: 83,
  FERMENTATION_FAILED_STATE: 84,

  // serving states
  PREPARE_SERVING_STATE: 88,
  COOL_BEFORE_SERVING_STATE: 90,
  MOUNT_TAP_STATE: 91,
  SERVING_TEMP_CONTROL_STATE: 92,
  SERVING_FAILED_STATE: 93,
  CARBONATING_STATE: 94,

  // clean in place states
  PREPARE_CIP_STATE: 101,
  DRAIN_STATE: 102, //deprecated
  BACKFLUSH_STATE: 103,
  PLACE_CIP_ACCESORIES_STATE: 105, //deprecated
  CIP_DONE_STATE: 108,
  CIP_FAILED_STATE: 109,
  CIRCULATE_BOILING_PATH_STATE: 111,
  CIRCULATE_MASHING_PATH_STATE: 112,
  RINSE_COUNTERFLOW_BOIL_STATE: 113,
  RINSE_COUNTERFLOW_MASHTUN_STATE: 114
};
export default PROCESS_STATES;

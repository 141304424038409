import { get, patch, post, del } from "@common/services/request";
import { apiEndpoints as api } from "@common/constants/urls";

export const getLaboratories = () => {
  return get(api.laboratories);
};

export const getHops = () => {
  return get(api.hops);
};

export const getFermentables = () => {
  return get(api.fermentables);
};

export const getYeasts = () => {
  return get(api.yeasts);
};

export const getAdjuncts = () => {
  return get(api.adjuncts);
};

export const getAllIngredients = () => {
  return Promise.all([
    getHops(),
    getFermentables(),
    getYeasts(),
    getAdjuncts()
  ]);
};

export const patchHop = (id, data) => {
  return patch(`${api.createHop + id}/`, data);
};

export const patchFermentable = (id, data) => {
  return patch(`${api.createFermentable + id}/`, data);
};

export const patchYeast = (id, data) => {
  return patch(`${api.createYeast + id}/`, data);
};

export const patchAdjunct = (id, data) => {
  return patch(`${api.createAdjunct + id}/`, data);
};

export const postHops = data => {
  return post(api.createHop, data);
};

export const postFermentable = data => {
  return post(api.createFermentable, data);
};

export const postYeast = data => {
  return post(api.createYeast, data);
};

export const postAdjunct = data => {
  return post(api.createAdjunct, data);
};
export const deleteHops = id => {
  return del(`${api.createHop + id}/`);
};
export const deleteFermentable = id => {
  return del(`${api.createFermentable + id}/`);
};
export const deleteYeast = id => {
  return del(`${api.createYeast + id}/`);
};
export const deleteAdjunct = id => {
  return del(`${api.createAdjunct + id}/`);
};

const COMMAND_TYPES = {
  CMD_START_PROCESS: 0,
  CMD_STOP_PROCESS: 1,
  CMD_PAUSE_PROCESS: 2,
  CMD_RESUME_PROCESS: 3,
  CMD_RESUME_FERMENTATION: 4,
  CMD_START_FREQUENT_LOGGING: 5,
  CMD_UPDATE_RECIPE: 6,
  CMD_UPDATE_ESP: 7,
  CMD_UPDATE_IO: 8
};

export default COMMAND_TYPES;

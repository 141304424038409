import React, { Component } from "react";
import { connect } from "react-redux";
import * as sessionService from "@brewery/services/sessionService";
import * as recipeService from "@beers/services/recipeService";
/**
 * Temporary component to show how the ingredients should be added
 * Once we have a good solution for this the component can be removed
 * That's why we're keeping all state in this single component, to not clutter the rest of
 * the codebase
 */
class HopCarouselIngredients extends Component {
  state = {
    ingredients: []
  };

  componentDidMount() {
    sessionService
      .getSessionById(this.props.mqtt.ignoreSession)
      .then(session => {
        recipeService.getRecipeById(session.beer_recipe_id).then(recipe => {
          const newIngredients = [
            ...recipe.boiling[0].hops,
            ...recipe.boiling[0].other_ingredients
          ];
          this.setState({ ingredients: newIngredients });
        });
      });
  }
  render() {
    return (
      <div>
        <img
          src={require("@assets/images/hops_carousel_small.png")}
          style={{ width: "100%", height: "auto" }}
        />
        {this.state.ingredients
          .sort(
            (ingredientA, ingredientB) =>
              ingredientB.duration - ingredientA.duration
          )
          .map((ingredient, index) => (
            <p key={ingredient.ingredient_id + ingredient.duration}>
              <b> {index + 1}. </b>
              {`${ingredient.ingredient_name} ${ingredient.amount}gr - ${
                ingredient.duration
              }min`}
            </p>
          ))}
      </div>
    );
  }
}
function mapStateToProps({ mqtt }) {
  return { mqtt };
}
export default connect(
  mapStateToProps,
  null
)(HopCarouselIngredients);

import mixpanel from "mixpanel-browser";

export const ANALYTICS_TYPES = {
  SIGN_IN: "Login",
  SIGN_OUT: "Logout",
  SIGN_UP: "SignUp",
  ACCEPTED_TERMS: "AcceptTerms",
  ONBOARDING_STARTED: "OnboardingStarted",
  ONBOARDING_ENDED: "OnboardingEnded",
  CONNECT_DEVICE: "ConnectDevice",
  DEVICE_CONNECTED: "DeviceConnected",
  START_BREW_PACK: "StartBrewPackBrew",
  START_CUSTOM_RECIPE: "StartCustomRecipe",
  SCREEN_LOAD: "ScreenLoad",
  ERROR_REPORT: "ErrorReport",
  SET_SERVING_TEMP: "SetServingTemp",
  OPEN_MENU: "OpenMenu",
  OPEN_MENU_ITEM: "SelectMenuItem"
};

// A wrapper for the analytics
const analyticsService = {
  mixpanel,
  init: function() {
    mixpanel.init(process.env.MIXPANEL_TOKEN);
  },
  register: function() {
    const superProperties = {
      "App Version": process.env.VERSION || "development"
    };
    mixpanel.register(superProperties);
  },
  track: function(eventName, properties) {
    mixpanel.track(eventName, properties);
  }
};

export default analyticsService;

import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const NoRecipesFound = props => {
  return (
    <div
      className="center-element"
      style={{ height: "calc(100vh - 200px)", textAlign: "center" }}
    >
      <div>
        {/*https://www.iconfinder.com/icons/3017884/beer_drink_mug_icon#size=512
          Image is under CC 3.0 and needs crediting
          TODO: replace with our own assets
        */}
        <img
          src="https://cdn0.iconfinder.com/data/icons/st-patrick-s-day-22/24/Beer_Mug_drink-512.png"
          alt="No-recipes-found-image"
          style={{ width: "150px", marginTop: "20px" }}
        />
        <h1>No Recipes Found</h1>
        <div>
          <Link to={`${props.url}/edit/new`}>
            <Button bsStyle="primary">CREATE A NEW RECIPE</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoRecipesFound;

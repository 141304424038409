import React from "react";

const StatusNeedsAcidCleaning = ({ showModal }) => {
  return (
    // ENABLE ONCE ACID CLEANING KIT IS AT CUSTOMERS
    // <div
    //   onClick={() => showModal(deviceUUID)}
    //   className="device__status--needs-cleaning"
    // >
    //   <span className="ion-erlenmeyer-flask device__alert-icon" />
    //   <span className="device__alert-text"> Requires Your Maintenance </span>
    //   <span className="ion-ios-arrow-right device__chevron" />{" "}
    // </div>
    <div onClick={showModal} className="device__status--needs-cleaning">
      <span className="ion-erlenmeyer-flask device__alert-icon" />
      <span className="device__alert-text"> Contact support </span>
      <span className="ion-ios-arrow-right device__chevron" />{" "}
    </div>
  );
};

export default StatusNeedsAcidCleaning;

const PHASES = {
  1: "Rinse", // Startup
  2: "Mash",
  3: "Boil",
  4: "Cool",
  5: "Primary",
  6: "Secondary",
  7: "Conditioning",
  8: "Serving",
  9: "Preparing",
  10: "Cleaning",
  11: "Rinsing",
  12: "Preparing", // Fermentation
  13: "Carbonating",
  14: "Soaking",
  15: "Cleaning",
  16: "Rinsing"
};

export default PHASES;

import React from "react";

const ContentWrapper = ({ children, ...props }) => {
  return (
    <div className="container-fluid" {...props}>
      <div className="row">
        <div className="col-lg-8 col-lg-offset-2 col-md-12"> {children}</div>
      </div>
    </div>
  );
};

export default ContentWrapper;

import React from "react";
import "./ChartStatus.scss";

const ChartStatus = ({ items }) => {
  return (
    <div className="ChartStatus">
      {items.map(({ label, value, color = "white" }) => (
        <div
          key={label + value}
          className={`ChartStatus__item ChartStatus__item--${color}`}
        >
          <div className="ChartStatus__label">{label}</div>
          <div className="ChartStatus__value">{value}</div>
        </div>
      ))}
    </div>
  );
};

export default ChartStatus;

import PROCESS_STATES from "@common/constants/processStates";
import PROCESS_TYPES from "@common/constants/processTypes";

const chartUtil = {
  updateChartHistoricsOnly: (previousOptions, historics) => {
    // Push historic data to chart
    if (historics) {
      let historicData = previousOptions.series[3];
      historicData.data = chartUtil.processHistorics(historics);

      // Remove axis pointer
      let axisPointer = previousOptions.xAxis[0].axisPointer;
      axisPointer.show = false;
    }
    return previousOptions;
  },
  updateChartOptions: (
    frequentlogBatch,
    previousOptions,
    processSummary,
    historics = null
  ) => {
    const frequentlog = frequentlogBatch.logsList[0];

    // Push frequentlog data to the chart
    let realtimeData = previousOptions.series[0].data;
    realtimeData.push(chartUtil.processFrequentlog(frequentlog));

    // Push gravity sensor data
    if (
      frequentlog.currentStatus.procType === PROCESS_TYPES.PROC_FERMENTATION &&
      frequentlog.currentGravity !== 0 &&
      frequentlog.currentGravity !== null
    ) {
      let realtimeGravityData = previousOptions.series[4].data;
      realtimeGravityData.push(chartUtil.processGravitySensor(frequentlog));
    }

    // Update axis pointer to the new position
    let axisPointer = previousOptions.xAxis[0].axisPointer;
    axisPointer.value = new Date(frequentlog.timestampMs);

    // Update process summary / process estimation
    if (processSummary) {
      let processSummaryData = previousOptions.series[1];
      const [
        processedProcessSummary,
        mashingMarklines
      ] = chartUtil.processProcessSummary(frequentlog, processSummary);
      processSummaryData.data = processedProcessSummary;
      processSummaryData.markLine.data = mashingMarklines;
    }

    // Push historic data to chart
    if (historics) {
      let historicData = previousOptions.series[3];
      historicData.data = chartUtil.processHistorics(historics);
    }

    return previousOptions;
  },
  processGravitySensor: frequentlog => {
    const currentGravity = frequentlog.currentGravity;
    return {
      value: [new Date(frequentlog.timestampMs), currentGravity]
    };
  },
  processFrequentlog: frequentlog => {
    // Required for firmware returning null values
    const currentTemp = frequentlog.currentTemp
      ? frequentlog.currentTemp.toFixed(1)
      : null;
    return {
      value: [new Date(frequentlog.timestampMs), currentTemp],
      phase: frequentlog.processPhase
    };
  },
  processHistorics: historics => {
    let processedHistorics = [];

    historics
      .sort((a, b) => a.psi - b.psi || a.ts - b.ts)
      .forEach(log => {
        if (chartUtil.isValidData(log)) {
          const currentTemp = log.tmp ? log.tmp.toFixed(1) : null;
          processedHistorics.push({
            value: [new Date(log.t), currentTemp],
            phase: log.ps
          });
        }
      });
    return processedHistorics.sort(
      (a, b) => new Date(b.value[0] - new Date(a.value[0]))
    );
  },
  isValidData: historicLog => {
    const date = new Date(historicLog.t);
    const year = date.getFullYear();

    return (
      historicLog.tmp &&
      historicLog.ts &&
      historicLog.tmp < 120 &&
      historicLog.tmp >= 0 &&
      year > 2000 // Filter some corrupted data
    );
  },
  processProcessSummary: (frequentlog, processSummary) => {
    if (processSummary) {
      const processedProcessSummary = [];
      const mashingMarklines = [];
      let currentTimestamp = 0;

      const sessionStartTimestamp = frequentlog.timestampMs.toFixed();
      const currentTimeInState = frequentlog.currentStatus.timeInState;
      const currentTemp = frequentlog.currentTemp
        ? frequentlog.currentTemp.toFixed(2)
        : 0;

      // Get only the process summary items that have not yet happened
      // this can be done by checking if the current process summary index is bigger than
      // the process summary item's process summary index
      processSummary = processSummary.filter((_, index) => {
        return index >= frequentlog.processSummaryIndex;
      });

      // If there is a useraction, the prediction graph should move forward.
      // This is so the estimation stays accurate since waiting for useractions doesn't progress
      // the overall brewing
      if (frequentlog.currentStatus.action > 0) {
        currentTimestamp += frequentlog.currentStatus.timeInState / 1000;
      }

      processSummary.forEach((item, index) => {
        // TODO: null check might not be needed, please check
        let endTemperature = item.endTemperature ? item.endTemperature : null;
        let startTemp = item.startTemperature ? item.startTemperature : null;
        const duration = item.duration ? item.duration : 0;
        let processSummaryItem = {};

        // If it is the first item add a point where the realtime chart is now
        // this will make it seem fluid from realtime to prediction
        if (index === 0) {
          processSummaryItem = {
            name: index,
            value: [new Date(+sessionStartTimestamp), currentTemp],
            phase: item.phase
          };
          processedProcessSummary.push(processSummaryItem);
        }

        if (duration !== 0) {
          // If process item is in fermentation, also draw begin temp
          if (chartUtil.phaseIsFermenting(item)) {
            const secondaryTimeStamp = new Date(
              +sessionStartTimestamp -
                currentTimeInState +
                currentTimestamp * 1000
            );
            if (secondaryTimeStamp.getTime() < frequentlog.timestampMs) {
              processSummaryItem = {
                name: index,
                value: [new Date(frequentlog.timestampMs + 1), startTemp],
                phase: item.phase
              };
              processedProcessSummary.push(processSummaryItem);
            } else {
              processSummaryItem = {
                name: index,
                value: [secondaryTimeStamp, startTemp],
                phase: item.phase
              };
              processedProcessSummary.push(processSummaryItem);
            }
          }
          const timeStamp = new Date(
            +sessionStartTimestamp -
              currentTimeInState +
              (currentTimestamp + duration) * 1000
          );
          if (timeStamp.getTime() > frequentlog.timestampMs) {
            processSummaryItem = {
              name: index,
              value: [timeStamp, endTemperature],
              phase: item.phase
            };
            processedProcessSummary.push(processSummaryItem);
          }
        }

        const mashMarkline = chartUtil.generateMarklineMashing(
          item,
          new Date(
            +sessionStartTimestamp -
              currentTimeInState +
              currentTimestamp * 1000
          )
        );
        if (mashMarkline) mashingMarklines.push(mashMarkline);

        currentTimestamp = currentTimestamp + duration;
      });

      return [processedProcessSummary, mashingMarklines];
    }
  },
  phaseIsFermenting: processSummaryItem => {
    const { phase } = processSummaryItem;
    return (phase >= 5 && phase <= 7) || phase === 12;
  },
  generateMarklineMashing: (item, currentTimestamp) => {
    let mashStepItem = {};
    if (item.state === PROCESS_STATES.MASHING_MAINTAIN_STATE) {
      mashStepItem = {
        xAxis: currentTimestamp,
        label: {
          formatter: `${item.startTemperature &&
            item.startTemperature.toFixed()}°C`,
          position: "middle"
        }
      };
      return mashStepItem;
    } else if (
      item.state === PROCESS_STATES.LAUTERING_STATE ||
      item.state === PROCESS_STATES.SECONDARY_LAUTERING_STATE
    ) {
      mashStepItem = {
        xAxis: currentTimestamp,
        label: {
          formatter: "Lautering",
          position: "middle"
        }
      };
      return mashStepItem;
    }
    return null;
  },
  filterOutOfRangeData: (maxValue, items) =>
    items.map(item => {
      if (item && item.value && item.value[1] > maxValue) {
        item.value[1] = maxValue;
      }
      return item;
    })
};
export default chartUtil;

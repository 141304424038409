import React from "react";
import "./privacy-policy-content.scss";

const PrivacyPolicyContent = () => {
  return (
    <div className="privacy-policy-content">
      <div>
        <div>
          <h2>Privacy and Data Statement</h2>

          <p>Effective date: October 27, 2023</p>
        </div>

        <p>
          By using our website or by contacting us, we receive certain information from you. We attach great importance to the protection of your personal data and always handle your data with care. With this privacy statement, we want to inform you about the processing of personal data.
        </p>

        <h1>Which personal data can we process?</h1>
        <h2>When you use our services or contact us:</h2>
        <ul>
          <li>Contact details, like your name, address, email address and telephone number;</li>
          <li>Date of birth;</li>
          <li>Financial data, such as the payment type you chose and when you paid;</li>
          <li>Order details, which products you want to buy and have already bought from us;</li>
          <li>Account information (username and password); and</li>
          <li>other relevant information.</li>
        </ul>
        <h2>When you visit our website:</h2>
        <p>We use cookies to make our website work properly. A cookie is a small piece of data that is placed on your
          device during your visit to our website. Information about how you use our website is stored in such a cookie.
          Please see the following links for more information about the cookies we use and the data they process<span>
          </span>
          <insert link="" cookiestatement="">.</insert>
        </p>
        <h2>When you use our app:</h2>
        <ul>
          <li>Your identity;</li>
          <li>Your username;</li>
          <li>Your password;</li>
          <li>Your location;</li>
          <li>Data camera notifications;</li>
          <li>Mobile data;</li>
          <li>Your device ID;</li>
          <li>App history;</li>
          <li>Data from your MiniBrew machinery. For more information about how we collect and use information from our
            app, we refer you to the privacy policy of our app.</li>
        </ul>


        <h1>Lawfulness of processing</h1>
        <p>We may only process personal data if we have a legal basis for this. We base ourselves on the following
          principles:</p>
        <ol>
          <li>Consent;</li>
          <li>Contract;</li>
          <li>Legal obligation;</li>
          <li>Our legitimate interests such as, marketing purposes and improving our services. If there is a legitimate
            interest, we will of course check whether such interest does not override your rights or interests.</li>
        </ol>




        <h1>Use of your data</h1>
        <p>We can use your personal data to:</p>
        <ul>
          <li>identify you as a person and to address you by your name;</li>
          <li>execute the agreement we have with you, including the delivery of products and payment processing;</li>
          <li>respond to your comments, complaints or inquiries;</li>
          <li>provide customer care and support;</li>
          <li>make our website work properly and improve our services;</li>
          <li>offer promotional communications about our products and services;</li>
          <li>save and handle potential job applications;</li>
          <li>meet legal obligations if applicable, such as collecting information that is necessary for tax return;
          </li>
          <li>protect, investigate, and deter against fraudulent, unauthorized, or illegal activities.</li>
        </ul>
        <h2>Links on website</h2>
        <p>Our website may contain links to third-party websites that are not owned or controlled by MINIBREW. We have
          no control on how third parties handle your data. We advise you therefor to read their privacy statements
          before you use their website or services.</p>
        <h2>Klaviyo</h2>
        <p>klaviyo is a tool for the collection and processing of your (personal) data. We use klaviyo because we
          consider it important that the content on our website and/or our marketing activities is/are tailored to your
          specific needs. We use klaviyo for the following marketing purposes:</p>
        <ul>
          <li>Optimizing and improving our website or the findability of our website in search engines (Search Engine
            Optimization);</li>
          <li>Participating in those social media channels that you use and analyzing the results of our social media
            activities (Social Media Management);</li>
          <li>Optimizing the results of our landing pages; Analyzing the interactions you have with our organization,
            for example by visiting our website or accessing our email messages (Lead Scoring and Workflows);</li>
          <li>To inform you by e-mail, telephone or personally about products and services that may be of interest to
            you;</li>
          <li>Calculating our turnover back to our marketing campaigns (Closed Loop Analytics);</li>
          <li>Optimizing and improving the content on our website (Content Management);</li>
          <li>Sending newsletters and automated updates (E-mail marketing). If you subscribe to our newsletter, we can
            also analyze your visits via HubSpot by means of additional information, such as your name and email
            address, and provide you with targeted information about your favorite topics.</li>
        </ul>
        <p>The (personal) data collected by us via klaviyo will only be used to deliver and optimize our marketing
          activities. klaviyo processes personal data on two different ways:</p>
        <ol>
          <li>You are an anonymous visitor. In this case, we do not see which pages you visit. We can, however, analyze
            how often a page is viewed on our website. In this way we can pay attention to the most important parts of
            our website. Information we collect is your IP address and IP location.</li>
          <li>You are a registered visitor. This may be the case if you have provided us with your data, for example by
            subscribing to the newsletter. In this case, we automatically create and maintain a personal profile, which
            allows us to analyze which pages you visit, which newsletters you have opened and what you have clicked on.
          </li>
        </ol>




        <h1>Disclosure to third parties</h1>
        <p>We may also disclose your information to third parties if we determine that such disclosure is legally
          required or reasonably necessary for the execution of the agreement. Relevant examples of such disclosure
          include submitting your personal data to a third party that is responsible for executing an agreement or using
          this data to accurately respond to questions or complaints. Furthermore, your data will only be passed on to
          persons or bodies outside the European Economic Area (EEA) if the country in question has an appropriate level
          of data protection.</p>
        <h2>Shipment</h2>
        <p>We use third parties to send your order. They need to know where they can deliver your order. Therefor we
          share your address details and e-mail address with them so that they can deliver your order and keep you
          informed about the shipment.</p>
        <h2>Payment</h2>
        <p>We use third parties to process your payment. They will let us know when and if you payed to outstanding
          amount and other relevant details such as a payment reference.</p>
        <p>In order to offer you Klarna’s payment methods, we might in the checkout pass your personal data in the form
          of contact and order details to Klarna, in order for Klarna to assess whether you qualify for their payment
          methods and to tailor those payment methods for you. Your personal data transferred is processed in line
          with<span> </span><a href="https://www.klarna.com/international/privacy-policy/">Klarna’s own privacy
            notice</a></p>
        <p>In Switzerland, Klarna offers its services together with its subsidiary Billpay. If you share personal data
          with Klarna and Billpay you need to explain this in your privacy notice and link to Klarna’s and Billpay’s
          local privacy notices there. Below is an example of what that could look like in your existing privacy notice
          (the specific data categories transferred to be added, and text to be translated into your local language):
        </p>
        <p>Billpay’s/Klarna’s payment options: In order to be able to offer you Klarna’s payment options and to assess
          whether you qualify for their payment options and to tailor the payment options for you, we might in the
          checkout pass your personal data in the form of contact and order details to Klarna and Billpay. Your personal
          data is handled in accordance with applicable data protection law and in accordance with the information in
          Klarna’s (<span> </span><a
            href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/de_ch/privacy_bp">de</a><span> </span>,<span>
          </span><a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/fr_ch/privacy_bp">fr</a><span>
          </span>,<span> </span><a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/it_ch/privacy_bp">it</a><span>
          </span>or<span>
          </span><a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_ch/privacy_bp">en</a><span>
          </span>versions) and Billpay’s (<span> </span><a
            href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/de_ch/invoice">de</a><span> </span>,<span>
          </span><a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/EID/fr_ch/invoice">fr</a><span>
          </span>,<span> </span><a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/EID/it_ch/invoice">it</a><span>
          </span>or<span>
          </span><a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/EID/en_ch/invoice">en</a><span>
          </span>versions) privacy notices.</p>




        <h1>Special and/or sensitive personal data</h1>
        <p>We do not intend to collect data of our website visitors who are younger than 16 years of age, unless the
          parents or guardian granted permission for this data collection. However, we cannot check whether a website
          visitor is older than 16 years. For this reason, we encourage parents to be involved in the online activities
          of their children, in order to prevent the collection of data without parental consent. If you are convinced
          that we have collected personal information about a minor without this permission, please contact us using the
          contact details below. We will then make sure that this data will be deleted.</p>



        <h1>Data retention</h1>
        <p>We will retain your personal information for the period necessary to fulfill the purposes outlined in this
          privacy policy and will not store this information for more than two (2) years after the data is no longer
          necessary or those purposes, provided that no legal requirements exist to the contrary such as in the case of
          retention periods required by law.</p>



        <h1>Your rights regarding your personal information</h1>
        <p>We would like to inform you of your rights concerning the collection and use of your data. Under European
          law, individuals have the right to:</p>
        <ul>
          <li>
            Access their personal data
            <p>Individuals have the right to access the personal data we collect and may obtain a copy of this data by
              contacting us.</p>
          </li>
          <li>
            Right to rectification
            <p>Individuals have the right to have personal data rectified if it is incomplete and/or erroneous and can
              make a request for rectification. You can change (some of) your personal data in your account by editing
              your profile or by contacting us.</p>
          </li>
          <li>
            Right to erasure ('right to be forgotten')
            <p>In some circumstances, you have the “right to be forgotten”. Those circumstances include:</p>
            <ul>
              <li>The personal data are no longer necessary in relation to the purposes for which they were collected
                and/or processed;</li>
              <li>You withdraw the (explicit) consent you previously provided to process the information, and there is
                no other legal ground for the processing;</li>
              <li>You object to the processing of your personal data and have legitimate, compelling reasons which
                supersede our interests and reasons for processing your data;</li>
              <li>You object to your personal data being processed for direct marketing purposes;</li>
              <li>There are (no longer) any legal grounds for processing your data;</li>
              <li>We are obligated to delete your personal data after the time limits set by laws or other regulations;
              </li>
              <li>You are below the age of 16 and our website has collected your personal data.</li>
              <li>
                <span data-mce-fragment="1">If you would like to exercise any of these rights in our e-commerce shop or
                  our iOS App Store and Google Play Store app, MiniBrew, please get in touch with us using the contact
                  details provided below</span>.
              </li>
              <li>
                <span>MINIBREW B.V. </span><br /><span>Atoomweg 63</span><br /><span>3542AA
                  Utrecht</span><br /><span>hello@minibrew.io</span>
              </li>
              <li>
                <span>After your customer data is removed, including your identity (username, password, and name), it
                  will be permanently deleted</span>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            Right to restriction of processing
            <p>Individuals reserve the right to minimize the processing of your personal data. If we are informed that
              we are using incorrect personal data, we will not use this data until it has been rectified. We will
              furthermore restrict processing data if the processing is either unlawful or no longer necessary, or you
              oppose the erasure of your personal data and instead request the restriction of their use.</p>
          </li>
          <li>
            Right to data portability
            <p>You reserve the right to data portability, i.e. to receive the personal data concerning you that you have
              provided to us.</p>
          </li>
          <li>
            Right to object
            <p>You have in some cases the right to object to the processing of your personal data, for example because
              of your specific situation or against direct marketing.</p>
          </li>
        </ul>
        <p> </p>
        <p> </p>



        <h2>Contact</h2>
        <p>MINIBREW B.V.<br />Atoomweg 63<br />3542AA Utrecht<br />hello@minibrew.io</p>
        <h2>Complaints</h2>
        <p>If you are unhappy with the way we collect, store, or process your personal data, please contact us. If the
          situation is not resolved to your satisfaction, you may file a complaint with the Dutch Data Protection
          Authority (DPA).</p>
        <h2>Updates to our privacy policy</h2>
        <p>We reserve the right to adjust this privacy statement. If there are any changes, we will publish them on our
          website.</p>


      </div>
    </div>
  );
};

export default PrivacyPolicyContent;

import React from "react";
import { Button } from "react-bootstrap";
import { baseImage, kegImage } from "@common/constants/imageLink";
import hardware_pb from "@util/protobuf/hardware_pb";

const UserActionBar = ({ device, handleClick }) => {
  const isKeg = device.device_type === hardware_pb.MachineType.MACH_TYPE_KEG;
  const deviceImage = isKeg ? kegImage : baseImage;
  return (
    <div className="user-action-item">
      <div className="user-action-item__icon">
        <span className="ion-alert" />
      </div>
      <div className="user-action-item__device-icon">
        <img
          src={deviceImage}
          alt="Minibrew"
          className="user-action-item__device-image img-circle"
        />
      </div>
      <div className="user-action-item__content">
        <div className="user-action-item__device">
          {device.custom_name ? device.custom_name : "MiniBrew"}
          <Button
            className="pull-right user-action-item__button"
            bsStyle="danger"
            onClick={handleClick}
          >
            Go <span className="ion-chevron-right" />
          </Button>
        </div>
        <div className="user-action-item__message">
          Needs a user action right now!
        </div>
      </div>
    </div>
  );
};

export default UserActionBar;

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import PanelManager from "./PanelManager/PanelManager";
import TitleBar from "@common/components/TitleBar/TitleBar";
import LoadingSession from "./LoadingSession/LoadingSession";
import KegCleaning from "./KegCleaning/KegCleaning";
import * as sessionService from "@brewery/services/sessionService";
import * as mqttActions from "@common/actions/mqttActions";
import mqttService from "../../mqttService";
import mqttUtil from "../../mqttUtil";
import { routes } from "@common/constants/urls";
import {
  ACTIVE_SESSION_STATES,
  INACTIVE_SESSION_STATES
} from "@common/constants/sessionConstants";
import { isCleaningSession } from "@monitor/util";
import "./monitoring.scss";

/**
 * Manages the fetching of the session and passes the session down
 * to child components
 * @param {Object} props
 */
const Monitor = props => {
  const [session, setSession] = useState(null);
  const [fetchingSession, setFetchingSession] = useState(true);
  let currentSession = null;
  let notificationSubscription = null;
  const sessionID = props.match.params.id;

  useEffect(() => {
    fetchSession();
    listenToSessionStateChange();
    return () => {
      notificationSubscription.unsubscribe();
    };
  }, [sessionID]);

  const fetchSession = () => {
    props.ignoreNotifications(sessionID);

    sessionService.getSessionById(sessionID).then(response => {
      setFetchingSession(false);

      // Check if the new session state is inactive and the previous session state was active
      // if true, this means that the session got finished/failed
      if (
        currentSession !== null &&
        ACTIVE_SESSION_STATES.includes(currentSession.status) &&
        INACTIVE_SESSION_STATES.includes(response.status)
      ) {
        props.routeTo(routes.brewery);
      } else {
        setSession(response);
        currentSession = response;
      }
    });
  };

  const listenToSessionStateChange = () => {
    notificationSubscription = mqttService.notification$.subscribe(
      notification => {
        if (currentSession && currentSession.id === notification.session) {
          if (mqttUtil.sessionStateChanged(notification)) {
            mqttService.unsubscribeAllFrequentLog();
            fetchSession();
          }
        }
      }
    );
  };

  const sessionCleaning = isCleaningSession(session);

  const subtitle = () => {
    if (sessionCleaning) {
      return "";
    } else if (session && session.is_brewpack) {
      return (
        <div
          style={{
            display: "flex",
            padding: "0px 15px"
          }}
        >
          <div>
            <img
              className="circular--landscape"
              src={session.beer.image}
              alt="beerimage"
            />
          </div>
          <div
            style={{
              alignSelf: "center",
              marginLeft: "10px",
              textAlign: "left",
              fontStyle: "normal"
            }}
          >
            <div> {session.beer.name}</div>
            <small style={{ fontFamily: "Gotham-Book" }}>
              {session.beer.style_name}
            </small>
          </div>
        </div>
      );
    } else if (session) {
      return `${session.beer.name} version ${session.beer_recipe_version} on ${
        session.device.device_type === 0
          ? session.device.custom_name
          : session.device.uuid
      }`;
    }
  };

  const kegNeedsCleaning = session ? session.device.user_action === 38 : false;

  return (
    <div>
      <TitleBar
        title={sessionCleaning ? "CLEANING" : "MONITORING"}
        subtitle={subtitle()}
      >
        {session && !session.is_brewpack && !sessionCleaning && (
          <Button
            style={{ position: "absolute", right: "20px" }}
            bsStyle="warning"
            onClick={() =>
              props.routeTo(
                routes.recipeDetailsWithParams(
                  session.beer.id,
                  session.beer_recipe_version
                )
              )
            }
          >
            RECIPE
          </Button>
        )}
      </TitleBar>
      {kegNeedsCleaning ? (
        <KegCleaning session={session} kegNeedsCleaning={kegNeedsCleaning} />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-lg-10 col-lg-offset-1">
              {fetchingSession && <LoadingSession />}
              <PanelManager session={session} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    ignoreNotifications: sessionID =>
      dispatch(mqttActions.ignoreNotifications(sessionID)),
    routeTo: route => dispatch(push(route)),
    dispatch: action => dispatch(action)
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Monitor);

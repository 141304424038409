import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Modal } from "react-bootstrap";
import { post, put } from "@common/services/request";
import BeerForm from "./BeerForm/BeerForm";
import { routes, apiEndpoints as api } from "@common/constants/urls";

class BeerModal extends Component {
  state = {
    isLoading: false,
    error: false,
    beer: this.props.beer || null
  };

  saveBeer = formData => {
    const beerData = { ...formData };
    const { dispatch, beer, updateBeer } = this.props;
    const isNewBeer = !beer;
    const method = isNewBeer ? post : put;
    const beerId = beer ? beer.id + "/" : "";
    if (beer) beerData.id = beer.id;
    this.setState({ isLoading: true });
    method(`${api.beers}${beerId}`, beerData)
      .then(res => {
        this.setState({ isLoading: false, error: false });
        if (isNewBeer) dispatch(push(`${routes.beers}${res.id}/edit/new`));
        else updateBeer(beerData);
      })
      .catch(() => this.setState({ isLoading: false, error: true }));
  };

  render() {
    const { beer, show, hide } = this.props;
    const initialValues = beer
      ? {
          name: beer.name,
          style: beer.style.id,
          styleName: beer.style.name
        }
      : {};
    return (
      <Modal show={show} onHide={hide}>
        <BeerForm
          onSubmit={this.saveBeer}
          closeModal={hide}
          error={this.state.error}
          isLoading={this.state.isLoading}
          initialValues={initialValues}
          title={(this.props.beer && "EDIT BEER") || "CREATE A NEW BEER"}
        />
      </Modal>
    );
  }
}

export default connect()(BeerModal);

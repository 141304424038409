import React from "react";
import { object, func, string } from "prop-types";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";

import ProfileDropdownMenu from "./ProfileDropdownMenu";
import ProfileDropdownToggle from "./ProfileDropdownToggle";
import "./profile-dropdown.scss";

/**
 * Custom dropdown, see: https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
 */
class ProfileDropdown extends React.Component {
  render() {
    const { userInfo, signOut, currentPath } = this.props;
    return (
      <div className="header__account__wrapper">
        <Dropdown id="dropdown-custom-menu">
          <ProfileDropdownToggle bsRole="toggle">
            {!userInfo.user.image ? (
              <div className="header__account__icon">
                <span className="ion-person profile-icon" />
              </div>
            ) : (
              <img
                className="header__account__avatar"
                src={userInfo.user.image}
                alt=""
              />
            )}
          </ProfileDropdownToggle>
          <ProfileDropdownMenu
            pathName={currentPath}
            bsRole="menu"
            handleSignOut={signOut}
          />
        </Dropdown>
      </div>
    );
  }
}

function mapStateToProps({ userInfo, routing }) {
  return {
    userInfo,
    currentPath: routing.location.pathname
  };
}

function mapDispatchToProps() {
  return {};
}

ProfileDropdown.propTypes = {
  userInfo: object,
  signOut: func.isRequired,
  currentPath: string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDropdown);

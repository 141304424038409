import React from "react";
import { Button, Well } from "react-bootstrap";
import { FieldArray } from "redux-form";

import Fermentable from "./Fermentable/Fermentable";
import Step from "../../StageStep/StageStep";
import Container from "@common/components/Container/Container";
import {
  required,
  numberBetween
} from "../../../../../util/formValidationFunctions";
import { VALIDATION as V } from "@common/constants/recipeConstants";
import { normalizeNumber } from "../../../../../util/recipeHelper";
import ErrorList from "../ErrorList";

const normalizeTemp = normalizeNumber(2, 1);
const normalizeDuration = normalizeNumber(3, 0);

const mashingStepTemp = numberBetween(V.MIN_MASH_TEMP, V.MAX_MASH_TEMP);
const mashingStepDuration = numberBetween(
  V.MIN_MASH_STEP_DURATION,
  V.MAX_MASH_STEP_DURATION
);

class mashing extends React.Component {
  state = {
    fermentableAmountError: [false, false, false]
  };

  render() {
    const {
      fields,
      change,
      validationEnabled,
      efficiency,
      disableAddStages,
      meta: { error }
    } = this.props;
    return (
      <Container data-name="mashing">
        <h2>Mashing</h2>
        <Button
          className="AddButton"
          type="button"
          onClick={() =>
            fields.push({
              mash_in_water: null,
              ingredient_additions: [{ ingredient_type: "FERM" }],
              steps: [{ temperature: null, duration: null }]
            })
          }
          disabled={disableAddStages}
        >
          <i className="icon ion-plus" /> MASHING STAGE
        </Button>
        <p>
          System efficiency:{" "}
          {`${isNaN(efficiency * 100) ? "-" : (efficiency * 100).toFixed(1)} %`}
        </p>
        <p className="instruction">
          The MiniBrew is capable of mashing multiple times to achieve higher
          gravity beers. The minimum amount of grain that must be in a mashing
          step is 1.2 kg DBCG, and the maximum that may be included in a single
          mash step is 2.3 kg DBCG. The system efficiency will vary between 55
          and 70% based on the type of grain used and mass of the grains across
          all mashing steps.
        </p>
        {fields.map((stage, index) => (
          <Well className="Stage" key={index}>
            <h3>Mash Stage {index + 1}</h3>
            {!index ? null : (
              <Button
                className="TopRightBtn"
                title="Remove boiling stage"
                onClick={() => fields.remove(index)}
              >
                <i className="icon ion-close" />
              </Button>
            )}
            <div>
              <FieldArray
                name={`${stage}.ingredient_additions`}
                component={Fermentable}
                rerenderOnEveryChange={true}
                change={change}
                stageIndex={index}
              />
            </div>
            <div>
              <h3>Mashing Scheme</h3>
              <FieldArray
                name={`${stage}.steps`}
                component={Step}
                durationUnits="min"
                stageLabel="Mash"
                validation={
                  validationEnabled && {
                    temperature: [required, mashingStepTemp],
                    duration: [required, mashingStepDuration]
                  }
                }
                normalization={{
                  temperature: normalizeTemp,
                  duration: normalizeDuration
                }}
              />
            </div>
            <div className="clearfix" />
          </Well>
        ))}
        <ErrorList error={error} />
      </Container>
    );
  }
}

export default mashing;

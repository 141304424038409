// source: recipe.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

goog.exportSymbol("proto.BoilingStage", null, global);
goog.exportSymbol("proto.BrewRecipe", null, global);
goog.exportSymbol("proto.FermentationStep", null, global);
goog.exportSymbol("proto.FermentationStepType", null, global);
goog.exportSymbol("proto.IngredientAddition", null, global);
goog.exportSymbol("proto.MashingStage", null, global);
goog.exportSymbol("proto.PressureReliefType", null, global);
goog.exportSymbol("proto.TemperatureStep", null, global);
goog.exportSymbol("proto.Trigger", null, global);
goog.exportSymbol("proto.Trigger.TriggerType", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Trigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Trigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Trigger.displayName = "proto.Trigger";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TemperatureStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TemperatureStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TemperatureStep.displayName = "proto.TemperatureStep";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IngredientAddition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IngredientAddition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IngredientAddition.displayName = "proto.IngredientAddition";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MashingStage = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.MashingStage.repeatedFields_,
    null
  );
};
goog.inherits(proto.MashingStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MashingStage.displayName = "proto.MashingStage";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BoilingStage = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.BoilingStage.repeatedFields_,
    null
  );
};
goog.inherits(proto.BoilingStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BoilingStage.displayName = "proto.BoilingStage";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FermentationStep = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.FermentationStep.repeatedFields_,
    null
  );
};
goog.inherits(proto.FermentationStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FermentationStep.displayName = "proto.FermentationStep";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BrewRecipe = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.BrewRecipe.repeatedFields_,
    null
  );
};
goog.inherits(proto.BrewRecipe, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BrewRecipe.displayName = "proto.BrewRecipe";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Trigger.prototype.toObject = function(opt_includeInstance) {
    return proto.Trigger.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Trigger} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Trigger.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        value: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Trigger}
 */
proto.Trigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Trigger();
  return proto.Trigger.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Trigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Trigger}
 */
proto.Trigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.Trigger.TriggerType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Trigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Trigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Trigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Trigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.Trigger.TriggerType} */ (jspb.Message.getField(
    message,
    1
  ));
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(2, f);
  }
};

/**
 * @enum {number}
 */
proto.Trigger.TriggerType = {
  DURATION: 0,
  ACTIVITY_STARTED: 1,
  ACTIVITY_STOPPED: 3
};

/**
 * optional TriggerType type = 1;
 * @return {!proto.Trigger.TriggerType}
 */
proto.Trigger.prototype.getType = function() {
  return /** @type {!proto.Trigger.TriggerType} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ));
};

/**
 * @param {!proto.Trigger.TriggerType} value
 * @return {!proto.Trigger} returns this
 */
proto.Trigger.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Trigger} returns this
 */
proto.Trigger.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Trigger.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint32 value = 2;
 * @return {number}
 */
proto.Trigger.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.Trigger} returns this
 */
proto.Trigger.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Trigger} returns this
 */
proto.Trigger.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Trigger.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.TemperatureStep.prototype.toObject = function(opt_includeInstance) {
    return proto.TemperatureStep.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.TemperatureStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.TemperatureStep.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        startTemperature:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null
            ? undefined
            : f,
        endTemperature:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null
            ? undefined
            : f,
        endtrigger:
          (f = msg.getEndtrigger()) &&
          proto.Trigger.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TemperatureStep}
 */
proto.TemperatureStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TemperatureStep();
  return proto.TemperatureStep.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TemperatureStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TemperatureStep}
 */
proto.TemperatureStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setStartTemperature(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setEndTemperature(value);
        break;
      case 2:
        var value = new proto.Trigger();
        reader.readMessage(value, proto.Trigger.deserializeBinaryFromReader);
        msg.setEndtrigger(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TemperatureStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TemperatureStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TemperatureStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TemperatureStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(3, f);
  }
  f = message.getEndtrigger();
  if (f != null) {
    writer.writeMessage(2, f, proto.Trigger.serializeBinaryToWriter);
  }
};

/**
 * optional float start_temperature = 1;
 * @return {number}
 */
proto.TemperatureStep.prototype.getStartTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    1,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.TemperatureStep} returns this
 */
proto.TemperatureStep.prototype.setStartTemperature = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.TemperatureStep} returns this
 */
proto.TemperatureStep.prototype.clearStartTemperature = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TemperatureStep.prototype.hasStartTemperature = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional float end_temperature = 3;
 * @return {number}
 */
proto.TemperatureStep.prototype.getEndTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    3,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.TemperatureStep} returns this
 */
proto.TemperatureStep.prototype.setEndTemperature = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.TemperatureStep} returns this
 */
proto.TemperatureStep.prototype.clearEndTemperature = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TemperatureStep.prototype.hasEndTemperature = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Trigger endTrigger = 2;
 * @return {?proto.Trigger}
 */
proto.TemperatureStep.prototype.getEndtrigger = function() {
  return /** @type{?proto.Trigger} */ jspb.Message.getWrapperField(
    this,
    proto.Trigger,
    2
  );
};

/**
 * @param {?proto.Trigger|undefined} value
 * @return {!proto.TemperatureStep} returns this
 */
proto.TemperatureStep.prototype.setEndtrigger = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.TemperatureStep} returns this
 */
proto.TemperatureStep.prototype.clearEndtrigger = function() {
  return this.setEndtrigger(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TemperatureStep.prototype.hasEndtrigger = function() {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.IngredientAddition.prototype.toObject = function(opt_includeInstance) {
    return proto.IngredientAddition.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.IngredientAddition} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.IngredientAddition.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        insertionTime:
          (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        contactDuration:
          (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IngredientAddition}
 */
proto.IngredientAddition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IngredientAddition();
  return proto.IngredientAddition.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IngredientAddition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IngredientAddition}
 */
proto.IngredientAddition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setInsertionTime(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setContactDuration(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IngredientAddition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IngredientAddition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IngredientAddition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IngredientAddition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(2, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(3, f);
  }
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.IngredientAddition.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.IngredientAddition} returns this
 */
proto.IngredientAddition.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.IngredientAddition} returns this
 */
proto.IngredientAddition.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IngredientAddition.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint32 insertion_time = 2;
 * @return {number}
 */
proto.IngredientAddition.prototype.getInsertionTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.IngredientAddition} returns this
 */
proto.IngredientAddition.prototype.setInsertionTime = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.IngredientAddition} returns this
 */
proto.IngredientAddition.prototype.clearInsertionTime = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IngredientAddition.prototype.hasInsertionTime = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional uint32 contact_duration = 3;
 * @return {number}
 */
proto.IngredientAddition.prototype.getContactDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.IngredientAddition} returns this
 */
proto.IngredientAddition.prototype.setContactDuration = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.IngredientAddition} returns this
 */
proto.IngredientAddition.prototype.clearContactDuration = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IngredientAddition.prototype.hasContactDuration = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MashingStage.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.MashingStage.prototype.toObject = function(opt_includeInstance) {
    return proto.MashingStage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.MashingStage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.MashingStage.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        stepsList: jspb.Message.toObjectList(
          msg.getStepsList(),
          proto.TemperatureStep.toObject,
          includeInstance
        ),
        sparging:
          (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MashingStage}
 */
proto.MashingStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MashingStage();
  return proto.MashingStage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MashingStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MashingStage}
 */
proto.MashingStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setId(value);
        break;
      case 2:
        var value = new proto.TemperatureStep();
        reader.readMessage(
          value,
          proto.TemperatureStep.deserializeBinaryFromReader
        );
        msg.addSteps(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSparging(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MashingStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MashingStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MashingStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MashingStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.TemperatureStep.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(3, f);
  }
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.MashingStage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.MashingStage} returns this
 */
proto.MashingStage.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.MashingStage} returns this
 */
proto.MashingStage.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MashingStage.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated TemperatureStep steps = 2;
 * @return {!Array<!proto.TemperatureStep>}
 */
proto.MashingStage.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.TemperatureStep>} */ jspb.Message.getRepeatedWrapperField(
    this,
    proto.TemperatureStep,
    2
  );
};

/**
 * @param {!Array<!proto.TemperatureStep>} value
 * @return {!proto.MashingStage} returns this
 */
proto.MashingStage.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.TemperatureStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TemperatureStep}
 */
proto.MashingStage.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.TemperatureStep,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MashingStage} returns this
 */
proto.MashingStage.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};

/**
 * optional bool sparging = 3;
 * @return {boolean}
 */
proto.MashingStage.prototype.getSparging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    3,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.MashingStage} returns this
 */
proto.MashingStage.prototype.setSparging = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.MashingStage} returns this
 */
proto.MashingStage.prototype.clearSparging = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MashingStage.prototype.hasSparging = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BoilingStage.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.BoilingStage.prototype.toObject = function(opt_includeInstance) {
    return proto.BoilingStage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.BoilingStage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.BoilingStage.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        duration: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        additionsList: jspb.Message.toObjectList(
          msg.getAdditionsList(),
          proto.IngredientAddition.toObject,
          includeInstance
        ),
        boilTemp:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null
            ? undefined
            : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BoilingStage}
 */
proto.BoilingStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BoilingStage();
  return proto.BoilingStage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BoilingStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BoilingStage}
 */
proto.BoilingStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setDuration(value);
        break;
      case 3:
        var value = new proto.IngredientAddition();
        reader.readMessage(
          value,
          proto.IngredientAddition.deserializeBinaryFromReader
        );
        msg.addAdditions(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setBoilTemp(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BoilingStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BoilingStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BoilingStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BoilingStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(2, f);
  }
  f = message.getAdditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.IngredientAddition.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(4, f);
  }
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.BoilingStage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.BoilingStage} returns this
 */
proto.BoilingStage.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.BoilingStage} returns this
 */
proto.BoilingStage.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BoilingStage.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint32 duration = 2;
 * @return {number}
 */
proto.BoilingStage.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.BoilingStage} returns this
 */
proto.BoilingStage.prototype.setDuration = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.BoilingStage} returns this
 */
proto.BoilingStage.prototype.clearDuration = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BoilingStage.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * repeated IngredientAddition additions = 3;
 * @return {!Array<!proto.IngredientAddition>}
 */
proto.BoilingStage.prototype.getAdditionsList = function() {
  return /** @type{!Array<!proto.IngredientAddition>} */ jspb.Message.getRepeatedWrapperField(
    this,
    proto.IngredientAddition,
    3
  );
};

/**
 * @param {!Array<!proto.IngredientAddition>} value
 * @return {!proto.BoilingStage} returns this
 */
proto.BoilingStage.prototype.setAdditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.IngredientAddition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IngredientAddition}
 */
proto.BoilingStage.prototype.addAdditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.IngredientAddition,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BoilingStage} returns this
 */
proto.BoilingStage.prototype.clearAdditionsList = function() {
  return this.setAdditionsList([]);
};

/**
 * optional float boil_temp = 4;
 * @return {number}
 */
proto.BoilingStage.prototype.getBoilTemp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    4,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.BoilingStage} returns this
 */
proto.BoilingStage.prototype.setBoilTemp = function(value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.BoilingStage} returns this
 */
proto.BoilingStage.prototype.clearBoilTemp = function() {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BoilingStage.prototype.hasBoilTemp = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.FermentationStep.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.FermentationStep.prototype.toObject = function(opt_includeInstance) {
    return proto.FermentationStep.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.FermentationStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.FermentationStep.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        temperature:
          (f = msg.getTemperature()) &&
          proto.TemperatureStep.toObject(includeInstance, f),
        additionsList: jspb.Message.toObjectList(
          msg.getAdditionsList(),
          proto.IngredientAddition.toObject,
          includeInstance
        ),
        type: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        pressureRelief:
          (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FermentationStep}
 */
proto.FermentationStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FermentationStep();
  return proto.FermentationStep.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FermentationStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FermentationStep}
 */
proto.FermentationStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setId(value);
        break;
      case 2:
        var value = new proto.TemperatureStep();
        reader.readMessage(
          value,
          proto.TemperatureStep.deserializeBinaryFromReader
        );
        msg.setTemperature(value);
        break;
      case 3:
        var value = new proto.IngredientAddition();
        reader.readMessage(
          value,
          proto.IngredientAddition.deserializeBinaryFromReader
        );
        msg.addAdditions(value);
        break;
      case 5:
        var value = /** @type {!proto.FermentationStepType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 6:
        var value = /** @type {!proto.PressureReliefType} */ (reader.readEnum());
        msg.setPressureRelief(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FermentationStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FermentationStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FermentationStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FermentationStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = message.getTemperature();
  if (f != null) {
    writer.writeMessage(2, f, proto.TemperatureStep.serializeBinaryToWriter);
  }
  f = message.getAdditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.IngredientAddition.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.FermentationStepType} */ (jspb.Message.getField(
    message,
    5
  ));
  if (f != null) {
    writer.writeEnum(5, f);
  }
  f = /** @type {!proto.PressureReliefType} */ (jspb.Message.getField(
    message,
    6
  ));
  if (f != null) {
    writer.writeEnum(6, f);
  }
};

/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.FermentationStep.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FermentationStep.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional TemperatureStep temperature = 2;
 * @return {?proto.TemperatureStep}
 */
proto.FermentationStep.prototype.getTemperature = function() {
  return /** @type{?proto.TemperatureStep} */ jspb.Message.getWrapperField(
    this,
    proto.TemperatureStep,
    2
  );
};

/**
 * @param {?proto.TemperatureStep|undefined} value
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.setTemperature = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.clearTemperature = function() {
  return this.setTemperature(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FermentationStep.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * repeated IngredientAddition additions = 3;
 * @return {!Array<!proto.IngredientAddition>}
 */
proto.FermentationStep.prototype.getAdditionsList = function() {
  return /** @type{!Array<!proto.IngredientAddition>} */ jspb.Message.getRepeatedWrapperField(
    this,
    proto.IngredientAddition,
    3
  );
};

/**
 * @param {!Array<!proto.IngredientAddition>} value
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.setAdditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.IngredientAddition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IngredientAddition}
 */
proto.FermentationStep.prototype.addAdditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.IngredientAddition,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.clearAdditionsList = function() {
  return this.setAdditionsList([]);
};

/**
 * optional FermentationStepType type = 5;
 * @return {!proto.FermentationStepType}
 */
proto.FermentationStep.prototype.getType = function() {
  return /** @type {!proto.FermentationStepType} */ (jspb.Message.getFieldWithDefault(
    this,
    5,
    0
  ));
};

/**
 * @param {!proto.FermentationStepType} value
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.setType = function(value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.clearType = function() {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FermentationStep.prototype.hasType = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional PressureReliefType pressure_relief = 6;
 * @return {!proto.PressureReliefType}
 */
proto.FermentationStep.prototype.getPressureRelief = function() {
  return /** @type {!proto.PressureReliefType} */ (jspb.Message.getFieldWithDefault(
    this,
    6,
    0
  ));
};

/**
 * @param {!proto.PressureReliefType} value
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.setPressureRelief = function(value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.FermentationStep} returns this
 */
proto.FermentationStep.prototype.clearPressureRelief = function() {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FermentationStep.prototype.hasPressureRelief = function() {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BrewRecipe.repeatedFields_ = [2, 4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.BrewRecipe.prototype.toObject = function(opt_includeInstance) {
    return proto.BrewRecipe.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.BrewRecipe} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.BrewRecipe.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        schemaversion:
          (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        mashingList: jspb.Message.toObjectList(
          msg.getMashingList(),
          proto.MashingStage.toObject,
          includeInstance
        ),
        boiling:
          (f = msg.getBoiling()) &&
          proto.BoilingStage.toObject(includeInstance, f),
        fermentationList: jspb.Message.toObjectList(
          msg.getFermentationList(),
          proto.FermentationStep.toObject,
          includeInstance
        ),
        servingTemperature:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null
            ? undefined
            : f,
        chillingTemperature:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 6)) == null
            ? undefined
            : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BrewRecipe}
 */
proto.BrewRecipe.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BrewRecipe();
  return proto.BrewRecipe.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BrewRecipe} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BrewRecipe}
 */
proto.BrewRecipe.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSchemaversion(value);
        break;
      case 2:
        var value = new proto.MashingStage();
        reader.readMessage(
          value,
          proto.MashingStage.deserializeBinaryFromReader
        );
        msg.addMashing(value);
        break;
      case 3:
        var value = new proto.BoilingStage();
        reader.readMessage(
          value,
          proto.BoilingStage.deserializeBinaryFromReader
        );
        msg.setBoiling(value);
        break;
      case 4:
        var value = new proto.FermentationStep();
        reader.readMessage(
          value,
          proto.FermentationStep.deserializeBinaryFromReader
        );
        msg.addFermentation(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setServingTemperature(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setChillingTemperature(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BrewRecipe.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BrewRecipe.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BrewRecipe} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BrewRecipe.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = message.getMashingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.MashingStage.serializeBinaryToWriter
    );
  }
  f = message.getBoiling();
  if (f != null) {
    writer.writeMessage(3, f, proto.BoilingStage.serializeBinaryToWriter);
  }
  f = message.getFermentationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.FermentationStep.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFloat(5, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeFloat(6, f);
  }
};

/**
 * optional uint32 schemaVersion = 1;
 * @return {number}
 */
proto.BrewRecipe.prototype.getSchemaversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.setSchemaversion = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.clearSchemaversion = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BrewRecipe.prototype.hasSchemaversion = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated MashingStage mashing = 2;
 * @return {!Array<!proto.MashingStage>}
 */
proto.BrewRecipe.prototype.getMashingList = function() {
  return /** @type{!Array<!proto.MashingStage>} */ jspb.Message.getRepeatedWrapperField(
    this,
    proto.MashingStage,
    2
  );
};

/**
 * @param {!Array<!proto.MashingStage>} value
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.setMashingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.MashingStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MashingStage}
 */
proto.BrewRecipe.prototype.addMashing = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.MashingStage,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.clearMashingList = function() {
  return this.setMashingList([]);
};

/**
 * optional BoilingStage boiling = 3;
 * @return {?proto.BoilingStage}
 */
proto.BrewRecipe.prototype.getBoiling = function() {
  return /** @type{?proto.BoilingStage} */ jspb.Message.getWrapperField(
    this,
    proto.BoilingStage,
    3
  );
};

/**
 * @param {?proto.BoilingStage|undefined} value
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.setBoiling = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.clearBoiling = function() {
  return this.setBoiling(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BrewRecipe.prototype.hasBoiling = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * repeated FermentationStep fermentation = 4;
 * @return {!Array<!proto.FermentationStep>}
 */
proto.BrewRecipe.prototype.getFermentationList = function() {
  return /** @type{!Array<!proto.FermentationStep>} */ jspb.Message.getRepeatedWrapperField(
    this,
    proto.FermentationStep,
    4
  );
};

/**
 * @param {!Array<!proto.FermentationStep>} value
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.setFermentationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.FermentationStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.FermentationStep}
 */
proto.BrewRecipe.prototype.addFermentation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    4,
    opt_value,
    proto.FermentationStep,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.clearFermentationList = function() {
  return this.setFermentationList([]);
};

/**
 * optional float serving_temperature = 5;
 * @return {number}
 */
proto.BrewRecipe.prototype.getServingTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    5,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.setServingTemperature = function(value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.clearServingTemperature = function() {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BrewRecipe.prototype.hasServingTemperature = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional float chilling_temperature = 6;
 * @return {number}
 */
proto.BrewRecipe.prototype.getChillingTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    6,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.setChillingTemperature = function(value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.BrewRecipe} returns this
 */
proto.BrewRecipe.prototype.clearChillingTemperature = function() {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.BrewRecipe.prototype.hasChillingTemperature = function() {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * @enum {number}
 */
proto.FermentationStepType = {
  PRIMARY: 0,
  SECONDARY: 2,
  CLARIFICATION: 1
};

/**
 * @enum {number}
 */
proto.PressureReliefType = {
  AIRLOCK: 0,
  BLOW_OFF: 1,
  NONE: 2
};

goog.object.extend(exports, proto);

import React, { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";

import * as sessionService from "@brewery/services/sessionService";
import "./control-session-modal.scss";

const ControlSessionModal = ({
  handleClose,
  kegNeedsCleaning,
  sessionID,
  show
}) => {
  const [waitingForNotification, setwaitingForNotification] = useState(false);
  const [error, setError] = useState(false);

  const handleError = () => {
    setwaitingForNotification(false);
    setError(true);
  };

  const stopSession = () => {
    if (kegNeedsCleaning) {
      setwaitingForNotification(true);
      sessionService
        .stopServing(sessionID)
        .then(() => {
          handleClose();
        })
        .catch(handleError);
    } else {
      setwaitingForNotification(true);
      sessionService
        .deleteSession(sessionID)
        .then(() => {
          handleClose();
        })
        .catch(handleError);
    }
  };

  return (
    <div className="static-modal">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span className="ion-android-alert stop-session__icon" />
            <div className="stop-session__text">
              This will completely stop the session. {"\n \n"}If you are brewing
              a Brew Pack, you can only re-use the QR code if you are stopping
              before the automated mashing phase.
            </div>
          </div>
          <div className="clearfix" />
        </Modal.Body>
        <Modal.Footer>
          {error && (
            <Alert style={{ float: "left" }} bsStyle="danger">
              <span className="ion-alert-circled">
                {" "}
                Something happened, try again later
              </span>
            </Alert>
          )}
          {waitingForNotification && (
            <span
              className="start-brew-loading__icon ion-load-c spin"
              style={{ float: "left", marginTop: "6px" }}
            >
              {" "}
              Stopping session...
            </span>
          )}
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={stopSession} bsStyle="danger">
            Stop Session
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default connect(
  null,
  null
)(ControlSessionModal);

export function srmToCss(srm) {
  if (srm > 40) return "#390C11";
  if (srm < 0 || isNaN(srm)) return "#fff";
  return `#${lookupColor[parseInt(srm)]()}`;
}

const lookupColor = {
  "0": () => {
    return "FFFFFF";
  },
  "1": () => {
    return "FFE699";
  },
  "2": () => {
    return "FFD878";
  },
  "3": () => {
    return "FFCA5A";
  },
  "4": () => {
    return "FFBF42";
  },
  "5": () => {
    return "FBB123";
  },
  "6": () => {
    return "F8A600";
  },
  "7": () => {
    return "F39C00";
  },
  "8": () => {
    return "EA8F00";
  },
  "9": () => {
    return "E58500";
  },
  "10": () => {
    return "DE7C00";
  },
  "11": () => {
    return "D77200";
  },
  "12": () => {
    return "CF6900";
  },
  "13": () => {
    return "CB6200";
  },
  "14": () => {
    return "C35900";
  },
  "15": () => {
    return "BB5100";
  },
  "16": () => {
    return "B54C00";
  },
  "17": () => {
    return "B04500";
  },
  "18": () => {
    return "A63E00";
  },
  "19": () => {
    return "A13700";
  },
  "20": () => {
    return "9B3200";
  },
  "21": () => {
    return "952D00";
  },
  "22": () => {
    return "8E2900";
  },
  "23": () => {
    return "882300";
  },
  "24": () => {
    return "821E00";
  },
  "25": () => {
    return "7B1A00";
  },
  "26": () => {
    return "771900";
  },
  "27": () => {
    return "701400";
  },
  "28": () => {
    return "6A0E00";
  },
  "29": () => {
    return "660D00";
  },
  "30": () => {
    return "5E0B00";
  },
  "31": () => {
    return "5A0A02";
  },
  "32": () => {
    return "560A05";
  },
  "33": () => {
    return "520907";
  },
  "34": () => {
    return "4C0505";
  },
  "35": () => {
    return "470606";
  },
  "36": () => {
    return "440607";
  },
  "37": () => {
    return "3F0708";
  },
  "38": () => {
    return "3B0607";
  },
  "39": () => {
    return "3A070B";
  },
  "40": () => {
    return "36080A";
  }
};

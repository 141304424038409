import React, { Component } from "react";
import { Col, ControlLabel, FormControl } from "react-bootstrap";
import RootCloseWrapper from "react-overlays/lib/RootCloseWrapper";

import "./TypeAhead.scss";

import { get } from "@common/services/request";
import FieldError from "@common/components/FieldError/FieldError";

/**
 * This component shows a dropdown with a list of items as soon as the field is focused.
 * These items come from an api endpoint and the user can filter the results by typing.
 *
 * location: location of the field in the "form" property of the store (created by Redux-form).
 *   This is necessary if the field is nested instead of in the root of the form.
 * attrMap: a mapping between a property of the chosen object coming from the api and the
 *   name that should be assigned to the property in the form, e.g: { id: idInField }
 *
 * TODO: This is a temp solution for the beermodal, super dirty fix
 */

class TypeAhead extends Component {
  state = {
    expanded: false,
    netError: false,
    options: [],
    filteredOptions: [],
    isChoiceMade: false
  };

  componentDidMount() {
    this.fetchIngredientInfo(this.props.endpoint);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.endpoint !== this.props.endpoint) {
      this.fetchIngredientInfo(nextProps.endpoint);
    }
  }

  expand = () => this.setState({ expanded: true });

  collapse = () => this.setState({ expanded: false });

  choiceHandler = (e, choice) => {
    const { input, change, location, attrMap } = this.props;
    if (choice) {
      input.onChange(choice.name);
      for (let attr in attrMap) {
        const fieldLocation = location
          ? `${location}.${attrMap[attr]}`
          : attrMap[attr];
        change(fieldLocation, choice[attr]);
      }
      this.setState({ isChoiceMade: true });
      this.collapse();
    }
  };

  changeHandler = e => {
    this.setState({ isChoiceMade: false });
    const filtered = this.state.options.filter(op =>
      op.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ filteredOptions: filtered });
    this.expand();
    this.props.input.onChange(e.target.value);
  };

  keyHandler = e => {
    switch (e.key) {
      case "Enter":
        if (!this.state.isChoiceMade) {
          e.preventDefault();
          if (this.state.filteredOptions.length)
            this.choiceHandler(this.state.filteredOptions[0]);
        }
        break;
      default:
        break;
    }
  };

  fetchIngredientInfo(endpoint) {
    get(`${endpoint}`)
      .then(res =>
        this.setState({
          options: res.results || res,
          filteredOptions: res.results || res,
          netError: false
        })
      )
      .catch(() => this.setState({ netError: true, expanded: false }));
  }

  render() {
    const {
      input: { value },
      label,
      meta: { touched, error },
      rowIndex,
      smSize
    } = this.props;

    return (
      <RootCloseWrapper
        event="click"
        disabled={!this.state.expanded}
        onRootClose={this.collapse}
      >
        <Col sm={smSize || 2} className="type-ahead">
          {!rowIndex && <ControlLabel>{label}</ControlLabel>}
          <FormControl
            type="text"
            placeholder="Start typing..."
            onFocus={this.changeHandler}
            onChange={this.changeHandler}
            onKeyDown={this.keyHandler}
            value={value}
          />
          {this.state.expanded && (
            <ul className={`options ${!rowIndex && "first"}`}>
              {this.state.filteredOptions &&
                this.state.filteredOptions.map(option => (
                  <li
                    key={option.id}
                    className={`option`}
                    onMouseDown={e => this.choiceHandler(e, option)}
                  >
                    {option.name}
                  </li>
                ))}
              {/* <li className="option add">+New Ingredient</li> */}
            </ul>
          )}
          {this.state.netError && <FieldError>Network error</FieldError>}
          {touched && (error && <FieldError>{error}</FieldError>)}
        </Col>
      </RootCloseWrapper>
    );
  }
}

export default TypeAhead;

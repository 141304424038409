import React from "react";
import { Field, reduxForm } from "redux-form";
import { Form, Row, Col, ControlLabel } from "react-bootstrap";
import CustomField from "../CreateRecipe/CustomField/CustomField";
import { required, number, numberBetween } from "@util/formValidationFunctions";
import { normalizeNumber } from "@util/recipeHelper";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";

const normalizeDryYield = normalizeNumber(3, 2);
const normalizeMoisture = normalizeNumber(3, 2);
const validateMaxHundred = numberBetween(0, 100);

class CustomFermentableForm extends React.Component {
  componentDidMount() {
    const { ingredient } = this.props;
    if (
      ingredient !== null &&
      ingredient !== undefined &&
      ingredient.type == "Fermentable"
    ) {
      this.props.initialize(ingredient);
    }
  }
  render() {
    const { handleSubmit, isSaving, valid } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={10} mdOffset={1}>
            <Field
              name="name"
              component={CustomField}
              label="Name"
              mdSize={12}
              smSize={12}
              validate={[required]}
              autoComplete="off"
            />
            <Col sm={12} md={12}>
              <ControlLabel>Type</ControlLabel>
              <Field
                name="fermentable_type"
                component="select"
                className="form-control"
              >
                <option value="" defaultValue disabled hidden>
                  Choose here
                </option>
                <option value="GRA">Grain</option>
                <option value="EXTR">Extract</option>
                <option value="SUG">Sugar</option>
                <option value="ADJ">Adjunct</option>
                <option value="DEXTR">Dry Extract</option>
              </Field>
            </Col>
            <Col sm={12} md={12}>
              <ControlLabel>Requires mashing</ControlLabel>
              <Field
                name="requires_mashing"
                component="input"
                type="checkbox"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  marginLeft: "3px",
                  transform: "scale(1.5)"
                }}
              />
            </Col>
            <Field
              name="dry_yield"
              component={CustomField}
              type="number"
              label="Dry yield"
              mdSize={12}
              smSize={12}
              validate={[required, number, validateMaxHundred]}
              normalize={normalizeDryYield}
            />
            <Field
              name="moisture"
              component={CustomField}
              type="number"
              label="Moisture"
              mdSize={12}
              smSize={12}
              validate={[required, number, validateMaxHundred]}
              normalize={normalizeMoisture}
            />
            <Field
              name="notes"
              component={CustomField}
              label="Notes"
              componentClass="textarea"
              mdSize={12}
              smSize={12}
            />
            <ButtonWithLoad
              type="submit"
              bsStyle="primary"
              style={{
                float: "right",
                marginTop: "20px",
                marginBottom: "10px"
              }}
              disabled={!valid}
              isLoading={isSaving}
            >
              Save fermentable
            </ButtonWithLoad>
            <div className="clearfix" />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({ form: "customFermentable" })(CustomFermentableForm);

import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Modal,
  ButtonGroup,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Alert,
  Col,
  Tabs,
  Tab
} from "react-bootstrap";
import QrReader from "react-qr-reader";
import { Typeahead } from "react-bootstrap-typeahead";
import { SESSION_TYPES } from "@common/constants/sessionConstants";
import { routes } from "@common/constants/urls";
import { isBrewable } from "../../../util/recipeValidation";
import * as sessionService from "@brewery/services/sessionService";
import * as recipeService from "@beers/services/recipeService";
import * as deviceService from "@common/services/deviceService";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import { ACCESS_TYPES } from "@common/constants/accessTypes";
import "./BrewModal.scss";

const scanStates = {
  scanning: 0,
  success: 1,
  error: 2
};
const initialState = {
  fetchingRecipes: false,
  fetchingDevices: false,
  fetchingBeers: false,
  recipes: [],
  devices: [],
  beers: [],
  error: false,
  errorMessage: "",
  recipeError: false,
  brewable: true,
  selectedBeer: null,
  selectedDevice: null,
  selectedRecipe: null,
  waitingForNotification: false,
  startSessionFailed: false,
  submitted: false,
  seq: 0,
  isSaving: false,
  activeTab: 1,
  scanResult: null,
  scanState: scanStates.scanning,
  scanError: false,
  brewticket: null,
  enterManually: false,
  manualCode: ""
};

class BrewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.fetchDevices();
    this.fetchBeers();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.show !== nextProps.show) {
      if (nextProps.show == true) {
        this.fetchDevices();
        this.fetchBeers();
      } else {
        this.setState(initialState);
      }
    }

    if (nextProps.show !== this.props.show && nextProps.show) {
      this.fetchDevices();
      this.fetchRecipes();
    } else if (!nextProps.show) {
      this.setState({
        selectedDevice: null,
        selectedRecipe: null,
        submitted: false
      });
    }
  }

  startBrew = () => {
    const {
      selectedDevice,
      selectedRecipe,
      activeTab,
      brewticket,
      enterManually,
      manualCode
    } = this.state;
    let data = {};
    this.setState({ submitted: true });

    // Check if brewpack or custom recipe
    if (activeTab === 1) {
      if (isBrewable(selectedRecipe)) {
        data = {
          minibrew_uuid: selectedDevice.uuid,
          session_type: SESSION_TYPES.SESSION_TYPE_BREW_FERM,
          beer_recipe_id: selectedRecipe.id
        };
      } else {
        this.setState({ brewable: false });
        return;
      }
    } else if (!enterManually) {
      data = {
        minibrew_uuid: selectedDevice.uuid,
        session_type: SESSION_TYPES.SESSION_TYPE_BREW_FERM,
        ticket_id: brewticket.uuid
      };
    } else {
      data = {
        minibrew_uuid: selectedDevice.uuid,
        session_type: SESSION_TYPES.SESSION_TYPE_BREW_FERM,
        ticket_id: manualCode
      };
    }

    if (
      this.state.selectedDevice &&
      (this.state.selectedRecipe || brewticket || manualCode.length > 0)
    ) {
      this.setState({ isSaving: true, error: null });
      sessionService
        .startSession(data)
        .then(response => {
          if (response.error <= 0) {
            throw Error(response.message);
          } else {
            this.props.handleClose();
            this.props.dispatch(push(`/brewery/monitor/${response.id}`));
          }
        })
        .catch(err => {
          err.then(message => {
            try {
              this.setState({
                errorMessage: message.minibrew_uuid[0],
                error: true,
                isSaving: false
              });
            } catch (err) {
              this.setState({
                errorMessage: "",
                error: true,
                isSaving: false
              });
            }
          });
        });
    } else {
      this.setState({ brewable: false });
    }
  };

  fetchRecipes = beerID => {
    if (beerID) {
      this.setState({ fetchingRecipes: true, recipeError: false });
      recipeService
        .getRecipes(beerID)
        .then(response => {
          this.setState({ recipes: response });
          if (
            this.props.selectedRecipe &&
            this.props.selectedRecipe.beer_id == beerID
          ) {
            // If a selectedRecipe is passed via props, find it and set the state
            const selectedRecipe = response.find(
              recipe => recipe.id == this.props.selectedRecipe.id
            );
            this.setState({
              selectedRecipe: selectedRecipe
            });
          } else {
            const latest = response.reduce((a, b) => {
              return +a.version_name > +b.version_name ? a : b;
            });
            this.setState({ selectedRecipe: latest });
          }
          this.setState({ fetchingRecipes: false });
        })
        .catch(() => {
          this.setState({ recipeError: true, fetchingRecipes: false });
        });
    }
  };

  fetchDevices = () => {
    this.setState({ fetchingDevices: true });
    deviceService
      .getReadyToBrewDevices()
      .then(response => {
        this.setState({ devices: response });
        if (this.props.selectedDevice) {
          // If a selectedDevice is passed via props, find it and set the state
          const selectedDevice = response.find(
            device => device.uuid === this.props.selectedDevice
          );
          this.setState({
            selectedDevice: selectedDevice
          });
        }
        this.setState({ fetchingDevices: false });
      })
      .catch(() => this.setState({ error: true, fetchingDevices: false }));
  };

  fetchBeers = () => {
    this.setState({ fetchingBeers: true });
    recipeService
      .getBeers(this.props.userInfo.user.id)
      .then(response => {
        this.setState({ beers: response });
        if (this.props.selectedRecipe) {
          // If a selectedRecipe is passed via props, find it and set the state
          const selectedBeer = response.find(
            beer => beer.id == this.props.selectedRecipe.beer_id
          );
          this.setState({
            selectedBeer: selectedBeer
          });
          this.fetchRecipes(this.props.selectedRecipe.beer_id);
        }
        this.setState({ fetchingBeers: false });
      })
      .catch(() => {
        this.setState({ error: true, fetchingBeers: false });
      });
  };

  navigateToEditRecipe = () => {
    const { selectedRecipe } = this.state;
    this.props.dispatch(
      push(
        routes.editRecipeWithValidate(selectedRecipe.beer_id, selectedRecipe.id)
      )
    );
  };

  navigateToRecipeDetail = () => {
    this.props.handleClose();
    const { selectedRecipe } = this.state;
    this.props.dispatch(
      push(
        routes.recipeDetailsWithParams(
          selectedRecipe.beer_id,
          selectedRecipe.version_name
        )
      )
    );
  };

  ableToStartBrew = () => {
    const {
      selectedBeer,
      selectedDevice,
      selectedRecipe,
      recipes
    } = this.state;

    if (
      selectedBeer &&
      selectedDevice &&
      selectedRecipe &&
      recipes.length > 0
    ) {
      return true;
    }
    return false;
  };

  setActiveTab = key => {
    this.setState({ activeTab: key, error: false });
  };

  handleScan = data => {
    if (data) {
      const hashedId = data.split("/")[4];
      recipeService
        .getBrewpackByQR(hashedId)
        .then(res =>
          this.setState({
            brewticket: res,
            scanState: scanStates.success
          })
        )
        .catch(() => {
          this.setState({ scanError: true, scanState: scanStates.error });
        });
      this.setState({
        scanResult: data
      });
    }
  };

  handleScanError = err => {
    if (err) {
      this.setState({ scanError: err });
    }
  };

  resetScan = () => {
    this.setState({ scanState: scanStates.scanning, scanError: false });
  };

  updateManualCode = e => {
    this.setState({ manualCode: e.target.value });
  };

  toggleEnterManual = () => {
    this.setState(prevState => ({
      enterManually: !prevState.enterManually
    }));
  };
  render() {
    const { show, handleClose, userInfo } = this.props;
    const {
      recipes,
      devices,
      submitted,
      selectedDevice,
      selectedRecipe,
      selectedBeer,
      waitingForNotification,
      error,
      errorMessage,
      recipeError,
      fetchingDevices,
      fetchingRecipes,
      fetchingBeers,
      isSaving,
      activeTab
    } = this.state;
    const showRecipesField = selectedRecipe && !fetchingRecipes;
    const showBeersField =
      (selectedBeer && !fetchingBeers) || !this.props.selectedRecipe;
    const showDevicesField =
      (selectedDevice && !fetchingDevices) || !this.props.selectedDevice;
    const sortedRecipes = [...recipes].sort((a, b) => {
      if (a.beer_name < b.beer_name) return 1;
      if (a.beer_name > b.beer_name) return -1;
      if (parseInt(a.version_name) < parseInt(b.version_name)) return 1;
      if (parseInt(a.version_name) > parseInt(b.version_name)) return -1;
    });

    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            START NEW BREW
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            id="brew-modal-tabs"
            style={{ color: "black" }}
            activeKey={activeTab}
            onSelect={this.setActiveTab}
          >
            <Tab title="CUSTOM" eventKey={1}>
              <Form>
                <FormGroup controlId="DeviceSelect">
                  <Col md={12}>
                    <ControlLabel>Device</ControlLabel>
                    {showDevicesField && (
                      <Typeahead
                        onChange={selectedDevice => {
                          this.setState({ selectedDevice: selectedDevice[0] });
                        }}
                        labelKey={option => `${option.custom_name}`}
                        options={devices}
                        defaultInputValue={
                          selectedDevice ? selectedDevice.custom_name : ""
                        }
                        isLoading={fetchingDevices}
                        placeholder="Choose a device"
                        emptyLabel="No available device found"
                      />
                    )}
                    {submitted && !selectedDevice && (
                      <HelpBlock style={{ color: "red" }}>
                        Field is required
                      </HelpBlock>
                    )}
                  </Col>
                  <div className="clearfix" />
                </FormGroup>
                <FormGroup controlId="RecipeSelect">
                  <Col md={8}>
                    <ControlLabel>Beers</ControlLabel>
                    {showBeersField && (
                      <Typeahead
                        onChange={selectedBeer => {
                          if (selectedBeer[0]) {
                            this.setState({
                              selectedRecipe: null,
                              selectedBeer: selectedBeer[0],
                              recipeError: false
                            });
                            this.fetchRecipes(selectedBeer[0].id);
                          }
                        }}
                        labelKey={option => `${option.name}`}
                        options={this.state.beers}
                        defaultInputValue={
                          selectedBeer ? selectedBeer.name : ""
                        }
                        isLoading={fetchingRecipes}
                        placeholder="Choose a beer"
                        emptyLabel="No recipes found"
                      />
                    )}
                    {submitted && !selectedRecipe && (
                      <HelpBlock style={{ color: "red" }}>
                        Field is required
                      </HelpBlock>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup controlId="RecipeSelect">
                  <Col md={4}>
                    <ControlLabel>Version</ControlLabel>
                    {showRecipesField && (
                      <Typeahead
                        ref={ref => (this._typeaheadVersions = ref)}
                        disabled={sortedRecipes.length === 0}
                        onChange={selectedRecipe => {
                          if (selectedRecipe != false) {
                            this.setState({
                              selectedRecipe: selectedRecipe[0]
                            });
                          }
                        }}
                        labelKey={option => `Version ${option.version_name}`}
                        options={sortedRecipes}
                        defaultInputValue={
                          selectedRecipe
                            ? `Version ${selectedRecipe.version_name}`
                            : ""
                        }
                        isLoading={fetchingRecipes}
                        placeholder="Choose a version"
                        emptyLabel="No version found"
                      />
                    )}
                    {submitted && !selectedRecipe && (
                      <HelpBlock style={{ color: "red" }}>
                        Field is required
                      </HelpBlock>
                    )}
                  </Col>
                </FormGroup>
              </Form>
              <div className="clearfix" />
              <div
                style={{
                  marginTop: "20px",
                  padding: "0px 15px"
                }}
              >
                {error && (
                  <Alert bsStyle="danger">
                    <span className="ion-alert-circled">
                      {" "}
                      {errorMessage
                        ? errorMessage
                        : "Something went wrong please try again later"}
                    </span>
                  </Alert>
                )}
                {recipeError && (
                  <Alert bsStyle="danger">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center"
                      }}
                    >
                      <div>
                        <span
                          className="ion-android-alert"
                          style={{ marginRight: "10px", fontSize: "24px" }}
                        />
                      </div>
                      No recipes found
                    </div>
                  </Alert>
                )}
                {selectedRecipe &&
                  !selectedRecipe.brewable &&
                  selectedRecipe.times_brewed === 0 && (
                    <Alert bsStyle="danger">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div>
                          <span
                            className="ion-android-alert"
                            style={{ marginRight: "10px", fontSize: "24px" }}
                          />
                        </div>
                        <div>
                          Your recipe cannot be brewed because it does not meet
                          the requirements of the MiniBrew system. You can see
                          why by going to the Edit page for this beer.
                        </div>
                        <div>
                          <Button
                            style={{
                              float: "right"
                            }}
                            onClick={this.navigateToEditRecipe}
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                      <div className="clearfix" />
                    </Alert>
                  )}

                {selectedRecipe &&
                  !selectedRecipe.brewable &&
                  selectedRecipe.times_brewed > 0 && (
                    <Alert bsStyle="danger">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div>
                          <span
                            className="ion-android-alert"
                            style={{ marginRight: "10px", fontSize: "24px" }}
                          />
                        </div>
                        <div>
                          Your recipe cannot be brewed because it no longer
                          meets the requirements of the MiniBrew system. Visit
                          the Recipe Detail page to see which versions can be
                          brewed. Create a new version of any “unbrewable”
                          recipe to see what you need to change.
                        </div>
                        <div>
                          <Button onClick={this.navigateToRecipeDetail}>
                            Recipe Detail
                          </Button>
                        </div>
                      </div>
                      <div className="clearfix" />
                    </Alert>
                  )}
              </div>
            </Tab>
            <Tab title="BREWPACK" eventKey={2}>
              <Form>
                <FormGroup controlId="DeviceSelect">
                  <ControlLabel>Device</ControlLabel>
                  {showDevicesField && (
                    <Typeahead
                      onChange={selectedDevice => {
                        this.setState({ selectedDevice: selectedDevice[0] });
                      }}
                      labelKey={option => `${option.custom_name}`}
                      options={devices}
                      defaultInputValue={
                        selectedDevice ? selectedDevice.custom_name : ""
                      }
                      isLoading={fetchingDevices}
                      placeholder="Choose a device"
                      emptyLabel="No available device found"
                    />
                  )}
                  {submitted && !selectedDevice && (
                    <HelpBlock style={{ color: "red" }}>
                      Field is required
                    </HelpBlock>
                  )}
                  <div className="clearfix" />
                </FormGroup>
                <ButtonGroup style={{ marginBottom: 10 }}>
                  <Button
                    disabled={!this.state.enterManually}
                    onClick={this.toggleEnterManual}
                    style={{ marginRight: 0 }}
                  >
                    Scan
                  </Button>
                  <Button
                    disabled={this.state.enterManually}
                    onClick={this.toggleEnterManual}
                  >
                    Type
                  </Button>
                </ButtonGroup>
                ;
                {activeTab == 2 && (
                  <>
                    {this.state.scanState == scanStates.scanning &&
                      !this.state.enterManually && (
                        <QrReader
                          delay={300}
                          onError={this.handleError}
                          onScan={this.handleScan}
                          style={{ width: "100%" }}
                        />
                      )}
                    {this.state.enterManually && (
                      <FormControl
                        type="text"
                        placeholder="Brewpack code"
                        value={this.state.manualCode}
                        onChange={this.updateManualCode}
                      />
                    )}
                  </>
                )}
                {this.state.scanState == scanStates.success &&
                  !this.state.enterManually && (
                    <div className="flex flex-row">
                      <div>
                        <img
                          src={this.state.brewticket.beer_img}
                          style={{ width: 80, height: 80, borderRadius: 6 }}
                        />
                      </div>
                      <div
                        className="flex flex-column"
                        style={{ marginLeft: 20 }}
                      >
                        <div>{this.state.brewticket.beer_name}</div>
                        <div className="font-book">
                          {this.state.brewticket.brewery_name}
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.scanState == scanStates.error && (
                  <Alert bsStyle="danger">Invalid QR code</Alert>
                )}
                {this.state.error && (
                  <Alert bsStyle="danger">Invalid code</Alert>
                )}
              </Form>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          {waitingForNotification && (
            <span
              className="start-brew-loading__icon ion-load-c spin"
              style={{ float: "left", marginTop: "6px" }}
            >
              {" "}
              Starting brewing session...
            </span>
          )}
          <Button onClick={handleClose}>Close</Button>
          {activeTab == 2 && this.state.scanState !== scanStates.scanning && (
            <Button onClick={this.resetScan}>Rescan</Button>
          )}
          <ButtonWithLoad
            onClick={this.startBrew}
            bsStyle="primary"
            disabled={
              selectedRecipe &&
              !selectedRecipe.brewable &&
              userInfo.user.access_type !== ACCESS_TYPES.BP_RESEARCHER
            }
            isLoading={isSaving}
          >
            Start new brew
          </ButtonWithLoad>
          {this.state.selectedRecipe ? this.state.selectedRecipe.name : ""}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = ({ mqtt, userInfo }) => {
  return { mqtt, userInfo };
};

export default connect(
  mapStateToProps,
  null
)(BrewModal);

import React from "react";
import PropTypes from "prop-types";

class BeerActionToggle extends React.Component {
  state = {};

  handleClick = e => {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    return <div onClick={this.handleClick}>{this.props.children}</div>;
  }
}

BeerActionToggle.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.object
};

export default BeerActionToggle;

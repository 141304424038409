import React, { useRef, useEffect } from "react";

// UserActionVideo
const UserActionVideo = ({ index, action, actionIndex }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (index === actionIndex) {
      setTimeout(() => {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }, 300);
    }
  }, [index, action]);

  return (
    <div>
      <div>
        <img
          className="replay-button"
          onClick={() => {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
          }}
          src={require("@assets/images/ReplayButton.svg")}
        />
        <video
          ref={videoRef}
          playsInline
          controls
          muted
          autoPlay
          style={{ width: "100%" }}
        >
          <source src={action.video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default UserActionVideo;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { Button, Row, Col, ControlLabel } from "react-bootstrap";

import CustomField from "../../../CustomField/CustomField";
import { getSteps } from "../FermUtils";
import TypeAhead from "../../TypeAhead/TypeAhead";
import { apiEndpoints as api } from "@common/constants/urls";
import { normalizeNumber } from "../../../../../../util/recipeHelper";

const normalizeAmount = normalizeNumber(3, 2);
const normalizeDuration = normalizeNumber(2, 1);

class FermHops extends Component {
  componentWillUnmount() {
    const { fields } = this.props;
    fields.removeAll();
  }

  render() {
    const { fields, steps, change, disableAdd } = this.props;

    return (
      <div>
        <h3>Dry Hop</h3>
        <Button
          type="button"
          onClick={() =>
            fields.push({
              ingredient_id: null,
              ingredient_name: null,
              ingredient_type: "HOP",
              addition_step: steps.length ? `${steps[0].stageIndex}-0` : null,
              duration: null,
              amount: null,
              alpha_acid: null
            })
          }
          className="AddButton"
          disabled={disableAdd}
        >
          <i className="icon ion-plus" /> HOP
        </Button>

        {!!fields.length && (
          <div>
            {fields.map((hop, index) => (
              <Row key={index} className="list-item">
                <Field
                  name={`${hop}.ingredient_name`}
                  component={TypeAhead}
                  forceValidation
                  label="Name"
                  smSize={3}
                  rowIndex={index}
                  change={change}
                  location={`while_fermenting.hops[${index}]`}
                  endpoint={api.hops}
                  attrMap={{ id: "ingredient_id" }}
                />
                <Field
                  name={`${hop}.amount`}
                  type="number"
                  component={CustomField}
                  label="Grams"
                  rowIndex={index}
                  normalize={normalizeAmount}
                  step="0.01"
                />
                <Col sm={4}>
                  {!index && <ControlLabel>When?</ControlLabel>}
                  <div>
                    <Field
                      name={`${hop}.addition_step`}
                      component="select"
                      className="form-control"
                    >
                      {(steps.length &&
                        steps.map((step, index) => (
                          <option
                            value={`${step.stageIndex}-${step.index}`}
                            key={index}
                          >
                            {`Stage ${step.stageIndex + 1} (${
                              step.stageLabel
                            }) step ${step.index + 1}`}
                          </option>
                        ))) || <option>No secondary stages</option>}
                    </Field>
                  </div>
                </Col>
                <Field
                  name={`${hop}.duration`}
                  type="number"
                  component={CustomField}
                  label="Days"
                  smSize={2}
                  rowIndex={index}
                  normalize={normalizeDuration}
                  step="0.1"
                />
                {/* <Field
                  name={`${hop}.alpha_acid`}
                  type="number"
                  step="0.1"
                  component={CustomField}
                  label="Alpha Acid"
                  rowIndex={index}
                  overlay={PROFILE_PROPS.ALPHA_ACID}
                  normalize={normalizeAlphaAcid}
                /> */}
                <Col sm={1}>
                  <Button
                    className="remove-btn"
                    style={{
                      marginTop: index ? "4px" : "31px"
                    }}
                    type="button"
                    title="Remove ingredient"
                    onClick={() => fields.remove(index)}
                  >
                    <i className="icon ion-close" />
                  </Button>
                </Col>
              </Row>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  steps: getSteps(state)
});

export default connect(mapStateToProps)(FermHops);

import React from "react";
import { useEffect, useState } from "react";
import PROCESS_STATES from "@common/constants/processStates";
import Temperatures from "../Temperatures/Temperatures";
import Gravity from "../Gravity/Gravity";
import UpdateTargetTempSlider from "../UpdateTargetTempSlider/UpdateTargetTempSlider";
import "./ChartHeader.scss";
import * as recipeService from "@beers/services/recipeService";
import { isCleaningSession } from "@monitor/util";

const ChartHeader = ({ lastFrequentLog, session }) => {
  const initialValues = {
    currentTemp: 0,
    setpointTemp: 0,
    currentGravity: 0,
    timeTillNextUserAction: 0,
    currentStatus: 0
  };

  let {
    currentTemp,
    setpointTemp,
    currentGravity,
    currentStatus
  } = lastFrequentLog ? lastFrequentLog.logsList[0] : initialValues;

  const [setpointGravity, setSetPointGravity] = useState(0);

  useEffect(() => {
    if (!isCleaningSession(session) && !session.is_brewpack) {
      recipeService.getRecipeById(session.beer_recipe_id).then(response => {
        setSetPointGravity(response.fg);
      });
    }
  }, []);

  const showSlider =
    currentStatus.procState === PROCESS_STATES.SERVING_TEMP_CONTROL_STATE;

  return (
    <div>
      {lastFrequentLog && (
        <div className="ChartHeader">
          <div className="ChartHeader__list">
            <div style={{ display: "flex" }}>
              {setpointTemp && (
                <div className="ChartHeader__item ChartHeader__item--temp">
                  <div className="ChartHeader__item-title">Temperature</div>
                  <Temperatures current={currentTemp} target={setpointTemp} />
                </div>
              )}

              {showSlider && (
                <div className="ChartHeader__item ChartHeader__item--slider">
                  <UpdateTargetTempSlider
                    targetTemp={setpointTemp}
                    session={session}
                  />
                </div>
              )}
            </div>

            {currentGravity && setpointGravity ? (
              <div className="ChartHeader__item ChartHeader__item--gravity">
                <div className="ChartHeader__item-title">Gravity</div>
                <Gravity current={currentGravity} target={setpointGravity} />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartHeader;

const NOTIFICATION_TYPES = {
  NOT_PROCESS_STATE_CHANGED: 0,
  NOT_COMMAND_EXECUTED: 1,
  NOT_ERROR: 2,
  NOT_USER_ACTION_REQUIRED: 3,
  NOT_INGREDIENT_ADDED: 4,
  NOT_DEVICE_CONNECTED: 5,
  NOT_DEVICE_DISCONNECTED: 6,
  NOT_PROCESS_ESTIMATION_CHANGED: 7,
  NOT_SESSION_STATE_CHANGED: 8
};

export default NOTIFICATION_TYPES;

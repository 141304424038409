import React from "react";

const CircledNumber = ({ number }) => {
  return (
    <div
      style={{
        height: "30px",
        width: "30px",
        backgroundColor: "transparent",
        borderRadius: "30px",
        border: "2px solid #24262c",
        textAlign: "center",
        paddingTop: "3px",
        display: "inline-block",
        marginRight: "15px"
      }}
    >
      <span>{number}</span>
    </div>
  );
};

export default CircledNumber;

import React from "react";
import Spinner from "@common/components/Spinner/Spinner";

const LoadingSession = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}
    >
      <Spinner className={"dark-spinner"} />
      <div>Fetching session information...</div>
    </div>
  );
};

export default LoadingSession;

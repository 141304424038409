import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Alert, Grid, Row, Col, Button, Panel } from "react-bootstrap";
import Container from "@common/components/Container/Container";

import "./TermsAndConditions.scss";
import Navigation from "@common/components/Navigation/Navigation";
import { routes } from "@common/constants/urls";
import errorMessages from "@common/constants/errorMessages";
import {
  getUserInformation,
  patchUserInformation
} from "@profile/actions/userActions";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import TcContent from "./TcContent";
import PrivacyPolicyContent from "../PrivacyPolicy/PrivacyPolicyContent";

class TermsAndConditions extends Component {
  state = {
    error: false,
    tcOpen: false,
    ppOpen: false
  };

  componentDidMount() {
    this.props.getUserInformation();
  }

  accept = () => {
    const { user } = this.props.userInfo;
    this.props.patchUserInformation({ terms_conditions_accepted: true });
    analyticsService.track(ANALYTICS_TYPES.ACCEPTED_TERMS, { UserID: user.id });
  };

  togglePPOpen = () => {
    this.setState(prevstate => {
      return { ppOpen: !prevstate.ppOpen };
    });
  };

  toggleTCOpen = () => {
    this.setState(prevstate => {
      return { tcOpen: !prevstate.tcOpen };
    });
  };

  render() {
    const { user, isFetching } = this.props.userInfo;
    const { tcOpen, ppOpen } = this.state;

    const body = (
      <Grid>
        <Row className="terms-and-conditions">
          <Col sm={8} smOffset={2}>
            <Container>
              <h2>Legal Disclaimer</h2>
              <p style={{ fontFamily: "Gotham-Book" }}>
                Use of your MiniBrew software and related services is subject to
                these Terms & Conditions. Please read them carefully.
              </p>

              <div>
                <Panel className="toc-panel" expanded={tcOpen}>
                  <Panel.Toggle
                    componentClass="div"
                    className="panel-toggle-button"
                    onClick={this.toggleTCOpen}
                  >
                    <Panel.Heading>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div>
                          <Panel.Title>A: Terms & Conditions</Panel.Title>
                          <div>
                            <small className="agree-text">
                              By clicking "Agree" you are agreeing to be bound
                              by these terms
                            </small>
                          </div>
                        </div>
                        {tcOpen ? (
                          <span className="ion-chevron-up" />
                        ) : (
                          <span className="ion-chevron-down" />
                        )}{" "}
                      </div>
                    </Panel.Heading>
                  </Panel.Toggle>
                  <Panel.Collapse>
                    <Panel.Body>
                      <TcContent />
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </div>

              <div>
                <Panel className="toc-panel" expanded={ppOpen}>
                  <Panel.Toggle
                    componentClass="div"
                    className="panel-toggle-button"
                    onClick={this.togglePPOpen}
                  >
                    <Panel.Heading>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div>
                          <Panel.Title>B: Privacy Policy</Panel.Title>
                          <div>
                            <small className="agree-text">
                              By clicking "Agree" you are agreeing to be bound
                              by these terms
                            </small>
                          </div>
                        </div>
                        {ppOpen ? (
                          <span className="ion-chevron-up" />
                        ) : (
                          <span className="ion-chevron-down" />
                        )}
                      </div>
                    </Panel.Heading>
                  </Panel.Toggle>

                  <Panel.Collapse>
                    <Panel.Body>
                      <PrivacyPolicyContent />
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                <Button
                  bsStyle="primary"
                  style={{
                    maxWidth: "200px",
                    margin: "30px",
                    paddingLeft: "40px",
                    paddingRight: "40px"
                  }}
                  onClick={this.accept}
                >
                  AGREE
                </Button>
                <small className="agree-text">
                  These terms can be found in the profile area at any time
                </small>
              </div>
            </Container>
          </Col>
          {this.state.error && (
            <Col sm={8} smOffset={2}>
              <Alert bsStyle="danger">
                <span className="ion-alert-circled">
                  {" "}
                  {errorMessages.network}
                </span>
              </Alert>
            </Col>
          )}
        </Row>
      </Grid>
    );

    return user && user.terms_conditions_accepted ? (
      <Redirect to={routes.brewery} />
    ) : (
      <div>
        <Navigation />
        {isFetching ? (
          <div
            className="ion-load-c spin"
            style={{ textAlign: "center", fontSize: "6rem" }}
          />
        ) : (
          body
        )}
      </div>
    );
  }
}

export default connect(
  ({ userInfo }) => ({ userInfo }),
  {
    getUserInformation,
    patchUserInformation
  }
)(TermsAndConditions);

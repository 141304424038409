import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { Modal, Button, Form, Row, Alert } from "react-bootstrap";
import TypeAhead from "../../CreateRecipe/Recipe/TypeAhead/TypeAheadBeerModal";
import CustomField from "../../CreateRecipe/CustomField/CustomField";
import { apiEndpoints as api } from "@common/constants/urls";
import {
  required,
  chooseDropdownOption
} from "../../../../util/formValidationFunctions";

class BeerForm extends Component {
  render() {
    const {
      handleSubmit,
      change,
      error,
      closeModal,
      isLoading,
      title
    } = this.props;
    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              name="name"
              component={CustomField}
              label="Name"
              smSize={12}
              mdSize={12}
              validate={required}
            />
          </Row>
          <Row>
            <Field
              name="styleName"
              component={TypeAhead}
              label="Style"
              change={change}
              endpoint={api.styles}
              attrMap={{ id: "style" }}
              smSize={12}
              validate={chooseDropdownOption}
            />
          </Row>

          {error && (
            <Alert bsStyle="danger">
              <span className="ion-alert-circled">
                Something happened, try again later
              </span>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isLoading && (
            <span
              className="start-brew-loading__icon ion-load-c spin"
              style={{ float: "left", marginTop: "6px" }}
            >
              {" "}
              Saving beer...
            </span>
          )}

          <Button onClick={closeModal}>CANCEL</Button>
          <Button type="submit" bsStyle="primary" disabled={isLoading}>
            SAVE
          </Button>
        </Modal.Footer>
      </Form>
    );
  }
}

export default reduxForm({
  form: "newBeer"
})(BeerForm);

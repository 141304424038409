import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Tabs, Tab, Alert } from "react-bootstrap";

import CustomHopForm from "./CustomHopForm";
import CustomFermentableForm from "./CustomFermentableForm";
import CustomYeastForm from "./CustomYeastForm";
import CustomAdjunctForm from "./CustomAdjunctForm";
import * as ingredientService from "@beers/services/ingredientService";
import "./custom-ingredient.scss";

class CustomIngredientModal extends Component {
  state = {
    error: null,
    isSaving: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState({ error: null });
    }
  }

  checkIngredient = ingredient => {
    if (ingredient) {
      return true;
    }
    return false;
  };

  getSubmit = data => {
    const httpFunction = this.getFunction();
    const { ingredient, onSuccess } = this.props;
    const edit = this.checkIngredient(ingredient);
    this.setState({ error: null, isSaving: true });
    const setError = err => this.setState({ error: err, isSaving: false });
    const setSuccess = () => this.setState({ error: null, isSaving: false });

    if (edit) {
      httpFunction(ingredient.id, data)
        .then(() => {
          this.props.onHide();
          this.props.getAllIngredients();
          setSuccess();
        })
        .catch(setError);
    } else {
      httpFunction(data)
        .then(response => {
          this.props.onHide();
          this.props.getAllIngredients();
          setSuccess();
          // TODO: I need the response.id from the backend
          onSuccess &&
            onSuccess({
              ...response,
              id: response.id
            });
        })
        .catch(setError);
    }
  };

  getFunction = () => {
    const edit = this.checkIngredient(this.props.ingredient); // get from url
    /**
     * 1 = hop
     * 2 = ferm
     * 3 = yeast
     * 4 = adjunct
     **/
    if (edit) {
      switch (this.props.activeTabKey) {
        case 1:
          return ingredientService.patchHop;
        case 2:
          return ingredientService.patchFermentable;
        case 3:
          return ingredientService.patchYeast;
        case 4:
          return ingredientService.patchAdjunct;
      }
    } else {
      switch (this.props.activeTabKey) {
        case 1:
          return ingredientService.postHops;
        case 2:
          return ingredientService.postFermentable;
        case 3:
          return ingredientService.postYeast;
        case 4:
          return ingredientService.postAdjunct;
      }
    }
  };

  setSelectedTab = key => {
    this.props.setActiveKey(key);
  };

  render() {
    const { error, isSaving } = this.state;
    const {
      show,
      onHide,
      activeTabKey,
      ingredient,
      showOnlyActiveKey
    } = this.props;
    const ingredientType = ingredient ? ingredient.type : null;

    const items = [
      {
        label: "Hop",
        form: CustomHopForm
      },
      {
        label: "Fermentable",
        form: CustomFermentableForm
      },
      {
        label: "Yeast",
        form: CustomYeastForm
      },
      {
        label: "Adjunct",
        form: CustomAdjunctForm
      }
    ];

    const formProps = {
      onSubmit: this.getSubmit,
      ingredient: this.props.ingredient,
      laboratories: this.props.ingredients.laboratories,
      isSaving: isSaving
    };

    return (
      <Modal show={show} className="custom-ingredient-modal" onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            Custom ingredient
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            activeKey={activeTabKey}
            id="uncontrolled-tab-example"
            onSelect={this.setSelectedTab}
          >
            {items.map((tab, index) => {
              const Component = tab.form;
              const key = index + 1;
              let isDisabled = false;
              if (showOnlyActiveKey) {
                isDisabled = activeTabKey !== key;
              } else if (ingredientType) {
                isDisabled = ingredientType !== tab.label;
              }

              return (
                <Tab
                  key={key}
                  eventKey={key}
                  title={tab.label}
                  disabled={isDisabled}
                >
                  <Component {...formProps} />
                </Tab>
              );
            })}
          </Tabs>
          {error && (
            <Alert bsStyle="danger">Creating an ingredient failed</Alert>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = ({ ingredients }) => {
  return { ingredients };
};

export default connect(
  mapStateToProps,
  null
)(CustomIngredientModal);

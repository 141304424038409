import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

/**
 * Protected route, see: https://reacttraining.com/react-router/web/example/auth-workflow
 * <PrivateRoute path="/" component={PortalLayout} />
 * @param {component} props.component the private component
 * @param {} rest optional other attributes or properties
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/home/"
            }}
          />
        )
      }
    />
  );
};

function mapStateToProps({ auth, userInfo }) {
  return {
    auth,
    userInfo
  };
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  auth: PropTypes.object
};

export default connect(mapStateToProps)(PrivateRoute);

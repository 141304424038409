import React from "react";
import {
  Col,
  ControlLabel,
  FormControl,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import FieldError from "@common/components/FieldError/FieldError";
import FieldWarn from "@common/components/FieldWarn/FieldWarn";

const customField = ({
  componentClass,
  input,
  label,
  type,
  style,
  step,
  rowIndex,
  readOnly,
  placeholder,
  smSize,
  mdSize,
  disabled,
  overlay,
  meta: { touched, error, warning }
}) => {
  return (
    <Col sm={smSize || 2} md={mdSize}>
      {!rowIndex &&
        (label && (
          <ControlLabel style={{ textOverflow: "ellipsis" }}>
            {(overlay && (
              <OverlayTrigger
                trigger={["hover", "focus", "click"]}
                placement="bottom"
                overlay={
                  <Popover id="popover-trigger-hover-focus">{overlay}</Popover>
                }
              >
                <div>
                  {label} <span className="ion-help-circled" />
                </div>
              </OverlayTrigger>
            )) || <span>{label}</span>}
          </ControlLabel>
        ))}
      <FormControl
        {...input}
        style={{
          ...style,
          border:
            touched && (error || warning)
              ? `1px ${error ? "red" : "darkorange"} solid`
              : null
        }}
        type={type || "text"}
        componentClass={componentClass}
        placeholder={placeholder || label}
        readOnly={readOnly}
        disabled={disabled}
        step={type === "number" ? step : null}
      />
      {touched && !error && warning && <FieldWarn>{warning}</FieldWarn>}
      {touched && error && (
        <FieldError>
          <small>{error[0] ? error[0].message : error.message}</small>
        </FieldError>
      )}
    </Col>
  );
};

export default customField;

import React, { Component } from "react";
import { Route, NavLink, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Badge } from "react-bootstrap";
import LoadingBar from "react-redux-loading-bar";
import NotificationsSystem from "reapop";
import theme from "reapop-theme-wybo";
import mqttService from "../../../monitor/mqttService";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import { take, filter } from "rxjs/operators";
import GlobalNotificationDisplayer from "@common/components/GlobalNotificationDispatcher/GlobalNotificationDispatcher";
import Monitor from "../../../monitor/pages/Monitor/Monitor";
import CreateRecipe from "@beers/pages/CreateRecipe/CreateRecipe";
import Navigation from "@common/components/Navigation/Navigation";
import DashboardPage from "../Dashboard/DashboardPage";
import Profile from "@profile/pages/Profile/Profile";
import BeerList from "@beers/pages/BeerList/BeerList";
import RecipeDetail from "@beers/pages/RecipeDetail/RecipeDetail";
import NotFoundPage from "@common/pages/404/NotFoundPage";
import CustomIngredientList from "@beers/pages/CustomIngredientList/CustomIngredientList";
import PrivacyPolicy from "@auth/pages/PrivacyPolicy/PrivacyPolicy";
import ProfileDropdown from "@common/components/ProfileDropdown/ProfileDropdown";
import Brewery from "@brewery/pages/Brewery/Brewery";
import TermsAndConditions from "@auth/pages/TermsAndConditions/TermsAndConditionsAuth";
import * as userActions from "@profile/actions/userActions";
import * as authActions from "@auth/actions/authenticationActions";
import * as mqttActions from "@common/actions/mqttActions";
import { routes } from "@common/constants/urls";
import { ALLOWED_TO_ACCESS } from "@common/constants/accessTypes";
import "./portal-layout.scss";
import SharedRecipes from "@beers/pages/SharedRecipes/SharedRecipes";
import SharedRecipeDetail from "@beers/pages/SharedRecipeDetail/SharedRecipeDetail";

const navItems = [
  // {
  //   name: "DASHBOARD",
  //   route: routes.dashboard
  // },
  {
    name: "BREWERY",
    route: routes.brewery
  },
  {
    name: "MY BEERS",
    route: routes.beers
  },
  {
    name: "MY INGREDIENTS",
    route: routes.myIngredients,
    props: {
      className: "visible-xs"
    }
  },
  {
    name: "COMMUNITY",
    route: routes.community
  },
  {
    name: "SETTINGS",
    route: routes.profile,
    props: {
      className: "visible-xs"
    }
  }
];
// Wrapper for all of the portal pages
class PortalLayout extends Component {
  state = {
    showMenu: false,
    intervalId: null
  };

  componentWillMount() {
    this.props.getUserInformation();
    this.props.getSharedFavourites();

    if (
      this.props.userInfo.user &&
      !ALLOWED_TO_ACCESS.includes(this.props.userInfo.user.access_type)
    ) {
      // localStorage.removeItem("token");
      // localStorage.removeItem("userId");
    }

    // Adding Gorgias script tag for customer support
    const script = document.createElement('script');
    script.setAttribute("id", "gorgias-chat-widget-install-v3");
    script.setAttribute('src', 'https://config.gorgias.chat/bundle-loader/01HBBJZXF691H8YNQV79VWA8S5');
    document.body.appendChild(script);
    // End of Gorgias script

  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.userInfo != nextProps.userInfo &&
      nextProps.userInfo.user !== this.props.userInfo.user
    ) {
      const { user } = nextProps.userInfo;
      const hashToken = localStorage.getItem("token");
      const { clientUUID } = nextProps.mqtt;

      /**
       * On the first connect with mqtt, subscribe to the notifications channel
       * Afterwards the MQTT.js library takes care of reconnecting to the subscribed
       * channels, so we stop listening after the initial connect.
       */
      const connected$ = mqttService.connected$.pipe(
        filter(connected => connected === true),
        take(1) // !!! I think that is not needed, since we want to resubscrube to notifications after manual disconect
      );
      connected$.subscribe(() => {
        mqttService.subscribeToNotifications(user.uuid);
      });
      if (!mqttService.isConnected()) {
        const mqttCliend = "breweryportal-" + clientUUID;
        const mqttUser = "breweryportal-" + user.uuid;
        mqttService.connect(mqttCliend, mqttUser, hashToken);
      }
    }

    // close menu when the path change
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({
        showMenu: false
      });
    }
  }

  handleMenuClick = () => {
    this.setState(prevState => {
      return { showMenu: !prevState.showMenu };
    });
  };

  getAmountOfDevicesWithUseraction = () => {
    let counter = 0;
    if (this.props.brewery.list) {
      const { brew_clean_idle, fermenting, serving } = this.props.brewery.list;
      let devices = brew_clean_idle.concat(fermenting, serving);

      devices.forEach(device => {
        if (device.user_action !== 0) counter++;
      });
    }
    return counter;
  };

  render() {
    const { user, isFetching } = this.props.userInfo;

    const renderBadge = () => {
      if (this.getAmountOfDevicesWithUseraction() > 0) {
        return (
          <Badge className="header__account__badge">
            {this.getAmountOfDevicesWithUseraction()}
          </Badge>
        );
      }
    };

    return !this.props.userInfo.user || isFetching ? (
      <div
        className="ion-load-c spin"
        style={{ textAlign: "center", fontSize: "6rem" }}
      />
    ) : !user.terms_conditions_accepted ? (
      <Redirect to={routes.termsAndPrivacy} />
    ) : user.force_password_change ? (
      <Redirect to={routes.firstPasswordChange} />
    ) : user && !ALLOWED_TO_ACCESS.includes(user.access_type) ? (
      <Redirect to={routes.subscribe} />
    ) : (
      <div>
        <LoadingBar showFastActions className="header__loading" />
        <Navigation
          showBurger={true}
          handleMenuClick={this.handleMenuClick}
          showMenu={this.state.showMenu}
          showBell={true}
          color={"white"}
          portal={true}
        >
          <div
            className={
              "header__nav--portal col-xs-12 col-sm-6 col-md-6 " +
              (this.state.showMenu ? "header__nav__show" : "header__nav__hide")
            }
          >
            {navItems.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  activeClassName="active"
                  to={item.route}
                  {...item.props}
                >
                  {item.name}
                  {item.name === "BREWERY" && renderBadge()}
                </NavLink>
              );
            })}
            {/* <NavLink
              key="community"
              activeClassName="active"
              to={routes.community}
            >
              COMMUNITY
            </NavLink> */}
            <a className="visible-xs" onClick={this.props.signOut}>
              SIGN OUT
            </a>
          </div>
          <div className="header__account hidden-xs col-sm-3 col-md-3 ">
            <ProfileDropdown
              signOut={() => {
                analyticsService.track(ANALYTICS_TYPES.SIGN_OUT, {
                  Forced: false
                });
                analyticsService.mixpanel.reset();
                this.props.signOut();
                this.props.removeUserData();
              }}
            />
            <p className="header__account__text">
              <span className="hidden-sm">
                {this.props.userInfo.user.first_name.toUpperCase()}{" "}
              </span>
              {user.beta_tester && <Badge>BETA</Badge>}
            </p>
          </div>
        </Navigation>
        <main className="portal-main">
          <NotificationsSystem theme={theme} />
          <GlobalNotificationDisplayer />
          <Switch>
            <Route path={routes.monitor} component={Monitor} />
            <Route
              path={routes.communityRecipe}
              component={SharedRecipeDetail}
            />
            <Route path={routes.community} component={SharedRecipes} />
            <Route path={routes.brewery} component={Brewery} />
            <Route path={routes.createRecipe} component={CreateRecipe} />
            <Route path={routes.dashboard} component={DashboardPage} />
            <Route path={routes.profile} component={Profile} />
            <Route
              path={routes.myIngredients}
              component={CustomIngredientList}
            />
            <Route path={routes.privacyPolicy} component={PrivacyPolicy} />
            <Route path={routes.tcAuth} component={TermsAndConditions} />
            <Route path={routes.editRecipe} component={CreateRecipe} />
            <Route exact path={routes.newRecipe} component={CreateRecipe} />
            <Route path={routes.newVersionRecipe} component={CreateRecipe} />
            <Route
              path={routes.recipeDetailsWithVersion}
              component={RecipeDetail}
            />
            <Route path={routes.recipeDetails} component={RecipeDetail} />
            <Route path={routes.beers} component={BeerList} />

            <Redirect from={""} to={routes.brewery} />
            <Route component={NotFoundPage} />
          </Switch>
        </main>
        {/* // comment old zendesk support link
        <a
          className="support-button btn btn-primary"
          href="https://minibrew.zendesk.com/hc/"
          target="_blank"
        >
          <div style={{ fontSize: "16px" }}>
            <span className="ion-help-buoy"> Support</span>
          </div>
        </a>
        */}
      </div>
    );
  }
}

function mapStateToProps({ userInfo, auth, mqtt, routing, brewery }) {
  return { userInfo, auth, mqtt, routing, brewery };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserInformation: token =>
      dispatch(userActions.getUserInformation(token)),
    getSharedFavourites: () => dispatch(userActions.getUsersSharedFavourites()),
    signOut: () => dispatch(authActions.signOut()),
    removeUserData: () => dispatch(userActions.removeUserData()),
    connect: credentials => dispatch(mqttActions.connectToMQTT(credentials)),
    subscribeNotifications: userUUID =>
      dispatch(mqttActions.subscribeNotifications(userUUID))
  };
}

PortalLayout.propTypes = {
  match: PropTypes.object,
  userInfo: PropTypes.object,
  auth: PropTypes.object,
  mqtt: PropTypes.object,
  getUserInformation: PropTypes.func,
  signOut: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortalLayout);

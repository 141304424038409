import React from "react";
import moment from "moment";

import DeviceDropdown from "./DeviceDropdown/DeviceDropdown";
import StatusNeedsCleaning from "./StatusNeedsCleaning/StatusNeedsCleaning";
import StatusNeedsAcidCleaning from "./StatusNeedsAcidCleaning/StatusNeedsAcidCleaning";
import StatusDisconnected from "./StatusDisconnected/StatusDisconnected";
import StatusUserAction from "./StatusUserAction/StatusUserAction";
import StatusActive from "./StatusActive/StatusActive";
import StatusIdle from "./StatusIdle/StatusIdle";
import StatusIdleKeg from "./StatusIdleKeg/StatusIdleKeg";
import StatusUpdating from "./StatusUpdating/StatusUpdating";
import StatusSessionStarting from "./StatusSessionStarting/StatusSessionStarting";
import StatusBrewingDisconnected from "./StatusBrewingDisconnected/StatusBrewingDisconnected";
import PROCESS_TYPES from "@common/constants/processTypes";
import "./device-item.scss";

const DeviceItem = ({
  device,
  showModal,
  showWifiModal,
  showDeviceModal,
  showCleaningModal,
  showStopSessionModal,
  showUnregisterDeviceModal,
  // showAcidCleanModal, ENABLE ONCE ACID CLEANING IS SUPPORTED
  showAcidSupportModal
}) => {
  const activeDisconnected =
    (device.process_type == PROCESS_TYPES.PROC_BREWING ||
      device.process_type == PROCESS_TYPES.PROC_CLEAN_MINIBREW ||
      device.process_type == PROCESS_TYPES.PROC_FERMENTATION ||
      device.process_type == PROCESS_TYPES.PROC_SERVING) &&
    !device.online;
  const isDisconnected = !device.online && !activeDisconnected;
  const isStarting = device.is_starting;
  const isUpdating = !isDisconnected && !activeDisconnected && device.updating;
  const needsAcidCleaning =
    (device.user_action === 63 || device.needs_acid_cleaning) &&
    !isDisconnected &&
    !device.updating &&
    device.process_type === 0;
  const needsCleaning =
    device.user_action === 12 &&
    !isDisconnected &&
    !needsAcidCleaning &&
    !device.updating; // TODO: replace with constant when available
  const userAction =
    device.user_action !== 0 &&
    !isDisconnected &&
    !isUpdating &&
    !needsCleaning &&
    !needsAcidCleaning &&
    !activeDisconnected;
  const isIdle =
    device.process_type == PROCESS_TYPES.PROC_IDLE &&
    !isDisconnected &&
    !userAction &&
    !isUpdating &&
    !needsCleaning &&
    !needsAcidCleaning &&
    !isStarting;
  const isCleaning = device.process_type == PROCESS_TYPES.PROC_CLEAN_MINIBREW;
  const isActive =
    (device.process_type == PROCESS_TYPES.PROC_BREWING ||
      device.process_type == PROCESS_TYPES.PROC_CLEAN_MINIBREW ||
      device.process_type == PROCESS_TYPES.PROC_FERMENTATION ||
      device.process_type == PROCESS_TYPES.PROC_SERVING) &&
    !userAction &&
    !isUpdating &&
    !isDisconnected &&
    !activeDisconnected;
  const isKeg = device.device_type === 1;
  const isIdleProcess = device.process_type == PROCESS_TYPES.PROC_IDLE;

  return (
    <div className="brewery__device">
      <div className="device__header">
        <img
          src={device.image}
          className="device__image"
          alt="Minibrew image"
        />
        <div className="device__name">
          <div className="device__device-name">
            {isKeg ? device.beer_name : device.title}
          </div>
          <div className="device__status-text">{device.sub_title}</div>
        </div>
        <div className="device__menu">
          <DeviceDropdown
            showDeviceModal={showDeviceModal}
            device={device}
            showWifiModal={showWifiModal}
            showCleaningModal={showCleaningModal}
            showStopSessionModal={showStopSessionModal}
            isDisconnected={isDisconnected}
            showUnregisterDeviceModal={showUnregisterDeviceModal}
          >
            <button className="device__menu-button">
              <span className="ion-ios-more device__menu-icon" />
            </button>
          </DeviceDropdown>
        </div>
        <div className="clearfix" />
      </div>
      <div className="device__info">
        <div className="row">
          <div className="device__info-labels">
            {!needsCleaning && !isIdle && !isCleaning && (
              <React.Fragment>
                {!isKeg && <p>Name</p>}
                <p>Beerstyle</p>
              </React.Fragment>
            )}
            <p>Stage</p>
            <p>{device.user_action ? "Waiting" : "Time left"}</p>
            {isKeg && <p>Identifier</p>}
          </div>
          <div className="device__info-values">
            {!needsCleaning && !isIdle && !isCleaning && (
              <React.Fragment>
                {!isKeg && <p>{device.beer_name || "-"}</p>}
                <p>{device.beer_style || "-"}</p>
              </React.Fragment>
            )}
            <p>
              {device.stage}
              {device.target_temp && !isIdleProcess
                ? ` (${device.target_temp}°C)`
                : ""}
            </p>
            <p style={{ fontFamily: "Gotham-Bold" }}>
              {!isIdleProcess || device.status_time > 0
                ? moment.duration(device.status_time, "seconds").humanize()
                : "-"}
            </p>
            {isKeg && <p>{device.uuid}</p>}
          </div>
        </div>
      </div>
      {isUpdating && <StatusUpdating />}
      {needsCleaning && (
        <StatusNeedsCleaning
          showModal={showCleaningModal}
          deviceUUID={device.uuid}
        />
      )}
      {isDisconnected && (
        <StatusDisconnected showModal={() => showWifiModal(device)} />
      )}
      {userAction && <StatusUserAction sessionId={device.session_id} />}
      {isActive && (
        <StatusActive
          currentTemp={device.current_temp}
          targetTemp={device.target_temp}
          sessionId={device.session_id}
        />
      )}
      {activeDisconnected && (
        <StatusBrewingDisconnected showModal={() => showWifiModal(device)} />
      )}
      {isIdle && !isKeg && (
        <StatusIdle showModal={showModal} deviceUUID={device.uuid} />
      )}
      {isIdle && isKeg && <StatusIdleKeg />}
      {!isKeg && needsAcidCleaning && (
        <StatusNeedsAcidCleaning
          showModal={showAcidSupportModal}
          deviceUUID={device.uuid}
        />
      )}
      {isStarting && <StatusSessionStarting />}
    </div>
  );
};

export default DeviceItem;

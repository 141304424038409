import { DEVICE_ACTIONS as type } from "@common/constants/actionTypes";
import {
  MACH_CONN_STATUS,
  MACH_STATES
} from "@common/constants/sessionConstants";

const initialState = {
  list: [],
  isFetching: false,
  error: false,
  errorMessage: ""
};

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case type.GET_DEVICES:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: true,
        error: false
      });
    case type.GET_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: false,
        list: action.devices
      });
    case type.GET_DEVICES_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: false,
        error: true
      });
    default:
      return state;
  }
}

export const getAvailableDevices = state => {
  return state.list.filter(
    device =>
      device.connection_status === MACH_CONN_STATUS.MACH_CONN_STATUS_ONLINE &&
      device.current_state === MACH_STATES.MACH_STATE_IDLE &&
      device.user_action === 0
  );
};

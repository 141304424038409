import React, { useState } from "react";
import {
  Alert,
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Tabs,
  Tab
} from "react-bootstrap";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import {
  addDevice,
  editDevice,
  getDevices
} from "@common/services/deviceService";
import WifiContent from "../WifiModal/WifiContent";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import "./add-device-modal.scss";

const AddDeviceModal = ({ user, show, handleClose, fetchBrewery }) => {
  const [deviceID, setDeviceID] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [activeKey, setActiveKey] = useState(1);
  const [error, setError] = useState(null);
  const [deviceIsOnline, setDeviceIsOnline] = useState(false);

  const addNewDevice = e => {
    e.preventDefault();
    setError(null);
    setIsSaving(true);
    addDevice(deviceID)
      .then(() => {
        editDevice(deviceID, { custom_name: deviceName })
          .then(() => {
            setIsSaving(false);
            setActiveKey(2);
            pollIsDeviceOnline();
          })
          .catch(err => {
            setIsSaving(false);
            err.then(error => setError(handleError(error.device[0])));
          });
      })
      .catch(err => {
        setIsSaving(false);
        err.then(error => setError(handleError(error.device[0])));
      });
  };

  const pollIsDeviceOnline = () => {
    const intervalID = setInterval(() => {
      fetchBrewery();
      getDevices().then(res => {
        res.forEach(device => {
          if (device.uuid === deviceID && device.connection_status === 1) {
            analyticsService.track(ANALYTICS_TYPES.DEVICE_CONNECTED, {
              UserID: user.id,
              DeviceID: device.uuid
            });
            clearInterval(intervalID);
            setDeviceIsOnline(true);
            setActiveKey(3);
          }
        });
      });
    }, 8000);
  };

  const handleError = error => {
    if (error.indexOf("Object") == -1) {
      return error;
    } else {
      return "Device with this ID is not found.";
    }
  };

  const close = () => {
    setError(null);
    setActiveKey(1);
    setDeviceID("");
    setDeviceName("");
    setDeviceIsOnline(false);
    handleClose();
  };
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center" }}>ADD DEVICE</Modal.Title>
      </Modal.Header>
      <form onSubmit={addNewDevice}>
        <Modal.Body>
          <Tabs
            id="add-device-tabs"
            className="add-device__tabs"
            onSelect={() => {}} // Needed for react-bootstrap
            activeKey={activeKey}
          >
            <Tab
              eventKey={1}
              title={`1. Add device ${activeKey > 1 ? "✔" : ""}`}
              style={{ cursor: "normal" }}
              disabled
            >
              <div className="add-device__wrapper">
                <img
                  src={require("@assets/images/scan_qr.jpg")}
                  className="qrcode-image"
                />
                <FormGroup controlId="addDevice" className="add-device__form">
                  <div className="add-device__description">
                    Enter the device ID to start the setup process. This is
                    located under the QR code on the back of your MinBrew Base.
                    You don’t need to set up your Smart Kegs. They will appear
                    automatically during fermentation and serving.
                  </div>
                  <ControlLabel>Device ID</ControlLabel>
                  <FormControl
                    type="text"
                    value={deviceID}
                    placeholder="xxxxxxxxx-xxxxxxxx"
                    onChange={e => setDeviceID(e.target.value)}
                    required
                  />
                  <ControlLabel>Name your minibrew</ControlLabel>
                  <FormControl
                    type="text"
                    value={deviceName}
                    placeholder="Device name"
                    onChange={e => setDeviceName(e.target.value)}
                    required
                  />
                </FormGroup>
              </div>
              {error && (
                <div className="add-device__alert">
                  <Alert bsStyle="danger">{error}</Alert>
                </div>
              )}
            </Tab>
            <Tab
              eventKey={2}
              title={`2. Wi-Fi ${activeKey > 2 ? "✔" : ""}`}
              disabled
            >
              <WifiContent />
              {!deviceIsOnline && (
                <div style={{ textAlign: "center" }}>
                  <i className="ion-load-c spin" style={{ fontSize: "28px" }} />
                  <div>Connecting...</div>
                </div>
              )}
            </Tab>
            <Tab eventKey={3} title="3. Finish" disabled>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <img
                  src={require("@assets/images/cheers.svg")}
                  style={{ height: "150px" }}
                />
                <div
                  style={{
                    textAlign: "center",
                    fontFamily: "Gotham-Book",
                    marginTop: "10px"
                  }}
                >
                  Congratulations, your MiniBrew is connected!{" "}
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          {activeKey == 1 ? (
            <ButtonWithLoad
              isLoading={isSaving}
              type="submit"
              bsStyle="primary"
            >
              Next
            </ButtonWithLoad>
          ) : activeKey == 2 ? (
            ""
          ) : (
            <Button onClick={close} bsStyle="primary">
              Finish
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddDeviceModal;

import React from "react";
import { connect } from "react-redux";
import "./status-brewing-disconnected.scss";

const StatusBrewingDisconnected = ({ showModal }) => {
  return (
    <div className="device__status--brewing-disconnected" onClick={showModal}>
      <span className="ion-ios-bolt device__alert-icon" />
      <span className="device__alert-text"> Device is offline! </span>
      <span className="ion-ios-arrow-right device__chevron" />
    </div>
  );
};

export default connect(undefined, undefined)(StatusBrewingDisconnected);

import React, { useState, useEffect } from "react";
import { timeout, catchError, tap, filter } from "rxjs/operators";
import last from "lodash/last";
import head from "lodash/head";
import get from "lodash/get";
import UserActionManager from "../UserActionManager/UserActionManager";
import ChartHeader from "../ChartHeader/ChartHeader";
import Chart from "../Chart/Chart";
import ConnectionLostOverlay from "./ConnectionLostOverlay/ConnectionLostOverlay";
import * as historicSessionService from "@brewery/services/historicSessionService";
import PHASE_STATUS from "@common/constants/phaseStatus";
import mqttService from "../../../mqttService";
import mqttUtil from "../../../mqttUtil";
import chartUtil from "./chartUtil";
import PhasesProgress from "../PhasesProgress/PhasesProgress";
import {
  MAX_BREWING_TEMP,
  MAX_FERMENTING_TEMP,
  MAX_SERVING_TEMP,
  MAX_CLEANING_TEMP
} from "@common/constants/chartOptions";
import { MACH_STATES } from "@common/constants/sessionConstants";

/**
 * Listens to the frequentlog channel and takes care of useractions
 */
const ChartManager = ({ active, session, options, type, open, mqtt }) => {
  const [progressWidths, setProgressWidths] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState([]);
  const [chartOptions, setChartOptions] = useState(options);
  const [lastFrequentLog, setLastFrequentLog] = useState();
  const [brokerMachineConnected, setBrokerMachineConnected] = useState(true);
  /**
   * Using a local frequent logs array, we can circumvent the constant
   * execution of useEffect. We only execute useEffect when the active state changes
   * and not when frequentlogs update.
   */
  let localFrequentLogs = [];
  let chartOptionsCache = JSON.parse(JSON.stringify(options)); // make a deep copy of options prop
  let processSummary = null;
  let historics = null;

  useEffect(() => {
    let subscriptions = [];

    if (active) {
      setBrokerMachineConnected(false);
      fetchHistorics()
        .then(response => {
          historics = response.data;
        })
        .catch(() => {
          historics = null;
        });

      const frequentLogResponseSubscription = mqttService.frequentLogResponse$.subscribe(
        frequentlogResponse => {
          if (frequentlogResponse && frequentlogResponse.processSummaryList) {
            processSummary = frequentlogResponse.processSummaryList;
          }
        }
      );
      const notificationSubscription = mqttService.notification$.subscribe(
        notification => {
          if (session.id === notification.session) {
            // Handle Process summary change
            if (mqttUtil.processSummaryChanged(notification)) {
              // TODO: UPDATE CHART OPTIONS
              processSummary = notification.processSummaryList;
            }
          }
        }
      );

      let frequentlogWithTimeout$ = mqttService.frequentLog$.pipe(
        timeout(5000),
        tap(log => {
          if (log.session && log.session !== session.id) {
            // find if there are any subscbiptions which are on different devices from current session device
            const unused = mqttUtil.findNoNUsedFrequentLogSubscriptions(
              session.device.uuid,
              mqttService.subscriptions
            );
            unused.forEach(topic => {
              mqttService.unsubscribe(topic);
            });

            throw new Error("session.ID and and log.sessio are different");
          }
        }),
        filter(log => log.session === 0 || log.session === session.id),
        catchError(() => {
          if (mqttService.isConnected()) {
            const { uuid } = session.device;
            mqttService.publishRequestFrequentLogs(uuid, mqtt.clientUUID);
          }
          setBrokerMachineConnected(false);
          return frequentlogWithTimeout$;
        })
      );

      let frequentlogSubscription = frequentlogWithTimeout$.subscribe(
        frequentlog => {
          // frequentlog = require("../../../../__mocks__/pd2532/frequentlog.json");
          // processSummary = require("../../../../__mocks__/pd2532/processsummary.json");
          // historics = require("../../../../__mocks__/pd2532/historics.json");
          setBrokerMachineConnected(true);
          setLastFrequentLog(frequentlog);
          localFrequentLogs = [...localFrequentLogs, frequentlog.logsList[0]];

          const updatedChartOptions = chartUtil.updateChartOptions(
            frequentlog,
            chartOptionsCache,
            processSummary,
            historics
          );

          const maxTemp = getMaxTemp(type);
          updatedChartOptions.series[1].data = chartUtil.filterOutOfRangeData(
            maxTemp,
            updatedChartOptions.series[1].data
          );
          updatedChartOptions.series[0].data = chartUtil.filterOutOfRangeData(
            maxTemp,
            updatedChartOptions.series[0].data
          );
          updatedChartOptions.series[3].data = chartUtil.filterOutOfRangeData(
            maxTemp,
            updatedChartOptions.series[3].data
          );

          let chartAxisData = []
            .concat(updatedChartOptions.series[3].data) // historics
            .concat(updatedChartOptions.series[0].data) // realtime
            .concat(updatedChartOptions.series[1].data); // prediction
          const sortedChartAxisData = chartAxisData.sort(
            (x, y) =>
              x.value[0].getTime() - y.value[0].getTime() || x.phase - y.phase
          );

          let groupedDataByTime = [];
          let prevEntry = {
            phase: 0
          };
          let indexPhase = 0;
          sortedChartAxisData.forEach(entry => {
            if (entry.phase !== prevEntry.phase) {
              indexPhase++;

              groupedDataByTime[indexPhase] = {
                phase: entry.phase,
                data: [entry]
              };

              prevEntry = entry;
            } else {
              groupedDataByTime[indexPhase].data.push(entry);
            }
          });

          let fullPeriod = 0;
          const firstProcess = head(groupedDataByTime.filter(Boolean));
          const lastProcess = last(groupedDataByTime);
          const firstProcessDate = get(firstProcess, "data.0.value.0");
          const lastProcessDate = get(last(lastProcess.data), "value.0");
          if (firstProcessDate && lastProcessDate) {
            fullPeriod = lastProcessDate.getTime() - firstProcessDate.getTime();
          }

          const percentages = [];

          groupedDataByTime.forEach((entry, index) => {
            let startDate = null;
            let endDate = null;
            if (entry.data.length > 1) {
              startDate = entry.data[0].value[0];
              endDate = entry.data[entry.data.length - 1].value[0];
            } else {
              const prevPeriod = groupedDataByTime[index - 1];
              startDate = get(prevPeriod, [
                "data",
                get(prevPeriod, "data.length") - 1,
                "value",
                0
              ]);
              endDate = get(entry, "data.0.value.0");
            }

            if (endDate && startDate) {
              const period = endDate.getTime() - startDate.getTime();
              const percentage = (period / fullPeriod) * 100;
              percentages.push({
                phase: entry.phase,
                percentage,
                startDate,
                endDate
              });
            }
          });
          // remove items with small percentage
          // and combine two phases when they are the same after removing empty one
          const percentagesCombined = percentages
            .filter(p => p.percentage > 0.9)
            .reduce((prev, current) => {
              const lastItem = prev[prev.length - 1];
              if (lastItem && lastItem.phase === current.phase) {
                prev[prev.length - 1].percentage += current.percentage;
                prev[prev.length - 1].endDate = current.endDate;
              } else {
                prev.push(current);
              }
              return prev;
            }, []);

          // sometimes calculations are not very correct,
          // in case total phases is less than 100% we incrase the size of last phase.
          const percentagesSum = percentagesCombined.reduce(
            (prev, current) => prev + current.percentage,
            0
          );
          if (percentagesSum < 99 && last(percentagesCombined)) {
            const difference = 100 - percentagesSum;
            const lastItem = last(percentagesCombined);
            lastItem.percentage = lastItem.percentage + difference;
          }

          const progressPercentages = [];
          const currentTimestampNormalized =
            frequentlog.logsList[0].timestampMs;

          percentagesCombined.forEach(entry => {
            let progress = 0;
            const { startDate, endDate, phase } = entry;

            if (
              typeof startDate.getTime === "function" &&
              typeof endDate.getTime === "function" &&
              currentTimestampNormalized > startDate.getTime() &&
              currentTimestampNormalized < endDate.getTime()
            ) {
              progress =
                ((currentTimestampNormalized - startDate.getTime()) /
                  (endDate.getTime() - startDate.getTime())) *
                100;
            } else if (currentTimestampNormalized > startDate.getTime()) {
              progress = 100;
            } else {
              progress = 0;
            }

            progressPercentages.push({
              phase,
              progress
            });
          });

          setProgressPercentage(progressPercentages);
          setProgressWidths(percentagesCombined);
          setChartOptions(updatedChartOptions);
        }
      );
      subscriptions = [
        frequentLogResponseSubscription,
        notificationSubscription,
        frequentlogSubscription
      ];
    } else {
      fetchHistorics()
        .then(response => {
          historics = response.data;
          const updatedChartOptions = chartUtil.updateChartHistoricsOnly(
            chartOptionsCache,
            historics
          );
          setChartOptions(updatedChartOptions);
        })
        .catch(() => {
          historics = null;
        });
    }
    return () => {
      setChartOptions(options);
      setLastFrequentLog(null);
      subscriptions.forEach(subscription => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    };
  }, [active, session]);

  const fetchHistorics = () => {
    let getHistorics;
    switch (type) {
      case PHASE_STATUS.BREWING:
        getHistorics = historicSessionService.getHistoricBrewing;
        break;
      case PHASE_STATUS.FERMENTATION:
        getHistorics = historicSessionService.getHistoricFermenting;
        break;
      case PHASE_STATUS.SERVING:
        getHistorics = historicSessionService.getHistoricServing;
        break;
      case PHASE_STATUS.CLEANING:
        getHistorics = historicSessionService.getHistoricCIP;
    }

    return getHistorics(session.id);
  };

  const isUpdating = session
    ? session.device.current_state === MACH_STATES.MACH_STATE_UPDATING
    : null;
  return (
    <div style={{ marginBottom: "20px" }}>
      <UserActionManager
        lastFrequentLog={lastFrequentLog}
        active={active}
        session={session}
      />
      <ChartHeader lastFrequentLog={lastFrequentLog} session={session} />
      <div>
        {type !== PHASE_STATUS.SERVING && active && (
          <PhasesProgress
            progressWidths={progressWidths}
            progressPercentages={progressPercentage}
          />
        )}
        <Chart active={active} option={chartOptions} open={open} />
      </div>
      {!brokerMachineConnected && active && !isUpdating && (
        <ConnectionLostOverlay />
      )}
    </div>
  );
};

export default ChartManager;

const getMaxTemp = type => {
  switch (type) {
    case PHASE_STATUS.BREWING:
      return MAX_BREWING_TEMP;
    case PHASE_STATUS.FERMENTATION:
      return MAX_FERMENTING_TEMP;
    case PHASE_STATUS.SERVING:
      return MAX_SERVING_TEMP;
    case PHASE_STATUS.CLEANING:
      return MAX_CLEANING_TEMP;
  }

  return 100000;
};

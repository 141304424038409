import React from "react";
import { Alert, Row, Col } from "react-bootstrap";

const customAlert = props => {
  return (
    <Row>
      <Col sm={4} smOffset={4}>
        <Alert bsStyle="danger">
          <span className="ion-alert-circled"> {props.msg}</span>
        </Alert>
      </Col>
    </Row>
  );
};

export default customAlert;

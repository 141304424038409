import React from "react";
import { Field } from "redux-form";
import { Button, Row, Col, ControlLabel } from "react-bootstrap";

import CustomField from "../../../CustomField/CustomField";
import TypeAhead from "../../TypeAhead/TypeAhead";
import { INGREDIENT_ENDPOINTS as ENDPOINTS } from "@common/constants/recipeConstants";
import { normalizeNumber } from "../../../../../../util/recipeHelper";

const normalizeAmount = normalizeNumber(3, 2);
const normalizeDuration = normalizeNumber(3, 0);

const INGREDIENT_TYPES = {
  FERM: "Fermentable",
  ADJ: "Adjunct"
};

const ingredients = ({ fields, change, addItem, removeItem, amount }) => {
  const resetIngredient = index => {
    change(`boiling[0].other_ingredients[${index}].ingredient_id`, null);
    change(`boiling[0].other_ingredients[${index}].ingredient_name`, null);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <h3>Other Ingredients</h3>
      <Button
        className="AddButton"
        type="button"
        disabled={amount > 5}
        onClick={addItem(fields)}
      >
        <i className="icon ion-plus" /> INGREDIENT
      </Button>

      {!fields.length ? null : (
        <div>
          {fields.map((ingredient, index) => {
            return (
              <div key={index} className="list-item">
                <Row>
                  <Col sm={2}>
                    {!index && <ControlLabel>Type</ControlLabel>}
                    <div>
                      <Field
                        name={`${ingredient}.ingredient_type`}
                        component="select"
                        className="form-control"
                        onChange={() => resetIngredient(index)}
                      >
                        {Object.keys(INGREDIENT_TYPES).map(key => (
                          <option value={key} key={key}>
                            {INGREDIENT_TYPES[key]}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </Col>
                  <Field
                    name={`${ingredient}.ingredient_name`}
                    component={TypeAhead}
                    label="Name"
                    rowIndex={index}
                    change={change}
                    forceValidation
                    location={`boiling[0].other_ingredients[${index}]`}
                    // HACK: Need to refactor to not use endpoint, data is coming from redux
                    endpoint={`/${
                      fields.get(index).ingredient_type == "FERM"
                        ? "boilfermentables"
                        : ENDPOINTS[fields.get(index).ingredient_type]
                    }/`}
                    attrMap={{
                      id: "ingredient_id",
                      dry_yield: "ingredient_dry_yield",
                      moisture: "ingredient_moisture"
                    }}
                    smSize={3}
                  />
                  <Field
                    name={`${ingredient}.amount`}
                    type="number"
                    component={CustomField}
                    rowIndex={index}
                    placeholder="0"
                    label="Amount (gr)"
                    smSize={3}
                    step="0.01"
                    normalize={normalizeAmount}
                  />
                  <Field
                    name={`${ingredient}.duration`}
                    type="number"
                    component={CustomField}
                    label="Duration (min)"
                    rowIndex={index}
                    smSize={3}
                    normalize={normalizeDuration}
                  />
                  {fields.get(index).ibu && (
                    <Field
                      name={`${ingredient}.ibu`}
                      component={CustomField}
                      label="IBU"
                      rowIndex={index}
                      readOnly={true}
                      smSize={1}
                    />
                  )}
                  <Button
                    className="remove-btn"
                    style={{ marginTop: index ? "4px" : "31px" }}
                    type="button"
                    title="Remove ingredient"
                    onClick={removeItem(fields, index)}
                  >
                    <i className="icon ion-close" />
                  </Button>
                </Row>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ingredients;

import React from "react";
import PulseMinibrew from "@common/components/PulseMinibrew/PulseMinibrew";

const ConnectionLostOverlay = () => {
  return (
    <div
      style={{
        top: "0",
        left: "0",
        position: "absolute",
        color: "white",
        backgroundColor: "rgba(29, 34, 40,  0.9)",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "3px"
      }}
    >
      <PulseMinibrew />
      <div>Connection lost, reconnecting...</div>
    </div>
  );
};

export default ConnectionLostOverlay;

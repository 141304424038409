import React from "react";
import ChartStatus from "../ChartStatus/ChartStatus";

const Temperatures = ({ current, target }) => {
  const items = [
    {
      label: "current",
      value: (current ? current.toFixed() : 0) + "°",
      color: "green"
    },
    { label: "target", value: (target ? target.toFixed() : 0) + "°" }
  ];

  return <ChartStatus items={items} />;
};

export default Temperatures;

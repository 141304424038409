import React from "react";
import PropTypes from "prop-types";
import "./navigation.scss";
import NotificationButton from "@common/components/GlobalNotificationDispatcher/NotificationButton";
import { store } from "../../../store";
import * as userActions from "@profile/actions/userActions";
import * as authActions from "@auth/actions/authenticationActions";
import { connect } from "react-redux";
import { routes } from "@common/constants/urls";
import { push } from "react-router-redux";

/**
 * <Navigation handleMenuClick={function} showMenu={true||false} showBurger={true||false}>
 *  <Button> put desired html elements here </Button>
 * </Navigation>
 * @param {Array} props.children html elements that will be put inside this component
 * @param {function} props.handleMenuClick function to toggle the menu
 * @param {boolean} props.showMenu boolean that determines if the menu is shown or hidden
 * @param {boolean} props.showBurger boolea n that determines if the hamburger menu is shown or hidden
 */

const Navigation = props => {
  const exit = () => {
    if (props.portal === false) {
      props.signOut();
      props.removeUserData();
      props.goToHome();
    } else {
      props.goToBrewery();
    }
  };
  const getColClass = () => {
    if (props.portal === true) {
      return " col-sm-3 ";
    } else {
      return " col-sm-4 ";
    }
  };

  return (
    <nav className={"header " + props.color}>
      <div className="container-fluid">
        <div className="row">
          <div className={"header__minibrew col-xs " + getColClass()}>
            {(props.colorLogo && (
              <img
                src={require("../../../assets/images/MB_logo_color.png")}
                className="header__minibrew__logo"
                alt="MINIBREW logo"
                onClick={exit}
              />
            )) || (
              <img
                src={require("../../../assets/images/MB_logo.png")}
                className="header__minibrew__logo"
                alt="MINIBREW logo"
                onClick={exit}
              />
            )}

            {props.showBurger && (
              <div>
                <span
                  className={
                    (props.showMenu ? "ion-close-round" : "ion-navicon-round") +
                    " header__minibrew__hamburger visible-xs"
                  }
                  onClick={props.handleMenuClick}
                />
              </div>
            )}
            {props.version && (
              <div className="header__minibrew__title hidden-sm hidden-xs hidden-md">
                <p>BREWERY</p>
                <p>
                  PORTAL{" "}
                  <small style={{ color: "#cfcecd" }}>
                    {process.env.VERSION || "dev"}
                  </small>
                </p>
              </div>
            )}
            {(props.auth && (
              <div className="header__minibrew__title">
                <p>FOR THE BREWERS</p>
                <p>OF TOMORROW</p>
              </div>
            )) || (
              <div className="header__minibrew__title hidden-sm hidden-xs hidden-md">
                <p>BREWERY</p>
                <p>
                  PORTAL{" "}
                  <small style={{ color: "#cfcecd" }}>
                    {process.env.VERSION || "dev"}
                  </small>
                </p>
              </div>
            )}

            {props.showBell && (
              <NotificationButton
                store={store}
                className="header__minibrew__hamburger visible-xs visible-sm"
              />
            )}
          </div>
          {props.children}
        </div>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  handleMenuClick: PropTypes.func,
  showMenu: PropTypes.bool,
  showBurger: PropTypes.bool,
  logoSrc: PropTypes.string
};
function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(authActions.signOut()),
    removeUserData: () => dispatch(userActions.removeUserData()),
    goToHome: () => dispatch(push(routes.home)),
    goToBrewery: () => dispatch(push(routes.brewery))
  };
}
function connectWithStore(store, WrappedComponent, ...args) {
  let ConnectedWrappedComponent = connect(...args)(WrappedComponent);
  return function(props) {
    return <ConnectedWrappedComponent {...props} store={store} />;
  };
}
export default connectWithStore(store, Navigation, null, mapDispatchToProps);

import * as type from "@common/constants/actionTypes";
import { push } from "react-router-redux";
import * as authenticationService from "../services/authenticationService";
import { routes } from "@common/constants/urls";
import mqttService from "@monitor/mqttService";

const attemptAuthentication = credentials => {
  return { type: type.AUTHENTICATE_USER, credentials };
};

const authenticationSuccess = token => {
  return { type: type.AUTHENTICATE_USER_SUCCESS, token };
};

const authenticationError = message => {
  return { type: type.AUTHENTICATE_USER_ERROR, message };
};

export const authenticateUser = (credentials, headers) => {
  return dispatch => {
    // email to lowercase, because the backend lowercases all emails.
    credentials.email = credentials.email.toLowerCase();
    dispatch(attemptAuthentication(credentials));
    authenticationService
      .authenticateUser(credentials, headers)
      .then(data => {
        // Just in case the server won't respond with an error code but won't send the jwt either
        if (!data.token)
          dispatch(authenticationError("Error retrieving token."));
        else {
          localStorage.setItem("token", data.token);
          localStorage.setItem("exp", data.exp * 1000);
          dispatch(authenticationSuccess(data.token));
          dispatch(push(routes.brewery));
        }
      })
      .catch(err => {
        // TODO: consider handling errors at a different point in the application (homogenize responses from backend?)
        if (typeof err === "string") dispatch(authenticationError(err));
        else if (err.message) dispatch(authenticationError(err.message));
        else
          err.then(() => {
            dispatch(authenticationError("Incorrect credentials")); // Some error messages come with 'custom permission django'
          });
      }); // Have to resolve res.json() promise
  };
};

export const signOut = () => {
  // TODO: would be cleaner to take care of setting and removing the token in a middleware
  if (mqttService.connected == true) {
    mqttService.end(() => {
      console.log("ended connection");
    });
  }
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("exp");
  return {
    type: type.SIGN_OUT
  };
};

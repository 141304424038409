import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab } from "react-bootstrap";

import RecipeVersionItem from "./RecipeVersionItem";

class RecipeVersions extends React.Component {
  state = {};

  renderNoRecipesFound() {
    if (this.props.recipes.length <= 0) {
      return <div className="recipe-detail__no-recipes"> No recipes </div>;
    }
  }

  renderNoInStoreRecipesFound() {
    if (
      this.props.recipes.filter(recipe => recipe.status === "PUBLISHED") <= 0
    ) {
      return (
        <div className="recipe-detail__no-recipes"> No recipes in store</div>
      );
    }
  }

  render() {
    return (
      <div className="recipe-detail__versions">
        <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
          <Tab eventKey={1} title="VERSIONS">
            {this.props.recipes
              .sort(
                (recipeA, recipeB) =>
                  +recipeB.version_name - +recipeA.version_name
              )
              .map((recipe, index) => (
                <RecipeVersionItem
                  selectedRecipe={this.props.selectedRecipe}
                  key={index}
                  recipe={recipe}
                  recipeIndex={index}
                  handleClick={this.props.handleClick}
                />
              ))}
            {this.renderNoRecipesFound()}
          </Tab>
        </Tabs>
      </div>
    );
  }
}

RecipeVersions.propTypes = {
  recipes: PropTypes.arrayOf(PropTypes.object),
  handleClick: PropTypes.func
};

export default RecipeVersions;

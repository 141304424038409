// Set up your root reducer here...
import { combineReducers } from "redux";
import auth from "@auth/reducers/authenticationReducer";
import mqtt from "@common/reducers/mqttReducer";
import userInfo from "@profile/reducers/userReducer";
import wifi from "@common/reducers/WifiReducer";
import beers from "@beers/reducers/beerReducer";
import brewery from "@brewery/reducers/breweryReducer";
import device from "@common/reducers/deviceReducer";
import ingredients from "@beers/reducers/ingredientReducer";
import { routerReducer } from "react-router-redux";
import { loadingBarReducer } from "react-redux-loading-bar";
import { reducer as reduxFormReducer } from "redux-form";
import { reducer as notificationsReducer } from "reapop";

const rootReducer = combineReducers({
  auth,
  mqtt,
  userInfo,
  wifi,
  beers,
  brewery,
  device,
  ingredients,
  loadingBar: loadingBarReducer,
  routing: routerReducer,
  form: reduxFormReducer,
  notifications: notificationsReducer()
});

export default rootReducer;

import React, { useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { FormGroup, FormControl, Alert } from "react-bootstrap";
import TitleBar from "@common/components/TitleBar/TitleBar";
import Container from "@common/components/Container/Container";
import { activateCoupon } from "@auth/services/authenticationService";
import { routes } from "@common/constants/urls";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import * as userActions from "@profile/actions/userActions";
import "./activate-coupon.scss";

const ActivateCoupon = ({ goToBrewery, getUserInformation, goToSubscribe }) => {
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEnterSubmit = e => {
    if (e.key === "Enter") {
      activate();
    }
  };

  const activate = () => {
    setLoading(true);
    setError(null);
    activateCoupon(code)
      .then(() => {
        getUserInformation();
        setTimeout(() => {
          setLoading(false);
          goToBrewery();
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
        setError("Invalid coupon code");
      });
  };
  return (
    <div>
      <TitleBar
        title="Activate Subscription"
        displayGoBack={true}
        goBack={goToSubscribe}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ maxWidth: "450px" }}>
          <Container>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <img
                src={require("@assets/images/subscribe.svg")}
                style={{ height: "120px", marginBottom: "15px" }}
              />
              <label
                style={{
                  fontFamily: "Gotham-Book",
                  fontStyle: "italic",
                  textAlign: "center"
                }}
              >
                please enter the activation code that was provided by MiniBrew
                or a MiniBrew reseller
              </label>
              <FormGroup>
                <FormControl
                  name="code"
                  type="text"
                  value={code}
                  placeholder="xxxx-xxxx-xxxx-xxxx"
                  onChange={e => setCode(e.target.value)}
                  onKeyPress={handleEnterSubmit}
                  required
                />
              </FormGroup>
              <ButtonWithLoad
                isLoading={loading}
                bsStyle="primary"
                onClick={activate}
              >
                Activate
              </ButtonWithLoad>
              {error && (
                <Alert
                  bsStyle="danger"
                  style={{ marginTop: "10px" }}
                  onDismiss={() => setError(null)}
                >
                  {error}
                </Alert>
              )}
            </div>
          </Container>
          {error && (
            <p style={{ textAlign: "center" }}>
              Please click{" "}
              <a
                href={routes.couponHelpUrl}
                target="_blank"
                name="Coupon Code Help"
              >
                here
              </a>{" "}
              if your coupon code is not valid.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getUserInformation: token =>
      dispatch(userActions.getUserInformation(token)),
    goToBrewery: () => dispatch(push(routes.brewery)),
    goToSubscribe: () => dispatch(push(routes.subscribe))
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ActivateCoupon);

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { requestPermission } from "./GlobalNotificationDispatcher";
import { connect } from "react-redux";
import * as userActions from "@profile/actions/userActions";

class NotificationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      undefined: false
    };
  }
  iOS = () => {
    return (
      ["iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  };

  handleClick = () => {
    if (this.iOS() === true) {
      if (
        window.confirm(
          "This feature is not available on iOS browsers. Visit the App Store to download the MiniBrew App for notifications."
        )
      ) {
        window.location.href =
          "https://apps.apple.com/nl/app/minibrew/id1097779004";
      }
    }
    if (this.props.userInfo.notifications === true) {
      this.props.setNotificationPermission(false);
    } else {
      requestPermission();
    }
  };

  render() {
    const iconStyle = { fontSize: "18px", fontWeight: 800 };
    if (this.props.userInfo.notifications == false) {
      return (
        <Button
          style={{
            borderRadius: 25,
            padding: "4px 10px",
            marginLeft: 20,
            marginTop: 20
          }}
          onClick={this.handleClick}
        >
          <i style={iconStyle} className="ion-ios-bell-outline" />
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            borderRadius: 25,
            padding: "4px 10px",
            marginTop: 20,
            backgroundColor: "#fdcc00",
            marginLeft: 20
          }}
          onClick={this.handleClick}
        >
          <i style={iconStyle} className="ion-ios-bell-outline" />
        </Button>
      );
    }
  }
}
const mapStateToProps = ({ userInfo }) => {
  return { userInfo };
};
function mapDispatchToProps(dispatch) {
  return {
    setNotificationPermission: permission =>
      dispatch(userActions.setNotificationPermission(permission))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationButton);

import React from "react";
import RootCloseWrapper from "react-overlays/lib/RootCloseWrapper";
import { MenuItem } from "react-bootstrap";

class BeerActionsDropdownMenu extends React.Component {
  triggerRootCloseEvent = event => {
    this.props.onClose(event, { source: "rootClose" });
  };
  render() {
    const { editBeer, deleteBeer } = this.props;
    return (
      <RootCloseWrapper
        event="click"
        disabled={!this.props.open}
        onRootClose={this.triggerRootCloseEvent}
      >
        <div className="dropdown-menu header__account__menu">
          <MenuItem eventKey="1" onSelect={editBeer}>
            Edit
          </MenuItem>
          <MenuItem eventKey="2" onSelect={deleteBeer}>
            Delete
          </MenuItem>
        </div>
      </RootCloseWrapper>
    );
  }
}

export default BeerActionsDropdownMenu;

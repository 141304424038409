import React from "react";
import { Button } from "react-bootstrap";
import "./ButtonWithLoad.scss";

const ButtonWithLoad = ({
  isLoading = false,
  disabled,
  loadingLabel = "Loading…",
  children,
  className,
  ...rest
}) => {
  return (
    <Button disabled={disabled || isLoading} {...rest} className={className}>
      {isLoading ? <React.Fragment>{loadingLabel}</React.Fragment> : children}
    </Button>
  );
};

export default ButtonWithLoad;

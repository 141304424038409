import { get, patch, post } from "@common/services/request";
import { apiEndpoints } from "@common/constants/urls";

export const updateUserDetails = data => {
  const fd = new FormData();
  const { image, ...rest } = data;

  image && fd.append("image", image); // Only add image if set
  fd.append("json_data_part", JSON.stringify(rest));

  return patch(apiEndpoints.me, fd, { "Content-Type": "multipart/form-data" });
};

export const getCurrentUser = () => get(apiEndpoints.me);

export const getCurrentSubscription = () => get(apiEndpoints.subscription);

export const cancelSubscription = subscription =>
  post(apiEndpoints.cancel, subscription);

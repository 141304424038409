import React from "react";
import { ProgressBar, Tooltip, OverlayTrigger } from "react-bootstrap";
import PHASES from "@common/constants/phases";

const progressTooltip = (phaseName, percentage) => (
  <Tooltip id="tooltip-top">
    {phaseName} - {percentage}
  </Tooltip>
);

const PhasesProgress = ({ progressWidths, progressPercentages }) => {
  return (
    <div className="progress-container">
      {progressWidths.map(({ percentage, phase }, index) => {
        const currentProgressPercentages = progressPercentages[index];
        let currentProgress = 0;
        if (currentProgressPercentages) {
          const { progress } = currentProgressPercentages;
          currentProgress = progress;
        }
        return (
          <OverlayTrigger
            key={index}
            placement="top"
            overlay={progressTooltip(
              PHASES[phase],
              currentProgress
                ? currentProgress.toFixed(1).toString() + "%"
                : "Not started"
            )}
          >
            <ProgressBar
              now={currentProgress}
              style={{
                width: `calc(${percentage}% - 2px)`,
                display: percentage < 0.9 ? "none" : null
              }}
              className={
                currentProgress <= 0
                  ? "monitoring__progressbar progress-not-active"
                  : "monitoring__progressbar"
              }
              label={
                currentProgress >= 100 ? (
                  <span className="progressbar-label">
                    {PHASES[phase]}
                    <i className="ion-checkmark-round" />
                  </span>
                ) : (
                  <span className="progressbar-label">{PHASES[phase]}</span>
                ) // NEED A NEW WAY TO PASS LABELS TO THIS COMPONENT
              }
            />
          </OverlayTrigger>
        );
      })}
      <div className="clearfix" />
    </div>
  );
};

export default PhasesProgress;

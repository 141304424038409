import React, { useEffect, useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import {
  getSharedRecipe,
  createBeer,
  createRecipe,
  favoriteSharedBeer,
  unFavoriteSharedBeer
} from "@beers/services/recipeService";
import BrewWater from "../CreateRecipe/Recipe/BrewWater/BrewWater";
import Mashing from "../RecipeDetail/RecipeDetails/RecipeDetailMashing";
import Boil from "../RecipeDetail/RecipeDetails/RecipeDetailBoil";
import Fermentation from "../RecipeDetail/RecipeDetails/RecipeDetailFerm";
import ContentWrapper from "@common/components/ContentWrapper/ContentWrapper";
import TitleBar from "@common/components/TitleBar/TitleBar";
import styled from "styled-components";
import BeerProfileBar from "@common/components/BeerProfileBar/BeerProfileBar";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import * as userActions from "@profile/actions/userActions";
import SharedRecipeNote from "./SharedRecipeNote";
import "./share-recipe-detail.scss";

const Header = ({ number, stageName }) => (
  <h2>
    <span className="step">{number}</span>
    &nbsp;
    <span className="step-title"> {stageName} </span>
  </h2>
);

const RemoveInnerMargin = styled.div`
  margin: -15px;
`;

const Sticky = styled.div`
  position: sticky;
  z-index: 10;
  top: 79px;
`;
const SharedRecipeDetail = props => {
  const id = props.match.params.id;
  const [recipe, setRecipe] = useState(null);
  const [isCloning, setIsCloning] = useState(false);

  useEffect(() => {
    function getRecipe() {
      getSharedRecipe(id)
        .then(response => {
          setRecipe(response);
        })
        .catch(() => setRecipe(null));
    }
    getRecipe();
  }, []);

  const title = !recipe
    ? "Loading..."
    : `${recipe.beer_name} v${recipe.version_name}`;

  const mashInWater = useMemo(() => {
    let totalMashInWater = 0;
    if (recipe) {
      recipe.mashing.forEach(step => {
        totalMashInWater += +step.mash_in_water;
      });
    }
    return totalMashInWater;
  }, [recipe]);

  const getBeerInfo = useCallback(() => {
    return { name: recipe.beer.name, style: recipe.beer.style.id };
  }, [recipe]);

  const cloneRecipe = () => {
    setIsCloning(true);
    createBeer(getBeerInfo())
      .then(response => {
        const copy = JSON.parse(JSON.stringify(recipe));
        copy.beer_id = response.id;
        copy.shared = false;
        createRecipe(copy).then(() => {
          setIsCloning(false);
          props.history.push("/beers/" + response.id);
        });
      })
      .catch(() => setIsCloning(false));
  };

  const toggleFavoriteBeer = () => {
    const toggle = recipe.favourite ? unFavouriteBeer : favouriteBeer;
    toggle();
  };

  const favouriteBeer = () => {
    favoriteSharedBeer(recipe.beer_id).then(() => {
      props.getSharedFavourites();
      setRecipe({ ...recipe, favourite: true });
    });
  };

  const getFavouriteID = () => {
    const favourite = props.userInfo.sharedFavourites.find(
      x => x.shared_beer == recipe.beer_id
    );
    return favourite.id;
  };

  const unFavouriteBeer = () => {
    unFavoriteSharedBeer(getFavouriteID()).then(() => {
      props.getSharedFavourites();
      setRecipe({ ...recipe, favourite: false });
    });
  };

  return (
    <div>
      <TitleBar
        title={title}
        subtitle={
          recipe &&
          `${recipe.beer.style.name} by ${recipe.beer.brewer.name || "Unkown"}`
        }
      />
      {recipe && (
        <>
          <Sticky>
            <BeerProfileBar beerProfile={recipe} />
          </Sticky>
          <ContentWrapper>
            <div
              className="flex"
              style={{ marginTop: "20px", justifyContent: "flex-end" }}
            >
              <ButtonWithLoad
                isLoading={isCloning}
                bsStyle="default"
                onClick={cloneRecipe}
              >
                Clone
              </ButtonWithLoad>

              <div className="love-button" onClick={toggleFavoriteBeer}>
                {recipe.favourite ? (
                  <i
                    className="icon ion-ios-heart"
                    style={{
                      fontSize: 28,
                      lineHeight: 0,
                      alignSelf: "center",
                      color: "#fa4c39"
                    }}
                  />
                ) : (
                  <i className="love-icon icon ion-ios-heart-outline" />
                )}
              </div>
            </div>
            <RemoveInnerMargin>
              <SharedRecipeNote note={recipe.public_note} />
            </RemoveInnerMargin>

            <RemoveInnerMargin>
              <BrewWater
                batchSize={6}
                totalWater={recipe.water_amount}
                preMixWater={mashInWater}
                kettleWater={recipe.water_amount - mashInWater}
              />
            </RemoveInnerMargin>
            <Mashing
              header={<Header number={1} stageName="Mashing" />}
              currentRecipe={recipe}
            />
            <Boil
              header={<Header number={2} stageName="Boil" />}
              user={props.userInfo.user}
              currentRecipe={recipe}
            />
            <Fermentation
              header={<Header number={3} stageName="Fermentation" />}
              currentRecipe={recipe}
            />
          </ContentWrapper>
        </>
      )}
      {/* {JSON.stringify(recipe)} */}
    </div>
  );
};

const mapStateToProps = ({ userInfo }) => {
  return { userInfo };
};
function mapDispatchToProps(dispatch) {
  return {
    getSharedFavourites: () => dispatch(userActions.getUsersSharedFavourites())
  };
}
const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(SharedRecipeDetail);

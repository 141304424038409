import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { Table } from "react-bootstrap";
import BeerActionDropdown from "./BeerActionDropdown";
import { routes } from "@common/constants/urls";
import "./BeerTable.scss";

class RecipesOverviewTable extends React.Component {
  state = {
    orderBy: "name",
    reverseOrder: true
  };

  setOrder = order => {
    if (this.state.orderBy === order) {
      this.setState({
        reverseOrder: !this.state.reverseOrder
      });
    } else {
      this.setState({
        orderBy: order
      });
    }
  };

  orderBy = (beerA, beerB) => {
    if (this.state.reverseOrder) {
      return beerA[this.state.orderBy] > beerB[this.state.orderBy];
    } else {
      return beerA[this.state.orderBy] < beerB[this.state.orderBy];
    }
  };

  routeToDetail = (e, beerID) => {
    if (e.target.tagName !== "TD") return;
    this.props.dispatch(push(`${routes.beers}${beerID}`));
  };

  render() {
    const renderArrow = name => {
      if (this.state.orderBy === name) {
        return (
          <span
            className={
              this.state.reverseOrder ? "ion-arrow-down-b" : "ion-arrow-up-b"
            }
          />
        );
      } else {
        return;
      }
    };

    return (
      <Table className="mbtable">
        <thead>
          <tr>
            <th onClick={() => this.setOrder("name")}>
              Name {renderArrow("name")}
            </th>
            <th>Style</th>
            <th className="hidden-xs" onClick={() => this.setOrder("status")}>
              Status {renderArrow("status")}
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.beers
            .sort((beerA, beerB) => this.orderBy(beerA, beerB))
            .map(beer => (
              <tr onClick={e => this.routeToDetail(e, beer.id)} key={beer.id}>
                <td>{beer.name}</td>
                <td>{beer.style.name}</td>
                <td className="hidden-xs">{beer.status}</td>
                <td>
                  <BeerActionDropdown
                    editBeer={() => this.props.editBeer(beer)}
                    deleteBeer={() => this.props.confirmBeerDeletion(beer.id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }
}

RecipesOverviewTable.propTypes = {
  beers: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func
};

export default connect()(RecipesOverviewTable);

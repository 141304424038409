// source: useraction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

goog.exportSymbol("proto.UserAction", null, global);
/**
 * @enum {number}
 */
proto.UserAction = {
  ACTION_UNDEFINED: 0,
  ACTION_PREPARE_RINSING: 15,
  ACTION_ADD_RINSE_WATER: 16,
  ACTION_CLEAN_BALLVALVE: 17,
  ACTION_CONNECT_WATER: 6,
  ACTION_EMPTY_KEG: 18,
  ACTION_PLACE_KEG: 7,
  ACTION_PLACE_CORRECT_KEG: 52,
  ACTION_KEG_NEEDS_UPDATE: 64,
  ACTION_ADD_BREW_WATER: 1,
  ACTION_FILL_MASH_TUN: 19,
  ACTION_FILL_CAROUSEL: 20,
  ACTION_START_BREWING: 21,
  ACTION_MANUAL_SPARGE: 22,
  ACTION_REPLACE_MASH: 23,
  ACTION_REPLACE_MASH_TUN: 66,
  ACTION_PLACE_CARROUSEL: 24,
  ACTION_RESUME_BREWING: 25,
  ACTION_REMOVE_KEG: 11,
  ACTION_NEEDS_CLEANING: 12,
  ACTION_BREWING_FAILED: 13,
  ACTION_BREWING_FAILED_PERFORM_ACID_CLEAN: 61,
  ACTION_MAX_COOLING_DURATION_ELAPSED: 67,
  ACTION_PAIR_GRAVITY_SENSOR: 36,
  ACTION_PREPARE_FERMENTATION: 26,
  ACTION_CHECK_ACTIVITY_STOPPED: 9,
  ACTION_CHECK_ACTIVITY_STARTED: 3,
  ACTION_PLACE_AIRLOCK: 28,
  ACTION_REMOVE_AIRLOCK: 29,
  ACTION_PLACE_BLOW_OFF: 53,
  ACTION_REMOVE_BLOW_OFF: 54,
  ACTION_PLACE_TRUB_CONTAINER: 30,
  ACTION_REMOVE_TRUB: 8,
  ACTION_ADD_INGREDIENT: 4,
  ACTION_REMOVE_INGREDIENT: 5,
  ACTION_FERMENTATION_FAILED: 33,
  ACTION_CHECK_CARBONATION: 65,
  ACTION_PREPARE_CLEANING: 2,
  ACTION_START_CLEANING: 32,
  ACTION_CLEAN_MINIBREW_FAILED: 35,
  ACTION_FINISH_CLEANING: 37,
  ACTION_CLEAN_MINIBREW_FAILED_PERFORM_ACID_CLEAN: 62,
  ACTION_CONNECT_PRESSURIZER: 31,
  ACTION_MOUNT_TAP: 10,
  ACTION_SERVING_FAILED: 34,
  ACTION_KEG_NEEDS_CLEANING: 38,
  ACTION_CHECK_KEG_PRESENCE: 39,
  ACTION_CHECK_BALLVALVE: 40,
  ACTION_CHECK_RINSE_CONNECTOR: 41,
  ACTION_CHECK_INLET_WATER: 42,
  ACTION_CHECK_KEG_EMPTY: 43,
  ACTION_CHECK_RINSE_FLOW: 44,
  ACTION_CHECK_MASH_TUN: 45,
  ACTION_CHECK_CAROUSEL: 46,
  ACTION_CHECK_CAROUSEL_JAM: 55,
  ACTION_CHECK_MASH_LINE_FLOW: 47,
  ACTION_CHECK_BOIL_LINE_FLOW: 48,
  ACTION_CHECK_CIP_CONTAINER: 49,
  ACTION_CHECK_CIP_LINES: 50,
  ACTION_CHECK_CLEANING_FLOW: 51,
  ACTION_NEEDS_ACID_CLEANING: 63,
  ACTION_PREPARE_ACID_CLEANING: 56,
  ACTION_CLEAN_CIP_FILTER: 57,
  ACTION_CHECK_ACID_CLEANING_FLOW: 58,
  ACTION_ACID_CLEAN_MINIBREW_FAILED: 59,
  ACTION_FINISH_ACID_CLEANING: 60
};

goog.object.extend(exports, proto);

import React, { useState } from "react";
import { Alert, Modal, Button } from "react-bootstrap";
import { unregisterDevice } from "@common/services/deviceService";
import { connect } from "react-redux";
import { notify, removeNotification } from "reapop";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import * as breweryActions from "@brewery/actions/breweryActions";

const UnregisterDeviceModal = ({
  show,
  handleClose,
  unregisterDeviceUUID,
  notify,
  fetchBrewery
}) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const unregister = () => {
    setIsLoading(true);
    unregisterDevice(unregisterDeviceUUID)
      .then(() => {
        setIsLoading(false);
        notifyUnregister();
        fetchBrewery();
        handleClose();
      })
      .catch(err => {
        setIsLoading(false);
        err.then(error => {
          setError(error.detail);
        });
      });
  };

  const notifyUnregister = () => {
    notify({
      title: "Unregister",
      message: "Device is unregistered from your account",
      status: "success",
      dismissible: true,
      dismissAfter: 8000,
      position: "br"
    });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setError(null);
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center" }}>
          UNREGISTER DEVICE
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          Are you sure you want to unregister this MiniBrew from your account?
        </div>
        {error && (
          <Alert bsStyle="danger" onDismiss={() => setError(null)}>
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Cancel</Button>
        <ButtonWithLoad
          isLoading={isLoading}
          onClick={unregister}
          bsStyle="danger"
        >
          Unregister
        </ButtonWithLoad>
      </Modal.Footer>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    notify: data => dispatch(notify(data)),
    removeNotification: id => dispatch(removeNotification(id)),
    fetchBrewery: () => dispatch(breweryActions.fetchBrewery())
  };
}

export default connect(
  null,
  mapDispatchToProps
)(UnregisterDeviceModal);

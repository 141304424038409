import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";

class ControlledPanel extends React.Component {
  state = {
    isOpen: false
  };

  toggle = () => {
    this.props.handleToggle();
  };

  render() {
    const { disabled, sessionDone } = this.props;
    const show = (!disabled && !sessionDone) || sessionDone;
    return (
      <div
        style={{ position: "relative" }}
        className={
          this.props.active ? "minibrew-panel--dark" : "minibrew-panel"
        }
      >
        <div
          onClick={show ? this.toggle : undefined}
          className={show && "pointer"}
        >
          <div className="row">
            <div className="col-xs-11 minibrew-panel__header">
              {this.props.header}
            </div>
            <div className="col-xs-1" style={{ textAlign: "right" }}>
              <h2 className="minibrew-panel__chevron">
                {show ? (
                  <span
                    className={
                      !this.props.open ? "ion-chevron-down" : "ion-chevron-up"
                    }
                  />
                ) : (
                  <span className="ion-locked" style={{ opacity: "0.4" }} />
                )}
              </h2>
            </div>
          </div>
        </div>
        <Collapse in={this.props.open}>
          <div>{this.props.children}</div>
        </Collapse>
      </div>
    );
  }
}

ControlledPanel.defaultProps = {
  initialOpen: false
};

ControlledPanel.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  initialOpen: PropTypes.bool,
  handleToggle: PropTypes.func,
  header: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ControlledPanel;

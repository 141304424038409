import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import TitleBar from "@common/components/TitleBar/TitleBar";
import Table from "./BeerTable/BeerTable";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import "./BeerList.scss";
import BeerModal from "../BeerModal/BeerModal";
import { del } from "@common/services/request";
import { apiEndpoints as api } from "@common/constants/urls";
import ConfirmationModal from "../../../common/components/ConfirmationModal/ConfirmationModal";
import * as beerActions from "../../../beers/actions/beerActions";

const normalizeString = str => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

class RecipePage extends Component {
  state = {
    searchbar: "",
    isBeerModalVisible: false,
    isConfirmationModalVisible: false,
    beerToBeDeleted: null,
    beerToBeEdited: null
  };

  componentDidMount() {
    const userId =
      localStorage.getItem("userId") || this.props.userInfo.user.id;
    this.props.fetchBeers(userId);
  }

  handleChange = event => {
    const newState = this.state;
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  };

  filteredList = beers => {
    let searchterm = this.state.searchbar.toLowerCase();

    // Remove accents/diacritics in string
    searchterm = normalizeString(searchterm);

    if (beers !== null) {
      return beers.filter(
        beer =>
          !!normalizeString(beer.name.toLowerCase()).includes(searchterm) ||
          !!normalizeString(beer.style.name.toLowerCase()).includes(
            searchterm
          ) ||
          !!normalizeString(beer.abv.toLowerCase()).includes(searchterm)
      );
    } else return [];
  };

  routeTo = () => {
    this.props.dispatch(push(this.props.match.url + "/new/"));
  };

  showBeerModal = () => this.setState({ isBeerModalVisible: true });

  closeBeerModal = () =>
    this.setState({ isBeerModalVisible: false, beerToBeEdited: null });

  showConfirmationModal = beerId =>
    this.setState({
      isConfirmationModalVisible: true,
      beerToBeDeleted: beerId
    });

  closeConfirmationModal = () =>
    this.setState({ isConfirmationModalVisible: false });

  editBeer = beer =>
    this.setState({ isBeerModalVisible: true, beerToBeEdited: beer });

  deleteBeer = beerID => {
    del(`${api.beers}${beerID}/`).then(() =>
      this.props.fetchBeers(this.props.userInfo.user.id)
    );
  };

  updateBeer = beer => {
    const updatedBeers = [...this.props.beers.list];
    const index = updatedBeers.findIndex(elem => elem.id === beer.id);
    const newStyle = {
      ...this.props.beers.list[index].style,
      id: beer.style,
      name: beer.styleName
    };
    updatedBeers[index] = {
      ...updatedBeers[index],
      name: beer.name,
      style: newStyle
    };
    this.setState({ isBeerModalVisible: false, beerToBeEdited: null });
    this.props.fetchBeers(this.props.userInfo.user.id);
  };

  render() {
    return (
      <div>
        <TitleBar title={"BEERS"} subtitle={"Overview"}>
          <Button
            bsStyle="primary"
            style={{ position: "absolute", right: "15px" }}
            onClick={this.showBeerModal}
          >
            <span className="ion-plus" /> NEW BEER
          </Button>
        </TitleBar>
        <div className="container-fluid">
          <div className="row">
            <div className="recipe__searchbar">
              <div className="col-lg-8 col-lg-offset-2 col-md-12">
                <FormGroup
                  controlId="signinForm"
                  className="recipe__searchbar__input"
                >
                  <FormControl
                    name="searchbar"
                    type="text"
                    value={this.state.search}
                    placeholder="Search"
                    onChange={this.handleChange}
                    autoFocus={true}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="recipe__table">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 col-md-12">
                <Table
                  editBeer={this.editBeer}
                  confirmBeerDeletion={this.showConfirmationModal}
                  beers={this.filteredList(this.props.beers.list)}
                />
                {!this.props.beers.isFetching &&
                  this.props.beers.list &&
                  this.props.beers.list.length === 0 && (
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={require("../../../assets/images/beer.svg")}
                        style={{ width: "40%" }}
                      />
                      <h2
                        style={{
                          fontWeight: "normal",
                          fontFamily: "Gotham-Bold"
                        }}
                      >
                        No beers yet, start creating!
                      </h2>
                      <Button
                        bsStyle="primary"
                        className="createbeer__button"
                        onClick={this.showBeerModal}
                      >
                        CREATE A NEW BEER
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <BeerModal
          show={this.state.isBeerModalVisible}
          hide={this.closeBeerModal}
          beer={this.state.beerToBeEdited}
          updateBeer={this.updateBeer}
        />
        <ConfirmationModal
          show={this.state.isConfirmationModalVisible}
          hide={this.closeConfirmationModal}
          title="DELETE BEER"
          msg="Are you sure you want to delete this beer? This will remove all shared recipes that belong to this beer to be removed from the community page."
          action={() => this.deleteBeer(this.state.beerToBeDeleted)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, userInfo, beers }) => {
  return {
    auth,
    userInfo,
    beers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBeers: brewerID => dispatch(beerActions.fetchBeers(brewerID))
  };
};

RecipePage.propTypes = {
  auth: PropTypes.object,
  userInfo: PropTypes.object,
  match: PropTypes.object,
  dispatch: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipePage);

import moment from "moment";
export const tempFormatter = tempValue => {
  try {
    const tempAsNumber = parseFloat(tempValue);
    if (isNaN(tempAsNumber)) {
      throw "Not a number";
    }
    const tempValueFormated = tempAsNumber.toFixed(1);
    return tempValueFormated + "°C";
  } catch (e) {
    return "--";
  }
};

export const tooltipFormatter = type => params => {
  const [time, temp] = params[0].value;
  const formatedTemp = tempFormatter(temp);
  const momentTime = moment(time);
  // eslint-disable-next-line no-unused-vars
  let formatedTime = "";
  if (type === "fermentation") {
    formatedTime = momentTime.fromNow();
  } else {
    formatedTime = momentTime.format("H:mm");
  }

  return `${params[0].seriesName}: ${formatedTemp}`;
};

import { WIFI_ACTIONS as type } from "@common/constants/actionTypes";

const initialState = {
  online: true
};

export default function wifiReducer(state = initialState, action) {
  switch (action.type) {
    case type.WIFI_ONLINE:
      return Object.assign({}, state, {
        online: true
      });
    case type.WIFI_OFFLINE:
      return Object.assign({}, state, {
        online: false
      });
    default:
      return state;
  }
}

import React from "react";
import { Alert } from "react-bootstrap";

const Error = ({ error }) => {
  return (
    <Alert bsStyle="danger">
      <div
        style={{
          display: "flex",
          flexDirection: "flex-row",
          alignItems: "center"
        }}
      >
        <i
          className="ion-android-alert"
          style={{ fontSize: "24px", marginRight: "15px" }}
        />{" "}
        <div style={{ fontFamily: "Gotham-Book" }}>{error}</div>
      </div>
    </Alert>
  );
};

export default Error;

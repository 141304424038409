import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { notify } from "reapop";
import moment from "moment";
import TitleBar from "@common/components/TitleBar/TitleBar";
import Divider from "@common/components/Divider/Divider";
import DeviceItem from "./DeviceItem/DeviceItem";
import WifiModal from "./WifiModal/WifiModal";
import BrewModal from "@common/components/BrewModal/BrewModal";
import CleaningModal from "./CleaningModal/CleaningModal";
import EditDeviceModal from "./EditDeviceModal/EditDeviceModal";
import AddDeviceModal from "./AddDeviceModal/AddDeviceModal";
import UnregisterDeviceModal from "./UnregisterDeviceModal/UnregisterDeviceModal";
import ControlSessionModal from "../../../monitor/pages/Monitor/ControlSession/ControlSessionModal3";
import SupportAcidModal from "./SupportAcidModal/SupportAcidModal";
import Spinner from "@common/components/Spinner/Spinner";
import * as breweryActions from "@brewery/actions/breweryActions";
import mqttService from "@monitor/mqttService";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import NOTIFICATION_TYPES from "@common/constants/notificationTypes";
import { CLEANING_TYPE } from "@common/constants/sessionConstants";
import "./brewery.scss";

class Brewery extends React.Component {
  state = {
    breweryOverview: undefined,
    error: false,
    showModal: false,
    showWifiModal: false,
    showCleaningModal: false,
    showDeviceModal: false,
    showStopSessionModal: false,
    showAddDeviceModal: false,
    showAcidSupportModal: false,
    showUnregisterDeviceModal: false,
    selectedSession: 0,
    selectedSerialNumber: 0,
    selectedDevice: undefined,
    selectedRecipe: undefined,
    cleaningDeviceUUID: undefined,
    unregisterDeviceUUID: null,
    cleaningType: null
  };

  componentDidMount() {
    this.props.fetchBrewery();
    this.sub = mqttService.notification$.subscribe(notification => {
      this.props.fetchBrewery();

      const COMMAND_FAILED = 1;
      if (
        notification.type === NOTIFICATION_TYPES.NOT_ERROR &&
        notification.error === COMMAND_FAILED
      ) {
        this.notifyUser(notification);
      }
    });
  }

  componentWillUnmount() {
    this.sub.unsubscribe();
  }

  notifyUser = notification => {
    const { notify } = this.props;
    notify({
      title: "",
      message: notification.message,
      status: "warning",
      dismissible: true,
      dismissAfter: 10000,
      position: "br"
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
      selectedDevice: "",
      selectedRecipe: ""
    });
  };

  handleCloseWifiModal = () => {
    this.setState({ showWifiModal: false });
  };

  showModal = deviceUUID => {
    this.setState({ showModal: true, selectedDevice: deviceUUID });
  };

  showWifiModal = device => {
    console.log(device);
    analyticsService.track(ANALYTICS_TYPES.CONNECT_DEVICE, {
      DeviceID: device.uuid,
      DeviceOnline: device.online
    });
    this.setState({ showWifiModal: true });
  };

  handleCloseDeviceModal = () => {
    this.setState({ showDeviceModal: false });
  };

  showDeviceModal = serialNumber => {
    this.setState({
      showDeviceModal: true,
      selectedSerialNumber: serialNumber
    });
  };

  toggleCleaningModal = deviceUUID => {
    this.setState(prevState => {
      return {
        showCleaningModal: !prevState.showCleaningModal,
        cleaningDeviceUUID: deviceUUID,
        cleaningType: CLEANING_TYPE.NORMAL
      };
    });
  };

  toggleAcidCleaningModal = deviceUUID => {
    this.setState(prevState => {
      return {
        showCleaningModal: !prevState.showCleaningModal,
        cleaningDeviceUUID: deviceUUID,
        cleaningType: CLEANING_TYPE.ACID
      };
    });
  };

  showStopSessionModal = sessionID => {
    this.setState({
      selectedSession: sessionID,
      showStopSessionModal: true
    });
  };

  showAddDeviceModal = () => {
    this.setState({
      showAddDeviceModal: true
    });
  };

  showUnregisterDeviceModal = deviceUUID => {
    this.setState({
      showUnregisterDeviceModal: true,
      unregisterDeviceUUID: deviceUUID
    });
  };

  showAcidSupportModal = () => {
    this.setState({
      showAcidSupportModal: true
    });
  };

  render() {
    const {
      showModal,
      showWifiModal,
      showDeviceModal,
      showStopSessionModal,
      showAddDeviceModal,
      showUnregisterDeviceModal,
      showAcidSupportModal,
      unregisterDeviceUUID
    } = this.state;
    const { userInfo } = this.props;
    const breweryOverview = this.props.brewery.list;
    const noDevices =
      breweryOverview &&
      breweryOverview.brew_clean_idle.length == 0 &&
      breweryOverview.fermenting.length == 0 &&
      breweryOverview.serving.length == 0;

    return (
      <div>
        <TitleBar title="BREWERY" subtitle="Overview">
          <Button
            onClick={() => this.showAddDeviceModal()}
            bsStyle="warning"
            style={{ position: "absolute", right: "15px" }}
          >
            ADD DEVICE
          </Button>
        </TitleBar>
        <div className="container-fluid">
          <div className="row">
            {breweryOverview && !noDevices && (
              <div className="col-md-12">
                <h3 className="brewery__phase">
                  BREWING{" "}
                  <span className="brewery__updated">
                    Last update:{" "}
                    {moment(this.props.brewery.lastUpdate).fromNow()}
                  </span>
                </h3>
                <div className="row">
                  {[
                    ...breweryOverview.brew_clean_idle,
                    ...breweryOverview.brew_acid_clean_idle
                  ].map(device => (
                    <div key={device.uuid} className="col-sm-6 col-md-4">
                      <DeviceItem
                        device={device}
                        showModal={this.showModal}
                        showWifiModal={this.showWifiModal}
                        showDeviceModal={this.showDeviceModal}
                        showCleaningModal={this.toggleCleaningModal}
                        showAcidCleanModal={this.toggleAcidCleaningModal}
                        showStopSessionModal={this.showStopSessionModal}
                        showUnregisterDeviceModal={
                          this.showUnregisterDeviceModal
                        }
                        showAcidSupportModal={this.showAcidSupportModal}
                      />
                    </div>
                  ))}
                </div>
                <Divider />
                <h3 className="brewery__phase">FERMENTING</h3>
                <div className="row">
                  {breweryOverview.fermenting.map(device => (
                    <div key={device.uuid} className="col-sm-6 col-md-4">
                      <DeviceItem
                        device={device}
                        showModal={this.showModal}
                        showWifiModal={this.showWifiModal}
                        showDeviceModal={this.showDeviceModal}
                        showCleaningModal={this.toggleCleaningModal}
                        showStopSessionModal={this.showStopSessionModal}
                        showUnregisterDeviceModal={
                          this.showUnregisterDeviceModal
                        }
                        showAcidSupportModal={this.showAcidSupportModal}
                      />
                    </div>
                  ))}
                </div>
                <Divider />
                <h3 className="brewery__phase">SERVING</h3>
                <div className="row">
                  {breweryOverview.serving.map(device => (
                    <div key={device.uuid} className="col-sm-6 col-md-4">
                      <DeviceItem
                        device={device}
                        showModal={this.showModal}
                        showWifiModal={this.showWifiModal}
                        showDeviceModal={this.showDeviceModal}
                        showCleaningModal={this.toggleCleaningModal}
                        showStopSessionModal={this.showStopSessionModal}
                        showUnregisterDeviceModal={
                          this.showUnregisterDeviceModal
                        }
                        showAcidSupportModal={this.showAcidSupportModal}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!breweryOverview && <Spinner className={"center-spinner"} />}
            {breweryOverview && noDevices && (
              <div className="breweryoverview--empty">
                <img
                  src={require("@assets/images/beer.svg")}
                  alt="add-device-image"
                  className="breweryoverview--empty__image"
                />
                <div
                  className="breweryoverview--empty_desc"
                  style={{ fontFamily: "Gotham-Book", marginBottom: "15px" }}
                >
                  No devices have been set up yet, add a device by clicking the
                  button below.
                </div>
                <Button
                  bsStyle="primary"
                  className="breweryoverview--empty__button"
                  onClick={this.showAddDeviceModal}
                >
                  ADD DEVICE
                </Button>
              </div>
            )}
          </div>
        </div>
        <ControlSessionModal
          show={showStopSessionModal}
          sessionID={this.state.selectedSession}
          handleClose={() => this.setState({ showStopSessionModal: false })}
        />
        <BrewModal
          show={showModal}
          handleClose={this.handleClose}
          selectedDevice={this.state.selectedDevice}
          selectedRecipe={this.state.selectedRecipe}
          fetchBrewery={this.props.fetchBrewery}
        />
        <WifiModal
          show={showWifiModal}
          handleClose={this.handleCloseWifiModal}
        />
        <AddDeviceModal
          user={userInfo.user}
          show={showAddDeviceModal}
          handleClose={() => this.setState({ showAddDeviceModal: false })}
          fetchBrewery={this.props.fetchBrewery}
        />
        <UnregisterDeviceModal
          show={showUnregisterDeviceModal}
          handleClose={() =>
            this.setState({ showUnregisterDeviceModal: false })
          }
          unregisterDeviceUUID={unregisterDeviceUUID}
        />
        <EditDeviceModal
          show={showDeviceModal}
          handleClose={this.handleCloseDeviceModal}
          serialNumber={this.state.selectedSerialNumber}
        />
        <CleaningModal
          show={this.state.showCleaningModal}
          type={this.state.cleaningType}
          handleClose={this.toggleCleaningModal}
          deviceUUID={this.state.cleaningDeviceUUID}
        />
        <SupportAcidModal
          show={showAcidSupportModal}
          handleClose={() => this.setState({ showAcidSupportModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ brewery, userInfo }) => {
  return {
    brewery,
    userInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchBrewery: () => dispatch(breweryActions.fetchBrewery()),
    notify: data => dispatch(notify(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Brewery);

import React from "react";
import ChartStatus from "../ChartStatus/ChartStatus";

const Gravity = ({ current, target }) => {
  const items = [
    {
      label: current ? "current" : "awaiting measurement…",
      value: current ? parseFloat(current / 1000).toFixed(3) : "- -", // Divide by 1000 for formatting 1050 to 1.050
      color: "yellow"
    },
    {
      label: "target",
      value: target ? parseFloat(target).toFixed(3) : 0
    }
  ];

  return <ChartStatus items={items} />;
};

export default Gravity;

import React from "react";
import PropTypes from "prop-types";

import "./container.scss";

/**
 * <Container>
 *  <a> Put the desired html elements between the Container tags </a>
 * </Container>
 * @param {Array} props.children html elements that will be put inside this component
 */
const Container = ({ children, style, ...props }) => {
  return (
    <div className="minibrew-container" style={style} {...props}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Container;

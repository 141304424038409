import React from "react";
import Error from "./Error";

const ErrorList = ({ error }) => {
  return (
    <>
      {error ? (
        <div>
          {error.map((err, index) => (
            <Error error={err.message} key={index} />
          ))}
        </div>
      ) : (
        <span />
      )}
    </>
  );
};

export default ErrorList;

import NOTIFICATION_TYPES from "@common/constants/notificationTypes";
import COMMAND_TYPES from "@common/constants/commandTypes";
import * as mqttTopics from "@common/constants/mqttTopics";

/**
 * Simple collections of functions to make it more clear what we are exactly checking in
 * notifications or logs
 */
const mqttUtil = {
  hasNoNewNextUseraction: frequentlog =>
    frequentlog.logsList[0].nextUserAction === undefined,
  hasNoNewPreviousUseraction: frequentlog =>
    frequentlog.logsList[0].previousUserAction === undefined,
  hasNewUseraction: (frequentlog, prevUseractionId) => {
    return (
      frequentlog.logsList[0].currentStatus.action !== undefined &&
      frequentlog.logsList[0].currentStatus.action !== prevUseractionId
    );
  },
  hasNoUseraction: frequentlog => {
    return frequentlog.logsList[0].currentStatus.action === undefined;
  },
  hasNewCurrentTemp: (frequentlog, oldTemp) => {
    return frequentlog.logsList[0].currentTemp !== oldTemp;
  },
  hasNewTargetTemp: (frequentlog, oldTemp) => {
    return frequentlog.logsList[0].setpointTemp !== oldTemp;
  },
  processSummaryChanged: notification => {
    return (
      notification.type === NOTIFICATION_TYPES.NOT_PROCESS_ESTIMATION_CHANGED
    );
  },
  sessionStateChanged: notification => {
    return notification.type === NOTIFICATION_TYPES.NOT_SESSION_STATE_CHANGED;
  },
  hasError: notification => {
    return notification.error !== 0 && notification.error !== undefined;
  },
  isExecuted: notification => {
    return notification.type === NOTIFICATION_TYPES.NOT_COMMAND_EXECUTED;
  },
  sessionIsStopped: notification => {
    return (
      notification.type === NOTIFICATION_TYPES.NOT_COMMAND_EXECUTED &&
      notification.command === COMMAND_TYPES.CMD_STOP_PROCESS
    );
  },
  getAllSubscriptionsByType: (type, subscriptions) => {
    return subscriptions.filter(s => s.startsWith(type));
  },
  findNoNUsedFrequentLogSubscriptions: function(uuid, subscriptions) {
    const filtered = this.getAllSubscriptionsByType(
      mqttTopics.logsTopic,
      subscriptions
    );
    return filtered.filter(s => !s.includes(uuid));
  }
};
export default mqttUtil;

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Account from "./Account/Account";
import TitleBar from "@common/components/TitleBar/TitleBar";
import ChangePassword from "./ChangePassword/ChangePassword";
import { routes } from "@common/constants/urls";

class Profile extends Component {
  render() {
    return (
      <div>
        <TitleBar title="SETTINGS" subtitle="" />
        <div className="container-fluid">
          <Switch>
            <Route path={routes.profile} component={Account} />
            <Route path={routes.changePassword} component={ChangePassword} />
            <Route
              exact
              path={routes.editAccount}
              render={() => <Account edit />}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Profile;

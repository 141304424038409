/* eslint-disable import/default */

import React from "react";
import { init } from "@sentry/browser";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import { history } from "./store/configureStore";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import Root from "@common/Root";
import analyticsService from "@common/services/analyticsService";
import { setUpWifiListeners } from "@common/util/wifiListener";
import { store } from "./store";
import "./styles/styles.scss"; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require("./favicon.ico"); // Tell webpack to load favicon.ico
momentDurationFormatSetup(moment);

console.dev = function(message) {
  if (process.env.NODE_ENV === "development") {
    console.log(`%cDEBUG: ` + `%c${message}`, "color: green;", "color: black;");
  }
};

// Initialize analytics
analyticsService.init();
analyticsService.register();

//set up and init serviceworkers
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(registration => {
        console.log("SW registered: ", registration);
      })
      .catch(registrationError => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}

// Set up error reporting with sentry.io
if (process.env.NODE_ENV !== "development") {
  try {
    init({
      dsn: process.env.SENTRY_URL || "",
      environment: process.env.SENTRY_ENVIRONMENT || "Local"
    });
  } catch (e) {
    console.log(e);
  }
}

// Set up wifi listener to see if the user has a internet connection
setUpWifiListeners(store.dispatch);

// Index is mainly used to configure hot module reload
// and to pass a configured store and router history to the Root component
render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("@common/Root", () => {
    const NewRoot = require("@common/Root").default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById("app")
    );
  });
}

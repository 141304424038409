import React from "react";

export const SubSelector = ({ selected, children, onClick }) => {
  const selectionStyle = {
    height: "100%",
    width: "45%",
    backgroundColor: "rgba(253, 204, 0, 0.44)",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    padding: 25,
    fontSize: 18
  };
  const selectionStyleSelected = Object.assign({}, selectionStyle);
  selectionStyleSelected.border = "2px solid black";
  selectionStyleSelected.backgroundColor = "#fdcc00";
  if (!selected) {
    return (
      <div onClick={onClick} style={selectionStyle} className={"sub-selector"}>
        {children}
      </div>
    );
  } else {
    return (
      <div onClick={onClick} style={selectionStyleSelected}>
        {children}
      </div>
    );
  }
};

export const SESSION_TYPES = {
  SESSION_TYPE_BREW_FERM: 0,
  SESSION_TYPE_FERMENTATION: 1,
  SESSION_TYPE_CLEAN_MINIBREW: 2,
  SESSION_TYPE_CLEAN_KEG: 3,
  SESSION_ACID_CLEAN_MINIBREW: 4
};

export const MACH_CONN_STATUS = {
  MACH_CONN_STATUS_OFFLINE: 0,
  MACH_CONN_STATUS_ONLINE: 1,
  MACH_CONN_STATUS_NOT_RESPONDING: 2
};

export const MACH_STATES = {
  MACH_STATE_IDLE: 0,
  MACH_STATE_ACTIVE: 1,
  MACH_STATE_PAUSED: 2,
  MACH_STATE_UPDATING: 3
};

export const SESSION_STATES = {
  STATUS_STARTING: 0,
  STATUS_ACTIVE: 1,
  STATUS_FINISHED: 2,
  STATUS_ABORTED: 4,
  STATUS_FAILED: 6
};

export const ACTIVE_SESSION_STATES = [
  SESSION_STATES.STATUS_STARTING,
  SESSION_STATES.STATUS_ACTIVE
];

export const INACTIVE_SESSION_STATES = [
  SESSION_STATES.STATUS_FINISHED,
  SESSION_STATES.STATUS_ABORTED,
  SESSION_STATES.STATUS_FAILED
];

export const CLEANING_TYPE = {
  NORMAL: "Normal",
  ACID: "Acid"
};

import React from "react";
import beerColorIcon from "../../../assets/images/BeerColorIcon.png";
import { srmToCss } from "../../../util/beercalc";

const BeerProfileBar = ({ beerProfile }) => {
  return (
    <div>
      {beerProfile && (
        <div className="beerprofile-bar">
          Beer profile
          <img
            src={beerColorIcon}
            style={{
              backgroundColor: srmToCss(beerProfile ? beerProfile.srm : 1)
            }}
            className="beer-color-icon__recipe"
            alt="srm"
          />
          <span className="beerprofile-label">
            {beerProfile.srm || "-"} <small>SRM</small>
          </span>
          <span className="beerprofile-label">
            {beerProfile.abv || "-"} <small>Abv</small>
          </span>
          <span className="beerprofile-label">
            {beerProfile.ibu || "-"} <small>IBU</small>
          </span>
          <span className="beerprofile-label">
            {beerProfile.kcal || "-"} <small>Kcals</small>
          </span>
          <span className="beerprofile-label">
            {beerProfile.og ? parseFloat(beerProfile.og).toFixed(3) : 0}{" "}
            <small>OG</small>
          </span>
          <span className="beerprofile-label">
            {beerProfile.fg ? parseFloat(beerProfile.fg).toFixed(3) : 0}{" "}
            <small>FG</small>
          </span>
        </div>
      )}
    </div>
  );
};

export default BeerProfileBar;

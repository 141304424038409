import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, ControlLabel, Dropdown } from "react-bootstrap";
import TypeAheadToggle from "./TypeAheadToggle";
import TypeAheadMenu from "./TypeAheadMenu";
import "./TypeAhead.scss";

import { NewIngredientConsumer } from "../../NewIngredientContext";
import FieldError from "@common/components/FieldError/FieldError";

/**
 * This component shows a dropdown with a list of items as soon as the field is focused.
 * These items come from an api endpoint and the user can filter the results by typing.
 *
 * location: location of the field in the "form" property of the store (created by Redux-form).
 *   This is necessary if the field is nested instead of in the root of the form.
 * attrMap: a mapping between a property of the chosen object coming from the api and the
 *   name that should be assigned to the property in the form, e.g: { id: idInField }
 *
 */

class TypeAhead extends Component {
  state = {
    expanded: false,
    netError: false,
    options: [],
    filteredOptions: [],
    isChoiceMade: false,
    menuOpen: null,
    activeKey: 1
  };

  componentDidMount() {
    this.fetchIngredientInfo(this.props.endpoint);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.endpoint !== this.props.endpoint ||
      nextProps.ingredients !== this.props.ingredients
    ) {
      this.fetchIngredientInfo(nextProps.endpoint);
    }
  }

  expand = () => this.setState({ expanded: true });

  collapse = () => this.setState({ expanded: false });

  choiceHandler = (e, choice) => {
    const { input, change, location, attrMap } = this.props;
    if (choice) {
      input.onChange(choice.name);
      for (let attr in attrMap) {
        const fieldLocation = location
          ? `${location}.${attrMap[attr]}`
          : attrMap[attr];
        change(fieldLocation, choice[attr]);
      }
      this.setState({ isChoiceMade: true, menuOpen: false });
      this.collapse();
    }
  };

  changeHandler = e => {
    this.setState({ isChoiceMade: false });
    const filtered = this.state.options.filter(op =>
      op.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ filteredOptions: filtered });
    this.expand();
    this.props.input.onChange(e.target.value);
  };

  blurHandler = e => {
    if (e.target.value && this.state.filteredOptions.length)
      this.choiceHandler(this.state.filteredOptions[0]);
    else this.props.input.onChange(null);
    this.collapse();
  };

  keyHandler = e => {
    switch (e.key) {
      case "Enter":
        if (!this.state.isChoiceMade) {
          e.preventDefault();
          if (this.state.filteredOptions.length)
            this.choiceHandler(this.state.filteredOptions[0]);
        }
        break;
      default:
        break;
    }
  };

  fetchIngredientInfo(endpoint) {
    let res = [];
    let activeKey = 1;
    if (endpoint.includes("hops")) {
      res = this.props.hops;
      activeKey = 1;
    } else if (endpoint.includes("boilfermentables")) {
      // HACK: Component needs to be refactored to not use endpoint anymore
      res = this.props.boilFermentables;
      activeKey = 2;
    } else if (endpoint.includes("fermentables")) {
      res = this.props.fermentables;
      activeKey = 2;
    } else if (endpoint.includes("yeasts")) {
      res = this.props.yeasts;
      activeKey = 3;
    } else if (endpoint.includes("adjuncts")) {
      res = this.props.adjuncts;
      activeKey = 4;
    }

    this.setState({
      activeKey,
      options: res.results || res,
      filteredOptions: res.results || res,
      netError: false
    });
  }
  toggle = () => {
    this.setState(prevState => {
      return { menuOpen: !prevState.menuOpen };
    });
  };
  render() {
    const {
      input: { value },
      label,
      meta: { touched, error },
      rowIndex,
      smSize,
      forceValidation
    } = this.props;
    const shouldValidate = forceValidation || touched;

    return (
      <Col sm={smSize || 2} className="type-ahead">
        {!rowIndex && <ControlLabel>{label}</ControlLabel>}
        <NewIngredientConsumer>
          {({ showModal, setActiveKey, setOnSuccess }) => {
            const showModalLocal = () => {
              showModal();
              setActiveKey(this.state.activeKey);
              setOnSuccess(value => {
                this.choiceHandler(null, value);
              });
            };

            return (
              <Dropdown
                id="dropdown"
                open={this.state.menuOpen}
                onToggle={this.toggle}
              >
                <TypeAheadToggle
                  bsRole="toggle"
                  error={shouldValidate && error}
                >
                  {value ? value : "Select ingredient"}
                  <span
                    className="caret"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "18px"
                    }}
                  />
                </TypeAheadToggle>

                <TypeAheadMenu
                  bsRole="menu"
                  options={this.state.options}
                  handleClick={this.choiceHandler}
                  showIngredientModal={showModalLocal}
                  user={this.props.user}
                />
              </Dropdown>
            );
          }}
        </NewIngredientConsumer>
        {this.state.netError && <FieldError>Network error</FieldError>}
        {shouldValidate && error && <FieldError>{error}</FieldError>}
      </Col>
    );
  }
}

const mapStateToProps = ({ ingredients, userInfo }) => ({
  ingredients: ingredients,
  hops: ingredients.hops,
  fermentables: ingredients.fermentables,
  boilFermentables: ingredients.fermentables.results
    ? ingredients.fermentables.results.filter(
        fermentable => fermentable.requires_mashing == false
      )
    : [],
  yeasts: ingredients.yeasts,
  adjuncts: ingredients.adjuncts,
  user: userInfo.user
});

export default connect(
  mapStateToProps,
  null
)(TypeAhead);

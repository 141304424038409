import React from "react";
import "./status-disconnected.scss";

const StatusDisconnected = ({ showModal }) => {
  return (
    <div onClick={showModal} className="device__status--disconnected">
      <span className="ion-ios-bolt device__alert-icon" />
      <span>Device not connected</span>
      <span className="device__text--idle" />
      <span className="ion-ios-arrow-right device__chevron" />
    </div>
  );
};

export default StatusDisconnected;

import React from "react";
import { Breadcrumb as BootstrapBreadCrumb } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Breadcrumb extends React.Component {
  state = {};

  getPathItems = () => {
    let items = this.props.routing.location.pathname.split("/").slice(1);
    return items;
  };

  getPath = item => {
    const pathName = this.props.routing.location.pathname;
    const path = pathName.substr(0, pathName.indexOf(item) + item.length) + "/";
    return path;
  };

  render() {
    return (
      <BootstrapBreadCrumb>
        {this.getPathItems().map(item => (
          <BootstrapBreadCrumb.Item key={item} active={true}>
            <Link to={this.getPath(item)}>{item}</Link>
          </BootstrapBreadCrumb.Item>
        ))}
      </BootstrapBreadCrumb>
    );
  }
}

const mapStateToProps = ({ routing }) => {
  return { routing };
};

export default connect(mapStateToProps, null)(Breadcrumb);

import React from "react";
import { Dropdown } from "react-bootstrap";
import DeviceMenu from "./DeviceMenu";
import DeviceToggle from "./DeviceToggle";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import { withRouter } from "react-router-dom";

const menuName = "Device Menu";
const DeviceDropdown = ({
  children,
  showDeviceModal,
  device,
  showWifiModal,
  showCleaningModal,
  disabled,
  isDisconnected,
  showStopSessionModal,
  showUnregisterDeviceModal,
  history
}) => {
  const handleSelect = (openModal, name, params) => {
    analyticsService.track(ANALYTICS_TYPES.OPEN_MENU_ITEM, {
      Menu: menuName,
      MenuItem: name,
      Screen: history.location.pathname
    });
    openModal(params);
  };
  const handleToggle = isOpen => {
    if (isOpen) {
      analyticsService.track(ANALYTICS_TYPES.OPEN_MENU, {
        Menu: menuName,
        Screen: history.location.pathname
      });
    }
  };

  return (
    <Dropdown
      id="dropdown-custom-menu"
      disabled={disabled}
      onToggle={handleToggle}
    >
      <DeviceToggle bsRole="toggle">{children}</DeviceToggle>
      <DeviceMenu
        bsRole="menu"
        showDeviceModal={params =>
          handleSelect(showDeviceModal, "Edit Device", params)
        }
        device={device}
        showWifiModal={() =>
          handleSelect(() => showWifiModal(device), "Set up Wi-Fi")
        }
        showCleaningModal={params =>
          handleSelect(showCleaningModal, "Start Cleaning", params)
        }
        isDisconnected={isDisconnected}
        showStopSessionModal={params =>
          handleSelect(showStopSessionModal, "Stop Session", params)
        }
        showUnregisterDeviceModal={params =>
          handleSelect(showUnregisterDeviceModal, "Unregister Device", params)
        }
      />
    </Dropdown>
  );
};

export default withRouter(DeviceDropdown);

import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";

import "./panel.scss";

/**
 * <Panel initialOpen={true||false} header={htmlElement}>
 *  <a> Put the desired html elements between the Container tags </a>
 * </Panel>
 * @param {boolean} props.initialOpen boolean to determine if the panel is collapsed or not
 * @param {element} props.header html element to be put inside the panel header
 * @param {Array} props.children html elements that will be put inside this component
 */
class Panel extends React.Component {
  state = {
    open: this.props.initialOpen
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div className="minibrew-panel">
        <div onClick={this.toggle} className="pointer">
          <div className="row">
            <div className="col-xs-8">{this.props.header}</div>
            <div className="col-xs-4" style={{ textAlign: "right" }}>
              <h2 className="minibrew-panel__chevron">
                <span
                  className={
                    !this.state.open ? "ion-chevron-down" : "ion-chevron-up"
                  }
                />
              </h2>
            </div>
          </div>
        </div>
        <Collapse in={this.state.open}>
          <div>{this.props.children}</div>
        </Collapse>
      </div>
    );
  }
}

Panel.defaultProps = {
  initialOpen: false
};

Panel.propTypes = {
  initialOpen: PropTypes.bool,
  header: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Panel;

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { logError } from "@common/util/exceptionHandler";
import { withRouter } from "react-router-dom";
import * as userActions from "@profile/actions/userActions";
import * as authActions from "@auth/actions/authenticationActions";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    const { pathname } = this.props.history.location;
    logError(error, {}, errorInfo, pathname);
  }

  goBack = () => {
    this.props.signOut();
    this.props.removeUserData();
    this.props.history.push("/auth/signin");
    this.props.changeKey();
  };

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div style={{ textAlign: "center" }}>
          <img
            style={{ width: "30%" }}
            src={require("../../../assets/images/bug_fixing.svg")}
          />
          <h2>Something went wrong.</h2>
          <p>If this keeps happening please submit a support ticket</p>
          <button className="btn btn-primary" onClick={this.goBack}>
            Go back
          </button>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(authActions.signOut()),
    removeUserData: () => dispatch(userActions.removeUserData())
  };
}
const enhance = compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
);
export default enhance(ErrorBoundary);

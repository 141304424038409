import React from "react";

const NewIngredientContext = React.createContext({});

export class NewIngredientProvider extends React.Component {
  state = {
    show: false,
    activeTabKey: 1,
    onSuccess: value => {
      console.log("default onSuccess", value);
    }
  };

  showModal = () => this.setState({ show: true });
  hideModal = () => this.setState({ show: false });

  setActiveKey = key => {
    this.setState({
      activeTabKey: key
    });
  };

  setOnSuccess = onSuccess => {
    this.setState({
      onSuccess
    });
  };

  render() {
    const { children } = this.props;

    return (
      <NewIngredientContext.Provider
        value={{
          show: this.state.show,
          showModal: this.showModal,
          hideModal: this.hideModal,
          setActiveKey: this.setActiveKey,
          activeTabKey: this.state.activeTabKey,
          onSuccess: this.state.onSuccess,
          setOnSuccess: this.setOnSuccess
        }}
      >
        {children}
      </NewIngredientContext.Provider>
    );
  }
}

export const NewIngredientConsumer = NewIngredientContext.Consumer;

import React from "react";
import { Button } from "react-bootstrap";

import TitleBar from "@common/components/TitleBar/TitleBar";
import Spinner from "@common/components/Spinner/Spinner";

class RecipeDetailLoading extends React.Component {
  state = {};

  render() {
    return (
      <div>
        <div>
          <TitleBar title={"LOADING"}>
            <Button bsStyle="warning" className="beer-profile-btn">
              BEER PROFILE{" "}
            </Button>
          </TitleBar>
          <div className="recipe-detail container-fluid">
            <div className="row recipe-detail__container">
              <div className="col-sm-3 col-md-3" style={{ padding: "0px" }} />
              <div className="col-xs-12 col-sm-9 col-md-9 recipe-detail__container__brewdata">
                <div className="row">
                  <Spinner className="center-spinner" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecipeDetailLoading;

import React from "react";
import "./Spinner.scss";

const Spinner = ({ className }) => (
  <div className={`spinner ${className}`}>
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </div>
);

export default Spinner;

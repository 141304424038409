import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import { push } from "react-router-redux";
import { routes } from "@common/constants/urls";
import Navigation from "@common/components/Navigation/Navigation";
import { NavLink } from "react-router-dom";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import { Badge } from "react-bootstrap";
import * as userActions from "@profile/actions/userActions";
import * as authActions from "@auth/actions/authenticationActions";
import ProfileDropdown from "@common/components/ProfileDropdown/ProfileDropdown";

const PaymentCompleted = ({
  userInfo,
  goToCommunity,
  goToSignIn,
  signOut,
  removeUserData,
  getUserInformation
}) => {
  const [loggingOut, setLoggingOut] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const navItems = [
    // {
    //   name: "DASHBOARD",
    //   route: routes.dashboard
    // },
    {
      name: "BREWERY",
      route: routes.brewery
    },
    {
      name: "BEERS",
      route: routes.beers
    },
    // {
    //   name: "COMMUNITY",
    //   route: routes.community
    // },
    {
      name: "PROFILE",
      route: routes.profile,
      props: {
        className: "visible-xs"
      }
    }
  ];
  const exit = () => {
    setLoggingOut(true);
    goToSignIn();
    signOut();

    removeUserData();
  };
  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };
  useEffect(() => {
    getUserInformation();
  }, []);

  if (userInfo.user && userInfo.user.access_type === 1) {
    return (
      <main>
        <Navigation
          showBurger={true}
          handleMenuClick={handleMenuClick}
          showBell={true}
          color={"white"}
          showMenu={showMenu}
        >
          <div
            className={
              "header__nav--portal col-xs-12 col-sm-6 " +
              (showMenu ? "header__nav__show" : "header__nav__hide")
            }
          >
            {navItems.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  activeClassName="active"
                  to={item.route}
                  {...item.props}
                >
                  {item.name}
                </NavLink>
              );
            })}
            <NavLink
              key="community"
              activeClassName="active"
              to={routes.community}
            >
              COMMUNITY
            </NavLink>
            <a className="visible-xs" onClick={exit}>
              SIGN OUT
            </a>
          </div>
          <div className="header__account col-sm-3 hidden-xs">
            <ProfileDropdown
              signOut={() => {
                analyticsService.track(ANALYTICS_TYPES.SIGN_OUT, {
                  Forced: false
                });
                analyticsService.mixpanel.reset();
                exit();
              }}
            />
            <p className="header__account__text">
              <span className="hidden-sm">
                {userInfo.user.first_name.toUpperCase()}{" "}
              </span>
              {userInfo.beta_tester && <Badge>BETA</Badge>}
            </p>
          </div>
        </Navigation>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            position: "relative"
          }}
        >
          <div style={{ flexDirection: "column", marginTop: "80px" }}>
            <h1>Thank you for becoming a Pro Member!</h1>
            <h2>Ready to start exploring Community Recipes?</h2>
            <ButtonWithLoad
              bsStyle="primary"
              className="btn__community"
              onClick={goToCommunity}
            >
              Go To Community
            </ButtonWithLoad>
          </div>
        </div>
      </main>
    );
  } else if (loggingOut) {
    return <h1>logging out</h1>;
  } else if (!userInfo.user) {
    return <div />;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        width: "100%",
        textAlign: "center"
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>Oops!</h1>
        <h2>Something went wrong with your payment</h2>
        <h3>Please try again.</h3>
        <ButtonWithLoad bsStyle="primary" onClick={exit}>
          Back To Sign In
        </ButtonWithLoad>
      </div>
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    goToCommunity: () => dispatch(push(routes.community)),
    goToSignIn: () => dispatch(push(routes.signin)),
    signOut: () => dispatch(authActions.signOut()),
    removeUserData: () => dispatch(userActions.removeUserData()),
    getUserInformation: token => dispatch(userActions.getUserInformation(token))
  };
}
function mapStateToProps({ userInfo }) {
  return { userInfo };
}

PaymentCompleted.propTypes = {
  userInfo: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCompleted);

import React from "react";
import ReactDOM from "react-dom";
import { Button, Overlay, Popover } from "react-bootstrap";

const CustomPopover = ({ title, description, action, className, style }) => {
  return (
    <Popover
      title={title}
      style={{ ...style, width: "250px", left: "-68px" }}
      className={className}
      placement="top"
    >
      {description}
      <div style={{ textAlign: "center", margin: "10px 0" }}>
        <Button onClick={action} bsStyle="danger">
          Yes, skip pairing
        </Button>
      </div>
    </Popover>
  );
};

class SkipPairingButton extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleToggle = this.handleToggle.bind(this);

    this.state = {
      show: false
    };
  }

  handleToggle() {
    this.setState({ show: !this.state.show });
  }

  render() {
    const { action } = this.props;
    return (
      <span style={{ position: "relative" }}>
        <Button
          ref={button => {
            this.target = button;
          }}
          onClick={this.handleToggle}
        >
          Skip pairing
        </Button>

        <Overlay
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          placement="top"
          rootClose
          container={this}
          target={() => ReactDOM.findDOMNode(this.target)}
        >
          <CustomPopover
            title="Are you sure?"
            description="You will not receive any real-time information about the gravity of your beer."
            action={action}
          />
        </Overlay>
      </span>
    );
  }
}

export default SkipPairingButton;

import React, { useEffect, useState } from "react";
import { getBeerstyles } from "@beers/services/beerstyleService";
import StyleBar from "./StyleBar";

const BeerStyleComparison = ({ styleID, beerProfile }) => {
  const [beerstyle, setBeerstyle] = useState(null);

  useEffect(() => {
    getBeerstyles().then(styles => {
      styles.forEach(style => {
        if (style.id === styleID) {
          setBeerstyle(style);
        }
      });
    });
  }, [styleID]);
  const beerprofileFields = [
    {
      name: "SRM",
      min: 0,
      max: 40,
      styleMaxField: "srm_max",
      styleMinField: "srm_min"
    },
    {
      name: "Abv",
      min: 0,
      max: 15,
      styleMaxField: "abv_max",
      styleMinField: "abv_min"
    },
    {
      name: "IBU",
      min: 0,
      max: 120,
      styleMaxField: "ibu_max",
      styleMinField: "ibu_min"
    },
    {
      name: "OG",
      min: 1.0,
      max: 1.15,
      styleMaxField: "og_max",
      styleMinField: "og_min"
    },
    {
      name: "FG",
      min: 1.001,
      max: 1.04,
      styleMaxField: "fg_max",
      styleMinField: "fg_min"
    }
  ];
  return (
    <div>
      {beerProfile && beerstyle && (
        <div>
          {beerprofileFields.map(field => (
            <React.Fragment key={field.name}>
              <span style={{ color: "black" }}>{field.name}</span>
              <StyleBar
                min={field.min}
                max={field.max}
                styleMin={beerstyle[field.styleMinField]}
                styleMax={beerstyle[field.styleMaxField]}
                actual={beerProfile[0][field.name.toLowerCase()]}
              />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default BeerStyleComparison;

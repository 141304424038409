import React from "react";
import PropTypes from "prop-types";

import CollapsablePanel from "@common/components/Panel/Panel";
import GenericTable from "@common/components/Table/Table";
import { hasAccess, betaFeature } from "@common/util/access";
const HOP_FILTER = ["Standard", "Bypass"];

class RecipeDetailBoil extends React.Component {
  sortByTime = (a, b) => {
    return b.duration - a.duration;
  };

  render() {
    const boiling = this.props.currentRecipe.boiling[0];
    const recipe = this.props.currentRecipe;
    const ingredients =
      boiling && (boiling.hops || boiling.other_ingredients)
        ? [...boiling.hops, ...boiling.other_ingredients]
        : [];
    return (
      <CollapsablePanel header={this.props.header} initialOpen={true}>
        <div>
          <h3>
            Boiling time:{" "}
            <span style={{ fontFamily: "Gotham-Medium" }}>
              {boiling.duration ? `${boiling.duration} min` : "-"}
            </span>
          </h3>
          {hasAccess(betaFeature.hopFilter, this.props.user) && (
            <h3>
              Hop Filter:{" "}
              <span style={{ fontFamily: "Gotham-Medium" }}>
                {typeof recipe.hop_filter == "number"
                  ? `${HOP_FILTER[recipe.hop_filter]}`
                  : "-"}
              </span>
            </h3>
          )}
          {ingredients.length ? (
            <React.Fragment>
              <h3>Hops & other ingredients</h3>
              <GenericTable
                headers={["", "Amount (gr)", "Duration (min)", "Alpha acid"]}
                data={ingredients.sort(this.sortByTime)}
                filter={["ingredient_name", "amount", "duration", "alpha_acid"]}
              />
            </React.Fragment>
          ) : null}
        </div>
      </CollapsablePanel>
    );
  }
}

RecipeDetailBoil.propTypes = {
  currentRecipe: PropTypes.object,
  header: PropTypes.object
};

export default RecipeDetailBoil;

import React from "react";
import { Field, reduxForm } from "redux-form";
import { Form, Row, Col, ControlLabel } from "react-bootstrap";
import CustomField from "../CreateRecipe/CustomField/CustomField";
import { required } from "@util/formValidationFunctions";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";

class CustomAdjunctForm extends React.Component {
  componentDidMount() {
    const { ingredient } = this.props;
    if (
      ingredient !== null &&
      ingredient !== undefined &&
      ingredient.type == "Adjunct"
    ) {
      this.props.initialize(ingredient);
    }
  }
  render() {
    const { handleSubmit, isSaving, valid } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={10} mdOffset={1}>
            <Field
              name="name"
              component={CustomField}
              label="Name"
              mdSize={12}
              smSize={12}
              validate={[required]}
            />
            <Col sm={12} md={12}>
              <ControlLabel>Type</ControlLabel>
              <Field
                name="adjunct_type"
                label="Type"
                component="select"
                className="form-control"
              >
                <option value="" defaultValue disabled hidden>
                  Choose here
                </option>
                <option value="SPICE">Spice</option>
                <option value="FINI">Fining</option>
                <option value="HERB">Herb</option>
                <option value="FLAV">Flavour</option>
                <option value="WAGEN">Water Agent</option>
                <option value="OTHER">Other</option>
              </Field>
            </Col>
            <Field
              name="notes"
              component={CustomField}
              label="Notes"
              componentClass="textarea"
              mdSize={12}
              smSize={12}
            />
            <ButtonWithLoad
              type="submit"
              bsStyle="primary"
              style={{
                float: "right",
                marginTop: "20px",
                marginBottom: "10px"
              }}
              isLoading={isSaving}
              disabled={!valid}
            >
              Save adjunct
            </ButtonWithLoad>
            <div className="clearfix" />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({ form: "customAdjunct" })(CustomAdjunctForm);

import React from "react";
import CircledNumber from "./CircledNumber";
import { Button } from "react-bootstrap";
import "./wifi-modal.scss";

const WifiContent = () => {
  const toggleOpen = () => {
    window.open("http://192.168.4.1", "_blank");
  };

  return (
    <React.Fragment>
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi">
          <CircledNumber number="1" />
        </div>
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          Press and hold the Connect button on the back of the device until the
          light turns purple.{" "}
        </div>
      </div>
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi">
          <CircledNumber number="2" />
        </div>
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          Connect to the MiniBrew Wi-Fi hotspot from this device. The Wi-Fi
          configuration tab should open automatically.
        </div>
      </div>
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi">
          <CircledNumber number="3" />
        </div>
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          Wait for this page to load, even if there is an error message.
        </div>
      </div>
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi">
          <CircledNumber number="4" />
        </div>
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          If nothing opens or loads, click this arrow button.
        </div>
        <div
          className="flex-column-wifi"
          style={{ flex: "2", alignItems: "flex-end" }}
        >
          <Button
            onClick={toggleOpen}
            bsStyle="warning"
            className="wifimodal-button"
          >
            <i
              className="ion-android-open"
              style={{ color: "black", fontSize: "14px" }}
            />
          </Button>
        </div>{" "}
      </div>
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi">
          <CircledNumber number="5" />
        </div>
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          Once the configuration window opens, you can enter your Wi-Fi network
          name and password.
        </div>
      </div>{" "}
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          Still no configuration window after multiple attempts?
        </div>
      </div>
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi">
          <CircledNumber number="1" />
        </div>
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          Be sure to wait at least 60 seconds for the configuration page to
          load. It can even appear 10-20 seconds after the loading indicator
          resolves.
        </div>
      </div>
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi">
          <CircledNumber number="2" />
        </div>
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          Unplug the MiniBrew device temporarily and start the steps again.
        </div>
      </div>
      <div className="flex-row-wifi wifimodal-text">
        <div className="flex-column-wifi">
          <CircledNumber number="3" />
        </div>
        <div className="flex-column-wifi" style={{ flex: "11" }}>
          Connect to the hotspot with other personal devices (e.g. phones,
          laptops).
        </div>
      </div>
    </React.Fragment>
  );
};

export default WifiContent;

import { del, get, post, put } from "@common/services/request";
import { apiEndpoints as api } from "@common/constants/urls";

export const getAllBeers = () => get(`${api.beers}`);

export const getBeers = brewerID => get(`${api.beers}?brewer=${brewerID}`);

export const getBeerById = beerID => get(`${api.beers}${beerID}/`);

export const createBeer = body => post(api.beers, body);

export const getRecipes = beerID => get(`${api.recipes}?beer=${beerID}`);

export const getRecipeById = recipeId => get(`${api.recipes}${recipeId}/`);

export const getAllRecipes = () => get(`/recipes/`);

export const validateRecipe = recipe => post(api.validateRecipe, recipe);

export const createRecipe = recipe => post(api.recipes, recipe);

export const getSharedRecipes = () => get(api.sharedRecipesPagination(10, 0));

export const getSharedRecipe = id => get(`${api.sharedRecipes}${id}/`);

export const toggleShareRecipe = recipe => {
  recipe.shared = !recipe.shared;
  return put(api.recipes + recipe.id + "/", recipe);
};

export const updateRecipe = recipe =>
  put(api.recipes + recipe.id + "/", recipe);

export const searchSharedRecipe = searchTerm =>
  get(`${api.sharedRecipesPagination(18, 0)}&search=${searchTerm}`);

export const favoriteSharedBeer = beerID =>
  post(api.sharedFavourites, { shared_beer: beerID });

export const unFavoriteSharedBeer = favoriteID =>
  del(api.sharedFavourites + `${favoriteID}/`);

export const getSharedFavorites = () => get(api.sharedFavourites);

export const getBrewpackByQR = hashedId => get(api.brewticket + hashedId + "/");

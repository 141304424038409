import { tooltipFormatter } from "./commonOptions";
import { MAX_BREWING_TEMP } from "@common/constants/chartOptions";

const chartOptions = () => ({
  animation: false,
  textStyle: {
    fontFamily: "Gotham-Medium"
  },
  tooltip: {
    trigger: "axis",
    formatter: tooltipFormatter("brewing"),
    hideDelay: 5000
  },
  legend: {
    data: ["Temperature", "Predicted"],
    orient: "vertical",
    top: "10%",
    left: "left",
    zlevel: 4
  },
  grid: {
    top: 5,
    left: 30,
    right: 60,
    bottom: 40
  },
  xAxis: [
    {
      type: "time",
      name: "Time",
      max: "dataMax",
      splitLine: {
        show: false
      },
      axisPointer: {
        value: 0,
        triggerTooltip: false,
        lineStyle: {
          color: "#fdcc00",
          opacity: 1,
          width: 2
        },
        label: {
          show: true,
          backgroundColor: "#fdcc00",
          color: "#24262c",
          fontFamily: "Gotham-Medium",
          borderRadius: 10,
          shadowColor: "#24262c"
        },
        handle: {
          show: true,
          color: "#24262c"
        }
      }
    }
  ],
  yAxis: [
    {
      type: "value",
      scale: false,
      name: "Temp.",
      max: MAX_BREWING_TEMP,
      min: 0,
      splitNumber: 5,
      boundaryGap: [0.2, 0.2]
    }
  ],
  series: [
    {
      name: "Actual Temperature",
      showSymbol: false,
      type: "line",
      data: [],
      smooth: true,
      areaStyle: { opacity: 0.3 },
      lineStyle: { width: 4 },
      color: ["#8dc538"],
      sampling: "average"
    },
    {
      name: "Predicted Temperature",
      connectNulls: true,
      showSymbol: false,
      type: "line",
      data: [],
      smooth: true,
      lineStyle: { width: 4, type: "dotted", opacity: 0.8 },
      color: ["#F2F1EE"],
      markLine: {
        symbol: [false, "circle"],
        data: [],
        lineStyle: {
          color: "#cfcecd",
          type: "dotted"
        }
      }
    },
    {
      name: "Phases",
      showSymbol: false,
      type: "line",
      data: [],
      markLine: {
        label: {
          show: false
        },
        symbol: false,
        data: [],
        lineStyle: {
          color: "#cfcecd",
          type: "solid",
          width: 2
        }
      }
    },
    {
      name: "Historic Temperature",
      showSymbol: false,
      type: "line",
      data: [],
      markLine: {
        symbol: false,
        data: [],
        lineStyle: {
          color: "#cfcecd",
          type: "solid",
          width: 2
        }
      },
      smooth: true,
      areaStyle: { opacity: 0.3 },
      lineStyle: { width: 4 },
      color: ["#8dc538"],
      sampling: "average"
    }
  ]
});
export default chartOptions;

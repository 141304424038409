import React from "react";
import { Modal, Button } from "react-bootstrap";
import get from "lodash/get";
import HopCarouselIngredients from "./HopCarouselIngredients";
import "./user-action-item.scss";
import UserActionCarousel from "./UserActionsCarousel";
import SkipPairingButton from "./SkipPairingButton";
const reactStringReplace = require("react-string-replace");
class UserActionModal extends React.Component {
  state = {
    index: 0,
    direction: null,
    imageloaded: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState({ index: 0, direction: null, imageloaded: false });
    }
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction
    });
  };

  handleImageLoaded = () => {
    this.setState({ imageloaded: true });
  };

  render() {
    const {
      show,
      handleClose,
      userAction,
      resumeSession,
      stopSession,
      isBrewpack,
      preview,
      type
    } = this.props;

    const { index, direction, imageloaded } = this.state;
    const step_length = userAction ? userAction.action_steps.length : 0;
    const userActionId = userAction ? userAction.user_action : 0;
    const currentstep = userAction
      ? userAction.action_steps[this.state.index]
      : null;

    const replaceFunction = (match, index) => {
      index;
      const splitString = match.split("|");
      const text = splitString[0];
      const link = splitString[1];
      return (
        <a href={link} target="_blank">
          {text}
        </a>
      );
    };
    return (
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          className="useraction__modal"
          bsSize="large"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                textAlign: "center",
                fontFamily: "Gotham-Bold",
                fontWeight: "normal"
              }}
            >
              {!this.state.imageloaded && "Loading useraction..."}
              {type
                ? `${type} Instruction`
                : this.props.userAction && get(currentstep, "title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body scrollable={true}>
            <div className="container-fluid ">
              <div className="row">
                <div className="col-md-8 col-xs-12" style={{ padding: "0" }}>
                  {userAction && (
                    <UserActionCarousel
                      userAction={userAction}
                      handleSelect={this.handleSelect}
                      index={index}
                      direction={direction}
                      handleImageLoaded={this.handleImageLoaded}
                      style={{ visibility: imageloaded ? "" : "hidden" }}
                    />
                  )}
                </div>
                <div
                  className="col-md-4 instruction-text-container"
                  style={{ marginTop: "20px" }}
                >
                  <div className="instruction-text">
                    Step:{" "}
                    {`${index + 1}/${
                      userAction ? userAction.action_steps.length : 0
                    }`}
                    <p
                      style={{
                        fontFamily: "Gotham-Book",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {reactStringReplace(
                        userAction
                          ? get(userAction, [
                              "action_steps",
                              index,
                              "description"
                            ])
                          : "",
                        /{{(.*?)}}/,
                        replaceFunction
                      )}
                    </p>
                    {/* USERACTION FILL HOP CAROUSELL */}
                    {userActionId == 20 &&
                      currentstep.id == 27 &&
                      !isBrewpack && <HopCarouselIngredients />}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!this.state.imageloaded && (
              <span
                className="start-brew-loading__icon ion-load-c spin"
                style={{ float: "left", marginTop: "6px" }}
              >
                {" "}
                Fetching useraction
              </span>
            )}
            {!preview && (
              <>
                {/* USERACTION PAIR GRAVITY SENSOR */}
                {userActionId == 36 && index == 0 && (
                  <SkipPairingButton action={stopSession || resumeSession} />
                )}
              </>
            )}

            <Button
              onClick={() =>
                this.handleSelect(this.state.index - 1, { direction: "prev" })
              }
              disabled={this.state.index == 0}
            >
              <span className="ion-chevron-left" /> Previous
            </Button>
            {this.state.index < step_length - 1 && (
              <Button
                onClick={() =>
                  this.handleSelect(this.state.index + 1, { direction: "next" })
                }
              >
                Next <span className="ion-chevron-right" />
              </Button>
            )}

            {// On click, we should stop session if that fn
            // is present, otherwise just resume it.
            this.state.index == step_length - 1 &&
              userActionId !== 36 &&
              !preview && (
                <Button
                  onClick={stopSession || resumeSession}
                  bsStyle="primary"
                >
                  Continue
                </Button>
              )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default UserActionModal;

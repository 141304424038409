import { USER_ACTIONS as type } from "@common/constants/actionTypes";
import * as userService from "@profile/services/userService";
import { generateErrorString } from "@common/services/errorService";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import { getSharedFavorites } from "@beers/services/recipeService";

/* set notification permissions */
const setPermission = permission => ({
  type: type.SET_PERMISSION,
  permission
});

export const setNotificationPermission = permission => dispatch => {
  dispatch(setPermission(permission));
};
/* GET */
const getUser = () => ({ type: type.GET_USER });

const getUserSuccess = userInformation => ({
  type: type.GET_USER_SUCCESS,
  userInformation
});

const getSharedFavouritesSuccess = favourites => ({
  type: type.GET_SHARED_FAVOURITES_SUCCESS,
  favourites
});

const getSubscribtionSuccess = subscription => ({
  type: type.GET_SUBSCRIPTIONS_SUCCESS,
  subscription
});

const cancelSubscriptonStart = () => ({ type: type.CANCEL_SUBSCRIPTION });
const cancelSubscriptionSuccess = response => ({
  type: type.CANCEL_SUBSCRIPTION_SUCCESS,
  response
});
const cancelSubscriptionError = errorMessage => ({
  type: type.CANCEL_SUBSCRIPTION_ERROR,
  errorMessage
});
const getUserError = message => ({ type: type.GET_USER_ERROR, message });

export const getUserInformation = () => dispatch => {
  dispatch(getUser());
  userService
    .getCurrentUser()
    .then(data => {
      if (!localStorage.getItem("userId")) {
        analyticsService.mixpanel.identify(data.uuid);
        analyticsService.mixpanel.people.set({
          UserID: data.uuid,
          $name: `${data.first_name} ${data.last_name}`,
          $first_name: data.first_name,
          $last_name: data.last_name,
          $email: data.email
        });
        analyticsService.track(ANALYTICS_TYPES.SIGN_IN);
      }
      localStorage.setItem("userId", data.id);
      dispatch(getUserSuccess(data));
    })
    .catch(response =>
      response.then(() => dispatch(getUserError(generateErrorString())))
    );
  userService.getCurrentSubscription().then(data => {
    dispatch(getSubscribtionSuccess(data[0]));
  });
};

export const getUsersSharedFavourites = () => dispatch => {
  getSharedFavorites().then(res => {
    dispatch(getSharedFavouritesSuccess(res));
  });
};

export const cancelSubscription = subscription => dispatch => {
  dispatch(cancelSubscriptonStart());
  const payload = { subscription_id: subscription };
  userService
    .cancelSubscription(payload)
    .then(response => {
      dispatch(cancelSubscriptionSuccess(response.subscription_id));
      userService.getCurrentSubscription().then(data => {
        dispatch(getSubscribtionSuccess(data[0]));
      });
    })
    .catch(response =>
      response.then(response => {
        dispatch(cancelSubscriptionError(response.message.toString()));
      })
    );
};
/* PATCH */
const patchUser = () => ({ type: type.PATCH_USER });

const patchUserSuccess = userInformation => ({
  type: type.PATCH_USER_SUCCESS,
  userInformation
});

const patchUserError = message => ({ type: type.PATCH_USER_ERROR, message });

export const patchUserInformation = (data, callback) => dispatch => {
  dispatch(patchUser());
  userService
    .updateUserDetails(data)
    .then(data => {
      localStorage.setItem("userId", data.id);
      dispatch(patchUserSuccess(data));
      callback && callback();
    })
    .catch(response =>
      response.then(() => dispatch(patchUserError(generateErrorString())))
    );
};

export const removeUserData = () => dispatch => {
  dispatch({ type: type.REMOVE_USER_DATA });
};

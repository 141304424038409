import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "./recipe-version-item.scss";

const RecipeVersionItem = props => {
  const getClassName = () =>
    props.selectedRecipe.id === props.recipe.id
      ? "recipe-version-item selected"
      : "recipe-version-item";
  const { recipe } = props;
  return (
    <div
      className={getClassName()}
      onClick={() => props.handleClick(recipe.id)}
    >
      <div style={{ float: "left" }}>
        <p className="recipe-version-item__version">
          Version {recipe.version_name}{" "}
          {recipe.shared && (
            <span className="recipe-version-item__shared">Shared</span>
          )}
        </p>
        <p className="recipe-version-item__text">
          Modified:{" "}
          {moment.unix(recipe.modified_timestamp).format("DD-MM-YYYY")}
        </p>
      </div>
      <div
        style={{ float: "right", textAlign: "right" }}
        className="recipe-version-item__brewed-container"
      >
        <span className="ion-chevron-right" />
      </div>
      <div className="recipe-version-item__brewed">
        {!recipe.times_brewed ? (
          <div className="flex flex-row">
            <p
              className={`recipe-version-item__${
                recipe.brewable ? "brewable" : "unbrewable"
              }`}
            >
              {recipe.brewable ? "Brewable" : "Unbrewable"}
            </p>
          </div>
        ) : (
          <React.Fragment>
            <p className="recipe-version-item__text">Brewed</p>
            <p className="recipe-version-item__text">
              {recipe.times_brewed}x
            </p>{" "}
          </React.Fragment>
        )}
      </div>
      <div className="clearfix" />
    </div>
  );
};

RecipeVersionItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  recipeIndex: PropTypes.number.isRequired,
  recipe: PropTypes.object.isRequired
};

export default RecipeVersionItem;

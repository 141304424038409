import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

import { PRESSURE_RELIEF } from "@common/constants/recipeConstants";
import CollapsablePanel from "@common/components/Panel/Panel";
import * as recipeHelper from "../../../../util/recipeHelper";

class RecipeDetailFerm extends React.Component {
  render() {
    const recipe = { ...this.props.currentRecipe };
    const steps = recipeHelper.getFermentationSteps(recipe);

    return (
      <CollapsablePanel header={this.props.header} initialOpen={true}>
        <h3>Fermentation scheme</h3>
        <div className="table-responsive">
          <Table>
            <thead>
              <tr>
                <th />
                <th>Temperature</th>
                <th>End Temperature</th>
                <th>Duration</th>
                <th>Additions</th>
              </tr>
            </thead>
            <tbody>
              {steps.length ? (
                steps.map((step, index) => (
                  <tr key={index}>
                    {step.stageIndex === 0 && (
                      <td rowSpan={step.stageStepsAmount}>
                        {step.stageName}{" "}
                        <small style={{ opacity: "0.6" }}>
                          {step.stageName == "Primary" && (
                            <small style={{ opacity: "0.6" }}>
                              ({PRESSURE_RELIEF[step.pressure_relief]})
                            </small>
                          )}
                        </small>
                      </td>
                    )}
                    <td>{step.temperature}</td>
                    <td>{step.end_temperature || "-"}</td>
                    <td>
                      {step.duration === 1
                        ? `${step.duration} day`
                        : `${step.duration} days`}
                    </td>
                    {step.ingredients ? (
                      <td>
                        <table>
                          <tbody>
                            {step.ingredients.map((ing, index) => (
                              <tr key={index}>
                                <td>
                                  {ing.ingredient_name} - {ing.amount}
                                  gr{" "}
                                  {ing.duration !== 0
                                    ? `for ${ing.duration} days`
                                    : ""}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Data Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CollapsablePanel>
    );
  }
}

RecipeDetailFerm.propTypes = {
  currentRecipe: PropTypes.object,
  header: PropTypes.object
};

export default RecipeDetailFerm;

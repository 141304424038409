import React from "react";
import "./PulseMinibrew.scss";

const PulseMinibrew = () => {
  return (
    <div className="pulse-container">
      <img
        src="https://s3.eu-central-1.amazonaws.com/minibrew/static/img/MiniBrew-logo.png"
        className="pulse-button"
      />
    </div>
  );
};

export default PulseMinibrew;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import UserActionManager from "../UserActionManager/UserActionManager";
import mqttService from "../../../mqttService";
import Container from "@common/components/Container/Container";

const KegCleaning = ({ kegNeedsCleaning, session, mqtt }) => {
  useEffect(() => {
    const { uuid } = session.device;
    if (kegNeedsCleaning) {
      mqttService.subscribeFrequentLog(uuid);
      mqttService.subscribeFrequentLogResp(uuid);
      mqttService.publishRequestFrequentLogs(uuid, mqtt.clientUUID);
    }
    return () => {
      mqttService.unsubscribeFrequentLog(uuid);
      mqttService.unsubscribeFrequentLogResp(uuid);
      mqttService.publishStopFrequentLogs(uuid, mqtt.clientUUID);
    };
  }, [session]);
  return (
    <React.Fragment>
      <div className="col-md-8 col-md-offset-2">
        <Container>
          <h2>Clean keg</h2>
          {kegNeedsCleaning && (
            <UserActionManager session={session} active={true} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ mqtt }) => ({
  mqtt
});

export default connect(
  mapStateToProps,
  null
)(KegCleaning);

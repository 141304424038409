import React from "react";

const headerStyle = {
  fontWeight: "normal",
  fontFamily: "Gotham-Bold",
  display: "inline-block",
  marginTop: 0
};

const containerStyle = {
  marginBottom: "20px"
};
const noteStyle = {
  whiteSpace: "pre-line",
  fontWeight: "normal",
  fontFamily: "Gotham-Book"
};
const SharedRecipeNote = ({ note }) => {
  if (!note || note == "") return null;
  return (
    <div className="minibrew-container" style={containerStyle}>
      <div>
        <h2 style={headerStyle}>Brewer's notes</h2>
      </div>
      <div style={noteStyle}>{note}</div>
    </div>
  );
};

export default SharedRecipeNote;

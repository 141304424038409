import { USER_ACTIONS as type, SIGN_OUT } from "@common/constants/actionTypes";

const initialState = {
  user: null,
  isFetching: false,
  isPatching: false,
  error: false,
  errorMessage: "",
  sharedFavourites: [],
  notifications: false,
  isCancelling: false,
  cancelResponse: "",
  subscription: {}
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case type.GET_SUBSCRIPTIONS:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetchingSubs: true,
        error: false
      });
    case type.GET_SUBSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingSubs: false,
        subscription: action.subscription
      });
    case type.GET_SUBSCRIPTIONS_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetchingSubs: false,
        error: true
      });
    case type.CANCEL_SUBSCRIPTION:
      return Object.assign({}, state, {
        errorMessage: "",
        isCancelling: true,
        error: false,
        cancelResponse: ""
      });
    case type.CANCEL_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        errorMessage: "",
        isCancelling: false,
        error: false,
        cancelResponse: action.response
      });
    case type.CANCEL_SUBSCRIPTION_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
        isCancelling: false,
        error: true
      });
    case type.SET_PERMISSION:
      return Object.assign({}, state, {
        notifications: action.permission
      });
    case type.PATCH_USER:
      return Object.assign({}, state, {
        errorMessage: "",
        isPatching: true,
        error: false
      });
    case type.GET_USER:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: true,
        error: false
      });
    case type.PATCH_USER_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isPatching: false,
        user: action.userInformation
      });
    case type.GET_USER_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: false,
        user: action.userInformation
      });
    case type.PATCH_USER_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isPatching: false,
        error: true
      });
    case type.GET_USER_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: false,
        error: true
      });
    case SIGN_OUT:
      return Object.assign({}, state, {
        user: null
      });
    case type.REMOVE_USER_DATA:
      return Object.assign({}, state, {
        user: null
      });
    case type.GET_SHARED_FAVOURITES_SUCCESS:
      return { ...state, sharedFavourites: action.favourites };
    default:
      return state;
  }
}

import { routes } from "@common/constants/urls";
import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";

// Wrapper for all of the authentication pages: SignIn, Register, PasswordReset
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.goToRegister = this.props.goToRegister.bind(this);
    this.goToSignIn = this.props.goToSignIn.bind(this);
  }

  render() {
    const redirectToRegister = this.goToRegister;
    const redirectToLogin = this.goToSignIn;
    return (
      <main className="container-fluid content--auth auth-bg">
        <section className="home-content col-md-5 col-sm-8 col-xs-12 ">
          <div className="mobile--content">
            <h1 className="text-white home-title">
              CREATE YOUR OWN RECIPES & ACCESS COMMUNITY RECIPES
            </h1>
          </div>
          <p className="text-white home-text">
            With the Minibrew Pro Membership, you get exclusive access to the
            MiniBrew community and more control over the entire brewing process.
            <ul>
              <li>
                <p>Create your own craft beer recipes</p>
              </li>
              <li>
                <p>Recommend & Share recipes with other users</p>
              </li>
              <li>
                <p>Customise, tweak and master available community recipes </p>
              </li>
              <li>
                <p>Over 10.000 craft beer recipes created</p>
              </li>
            </ul>
          </p>
          <div className="text-white center-text" style={{ marginTop: "7%" }}>
            <Button
              style={{
                border: "2px solid #fdcc00",
                background: "transparent",
                color: "#fdcc00",
                width: "45%",
                borderRadius: "5px",
                fontSize: "14px"
              }}
              onClick={redirectToLogin}
              bsStyle="secondary"
            >
              Sign in{" "}
            </Button>
            <Button
              style={{
                width: "45%",
                borderRadius: "5px",
                fontSize: "14px"
              }}
              bsStyle="primary"
              onClick={redirectToRegister}
            >
              Start free trial
            </Button>
          </div>
          <div className="text-white center-text">
            <p>Get started today. Cancel at any time.</p>
          </div>
        </section>
      </main>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    goToSignIn: () => dispatch(push(routes.signin)),
    goToRegister: () => dispatch(push(routes.register))
  };
}
export default connect(
  null,
  mapDispatchToProps
)(Home);

const PROCESS_TYPES = {
  PROC_IDLE: 0,
  PROC_BREWING: 1,
  PROC_CLEAN_MINIBREW: 2,
  PROC_CLEAN_KEG: 3,
  PROC_FERMENTATION: 4,
  PROC_SERVING: 5,
  PROC_ACID_CLEAN_MINIBREW: 6
};

export default PROCESS_TYPES;

export default {
  abv: 0,
  srm: 0,
  ibu: 0,
  fg: 0,
  og: 0,
  kcal: 0,
  private_note: "",
  public_note: "",
  water_amount: 6.5,
  shared: false,
  kettle_water: 0,
  serving_temperature: 5,
  hop_filter: 0,
  mashing: [
    {
      sparge_water: null,
      mash_in_water: 0,
      ingredient_additions: [
        {
          ingredient_id: null,
          ingredient_name: null,
          ingredient_type: "FERM",
          amount: null,
          duration: null,
          srm: null
        }
      ],
      steps: [
        {
          temperature: null,
          duration: null
        }
      ]
    }
  ],
  boiling: [
    {
      duration: null,
      hops: [],
      other_ingredients: []
    }
  ],
  fermenting: [
    {
      fermentation_stage_type: "PRIM",
      yeast: [
        {
          ingredient_id: null,
          ingredient_name: null,
          ingredient_type: "YEAST",
          amount: null
        }
      ],
      steps: [
        {
          temperature: null,
          duration: null
        }
      ]
    },
    {
      fermentation_stage_type: "COND",
      steps: [
        {
          temperature: null,
          duration: null
        }
      ]
    }
  ],
  while_fermenting: {
    hops: [],
    other_ingredients: []
  }
};

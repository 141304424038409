import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

const confirmationModal = props => {
  const {
    title,
    show,
    hide,
    action,
    msg,
    confirmLabel,
    cancelLabel,
    onCancel,
    noCancel,
    confirmStyle
  } = props;
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton={!noCancel}>
        <Modal.Title style={{ textAlign: "center" }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} className="center">
            <span style={{ fontFamily: "Gotham-Book" }}>{msg}</span>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!noCancel && (
          <Button
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              hide();
            }}
          >
            {cancelLabel || "CANCEL"}
          </Button>
        )}
        <Button
          bsStyle={confirmStyle || "danger"}
          onClick={() => {
            action();
            hide();
          }}
        >
          {confirmLabel || "CONFIRM"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default confirmationModal;

import React from "react";
import { Field } from "redux-form";
import { Button, Row } from "react-bootstrap";

import CustomField from "../../../CustomField/CustomField";
import TypeAhead from "../../TypeAhead/TypeAhead";
import { apiEndpoints as api } from "@common/constants/urls";
import { PROFILE_PROPS } from "@common/constants/recipeConstants";
import { normalizeNumber } from "../../../../../../util/recipeHelper";

const normalizeAmount = normalizeNumber(3, 2);
const normalizeDuration = normalizeNumber(3, 0);
const normalizeAlphaAcid = normalizeNumber(2, 1);
// const validateHopAmount = value =>
//   value > 12 ? "Must be 12gr or lower " : undefined;

const hops = ({ fields, change, addItem, removeItem, amount }) => (
  <div>
    <h3>Hops</h3>
    <Button
      type="button"
      disabled={amount > 5}
      onClick={addItem(fields)}
      className="AddButton"
    >
      <i className="icon ion-plus" /> HOP
    </Button>

    {!!fields.length && (
      <div>
        {fields.map((ingredient, index) => (
          <Row key={index} className="list-item">
            <Field
              name={`${ingredient}.ingredient_name`}
              component={TypeAhead}
              forceValidation
              label="Name"
              rowIndex={index}
              change={change}
              smSize={3}
              location={`boiling[0].hops[${index}]`}
              endpoint={api.hops}
              attrMap={{ id: "ingredient_id", avg_alpha_acid: "alpha_acid" }}
            />
            <Field
              name={`${ingredient}.amount`}
              type="number"
              component={CustomField}
              label="Grams"
              rowIndex={index}
              smSize={2}
              normalize={normalizeAmount}
              // validate={validateHopAmount}
              step="0.01"
            />
            <Field
              name={`${ingredient}.duration`}
              type="number"
              component={CustomField}
              label="Duration (min)"
              rowIndex={index}
              smSize={3}
              normalize={normalizeDuration}
            />
            <Field
              name={`${ingredient}.alpha_acid`}
              type="number"
              component={CustomField}
              rowIndex={index}
              label="Alpha Acid"
              overlay={PROFILE_PROPS.ALPHA_ACID}
              smSize={3}
              step="0.1"
              normalize={normalizeAlphaAcid}
            />
            <Button
              className="remove-btn"
              style={{ marginTop: index ? "4px" : "31px" }}
              type="button"
              title="Remove ingredient"
              onClick={removeItem(fields, index)}
            >
              <i className="icon ion-close" />
            </Button>
          </Row>
        ))}
      </div>
    )}
  </div>
);

export default hops;

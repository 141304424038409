import React from "react";
import { Field, reduxForm } from "redux-form";
import { Form, Row, Col, ControlLabel } from "react-bootstrap";
import CustomField from "../CreateRecipe/CustomField/CustomField";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import { required } from "@util/formValidationFunctions";

class CustomHopForm extends React.Component {
  componentDidMount() {
    const { ingredient } = this.props;
    if (
      ingredient !== null &&
      ingredient !== undefined &&
      ingredient.type == "Hop"
    ) {
      this.props.initialize(ingredient);
    }
  }
  render() {
    const { handleSubmit, isSaving, valid } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={10} mdOffset={1}>
            <Field
              name="name"
              component={CustomField}
              label="Name"
              mdSize={12}
              smSize={12}
              validate={[required]}
            />
            <Col sm={12} md={12}>
              <ControlLabel>Usage</ControlLabel>
              <Field
                name="usage"
                component="select"
                className="form-control"
                label="Usage"
              >
                <option value="" defaultValue disabled hidden>
                  Choose here
                </option>
                <option value="ARFLA">Aroma</option>
                <option value="BITTR">Bitter</option>
                <option value="ALL">Aroma/Bitter</option>
              </Field>
            </Col>
            <Field
              name="notes"
              component={CustomField}
              label="Notes"
              componentClass="textarea"
              mdSize={12}
              smSize={12}
            />

            <ButtonWithLoad
              type="submit"
              bsStyle="primary"
              style={{
                float: "right",
                marginTop: "20px",
                marginBottom: "10px"
              }}
              disabled={!valid}
              isLoading={isSaving}
            >
              Save hop
            </ButtonWithLoad>
            <div className="clearfix" />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({ form: "customHop" })(CustomHopForm);

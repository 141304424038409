// source: log.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

var status_pb = require("./status_pb.js");
goog.object.extend(proto, status_pb);
var hardware_pb = require("./hardware_pb.js");
goog.object.extend(proto, hardware_pb);
var recipe_pb = require("./recipe_pb.js");
goog.object.extend(proto, recipe_pb);
var useraction_pb = require("./useraction_pb.js");
goog.object.extend(proto, useraction_pb);
goog.exportSymbol("proto.ExtendedLog", null, global);
goog.exportSymbol("proto.Log", null, global);
goog.exportSymbol("proto.Log.ConsoleLogLevel", null, global);
goog.exportSymbol("proto.Log.PumpStatus", null, global);
goog.exportSymbol("proto.LogRequest", null, global);
goog.exportSymbol("proto.LogRequestResp", null, global);
goog.exportSymbol("proto.Logs", null, global);
goog.exportSymbol("proto.LogsResp", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExtendedLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ExtendedLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExtendedLog.displayName = "proto.ExtendedLog";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Log = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Log.repeatedFields_,
    null
  );
};
goog.inherits(proto.Log, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Log.displayName = "proto.Log";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Logs = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Logs.repeatedFields_,
    null
  );
};
goog.inherits(proto.Logs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Logs.displayName = "proto.Logs";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LogsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LogsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LogsResp.displayName = "proto.LogsResp";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LogRequest.displayName = "proto.LogRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LogRequestResp = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.LogRequestResp.repeatedFields_,
    null
  );
};
goog.inherits(proto.LogRequestResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LogRequestResp.displayName = "proto.LogRequestResp";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ExtendedLog.prototype.toObject = function(opt_includeInstance) {
    return proto.ExtendedLog.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ExtendedLog} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.ExtendedLog.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        availableMemory:
          (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        minutesSinceStartup:
          (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        mqttReconnectCount:
          (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        wifiReconnectCount:
          (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        wifiRssi: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        extraFermentationTimeSec:
          (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExtendedLog}
 */
proto.ExtendedLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExtendedLog();
  return proto.ExtendedLog.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExtendedLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExtendedLog}
 */
proto.ExtendedLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setAvailableMemory(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setMinutesSinceStartup(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setMqttReconnectCount(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setWifiReconnectCount(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setWifiRssi(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setExtraFermentationTimeSec(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExtendedLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExtendedLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExtendedLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExtendedLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(2, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(3, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(4, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(5, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(6, f);
  }
};

/**
 * optional uint32 available_memory = 1;
 * @return {number}
 */
proto.ExtendedLog.prototype.getAvailableMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.setAvailableMemory = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.clearAvailableMemory = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExtendedLog.prototype.hasAvailableMemory = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint32 minutes_since_startup = 2;
 * @return {number}
 */
proto.ExtendedLog.prototype.getMinutesSinceStartup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.setMinutesSinceStartup = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.clearMinutesSinceStartup = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExtendedLog.prototype.hasMinutesSinceStartup = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional int32 mqtt_reconnect_count = 3;
 * @return {number}
 */
proto.ExtendedLog.prototype.getMqttReconnectCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.setMqttReconnectCount = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.clearMqttReconnectCount = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExtendedLog.prototype.hasMqttReconnectCount = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional uint32 wifi_reconnect_count = 4;
 * @return {number}
 */
proto.ExtendedLog.prototype.getWifiReconnectCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.setWifiReconnectCount = function(value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.clearWifiReconnectCount = function() {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExtendedLog.prototype.hasWifiReconnectCount = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional int32 wifi_rssi = 5;
 * @return {number}
 */
proto.ExtendedLog.prototype.getWifiRssi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.setWifiRssi = function(value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.clearWifiRssi = function() {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExtendedLog.prototype.hasWifiRssi = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional uint32 extra_fermentation_time_sec = 6;
 * @return {number}
 */
proto.ExtendedLog.prototype.getExtraFermentationTimeSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.setExtraFermentationTimeSec = function(value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ExtendedLog} returns this
 */
proto.ExtendedLog.prototype.clearExtraFermentationTimeSec = function() {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExtendedLog.prototype.hasExtraFermentationTimeSec = function() {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Log.repeatedFields_ = [3, 4, 10];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Log.prototype.toObject = function(opt_includeInstance) {
    return proto.Log.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Log} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Log.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        session: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
        timestampMs:
          (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        currentStatus:
          (f = msg.getCurrentStatus()) &&
          status_pb.Status.toObject(includeInstance, f),
        sensorsList: jspb.Message.toObjectList(
          msg.getSensorsList(),
          hardware_pb.Sensor.toObject,
          includeInstance
        ),
        actuatorsList: jspb.Message.toObjectList(
          msg.getActuatorsList(),
          hardware_pb.Actuator.toObject,
          includeInstance
        ),
        extendedLog:
          (f = msg.getExtendedLog()) &&
          proto.ExtendedLog.toObject(includeInstance, f),
        processTime:
          (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
        processTimeRemaining:
          (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
        activeIngredientsList: jspb.Message.toObjectList(
          msg.getActiveIngredientsList(),
          recipe_pb.IngredientAddition.toObject,
          includeInstance
        ),
        pumpStatus:
          (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
        carousselPosition:
          (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
        panicMode:
          (f = jspb.Message.getBooleanField(msg, 15)) == null ? undefined : f,
        processSummaryIndex:
          (f = jspb.Message.getField(msg, 17)) == null ? undefined : f,
        processPhase:
          (f = jspb.Message.getField(msg, 21)) == null ? undefined : f,
        setpointTemp:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 18)) == null
            ? undefined
            : f,
        currentTemp:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 19)) == null
            ? undefined
            : f,
        setpointPumpFlow:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 20)) == null
            ? undefined
            : f,
        trubcontainerPresent:
          (f = jspb.Message.getBooleanField(msg, 22)) == null ? undefined : f,
        pressureReliefPresent:
          (f = jspb.Message.getBooleanField(msg, 23)) == null ? undefined : f,
        consoleLogLevel:
          (f = jspb.Message.getField(msg, 24)) == null ? undefined : f,
        currentGravity:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 25)) == null
            ? undefined
            : f,
        timeTillNextUserAction:
          (f = jspb.Message.getField(msg, 26)) == null ? undefined : f,
        nextUserAction:
          (f = jspb.Message.getField(msg, 28)) == null ? undefined : f,
        previousUserAction:
          (f = jspb.Message.getField(msg, 30)) == null ? undefined : f,
        pressurizerPresent:
          (f = jspb.Message.getBooleanField(msg, 27)) == null ? undefined : f,
        gravitySensorUpdateTimestampMs:
          (f = jspb.Message.getField(msg, 29)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Log}
 */
proto.Log.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Log();
  return proto.Log.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Log} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Log}
 */
proto.Log.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 11:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSession(value);
        break;
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setTimestampMs(value);
        break;
      case 2:
        var value = new status_pb.Status();
        reader.readMessage(value, status_pb.Status.deserializeBinaryFromReader);
        msg.setCurrentStatus(value);
        break;
      case 3:
        var value = new hardware_pb.Sensor();
        reader.readMessage(
          value,
          hardware_pb.Sensor.deserializeBinaryFromReader
        );
        msg.addSensors(value);
        break;
      case 4:
        var value = new hardware_pb.Actuator();
        reader.readMessage(
          value,
          hardware_pb.Actuator.deserializeBinaryFromReader
        );
        msg.addActuators(value);
        break;
      case 5:
        var value = new proto.ExtendedLog();
        reader.readMessage(
          value,
          proto.ExtendedLog.deserializeBinaryFromReader
        );
        msg.setExtendedLog(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setProcessTime(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setProcessTimeRemaining(value);
        break;
      case 10:
        var value = new recipe_pb.IngredientAddition();
        reader.readMessage(
          value,
          recipe_pb.IngredientAddition.deserializeBinaryFromReader
        );
        msg.addActiveIngredients(value);
        break;
      case 12:
        var value = /** @type {!proto.Log.PumpStatus} */ (reader.readEnum());
        msg.setPumpStatus(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCarousselPosition(value);
        break;
      case 15:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setPanicMode(value);
        break;
      case 17:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setProcessSummaryIndex(value);
        break;
      case 21:
        var value = /** @type {!proto.ProcessPhase} */ (reader.readEnum());
        msg.setProcessPhase(value);
        break;
      case 18:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setSetpointTemp(value);
        break;
      case 19:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setCurrentTemp(value);
        break;
      case 20:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setSetpointPumpFlow(value);
        break;
      case 22:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setTrubcontainerPresent(value);
        break;
      case 23:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setPressureReliefPresent(value);
        break;
      case 24:
        var value = /** @type {!proto.Log.ConsoleLogLevel} */ (reader.readEnum());
        msg.setConsoleLogLevel(value);
        break;
      case 25:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setCurrentGravity(value);
        break;
      case 26:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setTimeTillNextUserAction(value);
        break;
      case 28:
        var value = /** @type {!proto.UserAction} */ (reader.readEnum());
        msg.setNextUserAction(value);
        break;
      case 30:
        var value = /** @type {!proto.UserAction} */ (reader.readEnum());
        msg.setPreviousUserAction(value);
        break;
      case 27:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setPressurizerPresent(value);
        break;
      case 29:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setGravitySensorUpdateTimestampMs(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Log.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Log.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Log} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Log.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeUint32(11, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(1, f);
  }
  f = message.getCurrentStatus();
  if (f != null) {
    writer.writeMessage(2, f, status_pb.Status.serializeBinaryToWriter);
  }
  f = message.getSensorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hardware_pb.Sensor.serializeBinaryToWriter
    );
  }
  f = message.getActuatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hardware_pb.Actuator.serializeBinaryToWriter
    );
  }
  f = message.getExtendedLog();
  if (f != null) {
    writer.writeMessage(5, f, proto.ExtendedLog.serializeBinaryToWriter);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint32(8, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(9, f);
  }
  f = message.getActiveIngredientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      recipe_pb.IngredientAddition.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.Log.PumpStatus} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeEnum(12, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeUint32(13, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBool(15, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeUint32(17, f);
  }
  f = /** @type {!proto.ProcessPhase} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeEnum(21, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeFloat(18, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeFloat(19, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeFloat(20, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeBool(22, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeBool(23, f);
  }
  f = /** @type {!proto.Log.ConsoleLogLevel} */ (jspb.Message.getField(
    message,
    24
  ));
  if (f != null) {
    writer.writeEnum(24, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeFloat(25, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeUint32(26, f);
  }
  f = /** @type {!proto.UserAction} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeEnum(28, f);
  }
  f = /** @type {!proto.UserAction} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeEnum(30, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeBool(27, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeUint64(29, f);
  }
};

/**
 * @enum {number}
 */
proto.Log.PumpStatus = {
  PUMP_INVALID: 0,
  PUMP_IDLE: 1,
  PUMP_STARTING: 2,
  PUMP_PRIMING: 3,
  PUMP_STABLE_CHECK: 4,
  PUMP_STABLE: 5,
  PUMP_FAILED: 6
};

/**
 * @enum {number}
 */
proto.Log.ConsoleLogLevel = {
  LVL_NONE: 0,
  LVL_ERROR: 1,
  LVL_INFO: 2,
  LVL_ALL: 3
};

/**
 * optional uint32 session = 11;
 * @return {number}
 */
proto.Log.prototype.getSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setSession = function(value) {
  return jspb.Message.setField(this, 11, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearSession = function() {
  return jspb.Message.setField(this, 11, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasSession = function() {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional uint64 timestamp_ms = 1;
 * @return {number}
 */
proto.Log.prototype.getTimestampMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setTimestampMs = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearTimestampMs = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasTimestampMs = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Status current_status = 2;
 * @return {?proto.Status}
 */
proto.Log.prototype.getCurrentStatus = function() {
  return /** @type{?proto.Status} */ jspb.Message.getWrapperField(
    this,
    status_pb.Status,
    2
  );
};

/**
 * @param {?proto.Status|undefined} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setCurrentStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearCurrentStatus = function() {
  return this.setCurrentStatus(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasCurrentStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * repeated Sensor sensors = 3;
 * @return {!Array<!proto.Sensor>}
 */
proto.Log.prototype.getSensorsList = function() {
  return /** @type{!Array<!proto.Sensor>} */ jspb.Message.getRepeatedWrapperField(
    this,
    hardware_pb.Sensor,
    3
  );
};

/**
 * @param {!Array<!proto.Sensor>} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setSensorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.Sensor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Sensor}
 */
proto.Log.prototype.addSensors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.Sensor,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearSensorsList = function() {
  return this.setSensorsList([]);
};

/**
 * repeated Actuator actuators = 4;
 * @return {!Array<!proto.Actuator>}
 */
proto.Log.prototype.getActuatorsList = function() {
  return /** @type{!Array<!proto.Actuator>} */ jspb.Message.getRepeatedWrapperField(
    this,
    hardware_pb.Actuator,
    4
  );
};

/**
 * @param {!Array<!proto.Actuator>} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setActuatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.Actuator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Actuator}
 */
proto.Log.prototype.addActuators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    4,
    opt_value,
    proto.Actuator,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearActuatorsList = function() {
  return this.setActuatorsList([]);
};

/**
 * optional ExtendedLog extended_log = 5;
 * @return {?proto.ExtendedLog}
 */
proto.Log.prototype.getExtendedLog = function() {
  return /** @type{?proto.ExtendedLog} */ jspb.Message.getWrapperField(
    this,
    proto.ExtendedLog,
    5
  );
};

/**
 * @param {?proto.ExtendedLog|undefined} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setExtendedLog = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearExtendedLog = function() {
  return this.setExtendedLog(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasExtendedLog = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional uint32 process_time = 8;
 * @return {number}
 */
proto.Log.prototype.getProcessTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setProcessTime = function(value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearProcessTime = function() {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasProcessTime = function() {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional uint32 process_time_remaining = 9;
 * @return {number}
 */
proto.Log.prototype.getProcessTimeRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setProcessTimeRemaining = function(value) {
  return jspb.Message.setField(this, 9, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearProcessTimeRemaining = function() {
  return jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasProcessTimeRemaining = function() {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * repeated IngredientAddition active_ingredients = 10;
 * @return {!Array<!proto.IngredientAddition>}
 */
proto.Log.prototype.getActiveIngredientsList = function() {
  return /** @type{!Array<!proto.IngredientAddition>} */ jspb.Message.getRepeatedWrapperField(
    this,
    recipe_pb.IngredientAddition,
    10
  );
};

/**
 * @param {!Array<!proto.IngredientAddition>} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setActiveIngredientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};

/**
 * @param {!proto.IngredientAddition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IngredientAddition}
 */
proto.Log.prototype.addActiveIngredients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    10,
    opt_value,
    proto.IngredientAddition,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearActiveIngredientsList = function() {
  return this.setActiveIngredientsList([]);
};

/**
 * optional PumpStatus pump_status = 12;
 * @return {!proto.Log.PumpStatus}
 */
proto.Log.prototype.getPumpStatus = function() {
  return /** @type {!proto.Log.PumpStatus} */ (jspb.Message.getFieldWithDefault(
    this,
    12,
    0
  ));
};

/**
 * @param {!proto.Log.PumpStatus} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setPumpStatus = function(value) {
  return jspb.Message.setField(this, 12, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearPumpStatus = function() {
  return jspb.Message.setField(this, 12, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasPumpStatus = function() {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * optional uint32 caroussel_position = 13;
 * @return {number}
 */
proto.Log.prototype.getCarousselPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setCarousselPosition = function(value) {
  return jspb.Message.setField(this, 13, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearCarousselPosition = function() {
  return jspb.Message.setField(this, 13, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasCarousselPosition = function() {
  return jspb.Message.getField(this, 13) != null;
};

/**
 * optional bool panic_mode = 15;
 * @return {boolean}
 */
proto.Log.prototype.getPanicMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    15,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setPanicMode = function(value) {
  return jspb.Message.setField(this, 15, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearPanicMode = function() {
  return jspb.Message.setField(this, 15, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasPanicMode = function() {
  return jspb.Message.getField(this, 15) != null;
};

/**
 * optional uint32 process_summary_index = 17;
 * @return {number}
 */
proto.Log.prototype.getProcessSummaryIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setProcessSummaryIndex = function(value) {
  return jspb.Message.setField(this, 17, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearProcessSummaryIndex = function() {
  return jspb.Message.setField(this, 17, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasProcessSummaryIndex = function() {
  return jspb.Message.getField(this, 17) != null;
};

/**
 * optional ProcessPhase process_phase = 21;
 * @return {!proto.ProcessPhase}
 */
proto.Log.prototype.getProcessPhase = function() {
  return /** @type {!proto.ProcessPhase} */ (jspb.Message.getFieldWithDefault(
    this,
    21,
    0
  ));
};

/**
 * @param {!proto.ProcessPhase} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setProcessPhase = function(value) {
  return jspb.Message.setField(this, 21, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearProcessPhase = function() {
  return jspb.Message.setField(this, 21, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasProcessPhase = function() {
  return jspb.Message.getField(this, 21) != null;
};

/**
 * optional float setpoint_temp = 18;
 * @return {number}
 */
proto.Log.prototype.getSetpointTemp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    18,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setSetpointTemp = function(value) {
  return jspb.Message.setField(this, 18, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearSetpointTemp = function() {
  return jspb.Message.setField(this, 18, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasSetpointTemp = function() {
  return jspb.Message.getField(this, 18) != null;
};

/**
 * optional float current_temp = 19;
 * @return {number}
 */
proto.Log.prototype.getCurrentTemp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    19,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setCurrentTemp = function(value) {
  return jspb.Message.setField(this, 19, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearCurrentTemp = function() {
  return jspb.Message.setField(this, 19, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasCurrentTemp = function() {
  return jspb.Message.getField(this, 19) != null;
};

/**
 * optional float setpoint_pump_flow = 20;
 * @return {number}
 */
proto.Log.prototype.getSetpointPumpFlow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    20,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setSetpointPumpFlow = function(value) {
  return jspb.Message.setField(this, 20, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearSetpointPumpFlow = function() {
  return jspb.Message.setField(this, 20, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasSetpointPumpFlow = function() {
  return jspb.Message.getField(this, 20) != null;
};

/**
 * optional bool trubcontainer_present = 22;
 * @return {boolean}
 */
proto.Log.prototype.getTrubcontainerPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    22,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setTrubcontainerPresent = function(value) {
  return jspb.Message.setField(this, 22, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearTrubcontainerPresent = function() {
  return jspb.Message.setField(this, 22, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasTrubcontainerPresent = function() {
  return jspb.Message.getField(this, 22) != null;
};

/**
 * optional bool pressure_relief_present = 23;
 * @return {boolean}
 */
proto.Log.prototype.getPressureReliefPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    23,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setPressureReliefPresent = function(value) {
  return jspb.Message.setField(this, 23, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearPressureReliefPresent = function() {
  return jspb.Message.setField(this, 23, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasPressureReliefPresent = function() {
  return jspb.Message.getField(this, 23) != null;
};

/**
 * optional ConsoleLogLevel console_log_level = 24;
 * @return {!proto.Log.ConsoleLogLevel}
 */
proto.Log.prototype.getConsoleLogLevel = function() {
  return /** @type {!proto.Log.ConsoleLogLevel} */ (jspb.Message.getFieldWithDefault(
    this,
    24,
    0
  ));
};

/**
 * @param {!proto.Log.ConsoleLogLevel} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setConsoleLogLevel = function(value) {
  return jspb.Message.setField(this, 24, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearConsoleLogLevel = function() {
  return jspb.Message.setField(this, 24, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasConsoleLogLevel = function() {
  return jspb.Message.getField(this, 24) != null;
};

/**
 * optional float current_gravity = 25;
 * @return {number}
 */
proto.Log.prototype.getCurrentGravity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    25,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setCurrentGravity = function(value) {
  return jspb.Message.setField(this, 25, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearCurrentGravity = function() {
  return jspb.Message.setField(this, 25, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasCurrentGravity = function() {
  return jspb.Message.getField(this, 25) != null;
};

/**
 * optional uint32 time_till_next_user_action = 26;
 * @return {number}
 */
proto.Log.prototype.getTimeTillNextUserAction = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setTimeTillNextUserAction = function(value) {
  return jspb.Message.setField(this, 26, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearTimeTillNextUserAction = function() {
  return jspb.Message.setField(this, 26, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasTimeTillNextUserAction = function() {
  return jspb.Message.getField(this, 26) != null;
};

/**
 * optional UserAction next_user_action = 28;
 * @return {!proto.UserAction}
 */
proto.Log.prototype.getNextUserAction = function() {
  return /** @type {!proto.UserAction} */ (jspb.Message.getFieldWithDefault(
    this,
    28,
    0
  ));
};

/**
 * @param {!proto.UserAction} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setNextUserAction = function(value) {
  return jspb.Message.setField(this, 28, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearNextUserAction = function() {
  return jspb.Message.setField(this, 28, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasNextUserAction = function() {
  return jspb.Message.getField(this, 28) != null;
};

/**
 * optional UserAction previous_user_action = 30;
 * @return {!proto.UserAction}
 */
proto.Log.prototype.getPreviousUserAction = function() {
  return /** @type {!proto.UserAction} */ (jspb.Message.getFieldWithDefault(
    this,
    30,
    0
  ));
};

/**
 * @param {!proto.UserAction} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setPreviousUserAction = function(value) {
  return jspb.Message.setField(this, 30, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearPreviousUserAction = function() {
  return jspb.Message.setField(this, 30, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasPreviousUserAction = function() {
  return jspb.Message.getField(this, 30) != null;
};

/**
 * optional bool pressurizer_present = 27;
 * @return {boolean}
 */
proto.Log.prototype.getPressurizerPresent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    27,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setPressurizerPresent = function(value) {
  return jspb.Message.setField(this, 27, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearPressurizerPresent = function() {
  return jspb.Message.setField(this, 27, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasPressurizerPresent = function() {
  return jspb.Message.getField(this, 27) != null;
};

/**
 * optional uint64 gravity_sensor_update_timestamp_ms = 29;
 * @return {number}
 */
proto.Log.prototype.getGravitySensorUpdateTimestampMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};

/**
 * @param {number} value
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.setGravitySensorUpdateTimestampMs = function(value) {
  return jspb.Message.setField(this, 29, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Log} returns this
 */
proto.Log.prototype.clearGravitySensorUpdateTimestampMs = function() {
  return jspb.Message.setField(this, 29, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Log.prototype.hasGravitySensorUpdateTimestampMs = function() {
  return jspb.Message.getField(this, 29) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Logs.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Logs.prototype.toObject = function(opt_includeInstance) {
    return proto.Logs.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Logs} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Logs.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        seq: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        timestampMs:
          (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        isHistorical:
          (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
        logsList: jspb.Message.toObjectList(
          msg.getLogsList(),
          proto.Log.toObject,
          includeInstance
        ),
        session: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Logs}
 */
proto.Logs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Logs();
  return proto.Logs.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Logs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Logs}
 */
proto.Logs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeq(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setTimestampMs(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsHistorical(value);
        break;
      case 3:
        var value = new proto.Log();
        reader.readMessage(value, proto.Log.deserializeBinaryFromReader);
        msg.addLogs(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSession(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Logs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Logs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Logs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Logs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(5, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(2, f);
  }
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.Log.serializeBinaryToWriter);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(4, f);
  }
};

/**
 * optional uint32 seq = 1;
 * @return {number}
 */
proto.Logs.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Logs.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint64 timestamp_ms = 5;
 * @return {number}
 */
proto.Logs.prototype.getTimestampMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.setTimestampMs = function(value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.clearTimestampMs = function() {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Logs.prototype.hasTimestampMs = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional bool is_historical = 2;
 * @return {boolean}
 */
proto.Logs.prototype.getIsHistorical = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    2,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.setIsHistorical = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.clearIsHistorical = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Logs.prototype.hasIsHistorical = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * repeated Log logs = 3;
 * @return {!Array<!proto.Log>}
 */
proto.Logs.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.Log>} */ jspb.Message.getRepeatedWrapperField(
    this,
    proto.Log,
    3
  );
};

/**
 * @param {!Array<!proto.Log>} value
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.Log=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Log}
 */
proto.Logs.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.Log,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};

/**
 * optional uint32 session = 4;
 * @return {number}
 */
proto.Logs.prototype.getSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.setSession = function(value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Logs} returns this
 */
proto.Logs.prototype.clearSession = function() {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Logs.prototype.hasSession = function() {
  return jspb.Message.getField(this, 4) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.LogsResp.prototype.toObject = function(opt_includeInstance) {
    return proto.LogsResp.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.LogsResp} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.LogsResp.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        seq: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LogsResp}
 */
proto.LogsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LogsResp();
  return proto.LogsResp.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LogsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LogsResp}
 */
proto.LogsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeq(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LogsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LogsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LogsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LogsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
};

/**
 * optional uint32 seq = 1;
 * @return {number}
 */
proto.LogsResp.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.LogsResp} returns this
 */
proto.LogsResp.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.LogsResp} returns this
 */
proto.LogsResp.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LogsResp.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.LogRequest.prototype.toObject = function(opt_includeInstance) {
    return proto.LogRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.LogRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.LogRequest.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        seq: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        deviceId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        startOrStop:
          (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
        dumpHistory:
          (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f,
        extendedLog:
          (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LogRequest}
 */
proto.LogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LogRequest();
  return proto.LogRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LogRequest}
 */
proto.LogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeq(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDeviceId(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setStartOrStop(value);
        break;
      case 4:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setDumpHistory(value);
        break;
      case 5:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setExtendedLog(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(2, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(3, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(4, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(5, f);
  }
};

/**
 * optional uint32 seq = 1;
 * @return {number}
 */
proto.LogRequest.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LogRequest.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string device_id = 2;
 * @return {string}
 */
proto.LogRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.clearDeviceId = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LogRequest.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional bool start_or_stop = 3;
 * @return {boolean}
 */
proto.LogRequest.prototype.getStartOrStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    3,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.setStartOrStop = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.clearStartOrStop = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LogRequest.prototype.hasStartOrStop = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bool dump_history = 4;
 * @return {boolean}
 */
proto.LogRequest.prototype.getDumpHistory = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    4,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.setDumpHistory = function(value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.clearDumpHistory = function() {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LogRequest.prototype.hasDumpHistory = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bool extended_log = 5;
 * @return {boolean}
 */
proto.LogRequest.prototype.getExtendedLog = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    5,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.setExtendedLog = function(value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.LogRequest} returns this
 */
proto.LogRequest.prototype.clearExtendedLog = function() {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LogRequest.prototype.hasExtendedLog = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LogRequestResp.repeatedFields_ = [2, 3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.LogRequestResp.prototype.toObject = function(opt_includeInstance) {
    return proto.LogRequestResp.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.LogRequestResp} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.LogRequestResp.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        seq: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        logsList: jspb.Message.toObjectList(
          msg.getLogsList(),
          proto.Log.toObject,
          includeInstance
        ),
        processSummaryList: jspb.Message.toObjectList(
          msg.getProcessSummaryList(),
          status_pb.ProcessSummaryItem.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LogRequestResp}
 */
proto.LogRequestResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LogRequestResp();
  return proto.LogRequestResp.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LogRequestResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LogRequestResp}
 */
proto.LogRequestResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeq(value);
        break;
      case 2:
        var value = new proto.Log();
        reader.readMessage(value, proto.Log.deserializeBinaryFromReader);
        msg.addLogs(value);
        break;
      case 3:
        var value = new status_pb.ProcessSummaryItem();
        reader.readMessage(
          value,
          status_pb.ProcessSummaryItem.deserializeBinaryFromReader
        );
        msg.addProcessSummary(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LogRequestResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LogRequestResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LogRequestResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LogRequestResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = message.getLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.Log.serializeBinaryToWriter);
  }
  f = message.getProcessSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      status_pb.ProcessSummaryItem.serializeBinaryToWriter
    );
  }
};

/**
 * optional uint32 seq = 1;
 * @return {number}
 */
proto.LogRequestResp.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.LogRequestResp} returns this
 */
proto.LogRequestResp.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.LogRequestResp} returns this
 */
proto.LogRequestResp.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LogRequestResp.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * repeated Log logs = 2;
 * @return {!Array<!proto.Log>}
 */
proto.LogRequestResp.prototype.getLogsList = function() {
  return /** @type{!Array<!proto.Log>} */ jspb.Message.getRepeatedWrapperField(
    this,
    proto.Log,
    2
  );
};

/**
 * @param {!Array<!proto.Log>} value
 * @return {!proto.LogRequestResp} returns this
 */
proto.LogRequestResp.prototype.setLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.Log=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Log}
 */
proto.LogRequestResp.prototype.addLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.Log,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LogRequestResp} returns this
 */
proto.LogRequestResp.prototype.clearLogsList = function() {
  return this.setLogsList([]);
};

/**
 * repeated ProcessSummaryItem process_summary = 3;
 * @return {!Array<!proto.ProcessSummaryItem>}
 */
proto.LogRequestResp.prototype.getProcessSummaryList = function() {
  return /** @type{!Array<!proto.ProcessSummaryItem>} */ jspb.Message.getRepeatedWrapperField(
    this,
    status_pb.ProcessSummaryItem,
    3
  );
};

/**
 * @param {!Array<!proto.ProcessSummaryItem>} value
 * @return {!proto.LogRequestResp} returns this
 */
proto.LogRequestResp.prototype.setProcessSummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.ProcessSummaryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProcessSummaryItem}
 */
proto.LogRequestResp.prototype.addProcessSummary = function(
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    3,
    opt_value,
    proto.ProcessSummaryItem,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LogRequestResp} returns this
 */
proto.LogRequestResp.prototype.clearProcessSummaryList = function() {
  return this.setProcessSummaryList([]);
};

goog.object.extend(exports, proto);

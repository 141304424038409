import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form"; // ES6
import { notify } from "reapop";
import Sticky from "react-stickynode";
import Recipe from "./Recipe/Recipe";
import "./CreateRecipe.scss";
import { get, post, put } from "@common/services/request";
import ConfirmationModal from "../../../common/components/ConfirmationModal/ConfirmationModal";
import { calculateBeerProfile } from "./utils";
import TitleBar from "../../../common/components/TitleBar/TitleBar";
import BeerProfileBar from "../../../common/components/BeerProfileBar/BeerProfileBar";
import {
  NewIngredientProvider,
  NewIngredientConsumer
} from "./NewIngredientContext";
import * as ingredientActions from "../../../beers/actions/ingredientActions";
import CustomIngredientModal from "../CustomIngredientList/CustomIngredientModal";
import { validateRecipe } from "@beers/services/recipeService";
import { apiEndpoints as api, routes } from "@common/constants/urls";
import { removeNullIngredients } from "../../../util/recipeHelper";
import { logError } from "../../../common/util/exceptionHandler";
import { warn } from "./Recipe/validate";
import BeerStyleComparison from "./BeerStyleComparison/BeerStyleComparison";

class CreateRecipe extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.state = {
      beerName: "",
      beerStyle: "",
      beerStyleID: null,
      mode: null,
      modalIsVisible: false,
      modalAction: null,
      modalErrors: {},
      initialValidationTriggered: false,
      shouldValidateInitial: urlParams.get("mode") === "validate",
      isSaving: false
    };
  }

  componentWillMount() {
    this.fetchIngredients();

    const beer_id = this.props.match.params.id;
    const mode = this.props.location.search.split("=")[1];
    this.setState({ mode });
    get(`${api.beers}${beer_id}/`).then(beer =>
      this.setState({
        beerName: beer.name,
        beerStyle: beer.style.name,
        beerStyleID: beer.style.id
      })
    );
  }

  fetchIngredients = () => {
    this.props.fetchHops();
    this.props.fetchFermentables();
    this.props.fetchYeasts();
    this.props.fetchAdjuncts();
    this.props.fetchLaboratories();
  };

  recipeIsvalid = ({ boiling, mashing }) => {
    const boilingStepOne = boiling[0];
    const mashingStepOne = mashing[0].steps[0];

    const mashingOk =
      mashingStepOne.duration > 0 && mashingStepOne.temperature > 0;
    const boilingOk = boilingStepOne.duration > 0;

    const errorMap = {
      ["The mashing stage needs to have at least one step"]: !mashingOk,
      ["Needs to have a boiling duration of more than 1 minute"]: !boilingOk
    };

    this.setState({ modalErrors: errorMap });

    return mashingOk && boilingOk;
  };

  validateRecipe = recipe => {
    const urlRecipeId = this.props.match.params.recipeid;
    const newRecipe = urlRecipeId === "new";
    const newVersion = this.props.match.path.includes("newversion");
    const recipeId = newRecipe || newVersion ? "" : `${urlRecipeId}/`;

    this.setState({
      isSaving: true
    });

    const save = newRecipe || newVersion ? post : put;

    // Normalize beer profile fields
    recipe.fg = recipe.fg ? parseFloat(recipe.fg).toFixed(3) : 0;
    recipe.og = recipe.og ? parseFloat(recipe.og).toFixed(3) : 0;
    recipe.ibu = recipe.ibu ? parseInt(recipe.ibu) : 0;

    return validateRecipe(recipe)
      .then(() => this.saveRecipe(recipe, recipeId, save))
      .catch(e => {
        return e.then(err => {
          this.setState({
            isConfirmationModalVisible: true,
            modalAction: () => this.saveRecipe(recipe, recipeId, save)
          });
          this.setState({
            isSaving: false
          });
          throw new SubmissionError(err.errors);
        });
      });
  };

  saveRecipe = (recipe, recipeId, save) =>
    save(`${api.recipes}${recipeId}`, recipe)
      .then(() => {
        this.setState({
          isSaving: false
        });
        this.props.history.push(`${routes.beers}${recipe.beer_id}`);
      })
      .catch((e, context) => {
        const extraInfo = { recipeId, recipe };
        logError(e, context, extraInfo);
        this.setState({
          isSaving: false
        });
      });

  recipeHasWarnings = recipe => Object.keys(warn(recipe)).length > 0;

  handleSubmit = formData => {
    this.setState({ modalAction: null });
    const recipe = {
      ...removeNullIngredients(formData),
      beer_id: this.props.match.params.id
    };

    return this.validateRecipe(recipe);
  };

  notifyUser = e => {
    const { notify } = this.props;
    notify({
      title: "Oops!",
      message: "Something went wrong with submitting your form.",
      status: "warning",
      dismissible: true,
      dismissAfter: 6000,
      position: "br",
      buttons: [
        {
          name: "Details",
          primary: true,
          onClick: () => {
            e.then(err => alert(JSON.stringify(err)));
          }
        }
      ]
    });
  };

  closeConfirmationModal = () =>
    this.setState({
      isConfirmationModalVisible: false,
      shouldValidateInitial: true
    });

  scrollToError = () => {
    const { submitErrors } = this.props.form.recipe;
    const errorName = Object.keys(submitErrors)[0];

    let errorElement = null;
    errorElement = document.querySelector(`[name="${errorName}"]`);
    if (!errorElement) {
      errorElement = document.querySelector(`[data-name="${errorName}"]`);
    }

    if (errorElement) {
      const position =
        errorElement.getBoundingClientRect().top +
        document.documentElement.scrollTop;
      window.scrollTo({ top: position - 200, behavior: "smooth" });
    }
  };

  render() {
    const recipeId = this.props.match.params.recipeid;
    const { shouldValidateInitial } = this.state;
    const modalMessage = (
      <Fragment>
        <p style={{ fontFamily: "Gotham-Book" }}>
          The current recipe is not brewable, would you like to save anyway? You
          can review the highlighted fields and alert messages for further
          information.
        </p>
      </Fragment>
    );

    return (
      <div>
        <NewIngredientProvider>
          <TitleBar
            title={this.state.beerName.toUpperCase()}
            subtitle={this.state.beerStyle}
          />
          <Sticky enabled={true} top={80} innerZ={100}>
            <BeerProfileBar
              beerProfile={
                this.props.form.recipe && this.props.form.recipe.values
              }
            />
          </Sticky>
          <NewIngredientConsumer>
            {({ show, hideModal, setActiveKey, activeTabKey, onSuccess }) => (
              <CustomIngredientModal
                show={show}
                onHide={hideModal}
                activeTabKey={activeTabKey}
                setActiveKey={setActiveKey}
                ingredient={null}
                getAllIngredients={this.fetchIngredients}
                showOnlyActiveKey
                onSuccess={onSuccess}
              />
            )}
          </NewIngredientConsumer>
          <div className="CreateRecipe">
            <div className="container">
              <div className="row">
                <div className="hidden-md hidden-sm hidden-xs col-lg-2">
                  <Sticky enabled={true} top={140} innerZ={100}>
                    <BeerStyleComparison
                      styleID={this.state.beerStyleID}
                      beerProfile={this.props.beerProfile}
                    />
                  </Sticky>
                </div>
                <div className="hidden-lg col-md-10 col-md-offset-1">
                  <BeerStyleComparison
                    styleID={this.state.beerStyleID}
                    beerProfile={this.props.beerProfile}
                  />
                </div>
                <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-0">
                  <Recipe
                    onSubmit={this.handleSubmit}
                    recipeId={recipeId}
                    beerProfile={this.props.beerProfile}
                    validationEnabled={this.state.mode === "validate"}
                    shouldValidateInitial={shouldValidateInitial}
                    isSaving={this.state.isSaving}
                  />
                </div>
              </div>
            </div>
          </div>
        </NewIngredientProvider>
        <ConfirmationModal
          show={this.state.isConfirmationModalVisible}
          hide={this.closeConfirmationModal}
          title="BEFORE SAVING..."
          msg={modalMessage}
          action={this.state.modalAction}
          cancelLabel="Edit recipe"
          confirmLabel="Save"
          confirmStyle="primary"
          onCancel={this.scrollToError}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  beerProfile: calculateBeerProfile(state.form.recipe),
  form: state.form,
  user: state.userInfo.user
});

const mapDispatchToProps = {
  notify: notify,
  fetchHops: ingredientActions.fetchHops,
  fetchFermentables: ingredientActions.fetchFermentables,
  fetchYeasts: ingredientActions.fetchYeasts,
  fetchAdjuncts: ingredientActions.fetchAdjuncts,
  fetchLaboratories: ingredientActions.fetchLaboratories
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRecipe);

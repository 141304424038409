/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import AuthenticationLayout from "../authentication/pages/AuthLayout/AuthenticationLayout";
import PortalLayout from "@common/pages/Layouts/PortalLayout";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import TermsAndConditions from "@auth/pages/TermsAndConditions/TermsAndConditionsAuth";
import TermsAndPrivacy from "@auth/pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "@auth/pages/PrivacyPolicy/PrivacyPolicy";
import ChangePassword from "@profile/pages/Profile/ChangePassword/ChangePassword";
import ActivateSubscription from "@auth/pages/ActivateSubscription/ActivateSubscription";
import { routes } from "@common/constants/urls";
import ErrorBoundary from "@common/components/ErrorBoundary/ErrorBoundary";
import PaymentCompleted from "@auth/pages/PaymentCompleted/PaymentCompleted";
import ActivateCoupon from "@auth/pages/ActivateCoupon/ActivateCoupon";

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
class App extends React.Component {
  state = {
    errorBoundaryKey: 1
  };

  changeKey = () => {
    this.setState({ errorBoundaryKey: this.state.errorBoundaryKey + 1 });
  };
  render() {
    return (
      <ErrorBoundary
        key={this.state.errorBoundaryKey}
        changeKey={this.changeKey}
      >
        <Switch>
          <Route path={routes.paymentCompleted} component={PaymentCompleted} />
          <Route path={routes.auth} component={AuthenticationLayout} />
          <Route path={routes.tc} component={TermsAndConditions} />
          <Route
            path={routes.privacyPolicyRegister}
            component={PrivacyPolicy}
          />
          <Route path={routes.termsAndPrivacy} component={TermsAndPrivacy} />
          <PrivateRoute
            path={routes.subscribe}
            component={ActivateSubscription}
          />
          <Route path={routes.coupon} component={ActivateCoupon} />
          <Route
            path={routes.firstPasswordChange}
            render={props => (
              <ChangePassword
                isFirstChange
                nextRoute={routes.brewery}
                title="Please change your password"
                {...props}
              />
            )}
          />
          <PrivateRoute path="/" component={PortalLayout} />
        </Switch>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default App;

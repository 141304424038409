import React from 'react';
import { string, node } from 'prop-types';
import { LinkContainer } from "react-router-bootstrap";
import { MenuItem } from "react-bootstrap";

const LinkedMenuItem = ({ route, eventKey, children }) => (
  <LinkContainer to={route}>
    <MenuItem eventKey={eventKey}>{children}</MenuItem>
  </LinkContainer>
);

LinkedMenuItem.propTypes = {
  route: string,
  eventKey: string,
  children: node,
};

export default LinkedMenuItem;

import React from "react";
import { Route, NavLink, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import SignInPage from "@auth/pages/SignIn/SignInPage";
import RegisterPage from "@auth/pages/Register/RegisterPage";
import ResetPassword from "@auth/pages/ResetPassword/ResetPasswordPage";
import Navigation from "../../../common/components/Navigation/Navigation";
import analyticsService from "@common/services/analyticsService";
import { routes } from "@common/constants/urls";

import "./authentication-layout.scss";
import Home from "../Home/Home";

// Wrapper for all of the authentication pages: SignIn, Register, PasswordReset
class AuthenticationLayout extends React.Component {
  componentWillMount() {
    analyticsService.register();
    const { dispatch } = this.props;
    const token = localStorage.getItem("token");

    if (token) {
      dispatch(push(routes.brewery));
    }
  }

  render() {
    let showRegister = false;
    let color = "";
    if (
      window.location.href.indexOf("signin") > -1 ||
      window.location.href.indexOf("home") > -1 ||
      window.location.href.indexOf("resetpassword") > -1
    ) {
      color = "white";
      return (
        <div className="wrapper--auth white-bg ">
          <Navigation
            showBurger={false}
            auth={true}
            color={color}
            portal={false}
          >
            {!this.props.mobile && (
              <div className="header__nav--auth col-xs-4 col-sm-7  hidden-sm hidden-xs">
                <NavLink activeClassName="active" to={routes.signin}>
                  SIGN IN
                </NavLink>
                <NavLink
                  className="trial--btn"
                  activeClassName="active"
                  to={routes.register}
                >
                  Start free trial
                </NavLink>
              </div>
            )}
          </Navigation>

          <Switch>
            <Route path={routes.signin} component={SignInPage} />
            <Route path={routes.register} component={RegisterPage} />
            <Route path={routes.resetPassword} component={ResetPassword} />
            <Route path={routes.home} component={Home} />
          </Switch>
        </div>
      );
    } else {
      color = "black";
      return (
        <div className="wrapper--auth ">
          <Navigation
            showBurger={false}
            auth={true}
            color={color}
            colorLogo={true}
          >
            <div className="header__nav--auth col-xs-5 col-sm-9 ">
              {showRegister && (
                <NavLink
                  style={{ marginLeft: "15px" }}
                  activeClassName="active"
                  to={routes.register}
                >
                  REGISTER
                </NavLink>
              )}
            </div>
          </Navigation>
          <Switch>
            <Route path={routes.signin} component={SignInPage} />
            <Route path={routes.register} component={RegisterPage} />
            <Route path={routes.resetPassword} component={ResetPassword} />
            <Route path={routes.home} component={Home} />
          </Switch>
        </div>
      );
    }
  }
}

AuthenticationLayout.propTypes = {
  match: PropTypes.object,
  auth: PropTypes.object,
  dispatch: PropTypes.func
};

function mapStateToProps({ auth, userInfo }) {
  return { auth, userInfo };
}

export default connect(mapStateToProps)(AuthenticationLayout);

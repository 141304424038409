import React from "react";

const TcContent = () => {
  return (
    <div style={{ padding: "0px 15px" }}>

      
      

      <h2 className="c5" id="h.zfxovvv72zq3">
        <span className="c1">Terms and Conditions</span>
      </h2>
      <p className="c2">
        <span className="c0">Effective date: October 16, 2021,</span>
      </p>
      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h1 className="c5">
        <span className="c1">MINIBREW GENERAL TERMS AND CONDITIONS</span>
      </h1>





      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 1 - Definitions</span>
      </h2>
      <p className="c2">
        <span className="c0">
          In these General Terms and Conditions of Online Sale (“Terms”) the following words shall have the following meaning
          unless the context requires otherwise:
          <ol>
            <li>Agreement: any and every agreement entered into between MiniBrew and Buyer including these Terms, and any
              amendment thereto.</li>
            <li>Cooling-off Period: the period during which the Consumer-Buyer is entitled to exercise the right to withdraw
              from the contract for no reason within 30 Days from Contract formation as per these Terms;</li>
            <li>Consumer-Buyer: a natural person, who is acting for personal purposes not related to his/her business or
              professional activities;</li>
            <li>Digital content: data produced and supplied in a digital form;</li>
            <li>Withdrawal Right: the option for the Consumer-Buyer to cancel the Contract within the Cooling-off Period;</li>
            <li>Buyer or You: the party with whom MiniBrew enters into a Contract for the sale of a Product and/or related
              Services. This party may be either a Consumer-Buyer or a Commercial Buyer;</li>
            <li>Contract: the sales contract between MiniBrew and the Buyer for the Products;</li>
            <li>Products: any and all goods and services which are the subject of the Contract.</li>
            <li>Services: all services provided by MiniBrew to the Buyers, which are directly linked to the Purchase of a
              MiniBrew Product (e.g.POM-Service or “Pro Membership” as defined in these Terms);</li>
            <li>MiniBrew Brewing Agent: a MiniBrew certified brewing mechanic who facilitates repair &amp; return and warehouse;
            </li>
            <li>Commercial Buyer: a buyer, legal entity acting within the scope of his commercial, business, trade or
              professional activities.</li>
            <li>Website:<span> </span><a href="https://www.minibrew.io/">www.minibrew.io</a>
            </li>
            <li>Subscription: a contract for the Services during a certain period with a recurring payment.</li>
          </ol>
        </span>
      </p>




      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 2 - MiniBrew identity</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <p>
            Name: MiniBrew B.V. (referred to as “MiniBrew” herein)<br />Registered office: Amsterdam<br />Office address: Atoomweg
            63, 3542 AA Utrecht, the Netherlands<br />Telephone number: +31 85 060 2015<br />Email address:
            hello@minibrew.io<br />Website: www.minibrew.io<br />Chamber of Commerce registration number: 63971569
          </p>
        </span>
      </p>





      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 3 - Applicability and Contract Formation</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>These Terms as well as the<span> </span><a href="https://www.minibrew.io/pages/privacy-statement">Privacy
              Statement</a><span> </span>apply to all quotations from MiniBrew and to all Contracts concluded between
              MiniBrew and Buyer for purchase of products and related Services on the Website (“Products”) and delivery of
              such Products in the European Union and the following countries: Cyprus, Iceland, Norway, Switzerland, and the
              United Kingdom.</li>
            <li>Applicability of any general terms and conditions applied by Buyer is explicitly dismissed by MiniBrew.</li>
            <li>The Contract shall be formed as soon as Minibrew has sent an email confirmation to the Buyer confirming receipt
              of the order submitted by Buyer. Orders submitted by Buyer are binding and cannot be cancelled after order
              submission, which includes explicit acceptance of the payment obligation. No orders submitted through the
              Website are binding on MiniBrew unless explicitly accepted by MiniBrew. Acceptance will be effective upon
              transmission of MiniBrew’s email confirmation of acceptance of your order. MiniBrew has no obligation to accept
              orders placed through this Website. MiniBrew reserves the right to restrict ordered quantities as a condition to
              order acceptance.</li>
            <li>MiniBrew will provide the Buyer with a confirmation of the Contract within a reasonable period of time after
              entry into the Contract and in any event before the order is delivered. After acceptance of an Order, MiniBrew
              shall at all times be entitled to cancel such Order without stating its reasons, in which case MiniBrew shall
              not be obliged to refund more than the payments already made by User, if any</li>
            <li>Acceptance of these Terms include acceptance of automatic software updates of the Services and of the products
              connected with the Services.</li>
          </ol>
        </span>
      </p>





      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 4 - Warranty</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>MiniBrew warrants the Products (with the exception of consumables and opened packaging of brew packs) supplied
              hereunder will conform to the Specification and the Contract for a period of 24 months from the date of delivery
              to Consumer-Buyer, or for 100 brew sessions for a Commercial Buyer. However, this warranty applies only for
              Buyers in the EU and with the exception of the following countries Cyprus, Iceland, Norway, Switzerland, and the
              United Kingdom. Outside the EU, the warranty is limited to your statutory rights as a consumer, or in the case
              of a Commercial Buyer. Brew Packs have a limited period of use of one (1) year as printed on the packaging.</li>
            <li>Except as expressly set out in these Terms, to the fullest extent permitted by law MiniBrew hereby disclaims any
              and all further warranties, whether express or implied. The limited warranty set out will not affect or
              prejudice your statutory rights.</li>
            <li>The Products or Services available on the Website may be mispriced, described inaccurately, or unavailable.
              There can be delays in updating information on the Service and in the advertising on other websites. MiniBrew
              shall observe due care in informing the User of the figures, measurements, weights, features and other
              information concerning the Products, but cannot warrant that these shall be free of deviations. Any
              specifications or samples demonstrated or made available shall be no more than indications of the Products
              concerned.</li>
            <li>If MiniBrew should deliver Products to the Buyer which MiniBrew has obtained from its own suppliers, MiniBrew is
              not obliged to honour a warranty or liability in respect of the Buyer which is more far-reaching than that which
              MiniBrew can claim from its own supplier.</li>
            <li>
              <p>If Buyer has been able to prove that any Products supplied by MiniBrew to the Buyer do not conform to the
                agreed sales Contract, MiniBrew may choose, at its sole discretion, between:</p>
              <ol>
                <li>repair of the Product;</li>
                <li>replacing the Product upon the return of the original Product;</li>
                <li>to grant the Buyer a discount on the purchase price.</li>
                <li>Buyer shall be fully discharged of its warranty obligations by complying with one of the options
                  described above, and it shall not be held to pay any further compensation or damages.</li>
              </ol>
            </li>
            <li>The Products shall remain for the Buyers risk if MiniBrew should carry out any repairs to the Products.</li>
            <li>To obtain warranty service, please contact MiniBrew: hello@minibrew.io</li>
            <li>Please be ready to provide the order number set out on the email order confirmation MiniBrew sent and your
              product ID, which is placed on the back of the machine. The Product must be returned to the address as specified
              by MiniBrew at Buyers’ cost unless otherwise agreed.</li>
          </ol>
        </span>
      </p>






      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 5 - Withdrawal Right</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li><span>
              You have the right to cancel the purchase contract within 14 days of completing the
              payment without giving any reason.
              The cancellation period will expire after 14 days from the day you received an order
              confirmation.
            </span></li>
            <li><span>You have the right to cancel the delivery contract within 30 days without giving any reason. The
              cancellation period will expire after 30 days from the day on which you received the physical
              products..</span></li>
            <li>The Cooling-off Period commences on the Day after the date the Product is delivered.</li>
            <li>The Commercial Buyer shall not have the right to return Products without cause under this withdrawal right.</li>
            <li>The Consumer-Buyer will be obliged to handle the Product and its packaging with due care. The Product may be
              returned used to the extent necessary to determine the nature, characteristics and operation of the Product, and
              conform any instructions MiniBrew may give. The Consumer-Buyer will make the decision to withdraw from the
              Contract known by use of the Sample Form (annex 1 -link ) during the Cooling-Off Period and will return the
              Product forthwith, but at least within 14 Days from the date of submission of the filled-in Sample Form.
              MiniBrew will refund Buyer the purchase price for the Product(s) within thirty (30) Days of receipt of the
              Product at the address on the Sample Form. Consumer-Buyer will bear the costs of the return.</li>
          </ol>
        </span>
      </p>




      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 6 - MiniBrew's obligations in case of withdrawal</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>If Consumer-Buyer withdraws from the Purchase or delivery contract by submitting the form through electronic
              means, Minibrew will immediately issue a confirmation of receipt of the filled in Sample Form for withdrawal
              .MiniBrew will pay the refund into the bank or PayPal account Consumer-Buyer has given in the withdrawal
              notification form.</li>
            <li>Products which are sealed or which are not suitable to be returned for reasons of health protection or hygiene
              and the seal of which was broken after delivery are excluded from the Withdrawal Right. Such Products include
              opened cleaner bottles, beer brewing ingredient packages which have been opened, capsule boxes that have been
              opened and any other product MiniBrew may specify.</li>
          </ol>
        </span>
      </p>





      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 7 - Maintenance Service and Account Registration</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>In addition to the warranty as described in article 4 of these Terms, the Buyer has the possibility to purchase
              a Peace Of Mind Service (the “POM-Service”) which extends the warranty with 1 or 2 extra years and covers all
              costs caused by ordinary wear and tear in the MiniBrew Beer Brewing Machine.</li>
            <li>The POM-Service can be purchased at the same time as the MiniBrew Beer Brewing Machine or at any time
              thereafter.</li>
            <li>For the duration of the POM-Service, MiniBrew will carry out maintenance and repairs on the Beer Brewing Machine
              (parts and labour) insofar as such work is required as a result of normal wear and tear through normal use.</li>
            <li>
              <p>The Buyer will be eligible for free ad-hoc &amp; scheduled maintenance checks conducted by a MiniBrew Brewing
                Agent with regard to the following conditions:</p>
              <ol>
                <li>MiniBrew reserves the right to define which maintenance checks are to be performed by a Brewing Agent or
                  which can be conducted by the Buyer. In the event of any self-maintenance, the Buyer agrees to use
                  original MiniBrew parts during the maintenance as to avoid not being entitled to the benefits of the
                  POM-Service. If the Buyer is not able to prove the maintenance has been performed or any damage is
                  incurred due to the use of not approved MiniBrew parts or accessories, Minibrew reserves the right to
                  invoice the Buyer for the costs incurred by MiniBrew as a result of the incorrect maintenance</li>
                <li>Ad-hoc repairs are subject to fair use of the Product and can be declined should it become evident that
                  the Buyer is mistreating the Product. Fairuse of the Product includes, among other things, the
                  confirmation of the Buyer with regard to regular maintenance appointments with MiniBrew Brewing Agents
                  in the event the Buyer is notified via email or in-app updates, so as to avoid unnecessary damage and/or
                  wear and tear of mechanical items.</li>
              </ol>
            </li>
            <li>The POM-Service is applicable to a specific ID Base station or Smart Keg number related to the Buyer account.
            </li>
            <li>In order to use the POM-Service, Buyer needs to establish a member account ("Account"). To be able to offer
              Buyer POM-Service, Minibrew needs to process personal data such as your name, (email) address, phone number,
              location and brewing history. By registering for an Account, permission is given to disclose some of Buyers’
              personal information to third parties for verification purposes, and to obtain additional information about
              Buyer. MiniBrew may accept or reject registration for an account at her sole discretion.</li>
            <li>The use of another member’s Account, to permit anyone else to use the Account, or assign or otherwise transfer
              the Account to any other person or entity is forbidden. Buyer must provide accurate and complete information,
              and keep such information updated and accurate throughout the term of the POM-Service. Buyer is solely
              responsible for the activity that occurs on the Account. Buyer must notify MiniBrew immediately of any breach of
              security or unauthorized use of the Account. MiniBrew will not be liable for any losses caused by any
              unauthorized use of your Account. You cannot opt out of POM-Service-related emails, SMS messages as these are
              vital to performing our POM-Service to You, unless You terminate the POM-Service.</li>
            <li>Maintenance intervals are subject to brewing cycles made on the Beer Brewing Machine and the Buyer will be
              informed via email, sms or app updates.</li>
            <li>
              <p>The POM-Service encompasses all costs related to replacing/fixing mechanical items due to ordinary wear &amp;
                tear of the Beer Brewing Machine. The POM-Service will not provide any cover in respect of claims which are
                caused directly or indirectly by any of the following:</p>
              <ol>
                <li>You deliberately don’t clean your Beer Brewing Machine unless You can demonstrate to our reasonable
                  satisfaction that other precautions were taken to ensure the lifetime of your Beer Brewing Machine;</li>
                <li>You did not follow the Buyers actions MiniBrew provided at the time of purchase;</li>
                <li>a malfunction of any software, application or other material downloaded to your Beer Brewing Machine
                  from third parties;</li>
                <li>the effect of any virus, “back door”, “time bomb”, “Trojan horse”, “drop dead device” or malware on your
                  Product;</li>
                <li>adjustment to or alteration of your Beer Brewing Machine without original MiniBrew parts/ accessories.
                </li>
                <li>any damage to your Beer Brewing Machine;</li>
                <li>routine servicing or repairs by anyone not authorised by MiniBrew; or</li>
                <li>costs suffered by You as a result of not being able to use the Beer Brewing Machine, or any costs other
                  than the replacement of the Beer Brewing Machine.</li>
              </ol>
            </li>
            <li>MiniBrew Brewing agents reserve the right to define which mechanical pieces need replacing during a check-up.
            </li>
            <li>For the purpose of maintenance and repair work, whether or not instigated by MiniBrew, either the Buyer or
              Minibrew must transport the Beer Brewing Machine to and from MiniBrew Repair &amp; Return Center. The costs
              incurred to and from the Repair &amp; Return Center for such transportation will be covered by MiniBrew. This
              obligation applies for the duration of the Agreement, even in the event of a larger distance between the Buyer
              and the MiniBrew Repair &amp; Return Center within the European Union.</li>
            <li>All costs involved to repair the Beer Brewing Machine are covered by Minibrew.</li>
            <li><span>To exercise the right to cancel your POM Service, you must inform us within 30 (thirty) calendar days
              following the date on which the Beer Brewing Machine is received to [MiniBrew B.V., Atoomweg 63, 3542AA,
              Utrecht, hello@minibrew.io, 085060201] of your decision to cancel this contract by a clear statement (e.g. a
              letter sent by post, fax or e-mail). You may use the model cancellation form, but it is not obligatory. To
              meet the cancellation deadline, it is sufficient for you to send your communication concerning your exercise
              of the right of cancel before the cancellation period has expired.</span></li>
            <li>Buyers have the possibility to transfer the POM-Service when selling their Beer Brewing machine to a third
              party. In this event, the remainder of the POM-Service will follow the MiniBrew Beer Brewing Machine to the new
              owner.</li>
          </ol>
        </span>
      </p>







      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 8 - Delivery and execution</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>In principle, MiniBrew will deliver the product within a period between two and eighty (2-80) weeks from
              entering into the Contract, unless another period of delivery has been agreed, depending on the Product version
              and production possibilities. MiniBrew will use reasonable endeavours to manufacture and deliver your Products
              within the stated delivery period. However, actual Product delivery times may be longer, and if you order the
              Product knowing the delivery moment will be more than thirty (30) days after order confirmation, you hereby
              explicitly agree to such later delivery date as will be specified by MiniBrew in her sole discretion. Products
              are delivered Ex Works (INCOterms 2020) to Commercial Buyers.</li>
            <li>Any delivery or shipment dates given by MiniBrew are best estimates only and MiniBrew shall not be liable for
              any loss, damage, costs or expenses for failure to deliver in accordance with the delivery or shipment dates
              given. Delivery of all Products ordered in one order submission will take place in one delivery. In the event
              any of the Products ordered is out of stock, this may mean the whole order is delayed.</li>
            <li>The Buyer is not entitled to claim any compensation in the event of late delivery.</li>
            <li>MiniBrew reserves the right to refuse or cancel your order at any time for certain reasons including but not
              limited to: Product or service availability, errors in the description or price of the Product or Service, error
              in your order or other reasons.</li>
            <li>MiniBrew shall at all times be entitled to deliver in part consignments.</li>
            <li>If and to the extent that parties have not explicitly agreed in writing on the (costs of) delivery of the
              Products and the transfer of risk, the delivery to a Commercial Buyer shall be made at MiniBrew premises, and
              the risk of the Products and the packing thereof shall be transferred to the Buyer at the moment the Products
              are ready for dispatch, while the dispatch shall be effectuated for the Buyer’s account and risk. In the event
              the Buyer is considered to be a Consumer, the foregoing sentence shall not apply and the moment of delivery and
              transfer of risk is the moment that the Consumer has taken receipt of the Products.</li>
            <li>If the Buyer should fail to collect the Products it has ordered or should fail to do so promptly, it shall be in
              default without requiring a written notice of default. In such an event MiniBrew shall be entitled to store the
              Products for the Buyer’s account and risk and to sell these to a third party. The Buyer shall remain liable for
              the purchase price plus the interest and costs (by way of compensation) after the deduction of the net proceeds
              of such sale to a third party, if any.</li>
            <li>Subject to your compliance with these Terms, MiniBrew grants you a limited non-exclusive, non-transferable,
              non-sublicensable license to use the software pre-installed on your Product and related hardware as part of your
              use of the Product for your own personal, non-commercial purposes. You may not (i) copy, modify or create
              derivative works; (ii) reverse engineer, decompile or disassemble the Product; or (iv) make the functionality of
              the Product available to multiple users through any means.</li>
            <li>While MiniBrew aims that our Product &amp; Services are highly reliable and available, they are not intended to
              be reliable or available 100% of the time. Services offered are subject to sporadic interruptions and failures
              for a variety of reasons beyond MiniBrew’s control, including wi-fi intermittency, service provider uptime,
              mobile notifications and carriers, amongst others. You acknowledge these limitations and agree that MiniBrew is
              not responsible for any damages allegedly caused by the failure or delay of the Services to reflect current
              status or notifications.</li>
            <li>The Services will not be accessible without: (i) a working wi-fi network that is positioned to communicate
              reliably with the Products; (ii) a registered user account; (iii) mobile clients such as a supported smartphone
              or tablet or desktop (iv) always-on broadband internet access for the initial set-up of the system and for
              remote access thereafter; and (v) other system elements, such as a water connection as may be specified by
              MiniBrew. It is the Users’ responsibility to ensure that Users have all required system elements and that they
              are compatible and properly configured. You acknowledge that the Services may not work as described when the
              requirements and compatibility have not been met.</li>
          </ol>
        </span>
      </p>









      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 9 - Price</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>The prices on the Website are inclusive of VAT recycling levies and handling costs and duties for all countries
              within the EU. Prices shall be those prices published on this Website at the time you submit your order. These
              costs will be calculated separately as applicable and specified in your shopping basket and added to the total
              price of the order. The total price stated on the order form you submit shall be the total amount payable by you
              for the Products including all taxes, recycling levies, duties, handling and shipping charges.</li>
            <li>Prices are in euros, unless expressly stated otherwise at a Third Party website. Insofar as prices are stated in
              other currency than euro, then such statement of price is deemed to be based on the euro equivalent of such
              price at the date that the price statement was made.</li>
            <li>Delivery costs are not included in the Product price advertised on the Website but will be added onto the price
              in the shopping basket depending on the Buyers' choice of delivery method and time.</li>
            <li>Any change of factors having an impact on the prices of MiniBrew, including but not limited to rates of third
              parties, currency exchange rates, insurance rates, import and export duties and any other charges payable upon
              importation or exportation, freight charges and other charges, levies or taxes, may be charged on to Buyer by
              MiniBrew.</li>
            <li>Service fees; Buyer agrees to the fees and charges, terms of sale, payment and billing policies applicable to
              your use of the Services as stated in these Terms. MiniBrew may add new services for additional fees and
              charges, or amend fees and charges for existing Services, at any time in its sole discretion. All fees and
              charges for your use of the Service are non-refundable, except as may be expressly stated in Terms. If at any
              time fees for an existing Service are increased, Buyer is of course free to cease the use of the Service.
              Continued use of the Service will be treated as your agreement to the new terms.</li>
          </ol>
        </span>
      </p>







      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 10 - Payment</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>Buyers may be asked to supply certain information relevant to your purchase including, without limitation,
              Buyers credit card number, the expiration date of the credit card, billing address, and - if applicable -
              shipping information.</li>
            <li>Buyer must pay the purchase price and delivery costs in the order process by means of a credit card, PayPal, or
              IDEAL. Your account will be debited at the time of MiniBrew’s email confirmation of acceptance of your order.
              MiniBrew will ship the Products after receipt of the payment; without payment the order cannot be processed.
            </li>
            <li>Buyers represent and warrant that: (i) Buyer has the legal right to use any credit card(s) or other payment
              method(s) in connection with any Purchase; and that (ii) the information Buyer supplies to us is true, correct
              and complete. By submitting such information, Buyer grants MiniBrew the right to provide the information to
              third parties for purposes of facilitating the completion of Purchases.</li>
            <li>MiniBrew reserves the right to refuse any orders which are paid for by international credit cards and to refuse
              service to any account or individual that MiniBrew believes may be utilizing fraudulent credit card details.
            </li>
            <li>The charge back for a refund on a purchase, depends on the payment method. MiniBrew shall not be responsible or
              liable, directly or indirectly, for any damage or loss caused by the missing funds.</li>
            <li>Buyers represent and warrant that they are of legal age in their jurisdiction to form a binding contract (or if
              not, that they are over the age of 18 and they received a parent’s or guardian’s permission to use the Services
              and gotten their parent or guardian to agree to these Terms on their behalf).</li>
          </ol>
        </span>
      </p>









      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 11 - Subscriptions</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>MiniBrew Services are billed on a subscription basis ("Subscription(s)"). Users will be billed in advance on a
              recurring and periodic basis ("Billing Cycle"). Billing Cycles are set on a monthly, annual or biannual basis.
            </li>
            <li>At the end of each Billing Cycle, Users Subscription will automatically renew under the exact same conditions
              unless User cancels it or MiniBrew cancels it. Users may cancel their Subscription by contacting MiniBrew
              customer support upon 30 (thirty) days’ written notice.</li>
            <li>A valid payment method, including credit card, debit card, transfer, iDEAL or PayPal, is required to process the
              payment for Users Subscription. Users must provide MiniBrew with accurate and complete billing information
              including full name, address, state, post code, telephone number, and valid payment method information in order
              to enable processing of your Subscription Order.</li>
            <li>By submitting your payment information, User authorizes MiniBrew to charge all Subscription fees incurred
              through your account to such payment instruments.</li>
          </ol>
        </span>
      </p>







      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 12 - Limitation of liability</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>To the fullest extent permitted by law MiniBrew hereby disclaims any and all liability for any consequential,
              special or indirect damage, loss of profits, business opportunity or goodwill or a claim of a third party even
              if MiniBrew has been advised of the possibility of such damage, loss or claim. Nothing in these shall attempt to
              exclude liability that cannot be excluded under applicable law.</li>
            <li>Any liability on the part of MiniBrew, on any account whatsoever, whether contractual or non-contractual, shall
              be limited for each event or series of related events to a maximum of 25% of the amount (exclusive of VAT) which
              has been paid for the Product by the Buyer except where such limitation is not permissible under applicable law.
            </li>
            <li>Not excluded or restricted is MiniBrew's liability for damage resulting from intent or wilful recklessness on
              the part of MiniBrew or damage to person or property resulting directly from use of the Product.</li>
            <li>These terms and conditions set out the full extent of MiniBrew’s obligations and liabilities in respect of the
              supply of Products hereunder. Save as set forth herein, there are no other warranties, conditions or other terms
              that are binding on MiniBrew regarding the supply of Products hereunder.</li>
          </ol>
        </span>
      </p>







      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 13 - Data</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>1.Minibrew will process and control your personal data hereunder in accordance with her<span> </span><a
              href="https://minibrew.io/pages/privacy-statement">Privacy Statement</a><span> </span>which forms an integral part
              of this Contract.</li>
            <li>Your transaction records are accessible to you if you are registered and have generated an account.</li>
            <li>MiniBrew’s records relating to your transaction shall be deemed correct and conclusive unless proven otherwise.
            </li>
            <li>By submitting your order or registering for an Account, you agree and understand that MiniBrew may store,
              process and use the data that conforms to the Privacy Statement. MiniBrew works with other companies that help
              her provide the Products to you, such as freight carriers and credit card processing companies, and MiniBrew may
              share certain information with these companies for the purposes of fulfilling its obligations to you.</li>
          </ol>
        </span>
      </p>








      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 14 - Returns procedure</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>Due to the size and weight of the Product, MiniBrew bears the cost of delivery charges to Minibrew Repair center
              from any of the countries covered within the European Union and with the exception of the following countries
              such as Cyprus, Iceland, Norway, Switzerland, and the United Kingdom in the event a User returns the Product
              based on non-conformity.</li>
            <li>If a Buyer is located outside the countries covered within the European Union and wishes to return a Product,
              You will be responsible for paying all associated packaging and return shipping costs, which are dependent on
              your location.</li>
            <li>Buyer will be asked to motivate the reason for his return. Buyer will pack the Product including any and all
              accessories and documentation in the original boxes. If you don't have the packaging, Buyer can either repack
              the Product or MiniBrew will send you a new packaging for an additional fee of EUR 25.</li>
            <li>In case Buyer doesn’t have a POM-service, Buyer is responsible for the delivery cost when MiniBrew has repaired
              Buyers Beer Brewing Machine.</li>
            <li>To initiate a return please send an email to hello@minibrew.io. Nothing in this Return Policy will reduce or
              otherwise affect your statutory rights in relation to the Minibrew.</li>
            <li>In the event your Product does not work from the start, please contact Minibrew at<span> </span><a
              href="mailto:hello@minibrew.io">hello@minibrew.io</a><span> </span>instead of initiating the returns
              procedure.</li>
          </ol>
        </span>
      </p>









      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 15 - Force Majeure</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <p>MiniBrew will not be liable or responsible for any failure to perform, or delay in performance of, any obligations
            under a contract that is caused by an act or event beyond MiniBrew’s reasonable control, including without
            limitation acts of God, strikes, lock-outs or other industrial action by third parties, civil commotion, riot,
            terrorist attack, war, fire, explosion, storm, flood, earthquake, epidemic or other natural disaster, failure of
            public or private telecommunications networks or impossibility of the use of railways, shipping, aircraft, motor
            transport or other means of public or private transport.</p>
        </span>
      </p>








      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 16 - Account Use</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>You are responsible for maintaining the confidentiality of your account and password and for restricting access
              to your computer or mobile device, and You agree to accept responsibility for all activities that occur under
              your account or password.</li>
            <li>You agree not to disclose your password to any third party. You must notify MiniBrew immediately upon becoming
              aware of any breach of security or unauthorized use of your account.</li>
            <li>Buyer may not use as a username the name of another person or entity or that is not lawfully available for use,
              a name or trademark that is subject to any rights of another person or entity other than you without appropriate
              authorization, or a name that is otherwise offensive, vulgar or obscene.</li>
            <li>MiniBrew reserves the right to refuse service, terminate accounts, remove or edit content, or cancel orders in
              its sole discretion.</li>
          </ol>
        </span>
      </p>





      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <h2 className="c2">
        <span className="c1">Article 17 - Applicable law and competent court</span>
      </h2>
      <p className="c2">
        <span className="c0">
          <ol>
            <li>Any and all Contracts between MiniBrew and the Buyer shall be exclusively subject to Dutch law. The Parties
              explicitly exclude the applicability of the Vienna Convention on the International Sale of Goods.</li>
            <li>MiniBrew will try to resolve any disagreement you may have as quickly and efficiently as possible if you contact
              us by email at hello@minibrew.io.</li>
            <li>Your purchase of the Products and these Terms shall be governed by the laws of The Netherlands. The
              applicability of the United Nations Convention on Contracts for the International Sale of Goods is explicitly
              excluded.</li>
            <li>Failure to enforce any of the terms of this Contract shall not constitute a waiver of such term(s). The
              invalidity or the unenforceability of any provision of this Contract shall not adversely affect the
              enforceability or validity of the remaining provisions.</li>
            <li>Notwithstanding the foregoing, MiniBrew may seek injunctive or other equitable relief to protect its
              confidential information and intellectual property rights or to prevent loss of data or damage to its servers in
              any court of competent jurisdiction.</li>
            <li>Any dispute related to or arising out of a Contract between MiniBrew and the Buyer shall be exclusively brought
              before the competent court in Utrecht, the Netherlands.</li>
          </ol>
        </span>
      </p>







      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <p className="c2">
        <span className="c1">Annex 1 - Sample Form for Withdrawal</span>
      </p>
      <p className="c2">
        <span className="c0">
          <p>(complete and return this form only if you wish to cancel the contract)</p>
          <p>To: MiniBrew B.V.</p>
          <p>Hello@minibrew.io</p>
          <p>I/We* herewith inform you that I/we* wish to cancel our contract for</p>
          <p>the sale of the following products: [description of product]*</p>
          <p>Ordered on* [date on which order was placed]</p>
          <p>Received on* [date on which products were received]</p>
          <p>Order number [order# as stated in order confirmation]</p>
          <p>[Name of Consumer-Buyer]</p>
          <p>[Address of Consumer-Buyer]</p>
          <p>[Bank account number]</p>
          <p>[SWIFT Code]</p>
          <p>* Delete where not applicable or fill in where appropriate.</p>
        </span>
      </p>



      <p className="c2">
        <span className="c0">&nbsp;</span>
      </p>
      <p className="c2">
        <span className="c1">Previous versions:</span>
      </p>
      <p className="c2">
        <span className="c0">
          <ul>
            <li><a href="/pages/terms-and-conditions-2019">2019</a></li>
          </ul>
        </span>
      </p>


    </div>
  );
};

export default TcContent;

import React from "react";
import RootCloseWrapper from "react-overlays/lib/RootCloseWrapper";
import { bool, func, string } from "prop-types";
import { MenuItem } from "react-bootstrap";

import LinkedMenuItem from "./LinkedMenuItem";
import { routes } from "@common/constants/urls";

class ProfileDropdownMenu extends React.Component {
  triggerRootCloseEvent = event => {
    this.props.onClose(event, { source: "rootClose" });
  };

  render() {
    const items = [
      { label: "Settings", route: routes.profile },
      { label: "My ingredients", route: routes.myIngredients }
    ];

    return (
      <RootCloseWrapper
        event="click"
        disabled={!this.props.open}
        onRootClose={this.triggerRootCloseEvent}
      >
        <div
          className="dropdown-menu header__account__menu"
          onClick={this.triggerRootCloseEvent}
        >
          {items.map(({ label, route }, index) => (
            <LinkedMenuItem
              key={label}
              route={route}
              eventKey={(index + 1).toString()}
            >
              {label}
            </LinkedMenuItem>
          ))}
          <MenuItem divider />
          <MenuItem eventKey={6} onClick={this.props.handleSignOut}>
            Sign out
          </MenuItem>
        </div>
      </RootCloseWrapper>
    );
  }
}

ProfileDropdownMenu.propTypes = {
  handleSignOut: func,
  onClose: func,
  open: bool,
  // We need to receive the pathName prop to update the active section
  // of the dropdown although we are not using it
  pathName: string
};

export default ProfileDropdownMenu;

import React from "react";
import PropTypes from "prop-types";

import BreadCrumb from "../BreadCrumb/BreadCrumb";
import "./titlebar.scss";
import { Button } from "react-bootstrap";

/**
 * <TitleBar title={"RECIPES"} subtitle={"Overview"}>
 *  <Button bsStyle="primary"> NEW RECIPE </Button>
 * </TitleBar>
 * @param {string} props.title
 * @param {string} props.subtitle
 * @param {Array} props.children
 */
const TitleBar = props => {
  return (
    <div className="titlebar">
      <div className="row" style={{ width: "100%" }}>
        <div className="titlebar__breadcrumb">
          <BreadCrumb />
        </div>
        <div className="col-xs-12">
          {props.displayGoBack && (
            <Button
              style={{
                border: "1px solid #fdcc00",
                background: "transparent",
                color: "#fdcc00",
                borderRadius: "5px",
                display: "flex",
                whiteSpace: "pre-wrap",
                alignSelf: "center",
                marginTop: "35px"
              }}
              onClick={props.goBack}
            >
              <div className="ion-ios-arrow-back" />
              {"  "}
              Go back
            </Button>
          )}
          <h1 style={{ width: "100%" }}> {props.title} </h1>
          <h4> {props.subtitle} </h4>
        </div>
      </div>

      {props.children}
    </div>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default TitleBar;

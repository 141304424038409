import React from "react";
import { Field, reduxForm } from "redux-form";
import { Form, Row, Col, ControlLabel } from "react-bootstrap";
import CustomField from "../CreateRecipe/CustomField/CustomField";
import { required, number, numberBetween } from "@util/formValidationFunctions";
import { normalizeNumber } from "@util/recipeHelper";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";

const normalizeAttenuation = normalizeNumber(3, 2);
const validateBetweenAttenuation = numberBetween(0, 100);

class CustomYeastForm extends React.Component {
  componentDidMount() {
    const { ingredient } = this.props;
    if (
      ingredient !== null &&
      ingredient !== undefined &&
      ingredient.type == "Yeast"
    ) {
      this.props.initialize(ingredient);
    }
  }
  render() {
    const { handleSubmit, isSaving, valid } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={10} mdOffset={1}>
            <Field
              name="name"
              component={CustomField}
              label="Name"
              mdSize={12}
              smSize={12}
              validate={[required]}
            />
            <Col sm={12} md={12}>
              <ControlLabel>Type</ControlLabel>
              <Field
                name="yeast_form"
                label="Form"
                component="select"
                className="form-control"
              >
                <option value="" defaultValue disabled hidden>
                  Choose here
                </option>
                <option value="ALE">Liquid</option>
                <option value="LAGER">Dry</option>
              </Field>
            </Col>
            <Field
              name="attenuation"
              component={CustomField}
              type="number"
              label="Attenuation"
              mdSize={12}
              smSize={12}
              validate={[required, number, validateBetweenAttenuation]}
              normalize={normalizeAttenuation}
            />
            <Col sm={12} md={12}>
              <ControlLabel>Laboratory</ControlLabel>
              <Field
                name="laboratory"
                label="Form"
                component="select"
                className="form-control"
              >
                <option value="" defaultValue disabled hidden>
                  Choose one
                </option>
                {this.props.laboratories.map(lab => (
                  <option key={lab.id} value={lab.id}>
                    {lab.name}
                  </option>
                ))}
              </Field>
            </Col>
            <Field
              name="product_id"
              component={CustomField}
              label="Product id"
              mdSize={12}
              smSize={12}
              validate={[required]}
            />
            <Field
              name="notes"
              component={CustomField}
              label="Notes"
              componentClass="textarea"
              mdSize={12}
              smSize={12}
            />
            <ButtonWithLoad
              type="submit"
              bsStyle="primary"
              style={{
                float: "right",
                marginTop: "20px",
                marginBottom: "10px"
              }}
              isLoading={isSaving}
              disabled={!valid}
            >
              Save yeast
            </ButtonWithLoad>
            <div className="clearfix" />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({ form: "customYeast" })(CustomYeastForm);

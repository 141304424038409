import React from "react";
import { Row, Col } from "react-bootstrap";

const customWrapper = props => {
  return (
    <Row>
      <Col sm={4} smOffset={4}>
        {props.children}
      </Col>
    </Row>
  );
};

export default customWrapper;

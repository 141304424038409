import React, { useEffect, useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";

const popOver = content => (
  <Popover id="popover-trigger-hover-focus">{content}</Popover>
);
const Trigger = ({ children, content }) => (
  <OverlayTrigger
    trigger={["hover", "focus", "click"]}
    placement="bottom"
    overlay={popOver(content)}
  >
    {children}
  </OverlayTrigger>
);

const StyleBar = ({ min, max, styleMin, styleMax, actual }) => {
  const [actualPerc, setActualPerc] = useState(null);
  const [minPerc, setMinPerc] = useState(null);
  const [maxPerc, setMaxPerc] = useState(null);

  useEffect(() => {
    let actualPer = ((actual - min) / (max - min)) * 96;
    if (+actualPer > 100) {
      actualPer = 96;
    } else if (+actualPer < 0) {
      actualPer = 0;
    }
    setActualPerc(actualPer);

    const minPer = ((styleMin - min) / (max - min)) * 96;
    setMinPerc(minPer);

    const maxPer = ((styleMax - min) / (max - min)) * 96;
    setMaxPerc(96 - maxPer);
  }, [min, max, styleMin, styleMax, actual]);

  const helloStyle = {
    backgroundColor:
      actual >= +styleMin && actual <= +styleMax ? "green" : "red",
    height: "10px",
    width: "10px",
    left: actualPerc + "%",
    borderRadius: "5px",
    position: "absolute"
  };

  const minMaxStyle = {
    backgroundColor: "yellowgreen",
    height: "10px",
    left: minPerc + "%",
    right: maxPerc + "%",
    borderRadius: "5px",
    position: "absolute"
  };

  return (
    <div>
      {styleMin && styleMax ? (
        <Trigger content={`Min: ${styleMin} - Max: ${styleMax}`}>
          <div
            style={{
              backgroundColor: "gainsboro",
              height: "10px",
              width: "100%",
              borderRadius: "5px",
              position: "relative",
              marginBottom: "10px"
            }}
          >
            <div style={minMaxStyle} />
            <div style={helloStyle} />
          </div>
        </Trigger>
      ) : (
        <span style={{ fontFamily: "gotham-book", color: "black" }}>
          no guidelines
        </span>
      )}
    </div>
  );
};

export default StyleBar;

import React from "react";
import RootCloseWrapper from "react-overlays/lib/RootCloseWrapper";
import { Dropdown, MenuItem } from "react-bootstrap";

class UserActionToggle extends React.Component {
  state = {};

  handleClick = e => {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    return <div onClick={this.handleClick}>{this.props.children}</div>;
  }
}

class UserActionMenu extends React.Component {
  triggerRootCloseEvent = event => {
    this.props.onClose(event, { source: "rootClose" });
  };
  render() {
    return (
      <RootCloseWrapper
        event="click"
        disabled={!this.props.open}
        onRootClose={this.triggerRootCloseEvent}
      >
        <div
          className="dropdown-menu header__account__menu"
          style={{ left: "-180px", width: "220px" }}
        >
          {this.props.prevUAPresent && (
            <MenuItem eventKey="1" onClick={this.props.showPrev}>
              View Previous instruction
            </MenuItem>
          )}
          {this.props.nextUAPresent && (
            <MenuItem eventKey="2" onClick={this.props.showNext}>
              View Next instruction
            </MenuItem>
          )}
        </div>
      </RootCloseWrapper>
    );
  }
}

const UserActionDropdown = props => (
  <Dropdown
    id="useraction-dropdown"
    style={{ marginBottom: "5px" }}
    disabled={!props.enabled}
    className={props.enabled ? "" : "button-disabled"}
  >
    <UserActionToggle bsRole="toggle">
      <button
        className={"beertable__button " + (!props.enabled && "button-disabled")}
      >
        <span
          className={"ion-information-circled"}
          style={{ fontSize: "18px" }}
        />
      </button>
    </UserActionToggle>
    <UserActionMenu bsRole="menu" {...props} />
  </Dropdown>
);
export default UserActionDropdown;

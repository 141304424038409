import { logError } from "../util/exceptionHandler";

export const generateErrorString = config => {
  const errorTypes = {
    undefined: () => " There has been an error.", // Default
    string: () => config, // config is a string in this case
    object: () => logError(config) // @todo: Handle object error accordingly
  };

  return errorTypes[typeof config]();
};

import React, { Component } from "react";
import { connect } from "react-redux";
import mqttService from "@monitor/mqttService";
import NOTIFICATION_TYPES from "@common/constants/notificationTypes";
import Push from "push.js";
import * as userActions from "@profile/actions/userActions";
import { store } from "../../../store";
const MBIcon = require("../../../assets/images/MB_logo.png");

export const requestPermission = () => {
  Notification.requestPermission(function(permission) {
    if (permission === "granted") {
      store.dispatch(userActions.setNotificationPermission(true));
      Push.create("Minibrew Brew Portal", {
        body: "Notifications are now enabled!",
        icon: MBIcon,
        timeout: 5000,
        onClick: function() {
          window.focus();
          this.close();
        }
      });
    }
  });
};

class GlobalNotificationDisplayer extends Component {
  state = {
    lastNotification: []
  };
  iOS = () => {
    return (
      ["iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };
  componentDidMount() {
    if (!this.iOS()) {
      if (Notification.permission === "granted") {
        store.dispatch(userActions.setNotificationPermission(true));
      }
      this.sub = mqttService.notification$.subscribe(notification => {
        if (notification.type === NOTIFICATION_TYPES.NOT_USER_ACTION_REQUIRED) {
          if (
            !this.state.lastNotification.includes(notification) &&
            this.props.userInfo.notifications === true
          ) {
            if (this.state.lastNotification.length >= 3) {
              this.setState({ lastNotification: [] });
            }
            this.setState(prevState => ({
              lastNotification: [...prevState.lastNotification, notification]
            }));
            this.notifyUser(notification);
          }
        }
      });
    }
  }

  notifyUser = notification => {
    if (notification.message) {
      Push.create("Minibrew Brewery Portal", {
        requireInteraction: true,
        body: notification.message,
        icon: MBIcon,

        onClick: function() {
          window.focus();
          this.close();
        }
      });
    }
  };

  render() {
    return <div />;
  }
}
const mapStateToProps = ({ userInfo }) => {
  return { userInfo };
};
function mapDispatchToProps(dispatch) {
  return {
    setNotificationPermission: permission =>
      dispatch(userActions.setNotificationPermission(permission))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalNotificationDisplayer);

import React from "react";

import './not-found-page.scss';

const NotFoundPage = () => {
  return (
    <div className="notfound__container">
      <h1>404 NOT FOUND</h1>
    </div>
  );
};

export default NotFoundPage;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { timeout, catchError } from "rxjs/operators";
import useToggle from "../../../hooks/useToggle";
import PHASE_STATUS from "@common/constants/phaseStatus";
import {
  SESSION_STATES,
  SESSION_TYPES
} from "@common/constants/sessionConstants";
import PROCESS_TYPES from "@common/constants/processTypes";
import Panel from "../Panel/Panel";
import PanelHeader from "../PanelHeader/PanelHeader";
import ChartManager from "../ChartManager/ChartManager";
import mqttService from "../../../mqttService";
import ControlSession from "../ControlSession/ControlSession";
import BrewingOptions from "../ChartManager/Options/brewingOptions";
import FermentingOptions from "../ChartManager/Options/fermentationOptions";
import ServingOptions from "../ChartManager/Options/servingOptions";
import CleaningOptions from "../ChartManager/Options/cleaningOptions";
import LoadingSession from "../LoadingSession/LoadingSession";

/**
 * Manages the set up of the MQTT communication regarding frequent logging
 * and takes care of the panel state (which panel should open automatically)
 */
const PanelManager = ({ session, mqtt }) => {
  const [brewingOpen, setBrewingOpen, toggleBrewingOpen] = useToggle();
  const [fermentingOpen, setfermentingOpen, toggleFermentingOpen] = useToggle();
  const [servingOpen, setservingOpen, toggleServingOpen] = useToggle();
  const [activeProcess, setActiveProcess] = useState(null);
  const [lastLog, setLastLog] = useState(null);
  const [waitingForLog, setWaitingForLog] = useState(true);

  useEffect(() => {
    let mqttSubscriptions = [];
    //if (session !== null && activeProcess !== session.device.process_type) {
    if (session !== null) {
      const session_status = session.status;
      const process_type = session.device.process_type;
      updatePanelState(process_type);
      if (session_status == SESSION_STATES.STATUS_ACTIVE) {
        mqttSubscriptions = setUpMqttSubscriptions();
      } else if (session_status >= SESSION_STATES.STATUS_FINISHED) {
        setBrewingOpen(false);
        setfermentingOpen(false);
        setservingOpen(false);
        setWaitingForLog(false);
        setActiveProcess(PROCESS_TYPES.PROC_IDLE);
      }
    }
    return () => {
      if (mqttService.isConnected() && session) {
        const { uuid } = session.device;
        stopLogs(uuid, mqtt.clientUUID);
        mqttService.unsubscribeFrequentLog(uuid);
        mqttService.unsubscribeFrequentLogResp(uuid);
        mqttSubscriptions.forEach(subscription => subscription.unsubscribe());
      }
    };
  }, [session]);

  useEffect(() => {
    let subscription;
    if (session) {
      subscription = mqttService.frequentLog$.subscribe(frequentlog => {
        if (frequentlog.session === 0 || frequentlog.session === session.id) {
          setLastLog(frequentlog.logsList[0]);
        }
      });
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [session]);

  const updatePanelState = process_type => {
    if (process_type == PROCESS_TYPES.PROC_BREWING) {
      setBrewingOpen(true);
      setfermentingOpen(false);
      setservingOpen(false);
      setActiveProcess(PROCESS_TYPES.PROC_BREWING);
    } else if (process_type == PROCESS_TYPES.PROC_FERMENTATION) {
      setBrewingOpen(false);
      setfermentingOpen(true);
      setservingOpen(false);
      setActiveProcess(PROCESS_TYPES.PROC_FERMENTATION);
    } else if (process_type == PROCESS_TYPES.PROC_SERVING) {
      setBrewingOpen(false);
      setfermentingOpen(false);
      setservingOpen(true);
      setActiveProcess(PROCESS_TYPES.PROC_SERVING);
    } else if (process_type == PROCESS_TYPES.PROC_CLEAN_MINIBREW) {
      setActiveProcess(PROCESS_TYPES.PROC_CLEAN_MINIBREW);
    } else if (process_type == PROCESS_TYPES.PROC_ACID_CLEAN_MINIBREW) {
      setActiveProcess(PROCESS_TYPES.PROC_ACID_CLEAN_MINIBREW);
    }
  };
  const setUpMqttSubscriptions = () => {
    const { uuid } = session.device;

    let frequentlogWithTimeout$ = mqttService.frequentLog$.pipe(
      timeout(5000),
      catchError(() => {
        if (mqttService.isConnected()) {
          mqttService.subscribeFrequentLog(uuid);
          mqttService.subscribeFrequentLogResp(uuid);
          mqttService.publishRequestFrequentLogs(uuid, mqtt.clientUUID);
        }
        return frequentlogWithTimeout$;
      })
    );

    const frequentLogSubscription = frequentlogWithTimeout$.subscribe(fl => {
      const process_type = fl.logsList[0].currentStatus.procType;
      if (fl.logsList[0].currentStatus.procType !== PROCESS_TYPES.PROC_IDLE) {
        updatePanelState(process_type);
        setWaitingForLog(false);
        frequentLogSubscription.unsubscribe();
      }
    });

    return [frequentLogSubscription];
  };

  const stopLogs = () => {
    mqttService.publishStopFrequentLogs(session.device.uuid, mqtt.clientUUID);
  };

  const brewingActive = activeProcess == PROCESS_TYPES.PROC_BREWING;
  const fermentingActive = activeProcess == PROCESS_TYPES.PROC_FERMENTATION;
  const servingActive = activeProcess == PROCESS_TYPES.PROC_SERVING;
  const cleaningActive = activeProcess == PROCESS_TYPES.PROC_CLEAN_MINIBREW;
  const acidCleaningActive =
    activeProcess == PROCESS_TYPES.PROC_ACID_CLEAN_MINIBREW;

  const getTimeRemaining = () => {
    return lastLog ? lastLog.processTimeRemaining : null;
  };
  const sessionCleaning =
    session &&
    session.session_type === SESSION_TYPES.SESSION_TYPE_CLEAN_MINIBREW;
  const sessionAcidCleaning =
    session &&
    session.session_type === SESSION_TYPES.SESSION_ACID_CLEAN_MINIBREW;
  const sessionDone = session && session.status > SESSION_STATES.STATUS_ACTIVE;

  return (
    <div>
      {session && waitingForLog && <LoadingSession />}
      {!waitingForLog && (
        <div>
          {session && !sessionCleaning && !sessionAcidCleaning && (
            <div>
              <div className="control-session">
                {activeProcess !== 0 && (
                  <ControlSession currentLog={lastLog} session={session} />
                )}
              </div>
              <Panel
                header={
                  <PanelHeader
                    number={1}
                    stageName="Brewing"
                    deviceName={session.device.custom_name}
                    timeRemaining={brewingActive && getTimeRemaining()}
                    active={brewingActive}
                    done={activeProcess > PROCESS_TYPES.PROC_BREWING}
                    sessionDone={sessionDone}
                  />
                }
                handleToggle={toggleBrewingOpen}
                open={brewingOpen}
                active={brewingActive}
                disabled={activeProcess < PROCESS_TYPES.BREWING}
                sessionDone={sessionDone}
              >
                <ChartManager
                  active={brewingActive}
                  session={session}
                  options={BrewingOptions()}
                  type={PHASE_STATUS.BREWING}
                  open={brewingOpen}
                  mqtt={mqtt}
                />
              </Panel>
              <Panel
                header={
                  <PanelHeader
                    number={2}
                    stageName="Fermenting"
                    deviceName={session.device.custom_name}
                    timeRemaining={fermentingActive && getTimeRemaining()}
                    active={fermentingActive}
                    done={activeProcess > PROCESS_TYPES.PROC_FERMENTATION}
                    sessionDone={sessionDone}
                  />
                }
                handleToggle={toggleFermentingOpen}
                open={fermentingOpen}
                active={fermentingActive}
                disabled={activeProcess < PROCESS_TYPES.PROC_FERMENTATION}
                sessionDone={sessionDone}
              >
                <ChartManager
                  active={fermentingActive}
                  session={session}
                  options={FermentingOptions()}
                  type={PHASE_STATUS.FERMENTATION}
                  open={fermentingOpen}
                  mqtt={mqtt}
                />
              </Panel>
              <Panel
                header={
                  <PanelHeader
                    number={3}
                    stageName="Serving"
                    deviceName={session.device.custom_name}
                    timeRemaining={servingActive && getTimeRemaining()}
                    active={servingActive}
                    sessionDone={sessionDone}
                  />
                }
                handleToggle={toggleServingOpen}
                open={servingOpen}
                active={servingActive}
                disabled={activeProcess < PROCESS_TYPES.PROC_SERVING}
                sessionDone={sessionDone}
              >
                <ChartManager
                  active={servingActive}
                  session={session}
                  options={ServingOptions()}
                  type={PHASE_STATUS.SERVING}
                  open={servingOpen}
                  mqtt={mqtt}
                />
              </Panel>
            </div>
          )}
          {sessionAcidCleaning && (
            <div>
              <div className="control-session">
                {activeProcess !== 0 && (
                  <ControlSession currentLog={lastLog} session={session} />
                )}
              </div>
              <Panel
                header={
                  <PanelHeader
                    number={1}
                    stageName="Acid Cleaning"
                    deviceName={session.device.custom_name}
                    timeRemaining={getTimeRemaining()}
                    active={acidCleaningActive}
                  />
                }
                handleToggle={() => {}}
                open={true}
                active={acidCleaningActive}
              >
                <ChartManager
                  active={acidCleaningActive}
                  session={session}
                  options={CleaningOptions()}
                  type={PHASE_STATUS.CLEANING}
                  open={true}
                  mqtt={mqtt}
                />
              </Panel>
            </div>
          )}
          {sessionCleaning && (
            <div>
              <div className="control-session">
                {activeProcess !== 0 && (
                  <ControlSession currentLog={lastLog} session={session} />
                )}
              </div>
              <Panel
                header={
                  <PanelHeader
                    number={1}
                    stageName="Cleaning"
                    deviceName={session.device.custom_name}
                    timeRemaining={getTimeRemaining()}
                    active={cleaningActive}
                  />
                }
                handleToggle={() => {}}
                open={true}
                active={cleaningActive}
              >
                <ChartManager
                  active={cleaningActive}
                  session={session}
                  options={CleaningOptions()}
                  type={PHASE_STATUS.CLEANING}
                  open={true}
                  mqtt={mqtt}
                />
              </Panel>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ mqtt }) => ({
  mqtt
});

export default connect(
  mapStateToProps,
  null
)(PanelManager);

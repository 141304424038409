/* eslint-disable */
import mqtt from "mqtt";
import { MQTT_ACTIONS } from "@common/constants/actionTypes";
import { notificationResponseTopic } from "@common/constants/mqttTopics";
import notification_pb from "../../util/protobuf/notification_pb";
import * as mqttTopics from "@common/constants/mqttTopics";
import * as mqttQos from "@common/constants/mqttQos";

let channel;

/**
 * Middleware to handle mqtt connection
 */
const middleware = store => next => action => {
  const { dispatch } = store;
  switch (action.type) {
    // User request to connect
    case MQTT_ACTIONS.MQTT_CONNECT:
      const clientID = store.getState().mqtt.clientUUID;
      const options = {
        username: action.credentials.username,
        password: action.credentials.password,
        clientId: clientID,
        reconnectPeriod: 5000,
        connectTimeout: 30 * 5000,
        will: {
          topic: mqttTopics.lastWillTopic + clientID,
          payload: "offline",
          qos: mqttQos.atMostOnce,
          retain: false
        }
      };

      channel = mqtt.connect(
        process.env.MQTT_URL,
        options
      );

      channel.on("connect", () =>
        dispatch({ type: MQTT_ACTIONS.MQTT_CONNECT_SUCCESS })
      );
      channel.on("close", () =>
        dispatch({ type: MQTT_ACTIONS.MQTT_DISCONNECT })
      );
      channel.on("error", error =>
        dispatch({
          type: MQTT_ACTIONS.MQTT_CONNECT_ERROR,
          message: "Error: " + error
        })
      );
      channel.on("message", (topic, message) =>
        dispatch({
          type: MQTT_ACTIONS.MQTT_RECEIVE,
          topic: topic,
          message: message
        })
      );
      break;

    // User request to subscribe to channel
    case MQTT_ACTIONS.MQTT_SUBSCRIBE:
      if (channel !== undefined) channel.subscribe(action.topic);
      break;

    case MQTT_ACTIONS.MQTT_UNSUBSCRIBE:
      channel.unsubscribe(action.topic);
      break;

    // A message is received
    case MQTT_ACTIONS.MQTT_RECEIVE:
      const isNotification =
        action.topic.indexOf("backend/notifications/") !== -1;

      // If the received message is a notification, send a response that we received the notification
      if (isNotification) {
        const userUUID = action.topic.substr(action.topic.lastIndexOf("/") + 1);

        // Deserialize notification
        const message = notification_pb.Notification.deserializeBinary(
          new Uint8Array(action.message)
        ).toObject();

        // Create notification response
        let notResponse = new notification_pb.NotificationResp();
        notResponse.setSeq(message.seq);
        const bytes = notResponse.serializeBinary();

        // Perhaps dispatch a new action instead of calling channel here
        channel.publish(notificationResponseTopic + userUUID, bytes);
      }
      break;

    // User request to disconnect
    case MQTT_ACTIONS.MQTT_DISCONNECT:
      // if (channel !== undefined) channel.end();
      break;

    // User request to publish a message
    case MQTT_ACTIONS.MQTT_PUBLISH:
      if (channel !== undefined) channel.publish(action.topic, action.message);
      break;

    // We don't really need the default but ... just in case
    default:
      break;
  }
  return next(action);
};

export default middleware;
/* eslint-enable */

import {FERMENTATION_STAGE_TYPES as STAGE_TYPES} from '@common/constants/recipeConstants';

export const getSteps = state =>
  state.form.recipe.values.fermenting
    .map((stage, index) => ({ ...stage, stageIndex: index }))
    .filter(stage => stage.fermentation_stage_type !== "COND")
    .reduce((acc, stage) => {
      const steps = [];
      for (let i = 0; i < stage.steps.length; i++) {
        const newStep = {
          ...stage.steps[i],
          stageType: stage.fermentation_stage_type,
          stageIndex: stage.stageIndex,
          index: i,
          stageLabel: STAGE_TYPES[stage.fermentation_stage_type]
        };
        steps.push(newStep);
      }
      return acc.concat(steps);
    }, []);

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./FileInput.scss";

class FileInput extends Component {
  state = {
    fileName: "Choose an image..."
  };
  render() {
    const { name, refProp, style, className } = this.props;
    return (
      <label className={`custom-file-input ${className}`} style={style}>
        <Button>{this.state.fileName}</Button>
        <input
          name={name}
          type="file"
          ref={refProp}
          onChange={e => {
            this.setState({
              fileName: e.target.files[0].name
            });
          }}
        />
      </label>
    );
  }
}

export default FileInput;

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { createBeer, createRecipe } from "@beers/services/recipeService";
import { push } from "react-router-redux";
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";

const CloneModal = ({
  show,
  closeCloneModal,
  recipe,
  beerstyleID,
  dispatch,
  reload
}) => {
  const [recipeCopy, setRecipeCopy] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRecipeCopy(recipe);
  }, [recipe]);

  const handleChange = e => {
    const updatedRecipe = JSON.parse(JSON.stringify(recipeCopy));
    updatedRecipe["beer_name"] = e.target.value;
    setRecipeCopy(updatedRecipe);
  };

  const getBeerInfo = () => ({
    name: recipeCopy["beer_name"],
    style: beerstyleID
  });

  const cloneRecipe = () => {
    setLoading(true);
    createBeer(getBeerInfo())
      .then(response => {
        const copy = JSON.parse(JSON.stringify(recipe));
        copy.beer_id = response.id;
        copy.shared = false;

        createRecipe(copy)
          .then(() => {
            dispatch(push(`/beers/${response.id}`));
            reload();
          })
          .then(closeCloneModal)
          .finally(() => setLoading(false));
      })
      .catch(() => setLoading(false));
  };

  if (!recipeCopy) return null;
  return (
    <Modal show={show} onHide={closeCloneModal}>
      <Modal.Header closeButton style={{ textAlign: "center" }}>
        <Modal.Title>Clone recipe into new beer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="font-book" style={{ marginBottom: 20 }}>
          This will create a new beer and add a clone of the currently selected
          recipe as the first version.
        </div>
        <ControlLabel>Beer name</ControlLabel>
        <FormGroup>
          <FormControl
            required
            value={recipeCopy["beer_name"]}
            onChange={handleChange}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="primary" onClick={cloneRecipe} disabled={loading}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  null,
  null
)(CloneModal);

import React from "react";
import { connect } from "react-redux";
import { MenuItem } from "react-bootstrap";
import RootCloseWrapper from "react-overlays/lib/RootCloseWrapper";
import PROCESS_TYPES from "@common/constants/processTypes";

class DeviceMenu extends React.Component {
  handleRootClose = event => {
    this.props.onClose(event, { source: "rootClose" });
  };

  showCleaningModal = () => {
    const { showCleaningModal, device } = this.props;
    showCleaningModal(device.uuid);
  };

  showUnregisterDeviceModal = () => {
    const { showUnregisterDeviceModal, device } = this.props;
    showUnregisterDeviceModal(device.uuid);
  };

  render() {
    const {
      showDeviceModal,
      device,
      showWifiModal,
      isDisconnected,
      showStopSessionModal
    } = this.props;
    const isStarting = device.process_type == 0 && device.session_id;
    const disableStopSession = device.session_id === null || !!isStarting;
    const isKeg = device.device_type === 1;
    const isIdle = device.process_type === PROCESS_TYPES.PROC_IDLE;

    return (
      <RootCloseWrapper
        event="click"
        disabled={!this.props.open}
        onRootClose={this.handleRootClose}
      >
        <div className="dropdown-menu header__account__menu">
          {!isKeg && (
            <MenuItem
              eventKey="1"
              onSelect={() => showDeviceModal(device.serial_number)}
              disabled={isKeg}
            >
              Rename
            </MenuItem>
          )}
          <MenuItem eventKey="2" onClick={showWifiModal}>
            Set up Wi-Fi
          </MenuItem>
          <MenuItem
            eventKey="3"
            onSelect={() => showStopSessionModal(device.session_id)}
            disabled={disableStopSession}
          >
            Stop
          </MenuItem>
          {!isKeg && (
            <React.Fragment>
              <MenuItem
                eventKey="4"
                onSelect={this.showCleaningModal}
                disabled={isDisconnected || isKeg || !isIdle}
              >
                Clean
              </MenuItem>
              <MenuItem eventKey="5" onSelect={this.showUnregisterDeviceModal}>
                Unregister
              </MenuItem>
            </React.Fragment>
          )}
          <MenuItem disabled={true}>
            <p className="firmware-label">
              Firmware: <br />
              {device.software_version}
            </p>
          </MenuItem>
        </div>
      </RootCloseWrapper>
    );
  }
}

function mapStateToProps({ mqtt }) {
  return { mqtt };
}

export default connect(
  mapStateToProps,
  undefined
)(DeviceMenu);

import React from "react";
import { Button } from "react-bootstrap";

class TypeAheadToggle extends React.Component {
  handleClick = e => {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    return (
      <Button
        style={{
          borderRadius: "4px",
          padding: "12px 15px",
          width: "140px",
          textAlign: "left",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          border: this.props.error && "1px solid red"
        }}
        onClick={this.handleClick}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default TypeAheadToggle;

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Collapse,
  Tabs,
  Tab,
  Alert,
  DropdownButton,
  MenuItem
} from "react-bootstrap";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { push } from "react-router-redux";
import Sticky from "react-stickynode";

import TitleBar from "@common/components/TitleBar/TitleBar";
import BeerProfileBar from "@common/components/BeerProfileBar/BeerProfileBar";
import BrewHistory from "@common/components/BrewHistory/BrewHistory";
import RecipeVersions from "./RecipeVersions/RecipeVersions";
import Mashing from "./RecipeDetails/RecipeDetailMashing";
import Boil from "./RecipeDetails/RecipeDetailBoil";
import Fermentation from "./RecipeDetails/RecipeDetailFerm";
import BrewWater from "../CreateRecipe/Recipe/BrewWater/BrewWater";
import BrewModal from "@common/components/BrewModal/BrewModal";
import NoRecipesFound from "./NoRecipesFound/NoRecipesFound";
import Divider from "@common/components/Divider/Divider";
import RecipeDetailLoading from "./RecipeDetailLoading/RecipeDetailLoading";
import { ACCESS_TYPES } from "@common/constants/accessTypes";
import * as recipeService from "@beers/services/recipeService";
import * as sessionService from "@brewery/services/sessionService";
import * as deviceService from "@common/services/deviceService";
import "./recipe-detail.scss";
import NotesModal from "./NotesModal";
import CloneModal from "./CloneModal";

class RecipeDetail extends React.Component {
  state = {
    beer: {},
    recipes: [],
    currentRecipe: {},
    sessions: [],
    beerLoaded: false,
    recipeLoaded: false,
    open: false,
    showModal: false,
    showNotesModal: false,
    showCloneModal: false,
    activeNoteKey: 1,
    devices: []
  };

  init = () => {
    this.props.dispatch(showLoading());
    recipeService.getBeerById(this.props.match.params.id).then(res => {
      this.setState({
        beer: res,
        beerLoaded: true
      });
      this.props.dispatch(hideLoading());
    });

    this.props.dispatch(showLoading());
    recipeService.getRecipes(this.props.match.params.id).then(res => {
      let sortedRecipes = [...res];
      sortedRecipes.sort((a, b) => a.id - b.id);
      const initialRecipe = sortedRecipes[sortedRecipes.length - 1];

      let totalMashInWater = 0;
      if (initialRecipe) {
        initialRecipe.mashing.forEach(step => {
          totalMashInWater += +step.mash_in_water;
        });
      }

      this.setState({
        recipes: sortedRecipes,
        currentRecipe: initialRecipe,
        recipeLoaded: true,
        mashInWater: totalMashInWater
      });
      // If version param is present in url, eg: '/recipes/68/version/1'
      // Set currentRecipe to that specific version
      const versionParam = this.props.match.params.version;
      let recipeVersion;
      if (versionParam) {
        recipeVersion = res.find(
          recipe => +recipe.version_name == versionParam
        );
        this.setState({ currentRecipe: recipeVersion });
      }

      // intialRecipe.id must be checked if version param is available
      // Incase it is, use versionParam recipe instead of initialRecipe
      this.props.dispatch(hideLoading());
      const currentRecipeID = recipeVersion
        ? recipeVersion.id
        : initialRecipe.id;
      if (res.length) {
        this.getSessionsForRecipe(currentRecipeID);
      }
    });
  };

  componentWillMount() {
    this.init();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.version != nextProps.match.params.version) {
      const versionParam = nextProps.match.params.version;
      let recipeVersion;
      if (versionParam) {
        recipeVersion = this.state.recipes.find(
          recipe => +recipe.version_name == versionParam
        );
        this.setState({ currentRecipe: recipeVersion });
      }
    }
  }

  handleNoteTabChange = activeNoteKey => this.setState({ activeNoteKey });

  getSessionsForRecipe = id => {
    () => this.setState({ open: !this.state.open });
    this.props.dispatch(showLoading());
    sessionService.getSessionsByRecipeId(id).then(res => {
      this.setState({
        sessions: res
      });
      this.props.dispatch(hideLoading());
    });
  };

  setRecipe = recipeID => {
    const recipe = this.state.recipes.find(rec => rec.id === recipeID);
    let totalMashInWater = 0;
    recipe.mashing.forEach(step => {
      totalMashInWater += +step.mash_in_water;
    });
    this.setState({ currentRecipe: recipe, mashInWater: totalMashInWater });
    this.getSessionsForRecipe(recipe.id);
    this.props.dispatch(
      push(`/beers/${recipe.beer_id}/version/${recipe.version_name}`)
    );
  };

  renderPage() {
    const { beerLoaded, recipeLoaded, recipes } = this.state;
    return beerLoaded && recipeLoaded && recipes.length > 0;
  }

  routeToEdit = () => {
    const route = `/beers/${this.state.beer.id}/edit/${
      this.state.currentRecipe.id
    }?mode=validate`;

    this.props.dispatch(push(route));
  };

  routeToNewVersion = () => {
    const route = `/beers/${this.state.beer.id}/newversion/${
      this.state.currentRecipe.id
    }?mode=validate`;
    this.props.dispatch(push(route));
  };

  handleCloseModal = () => {
    this.setState(prevState => ({
      showModal: !prevState
    }));
  };

  openStartBrewModal = () => {
    deviceService.getDevices().then(res => {
      this.setState({ devices: res });
    });
    this.setState({
      showModal: true
    });
  };

  alreadyBrewed = () => !!this.state.currentRecipe.times_brewed;
  shareRecipe = () => {
    recipeService
      .toggleShareRecipe(this.state.currentRecipe)
      .then(() => this.init());
  };

  toggleNotesModal = () => {
    console.log("here");
    this.setState(prevState => ({
      showNotesModal: !prevState.showNotesModal
    }));
  };

  getBeerInfo = () => ({
    name: this.state.currentRecipe.beer_name,
    style: this.state.beer.style.id
  });

  cloneRecipe = () => {
    recipeService.createBeer(this.getBeerInfo()).then(response => {
      const copy = JSON.parse(JSON.stringify(this.state.currentRecipe));
      copy.beer_id = response.id;
      copy.shared = false;
      recipeService.createRecipe(copy).then(() => {
        this.props.dispatch(push(`/beers/${response.id}`));
        this.init();
      });
    });
  };

  toggleCloneModal = () => {
    this.setState(prevState => ({ showCloneModal: !prevState.showCloneModal }));
  };

  render() {
    const header = (number, stageName) => (
      <h2>
        <span className="step">{number}</span>
        &nbsp;
        <span className="step-title"> {stageName} </span>
      </h2>
    );

    const { currentRecipe } = this.state;
    const { accessType } = this.props;
    const recipe = this.state.currentRecipe;
    const noteType =
      this.state.activeNoteKey == 1 ? "private_note" : "public_note";
    return (
      <div>
        {this.renderPage() && (
          <div>
            <TitleBar
              title={this.state.beer.name.toUpperCase()}
              subtitle={this.state.beer.style.name}
            >
              <Button
                bsStyle="warning"
                className="beer-profile-btn"
                onClick={() => this.setState({ open: !this.state.open })}
              >
                BEER PROFILE{" "}
                <span
                  className={
                    !this.state.open ? "ion-chevron-down" : "ion-chevron-up"
                  }
                />
              </Button>
            </TitleBar>
            <Collapse in={this.state.open}>
              <Sticky enabled={true} top={80} innerZ={100}>
                <BeerProfileBar beerProfile={recipe} />
              </Sticky>
            </Collapse>
            <div className="recipe-detail container-fluid">
              <div className="row recipe-detail__container">
                <div className="col-sm-3 col-md-3" style={{ padding: "0px" }}>
                  <RecipeVersions
                    selectedRecipe={recipe}
                    recipes={this.state.recipes}
                    handleClick={this.setRecipe}
                  />
                </div>
                <div className="col-xs-12 col-sm-9 col-md-9 recipe-detail__container__brewdata">
                  <div className="row">
                    <Tabs id="recipe-detail-tabs">
                      <Tab eventKey={1} title="RECIPE">
                        <div className="col-md-10 col-md-offset-1">
                          <div className="row">
                            <div className="col-xs-4 col-md-4">
                              <h1>VERSION {recipe.version_name}</h1>
                            </div>
                            <div
                              style={{ flex: 1 }}
                              className="recipe-detail__actions"
                            >
                              <Button
                                bsStyle="default"
                                bsSize="large"
                                onClick={this.shareRecipe}
                                disabled={!recipe.brewable && !recipe.shared}
                              >
                                {recipe.shared ? "UNSHARE" : "SHARE"}
                              </Button>
                              <Button
                                disabled={this.alreadyBrewed()}
                                title={
                                  (this.alreadyBrewed() &&
                                    "You can't edit a beer once it's been brewed") ||
                                  "Edit recipe"
                                }
                                onClick={this.routeToEdit}
                              >
                                EDIT
                              </Button>
                              <Button
                                bsStyle="default"
                                onClick={this.routeToNewVersion}
                              >
                                NEW VERSION
                              </Button>
                              <Button
                                bsStyle="primary"
                                onClick={this.openStartBrewModal}
                                disabled={
                                  !currentRecipe.brewable &&
                                  accessType !== ACCESS_TYPES.BP_RESEARCHER
                                }
                              >
                                <React.Fragment>BREW</React.Fragment>
                              </Button>
                              <DropdownButton
                                pullRight
                                title=""
                                id="more-options"
                              >
                                <MenuItem onClick={window.print}>
                                  Print
                                </MenuItem>
                                <MenuItem onClick={this.toggleCloneModal}>
                                  Clone into new beer
                                </MenuItem>
                              </DropdownButton>
                            </div>
                          </div>

                          <div style={{ marginBottom: "15px" }}>
                            <Divider />
                          </div>
                          {!recipe.brewable && (
                            <div>
                              {!this.alreadyBrewed() ? (
                                <Alert bsStyle="danger" className="text-red">
                                  Your recipe cannot be brewed because it does
                                  not meet the requirements of the MiniBrew
                                  system. You can see why by going to the Edit
                                  page for this beer.
                                </Alert>
                              ) : (
                                <Alert bsStyle="danger" className="text-red">
                                  Your recipe cannot be brewed because it no
                                  longer meets the requirements of the MiniBrew
                                  system. Create a new version to see what you
                                  need to change.
                                </Alert>
                              )}
                            </div>
                          )}
                          <div
                            style={{
                              marginBottom: "15px",
                              textAlign: "right",
                              marginRight: "6px"
                            }}
                          >
                            <div>
                              Serving beer at {recipe.serving_temperature}
                              °C
                            </div>
                          </div>
                          <div className="recipe-detail__brew-water">
                            <div
                              className="minibrew-container"
                              style={{ marginBottom: "20px" }}
                            >
                              <div>
                                <h2
                                  style={{
                                    fontWeight: "normal",
                                    fontFamily: "Gotham-Bold",
                                    margin: 0,
                                    display: "inline-block"
                                  }}
                                >
                                  Notes
                                </h2>
                                <i
                                  style={{ float: "right", cursor: "pointer" }}
                                  className="icon ion-edit"
                                  onClick={this.toggleNotesModal}
                                />
                              </div>
                              <Tabs
                                id="notes-tabs"
                                activeKey={this.state.activeNoteKey}
                                onSelect={this.handleNoteTabChange}
                              >
                                <Tab eventKey={1} title="PRIVATE">
                                  <div style={{ whiteSpace: "pre-line" }}>
                                    <p
                                      style={{
                                        fontWeight: "normal",
                                        fontFamily: "Gotham-Book"
                                      }}
                                    >
                                      {recipe.private_note}
                                    </p>
                                  </div>
                                </Tab>
                                <Tab eventKey={2} title="PUBLIC">
                                  <div style={{ whiteSpace: "pre-line" }}>
                                    <p
                                      style={{
                                        fontWeight: "normal",
                                        fontFamily: "Gotham-Book"
                                      }}
                                    >
                                      {recipe.public_note}
                                    </p>
                                  </div>
                                </Tab>
                              </Tabs>
                            </div>

                            <BrewWater
                              batchSize={6}
                              totalWater={recipe.water_amount}
                              preMixWater={this.state.mashInWater}
                              kettleWater={
                                recipe.water_amount - this.state.mashInWater
                              }
                            />
                          </div>
                          <Mashing
                            header={header(1, "Mash")}
                            currentRecipe={recipe}
                          />
                          <Boil
                            header={header(2, "Boil")}
                            user={this.props.userInfo.user}
                            currentRecipe={recipe}
                          />
                          <Fermentation
                            header={header(3, "Fermentation")}
                            currentRecipe={recipe}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey={2} title="BREW HISTORY">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-10 col-md-offset-1">
                              <BrewHistory sessions={this.state.sessions} />
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <BrewModal
              show={this.state.showModal}
              handleClose={this.handleCloseModal}
              selectedRecipe={recipe}
            />
            <NotesModal
              show={this.state.showNotesModal}
              recipe={recipe}
              type={noteType}
              closeNotesModal={this.toggleNotesModal}
              refetchRecipes={this.init}
            />
            <CloneModal
              show={this.state.showCloneModal}
              recipe={recipe}
              closeCloneModal={this.toggleCloneModal}
              beerstyleID={this.state.beer.style.id}
              reload={this.init}
            />
          </div>
        )}
        {!this.state.recipeLoaded && <RecipeDetailLoading />}
        {!this.props.loadingBar.default && !this.renderPage() && (
          <NoRecipesFound url={this.props.match.url} />
        )}
      </div>
    );
  }
}

function mapStateToProps({ auth, loadingBar, userInfo }) {
  return {
    auth,
    loadingBar,
    accessType: userInfo.user.access_type,
    userInfo
  };
}

RecipeDetail.propTypes = {
  match: PropTypes.object,
  auth: PropTypes.object,
  dispatch: PropTypes.func
};

export default connect(mapStateToProps)(RecipeDetail);

import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import ControlSessionModal from "./ControlSessionModal";
import processStates from "@common/constants/processStates";
import processTypes from "@common/constants/processTypes";
import "./control-session.scss";

class ControlSession extends React.Component {
  state = {
    showModal: false
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  render() {
    const { showModal } = this.state;
    const { currentLog } = this.props;
    const disableButton =
      currentLog &&
      currentLog.currentStatus.procType === processTypes.PROC_SERVING &&
      currentLog.currentStatus.procState !==
        processStates.SERVING_TEMP_CONTROL_STATE;
    const kegNeedsCleaning =
      currentLog &&
      currentLog.currentStatus.procState ===
        processStates.SERVING_TEMP_CONTROL_STATE;

    return (
      <div>
        <Button
          className="control-session__button"
          onClick={this.openModal}
          disabled={disableButton}
        >
          STOP
        </Button>
        <ControlSessionModal
          show={showModal}
          sessionID={this.props.session.id}
          handleClose={() => this.setState({ showModal: false })}
          kegNeedsCleaning={kegNeedsCleaning}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ mqtt }) => {
  return { mqtt };
};

export default connect(
  mapStateToProps,
  null
)(ControlSession);

import { useEffect } from "react";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import { withRouter } from "react-router-dom";

const RouteAnalytics = ({ children, history }) => {
  useEffect(() => {
    analyticsService.track(ANALYTICS_TYPES.SCREEN_LOAD, {
      Screen: history.location.pathname
    });
  }, [history.location.pathname]);
  return children;
};

export default withRouter(RouteAnalytics);

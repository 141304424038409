import { BEER_ACTIONS } from "@common/constants/actionTypes";
import * as recipeService from "@beers/services/recipeService";

const getBeers = () => {
  return { type: BEER_ACTIONS.GET_BEERS };
};

const getBeersSuccess = beers => {
  return { type: BEER_ACTIONS.GET_BEERS_SUCCESS, beers };
};

const getBeersError = message => {
  return { type: BEER_ACTIONS.GET_BEERS_ERROR, message };
};

export const fetchBeers = brewerID => {
  return dispatch => {
    dispatch(getBeers());
    recipeService
      .getBeers(brewerID)
      .then(response => {
        dispatch(getBeersSuccess(response));
      })
      .catch(() => {
        dispatch(getBeersError("Fetching beers failed"));
      });
  };
};

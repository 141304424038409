import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routes } from "@common/constants/urls";
import "./status-user-action.scss";

const StatusUserAction = ({ sessionId, dispatch }) => {
  return (
    <div
      onClick={() => dispatch(push(routes.monitoring + sessionId))}
      className="device__status--useraction"
    >
      <span className="ion-alert device__alert-icon" />
      <span className="device__alert-text">Needs your attention</span>
      <span className="ion-ios-arrow-right device__chevron" />
    </div>
  );
};

export default connect(undefined, undefined)(StatusUserAction);

export const apiEndpoints = {
  adjuncts: "v1/adjuncts/?limit=200",
  beers: "v1/beers/",
  sharedFavourites: "v1/shared_favourites/",
  beerStyles: "v1/beerstyles/",
  changePw: "v1/users/psw-change/",
  devices: "v1/devices/",
  fermentables: "v1/fermentables/?limit=200",
  hops: "v1/hops/?limit=200",
  me: "v1/users/me/",
  recipes: "v1/recipes/",
  sharedRecipes: "v1/shared_recipes/",
  sharedRecipesPagination: (limit, offset) =>
    `v1/shared_recipes/?limit=${limit}&offset=${offset}`,
  validateRecipe: "v2/check_recipes/",
  sessions: "v1/sessions/",
  styles: "v1/styles/",
  users: "v1/users/",
  yeasts: "v1/yeasts/?limit=200",
  createHop: "v1/hops/",
  createFermentable: "v1/fermentables/",
  createYeast: "v1/yeasts/",
  createAdjunct: "v1/adjuncts/",
  laboratories: "v1/laboratories/",
  activateCoupon: "v1/coupons/redeem/",
  token: "v2/token/",
  brewery: "v1/breweryoverview/",
  refreshToken: "v2/token/refresh/",
  recover: "v1/users/recover/",
  brewticket: "v1/brewticket/",
  subscription: "v1/mollie_subscriptions/",
  cancel: "v1/memberships/cancel/"
};

export const routes = {
  community: "/community/",
  communityRecipe: "/community/recipe/:id/",
  coupon: "/activate-coupon/",
  analytics: "/analytics/",
  auth: "/auth/",
  beers: "/beers/",
  brewery: "/brewery/",
  changePassword: "/profile/change-password/",
  createRecipe: "/create-recipe/",
  dashboard: "/dashboard/",
  editAccount: "/profile/account/edit/",
  newVersionRecipe: "/beers/:id/newversion/:recipeid/",
  editRecipe: "/beers/:id/edit/:recipeid/",
  editRecipeWithParams: (beerID, recipeID) =>
    `/beers/${beerID}/edit/${recipeID}/`,
  editRecipeWithValidate: (beerID, recipeID) =>
    `/beers/${beerID}/edit/${recipeID}/?mode=validate`,
  firstPasswordChange: "/first-password-change/",
  membership: "/account/membership",
  monitoring: "/brewery/monitor/",
  monitorSession: "/brewery/monitoring/:id/",
  monitor: "/brewery/monitor/:id/",
  newRecipe: "/beers/new/",
  privacyPolicy: "/privacy/",
  privacyPolicyRegister: "/privacy-policy/",
  editProfile: "/profile/",
  profile: "/settings/",
  recipeDetails: "/beers/:id/",
  recipeDetailsWithParams: (beerID, version) =>
    `/beers/${beerID}/version/${version}`,
  recipeDetailsWithVersion: "/beers/:id/version/:version",
  recipes: "/recipes/",
  register: "/auth/register/",
  resetPassword: "/auth/resetpassword/",
  signin: "/auth/signin/",
  tc: "https://www.minibrew.io/pages/terms-and-conditions",
  tcAuth: "/terms/",
  termsAndPrivacy: "/tc-privacy/",
  myIngredients: "/my-ingredients/",
  token: "/token/",
  subscribe: "/subscribe/",
  paymentCompleted: "/payment-return/",
  home: "/auth/home/",
  couponHelpUrl:
    "https://minibrew.zendesk.com/hc/en-us/articles/360010531079-Activate-your-Pro-membership"
};

export const ING_HOP = "HOP";
export const ING_FERMENTABLE = "FERM";
export const ING_ADJUNCT = "ADJ";
export const ING_YEAST = "YEAST";

export const FERMENTABLE_TYPE = {
  GRAIN: "GRA",
  EXTRACT: "EXTR",
  SUGAR: "SUG",
  ADJUNCT: "ADJ",
  DRY_EXTRACT: "DEXTR"
};

export const PRESSURE_RELIEF = {
  0: "Airlock",
  1: "Blow off",
  2: "None"
};

export const FERMENTATION_STAGE_TYPES = {
  PRIM: "Primary",
  SECND: "Secondary",
  COND: "Conditioning"
};

export const INGREDIENT_ENDPOINTS = {
  FERM: "fermentables",
  HOP: "hops",
  YEAST: "yeasts",
  ADJ: "adjuncts"
};

export const PROFILE_PROPS = {
  SRM:
    "SRM is needed for calculation of the beer profile and optionally for calculating the correct amount of fermentables for brewpacks.",
  ALPHA_ACID:
    "Alpha acid is needed for calculation of the beer profile and optionally for calculating the correct amount of hops for brewpacks."
};

export const VALIDATION = {
  MIN_WATER_AMOUNT: 5,
  MAX_WATER_AMOUNT: 7,

  MIN_SERVING_TEMP: 5,
  MAX_SERVING_TEMP: 20,

  MIN_SPARGE_WATER: 0,
  MAX_SPARGE_WATER: 1,

  MIN_MASH_TEMP: 0,
  MAX_MASH_TEMP: 80,

  MIN_MASH_STEP_DURATION: 1,
  MAX_MASH_STEP_DURATION: 120,

  MIN_BOIL_TIME: 0,
  MAX_BOIL_TIME: 180,

  MIN_FERM_TEMP: 1,
  MAX_FERM_TEMP: 30,

  MIN_SRM: 0,
  MAX_SRM: 800
};

export const MAX_FERMENTING_STEPS = 20;
export const MAX_MASH_STAGES = 2;

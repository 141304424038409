import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, ControlLabel } from "react-bootstrap";
import { Field } from "redux-form";
import CustomField from "../CustomField/CustomField";

const stageStep = ({
  fields,
  durationUnits,
  validation,
  normalization,
  disableAdd,
  enableEndTemp,
  stageIndex,
  change
}) => {
  const setEndTemperature = (index, newVal) => {
    if (enableEndTemp) {
      change(
        `fermenting[${stageIndex}].steps[${index}].end_temperature`,
        newVal
      );
    }
  };
  return (
    <div>
      <Button
        className="AddButton"
        style={{ margin: "10px 0" }}
        onClick={() => fields.push({ temperature: null, duration: null })}
        disabled={disableAdd}
      >
        <i className="icon ion-plus" /> STEP
      </Button>

      {fields.map((step, index) => (
        <div key={index} className="list-item">
          <Row>
            <Col sm={3}>
              <ControlLabel
                style={{
                  marginTop: index ? "7px" : "37px",
                  display: "block",
                  textOverflow: "ellipsis"
                }}
              >
                Step {index + 1}
              </ControlLabel>
            </Col>
            <Field
              name={`${step}.temperature`}
              onChange={(e, newVal) => setEndTemperature(index, newVal)}
              component={CustomField}
              label={enableEndTemp ? "Start ºC" : "Temp ºC"}
              rowIndex={index}
              smSize={2}
              validate={(validation && validation.temperature) || null}
              normalize={(normalization && normalization.temperature) || null}
              type="number"
              step="0.1"
            />
            {enableEndTemp && (
              <Field
                name={`${step}.end_temperature`}
                component={CustomField}
                label="End ºC"
                rowIndex={index}
                smSize={2}
                validate={(validation && validation.temperature) || null}
                normalize={(normalization && normalization.temperature) || null}
                type="number"
                step="0.1"
              />
            )}
            <Field
              name={`${step}.duration`}
              component={CustomField}
              label={`Duration (${durationUnits})`}
              rowIndex={index}
              smSize={3}
              validate={(validation && validation.duration) || null}
              normalize={(normalization && normalization.duration) || null}
              type="number"
              step={enableEndTemp ? "0.01" : "1.0"}
            />
            {!index ? null : (
              <Button
                className="remove-btn"
                title="Remove step"
                onClick={() => fields.remove(index)}
              >
                <i className="icon ion-close" />
              </Button>
            )}
          </Row>
        </div>
      ))}
    </div>
  );
};

export default connect(
  null,
  null
)(stageStep);

import { captureException } from "@sentry/browser";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";

export const logError = (error, context, extraInfo = {}, pathname = null) => {
  if (pathname && pathname !== "") {
    analyticsService.track(ANALYTICS_TYPES.ERROR_REPORT, {
      Error: extraInfo,
      Screen: pathname
    });
  }
  captureException(error, {
    extra: {
      ...context,
      ...extraInfo
    }
  });
};

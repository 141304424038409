// Authentication actions
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_ERROR = "AUTHENTICATE_USER_ERROR";

export const SIGN_OUT = "SIGN_OUT";

// Get user information actions
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const USER_ACTIONS = {
  GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRIPTIONS_SUCESS",
  GET_SUBSCRIPTIONS: "GET_USER_SUBSCRIPTIONS",
  GET_SUBSCRIPTIONS_ERROR: "GET_SUBSCRIPTIONS_ERROR",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
  CANCEL_SUBSCRIPTION_ERROR: "CANCEL_SUBSCRIPTION_ERROR",
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",
  PATCH_USER: "PATCH_USER",
  PATCH_USER_SUCCESS: "PATCH_USER_SUCCESS",
  PATCH_USER_ERROR: "PATCH_USER_ERROR",
  REMOVE_USER_DATA: "REMOVE_USER_DATA",
  GET_SHARED_FAVOURITES_SUCCESS: "GET_SHARED_FAVOURITES_SUCCESS",
  SET_PERMISSION: "SET_PERMISSION"
};

// MQTT Notification channel actions
export const MQTT_ACTIONS = {
  MQTT_CONNECT: "MQTT:CONNECT",
  MQTT_CONNECT_SUCCESS: "MQTT:CONNECT_SUCCESS",
  MQTT_CONNECT_ERROR: "MQTT:CONNECT_ERROR",
  MQTT_DISCONNECT: "MQTT:DISCONNECT",
  MQTT_RECEIVE: "MQTT:RECEIVE",
  MQTT_SUBSCRIBE: "MQTT:SUBSCRIBE",
  MQTT_UNSUBSCRIBE: "MQTT:UNSUBSCRIBE",
  MQTT_PUBLISH: "MQTT:PUBLISH",
  MQTT_PROCESS_SUMMARY: "MQTT:PROCESS_SUMMARY",
  MQTT_IGNORE_NOTIFICATIONS: "MQTT:IGNORE_NOTIFICATIONS"
};

export const RECIPE_ACTIONS = {
  GET_RECIPES: "GET_RECIPES",
  GET_RECIPES_SUCCESS: "GET_RECIPES_SUCCESS",
  GET_RECIPES_ERROR: "GET_RECIPES_ERROR"
};

export const DEVICE_ACTIONS = {
  GET_DEVICES: "GET_DEVICES",
  GET_DEVICES_SUCCESS: "GET_DEVICES_SUCCESS",
  GET_DEVICES_ERROR: "GET_DEVICES_ERROR"
};

export const BEER_ACTIONS = {
  GET_BEERS: "GET_BEERS",
  GET_BEERS_SUCCESS: "GET_BEERS_SUCCESS",
  GET_BEERS_ERROR: "GET_BEERS_ERROR"
};

export const SESSION_ACTIONS = {
  GET_SESSION: "GET_SESSION",
  GET_SESSION_SUCCESS: "GET_SESSION_SUCCESS",
  GET_SESSION_ERROR: "GET_SESSION_ERROR",
  GET_SESSION_BY_RECIPE: "GET_SESSION_BY_RECIPE",
  GET_SESSION_BY_RECIPE_SUCCESS: "GET_SESSION_BY_RECIPE_SUCCESS",
  GET_SESSION_BY_RECIPE_ERROR: "GET_SESSION_BY_RECIPE_ERROR"
};

export const TOGGLE_MENU = "TOGGLE_MENU";

export const WIFI_ACTIONS = {
  WIFI_ONLINE: "WIFI_ONLINE",
  WIFI_OFFLINE: "WIFI_OFFLINE"
};

export const BREWERY_ACTIONS = {
  GET_BREWERY: "GET_BREWERY",
  GET_BREWERY_SUCCESS: "GET_BREWERY_SUCCESS",
  GET_BREWERY_ERROR: "GET_BREWERY_ERROR"
};

export const INGREDIENT_ACTIONS = {
  GET_HOP: "GET_HOP",
  GET_HOP_SUCCESS: "GET_HOP_SUCCESS",
  GET_HOP_ERROR: "GET_HOP_ERROR",
  GET_FERMENTABLE: "GET_FERMENTABLE",
  GET_FERMENTABLE_SUCCESS: "GET_FERMENTABLE_SUCCESS",
  GET_FERMENTABLE_ERROR: "GET_FERMENTABLE_ERROR",
  GET_YEAST: "GET_YEAST",
  GET_YEAST_SUCCESS: "GET_YEAST_SUCCESS",
  GET_YEAST_ERROR: "GET_YEAST_ERROR",
  GET_ADJUNCT: "GET_ADJUNCT",
  GET_ADJUNCT_SUCCESS: "GET_ADJUNCT_SUCCESS",
  GET_ADJUNCT_ERROR: "GET_ADJUNCT_ERROR",
  GET_LABORATORIES: "GET_LABORATORIES",
  GET_LABORATORIES_SUCCESS: "GET_LABORATORIES_SUCCESS",
  GET_LABORATORIES_ERROR: "GET_LABORATORIES_ERROR"
};

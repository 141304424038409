import React from "react";
import { Grid, Col } from "react-bootstrap";
import TcContent from "./TcContent";
import TitleBar from "@common/components/TitleBar/TitleBar";
import Container from "@common/components/Container/Container";

const TermsAndConditions = () => {
  return (
    <div>
      <TitleBar
        title="TERMS AND CONDITIONS"
        subtitle="Last updated: Dec 11th 2018,"
      />
      <Grid>
        <div>
          <Col sm={12} />
          <Container>
            <TcContent />
          </Container>
        </div>
      </Grid>
    </div>
  );
};

export default TermsAndConditions;

// source: hardware.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

goog.exportSymbol("proto.Actuator", null, global);
goog.exportSymbol("proto.ActuatorType", null, global);
goog.exportSymbol("proto.MachineConnectionStatus", null, global);
goog.exportSymbol("proto.MachineType", null, global);
goog.exportSymbol("proto.Sensor", null, global);
goog.exportSymbol("proto.SensorType", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Sensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Sensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Sensor.displayName = "proto.Sensor";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Actuator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Actuator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Actuator.displayName = "proto.Actuator";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Sensor.prototype.toObject = function(opt_includeInstance) {
    return proto.Sensor.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Sensor} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Sensor.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        value:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null
            ? undefined
            : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Sensor}
 */
proto.Sensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Sensor();
  return proto.Sensor.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Sensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Sensor}
 */
proto.Sensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.SensorType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setValue(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Sensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Sensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Sensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Sensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.SensorType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(2, f);
  }
};

/**
 * optional SensorType type = 1;
 * @return {!proto.SensorType}
 */
proto.Sensor.prototype.getType = function() {
  return /** @type {!proto.SensorType} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ));
};

/**
 * @param {!proto.SensorType} value
 * @return {!proto.Sensor} returns this
 */
proto.Sensor.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Sensor} returns this
 */
proto.Sensor.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Sensor.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional float value = 2;
 * @return {number}
 */
proto.Sensor.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    2,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.Sensor} returns this
 */
proto.Sensor.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Sensor} returns this
 */
proto.Sensor.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Sensor.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Actuator.prototype.toObject = function(opt_includeInstance) {
    return proto.Actuator.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Actuator} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Actuator.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        on: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Actuator}
 */
proto.Actuator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Actuator();
  return proto.Actuator.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Actuator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Actuator}
 */
proto.Actuator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.ActuatorType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setOn(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Actuator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Actuator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Actuator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Actuator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.ActuatorType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(2, f);
  }
};

/**
 * optional ActuatorType type = 1;
 * @return {!proto.ActuatorType}
 */
proto.Actuator.prototype.getType = function() {
  return /** @type {!proto.ActuatorType} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ));
};

/**
 * @param {!proto.ActuatorType} value
 * @return {!proto.Actuator} returns this
 */
proto.Actuator.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Actuator} returns this
 */
proto.Actuator.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Actuator.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional bool on = 2;
 * @return {boolean}
 */
proto.Actuator.prototype.getOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    2,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.Actuator} returns this
 */
proto.Actuator.prototype.setOn = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Actuator} returns this
 */
proto.Actuator.prototype.clearOn = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Actuator.prototype.hasOn = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * @enum {number}
 */
proto.MachineType = {
  MACH_TYPE_BASE: 0,
  MACH_TYPE_KEG: 1
};

/**
 * @enum {number}
 */
proto.MachineConnectionStatus = {
  MACH_CONN_STATUS_OFFLINE: 0,
  MACH_CONN_STATUS_ONLINE: 1,
  MACH_CONN_STATUS_NOT_RESPONDING: 2
};

/**
 * @enum {number}
 */
proto.SensorType = {
  TEMP_ENVIRONMENT: 0,
  TEMP_CONTROL_OUT: 1,
  TEMP_CONTROL_IN: 2,
  TEMP_LIQUID: 3,
  TEMP_PELTIER: 4,
  PUMP_CURRENT: 6,
  CAROUSSEL_POSITION: 7,
  TOP_CONNECTION_PRESENT: 9,
  BOTTOM_CONNECTION_PRESENT: 10,
  CAROUSEL_ZERO_POSITION: 12,
  ESP_CORE_TEMP: 13,
  BAROMETRIC_PRESSURE: 14,
  VALVE_MASH_TUN_OPEN: 15,
  VALVE_BOILING_KETTLE_OPEN: 16,
  VALVE_WATER_INLET_OPEN: 17,
  SYSTEM_TEMP: 18,
  BUTTON: 19,
  PCB_FAN: 20,
  GSENSOR_TEMP: 21,
  GSENSOR_GRAVITY: 22,
  GSENSOR_BATTERY: 23,
  GSENSOR_RSSI: 27,
  TEMP_CONTROL_POWER: 24,
  LIQUID_FLOW_POWER: 25,
  PELTIER_FAN_POWER: 26
};

/**
 * @enum {number}
 */
proto.ActuatorType = {
  ACTUATOR_UNUSED: 0,
  VALVE_TO_MASHING_TUN: 2,
  VALVE_TO_BOILING_KETTLE: 3,
  VALVE_WATER_INLET: 4,
  FAN_PCB: 5
};

goog.object.extend(exports, proto);

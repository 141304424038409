import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Alert } from "react-bootstrap";

import NOTIFICATION_TYPES from "@common/constants/notificationTypes";
import * as sessionService from "@brewery/services/sessionService";
import "./control-session-modal.scss";

class ControlSessionModal extends React.Component {
  state = {
    waitingForNotification: false,
    error: false
  };

  componentWillReceiveProps(nextProps) {
    const newNotification = nextProps.mqtt.notification;
    const lastNotification = this.props.mqtt.notification;

    if (
      newNotification !== lastNotification &&
      newNotification.commandSeq === this._seq
    ) {
      if (newNotification.type === NOTIFICATION_TYPES.NOT_COMMAND_EXECUTED) {
        clearTimeout(this._intervalIdStopSession);
        this.props.handleClose();
      } else if (newNotification.type === NOTIFICATION_TYPES.NOT_ERROR) {
        clearTimeout(this._intervalIdStopSession);
        this.setState({ error: true });
      }
      this.setState({ waitingForNotification: false });
    }
  }

  componentWillUnmount() {
    if (this._intervalIdStopSession) {
      clearTimeout(this._intervalIdStopSession);
    }
  }

  setWaiting = response => {
    this._seq = response.seq;
    this._intervalIdStopSession = setTimeout(() => {
      this.setState({
        waitingForNotification: false
      });
      this.props.handleClose();
    }, 8000);
  };

  handleException = () => {
    this.setState({ waitingForNotification: false });
  };

  stopSession = () => {
    if (this.props.kegNeedsCleaning) {
      this.setState({ waitingForNotification: true });
      sessionService
        .stopServing(this.props.sessionID)
        .then(response => this.setWaiting(response))
        .catch(this.handleException);
    } else {
      this.setState({ waitingForNotification: true });
      sessionService
        .deleteSession(this.props.sessionID)
        .then(response => this.setWaiting(response))
        .catch(this.handleException);
    }
  };

  render() {
    const { waitingForNotification, error } = this.state;
    const { show, handleClose } = this.props;

    return (
      <div className="static-modal">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: "center" }}>
              Are you sure?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span className="ion-android-alert stop-session__icon" />
              <div className="stop-session__text">
                This will completely stop the session. {"\n \n"}If you are
                brewing a Brew Pack, you can only re-use the QR code if you are
                stopping before the automated mashing phase.
              </div>
            </div>
            <div className="clearfix" />
          </Modal.Body>
          <Modal.Footer>
            {error && (
              <Alert style={{ float: "left" }} bsStyle="danger">
                <span className="ion-alert-circled">
                  {" "}
                  Something happened, try again later
                </span>
              </Alert>
            )}
            {waitingForNotification && (
              <span
                className="start-brew-loading__icon ion-load-c spin"
                style={{ float: "left", marginTop: "6px" }}
              >
                {" "}
                Stopping session...
              </span>
            )}
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={this.stopSession} bsStyle="danger">
              Stop Session
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ mqtt }) {
  return { mqtt };
}
export default connect(
  mapStateToProps,
  undefined
)(ControlSessionModal);

import React, { Component } from "react";
import { Field, FieldArray } from "redux-form";
import {
  Button,
  Row,
  Well,
  FormControl,
  ControlLabel,
  Col
} from "react-bootstrap";

import Step from "../../StageStep/StageStep";
import CustomField from "../../CustomField/CustomField";
import FermIngredients from "./FermIngredients/FermIngredients";
import FermHops from "./FermHops/FermHops";
import {
  FERMENTATION_STAGE_TYPES as STAGE_TYPES,
  VALIDATION
} from "@common/constants/recipeConstants";
import TypeAhead from "../TypeAhead/TypeAheadYeast";
import { apiEndpoints as api } from "@common/constants/urls";
import {
  required,
  numberBetween
} from "../../../../../util/formValidationFunctions";
import { normalizeNumber, yeast } from "../../../../../util/recipeHelper";
import ErrorList from "../ErrorList";

const normalizeAmount = normalizeNumber(3, 2);
const normalizeTemp = normalizeNumber(2, 1);
const normalizeDuration = normalizeNumber(2, 2);

const fermentationTemp = numberBetween(
  VALIDATION.MIN_FERM_TEMP,
  VALIDATION.MAX_FERM_TEMP
);

// Needs to be the same as the SCSS animation time
const ANIMATION_TIME = 300;
const PRESSURE_RELIEF = { airlock: 0, blowoff: 1 };

class Fermentation extends Component {
  constructor() {
    super();
    this.state = {
      animate: {}
    };
  }

  swapUp = (fields, index) => () => {
    if (index > 1) {
      // Set animation state
      this.setState({
        animate: {
          [index]: "swap-up",
          [index - 1]: "swap-down"
        }
      });
      setTimeout(() => {
        // Swap and reset animation state
        fields.swap(index, index - 1);
        this.setState({
          animate: {
            [index]: "swapped fade-down",
            [index - 1]: "swapped fade-up"
          }
        });
      }, ANIMATION_TIME);
    }
  };

  swapDown = (fields, index) => () => {
    if (index < fields.length - 2) {
      // Set animation state
      this.setState({
        animate: {
          [index]: "swap-down",
          [index + 1]: "swap-up"
        }
      });
      setTimeout(() => {
        // Swap and reset animation state
        fields.swap(index, index + 1);
        this.setState({
          animate: {
            [index]: "swapped fade-up",
            [index + 1]: "swapped fade-down"
          }
        });
      }, ANIMATION_TIME);
    }
  };

  render() {
    const {
      fields,
      change,
      validationEnabled,
      disableAddSteps,
      meta: { error }
    } = this.props;
    const { animate } = this.state;
    return (
      <div className="minibrew-container" data-name="fermenting">
        <Row>
          <Col sm={3}>
            <h2>Fermentation</h2>
          </Col>
          <Col
            sm={5}
            smOffset={4}
            md={4}
            mdOffset={5}
            style={{ marginTop: "10px" }}
          >
            <FormControl
              style={{ cursor: "pointer" }}
              name="fermentation_stage_type"
              componentClass="select"
              disabled={disableAddSteps}
              onChange={e => {
                const newStage = {
                  fermentation_stage_type: e.target.value,
                  steps: [{ temperature: null, duration: null }]
                };
                if (e.target.value === "PRIM") newStage.yeast = [yeast()];
                fields.insert(fields.length - 1, newStage);
                e.target.value = "INIT";
              }}
            >
              <option value="INIT">Add fermentation stage</option>
              {Object.keys(STAGE_TYPES).map(key => (
                <option value={key} key={key}>
                  {STAGE_TYPES[key]}
                </option>
              ))}
            </FormControl>
          </Col>
        </Row>
        <p className="instruction">
          You may have as many fermentation stages as you like. However, every
          recipe must have a Primary (open) fermentation stage and a
          Conditioning stage.
          <br />
          <br />
          After Boiling, the wort is cooled for 30 minutes or until it reaches
          25 ºC. Therefore, if you wish to pitch the yeast as soon as possible,
          we would recommend starting Stage 1 (Primary) with a Step that starts
          at 25 ºC. If you are expecting a highly active fermentation due to
          high sugar contents (and expected ABV) or volatile/active yeast, you
          can switch the pressure relief method from the Airlock to the Blow-Off
          Tube to avoid a big mess! Secondary (closed) fermentation is suggested
          for carbonation and/or to cold crash your beer.
          <br />
          <br />
          Additional Conditioning stages may be used to maintain or adjust the
          temperature, and will trigger a Trub Container removal at the end of
          the stage (unlike Secondary). If the Trub Container is removed, users
          will be prompted to reattach it at the beginning of the next stage. We
          suggest triggering at least 1 trub removal for every 10g of hops used
          for dry hopping.
        </p>
        {fields.map((stage, index) => {
          const stageLabel =
            STAGE_TYPES[fields.get(index).fermentation_stage_type];
          const swapUpClass = animate[index] || "";
          const swapDownClass = animate[index] || "";
          return (
            <Well
              key={index}
              className={`Stage ${swapUpClass} ${swapDownClass}`}
            >
              <h3>
                Stage {index + 1} - {stageLabel}
              </h3>
              {fields.get(index).fermentation_stage_type !== "PRIM" ? null : (
                <Row>
                  <Col sm={3}>
                    <ControlLabel
                      style={{ marginTop: "10px", display: "block" }}
                    >
                      Pressure relief
                    </ControlLabel>
                  </Col>
                  <Col sm={3}>
                    <Field
                      name={`${stage}.pressure_relief`}
                      component="select"
                      label="Pressure relief"
                      parse={value => Number(value)}
                      className="btn btn-default form-control"
                      style={{
                        marginLeft: "0px",
                        boxShadow: "none",
                        borderRadius: "6px"
                      }}
                    >
                      <option value={PRESSURE_RELIEF.airlock}>Airlock</option>
                      <option value={PRESSURE_RELIEF.blowoff}>Blow off</option>
                    </Field>
                  </Col>
                </Row>
              )}
              {fields.get(index).fermentation_stage_type !== "PRIM" ? null : (
                <Row>
                  <Col sm={3}>
                    <ControlLabel
                      style={{ marginTop: "37px", display: "block" }}
                    >
                      Yeast
                    </ControlLabel>
                  </Col>
                  <Field
                    name={`${stage}.yeast[0].ingredient_id`}
                    component={TypeAhead}
                    forceValidation
                    label="Name"
                    rowIndex={0}
                    change={change}
                    location={`fermenting[${index}].yeast[0]`}
                    endpoint={api.yeasts}
                    attrMap={{
                      id: "ingredient_id",
                      attenuation: "ingredient_attenuation"
                    }}
                    smSize={3}
                  />
                  <Field
                    name={`${stage}.yeast[0].amount`}
                    type="number"
                    component={CustomField}
                    label="Amount (gr)"
                    smSize={3}
                    normalize={normalizeAmount}
                    step="0.01"
                  />
                </Row>
              )}

              {!index || fields.length === index + 1 ? null : (
                <div className="TopRightBtn">
                  <Button
                    title="Swap up"
                    disabled={index < 2}
                    onClick={this.swapUp(fields, index)}
                  >
                    <i className="icon ion-android-arrow-up" />
                  </Button>
                  <Button
                    title="Swap down"
                    disabled={index > fields.length - 3}
                    onClick={this.swapDown(fields, index)}
                  >
                    <i className="icon ion-android-arrow-down" />
                  </Button>
                  <Button
                    title="Remove boiling step"
                    onClick={() => fields.remove(index)}
                  >
                    <i className="icon ion-close" />
                  </Button>
                </div>
              )}
              <FieldArray
                name={`${stage}.steps`}
                component={Step}
                stageLabel={stageLabel}
                change={change}
                stageIndex={index}
                enableEndTemp={true}
                durationUnits="days"
                validation={
                  validationEnabled && {
                    temperature: [required, fermentationTemp]
                  }
                }
                normalization={{
                  temperature: normalizeTemp,
                  duration: normalizeDuration
                }}
                disableAdd={disableAddSteps}
              />
            </Well>
          );
        })}

        <Well>
          <h3>While fermenting</h3>
          {!!fields
            .getAll()
            .filter(stage => stage.fermentation_stage_type !== "COND")
            .length && (
            <div>
              <FieldArray
                name="while_fermenting.hops"
                component={FermHops}
                change={change}
                disableAdd={disableAddSteps}
              />
              <FieldArray
                name="while_fermenting.other_ingredients"
                component={FermIngredients}
                change={change}
                disableAdd={disableAddSteps}
              />
            </div>
          )}
        </Well>
        <ErrorList error={error} />
      </div>
    );
  }
}

export default Fermentation;

import { get, post, put, del } from "@common/services/request";
import { apiEndpoints as api } from "@common/constants/urls";
import COMMAND_TYPES from "@common/constants/commandTypes";
import { SESSION_TYPES } from "@common/constants/sessionConstants";

export const startAcidCleaningSession = deviceUUID =>
  post(api.sessions, {
    minibrew_uuid: deviceUUID,
    session_type: SESSION_TYPES.SESSION_ACID_CLEAN_MINIBREW
  });

export const startCleaningSession = deviceUUID =>
  post(api.sessions, {
    minibrew_uuid: deviceUUID,
    session_type: SESSION_TYPES.SESSION_TYPE_CLEAN_MINIBREW
  });

export const getSessionsByRecipeId = recipeId =>
  get(`${api.sessions}?beer_recipe=${recipeId}`);

export const getSessionById = Id => get(`${api.sessions}${Id}/`);

export const startSession = body => post(api.sessions, body);

export const updateSession = (sessionId, body) =>
  put(`${api.sessions}${sessionId}/`, body);

export const deleteSession = sessionId => del(`${api.sessions}${sessionId}/`);

export const resumeSession = (sessionId, userAction) =>
  put(`${api.sessions}${sessionId}/`, {
    command_type: COMMAND_TYPES.CMD_RESUME_PROCESS,
    resume_action: userAction
  });

export const stopServing = sessionId =>
  put(`${api.sessions}${sessionId}/`, {
    command_type: COMMAND_TYPES.CMD_STOP_PROCESS
  });

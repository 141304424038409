import fetch from "isomorphic-fetch";
import { Subject } from "rxjs";
import { routes, apiEndpoints } from "@common/constants/urls";
import mqttService from "../../monitor/mqttService";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";

let currentlyRefreshing = false;
let refreshingToken$ = new Subject();

const defaultHeaders = {
  "Content-Type": "application/json",
  client: "Breweryportal",
  version: process.env.VERSION || 0
};

function handleResponse(res) {
  const contentType = res.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    // When the body's empty Backend should probably send a 204 instead
    if (res.status === 401) invalidToken();
    if (res.status >= 400) throw res.json();
    else return res.json();
  } else if (!res.ok) {
    throw Error(res.statusText);
  } else return null;
}

function handleError(err) {
  if (err.then) throw err;
  // If it's a promise (json response), bubble it up until the next catch
  else throw "Network error, please try again later.";
}

function invalidToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("exp");

  // If already at sign in page, we don't have to route
  if (location.href.indexOf(routes.signin) == -1) {
    analyticsService.track(ANALYTICS_TYPES.SIGN_OUT, { Forced: true });
    analyticsService.mixpanel.reset();
    location.href = "/";
  }
}

function checkToken(restCall, customHeaders, refreshCall = false) {
  return new Promise(resolve => {
    const token = localStorage.getItem("token");
    const currentTime = new Date().getTime();
    const expTime = localStorage.getItem("exp");

    if (expTime > currentTime) {
      const headers = { ...customHeaders };
      headers.Authorization = "Bearer " + token;
      resolve(restCall(headers));
    } else if (!currentlyRefreshing) {
      currentlyRefreshing = true;
      const headers = {
        "Content-Type": "application/json",
        Authorization: "TOKEN " + process.env.API_TOKEN,
        client: "Breweryportal",
        version: process.env.VERSION || 0
      };
      fetch(process.env.API_URL + apiEndpoints.refreshToken, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          refresh: token
        })
      })
        .then(handleResponse)
        .then(res => {
          // Normalize timestamp -> /token/ returns in ms instead of s
          const expiration = res.exp * 1000;
          localStorage.setItem("token", res.token);
          localStorage.setItem("exp", expiration);
          const headers = { ...customHeaders };
          headers.Authorization = "Bearer " + res.token;
          refreshingToken$.next(res.token);
          currentlyRefreshing = false;
          mqttService.reconnect(res.token);
          resolve(restCall(headers));
        });
    } else if (currentlyRefreshing && !refreshCall) {
      const subscription = refreshingToken$.subscribe(token => {
        const headers = { ...customHeaders };
        headers.Authorization = "Bearer " + token;
        subscription.unsubscribe();
        resolve(restCall(headers));
      });
    }
  });
}

export function get(url, customHeaders = defaultHeaders) {
  const restCall = restHeaders => {
    return fetch(process.env.API_URL + url, {
      method: "GET",
      headers: restHeaders
    })
      .then(handleResponse)
      .catch(handleError);
  };
  return checkToken(restCall, customHeaders);
}

export function post(url, body, headers = defaultHeaders) {
  // If it is a call with the Bearer - hashtoken, check if it is still valid
  // otherwise try to complete request after refreshing token
  // If it is not a bearer call, just complete the request with the application token
  if (localStorage.getItem("token") && headers == defaultHeaders) {
    const restCall = restHeaders => {
      // If type is multipart, remove the whole header to allow automatization
      // of config and boundary directly from browser
      if (restHeaders["Content-Type"].indexOf("multipart/form-data") > -1) {
        delete restHeaders["Content-Type"];
      }

      return fetch(process.env.API_URL + url, {
        method: "POST",
        headers: restHeaders,
        body:
          restHeaders["Content-Type"] === "application/json"
            ? JSON.stringify(body)
            : body
      })
        .then(handleResponse)
        .catch(handleError);
    };
    const refreshCall = url.indexOf("refresh") > -1;
    return checkToken(restCall, headers, refreshCall);
  } else {
    // If type is multipart, remove the whole header to allow automatization
    // of config and boundary directly from browser
    if (headers["Content-Type"].indexOf("multipart/form-data") > -1) {
      delete headers["Content-Type"];
    }
    return fetch(process.env.API_URL + url, {
      method: "POST",
      headers: headers,
      body:
        headers["Content-Type"] === "application/json"
          ? JSON.stringify(body)
          : body
    })
      .then(handleResponse)
      .catch(handleError);
  }
}

export function patch(url, body, headers) {
  const customHeaders = {
    ...defaultHeaders,
    ...headers
  };

  customHeaders.Authorization = "Bearer " + localStorage.getItem("token");

  // If type is multipart, remove the whole header to allow automatization
  // of config and boundary directly from browser
  if (customHeaders["Content-Type"].indexOf("multipart/form-data") > -1) {
    delete customHeaders["Content-Type"];
  }

  const restCall = restHeaders => {
    return fetch(process.env.API_URL + url, {
      method: "PATCH",
      headers: restHeaders,
      body:
        customHeaders["Content-Type"] === "application/json"
          ? JSON.stringify(body)
          : body
    })
      .then(handleResponse)
      .catch(handleError);
  };
  return checkToken(restCall, customHeaders);
}

export function put(url, body, headers = defaultHeaders) {
  const restCall = restHeaders => {
    return fetch(process.env.API_URL + url, {
      method: "PUT",
      headers: restHeaders,
      body: JSON.stringify(body)
    })
      .then(handleResponse)
      .catch(handleError);
  };
  return checkToken(restCall, headers);
}
export function del(url, headers = defaultHeaders) {
  const restCall = restHeaders => {
    return fetch(process.env.API_URL + url, {
      method: "DELETE",
      headers: restHeaders
    })
      .then(handleResponse)
      .catch(handleError);
  };
  return checkToken(restCall, headers);
}

import React, { Component } from "react";
import { Field, FieldArray, reduxForm, touch, stopSubmit } from "redux-form";
import { connect } from "react-redux";
import { Form, Alert, ControlLabel } from "react-bootstrap";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { debounce } from "lodash";

import Error from "./Error";
import BrewWater from "./BrewWater/BrewWater";
import Mashing from "./Mashing/Mashing";
import Boiling from "./Boiling/Boiling";
import Fermentation from "./Fermentation/Fermentation";
import initialValues from "./initialValues";
import CustomField from "../CustomField/CustomField";
import { validateRecipe } from "@beers/services/recipeService";
import { get } from "@common/services/request";
import { apiEndpoints as api } from "@common/constants/urls";
import {
  MAX_FERMENTING_STEPS,
  MAX_MASH_STAGES,
  FERMENTABLE_TYPE
} from "@common/constants/recipeConstants";
import {
  validateIngredients,
  normalizeNumber,
  countFermentationSteps,
  countMashStages
} from "../../../../util/recipeHelper";
import {
  calculateTotalMashInWater,
  calculateTotalWater,
  calculateKettleWater,
  calculateBeerProfile
} from "../utils";
const normalizeServingTemp = normalizeNumber(2, 2);
const { clearSubmitErrors } = require("redux-form/lib/actions").default;

class Recipe extends Component {
  state = {
    isLoading: true,
    netError: false,
    batchSize: 6,
    preMixWater: 1.0,
    totalWater: 0,
    kettleWater: 0,
    efficiency: 0,
    calcWater: true
  };

  componentDidMount() {
    this.props.dispatch(showLoading());
    if (this.props.recipeId === "new") {
      this.props.dispatch(hideLoading());
      this.setState({ isLoading: false });
      return;
    }
    get(`${api.recipes}${this.props.recipeId}/`)
      .then(res => {
        const recipe = validateIngredients(res);
        this.props.initialize(recipe);
        this.props.dispatch(hideLoading());
        this.setState({ isLoading: false });
        // Trigger first validation
        // this.props.shouldValidateInitial && this.props.handleSubmit();

        if (this.props.shouldValidateInitial) {
          validateRecipe(recipe)
            .then(() => {})
            .catch(e => {
              e.then(err => {
                // Manually trigger stopSubmit to add the validation errors
                // to the redux store
                this.props.dispatch(stopSubmit("recipe", err.errors));
              });
            });
        }

        // Touch all fields so validation get's triggered
        this.props.shouldValidateInitial &&
          Object.keys(this.props.form.recipe.registeredFields).forEach(item => {
            this.props.touch(item);
          });
      })
      .catch(() => {
        this.setState({ isLoading: false, netError: true });
        this.props.dispatch(hideLoading());
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.form.recipe) {
      if (nextProps.form.recipe.values !== this.props.form.recipe.values) {
        this.updateStore(nextProps);
        if (this.props.shouldValidateInitial) {
          const recipe = JSON.parse(
            JSON.stringify(nextProps.form.recipe.values)
          );
          recipe.fg = recipe.fg ? parseFloat(recipe.fg).toFixed(3) : 0;
          recipe.og = recipe.og ? parseFloat(recipe.og).toFixed(3) : 0;
          recipe.ibu = recipe.ibu ? parseInt(recipe.ibu) : 0;
          recipe.beer_id = this.props.routing.location.pathname.split("/")[2];

          this.validate(recipe);
        }
        if (this.state.calcWater) {
          this.calculateWaterAmount(nextProps);
        }
      }
    }
  }

  componentWillUnmount() {
    this.setState({ calcWater: false });
  }

  validate = debounce(
    recipe => {
      validateRecipe(recipe)
        .then(() => {
          this.props.dispatch(clearSubmitErrors("recipe"));
        })
        .catch(e => {
          e.then(err => {
            this.props.dispatch(stopSubmit("recipe", err.errors));
          });
        });
    },
    300,
    { trailing: true }
  );

  updateStore = debounce(
    nextProps => {
      const beerProfile = calculateBeerProfile(nextProps.form.recipe);
      if (beerProfile) {
        const efficiency = beerProfile[1] ? beerProfile[1] : 0.0;
        const { change } = this.props;

        if (this.state.efficiency !== efficiency) {
          this.setState({ efficiency: efficiency });
        }

        for (let field in beerProfile[0]) {
          if (nextProps.form.recipe.values[field] !== beerProfile[0][field]) {
            change(field, beerProfile[0][field]);
          }
        }
      }
    },
    300,
    { trailing: true }
  );

  updateWaterInStore = (totalWater, kettleWater) => {
    const { change } = this.props;
    change("water_amount", totalWater.toFixed(2));
    change("kettle_water", kettleWater.toFixed(2));
  };

  calculateWaterAmount = nextProps => {
    let allStagesWeight = 0;
    let mashStagesWeight = {};
    if (nextProps.form.recipe.values) {
      nextProps.form.recipe.values.mashing.forEach((stage, index) => {
        let mashStageWeight = 0;
        stage.ingredient_additions.forEach(ingredient => {
          if (ingredient.fermentable_type == FERMENTABLE_TYPE.GRAIN) {
            allStagesWeight += +ingredient.amount;
            mashStageWeight += +ingredient.amount;
          }
        });
        mashStagesWeight[index] = mashStageWeight;
      });

      if (
        this.props.form.recipe.values.mashing !==
        nextProps.form.recipe.values.mashing
      ) {
        const newPreMixWater = calculateTotalMashInWater(allStagesWeight);
        const totalWater = calculateTotalWater(allStagesWeight);

        this.setState({
          preMixWater: newPreMixWater
        });
        if (!isNaN(totalWater)) {
          this.updateWaterInStore(
            totalWater,
            calculateKettleWater(newPreMixWater, totalWater)
          );
          for (let stage in mashStagesWeight) {
            let newMashInWater = (mashStagesWeight[stage] / 1600).toFixed(2);
            if (
              nextProps.form.recipe.values.mashing[stage].mash_in_water !==
              newMashInWater
            ) {
              this.props.change(
                `mashing[${stage}].mash_in_water`,
                newMashInWater
              );
            }
          }
        }
      }
    }
  };

  render() {
    const { change, isSaving, handleSubmit, valid, error } = this.props;
    const validationEnabled = false;
    const { batchSize, preMixWater, efficiency } = this.state;
    const totalWater = this.props.form.recipe.values
      ? this.props.form.recipe.values.water_amount
      : 0;
    const kettleWater = this.props.form.recipe.values
      ? this.props.form.recipe.values.kettle_water
      : 0;
    const totalFermentationSteps = countFermentationSteps(
      this.props.form.recipe
    );
    const totalMashStages = countMashStages(this.props.form.recipe);
    return (
      (this.state.netError && (
        <h2 className="error-msg">
          An error occurred while retrieving the recipe.
        </h2>
      )) ||
      (!this.state.isLoading && (
        <Form horizontal onSubmit={handleSubmit}>
          <div className="minibrew-container" style={{ marginTop: 0 }}>
            <h2>Recipe details {this.state.weigth}</h2>
            <div className="row">
              <div className="col-md-7">
                {" "}
                <p className="instruction">
                  Currently the MiniBrew supports brewing on the basis of a 5.5
                  L target batch size. The amount of water required for the
                  recipe is calculated based on the fermentables included in the
                  mash, we use a 1L/1.6kg ratio for in-mashing (though the water
                  calculations account for more absorption). The MiniBrew can
                  only handle grain milled to{" "}
                  <b>
                    Dry Basis Coarse Grind (
                    <a href="https://byo.com/article/understanding-malt-spec-sheets-advanced-brewing/">
                      DBCG
                    </a>
                    ) standards (0.7-1.0mm gap on a two roll mill)
                  </b>
                  . Please take these limitations and typical water absorption
                  rates into consideration when calculating grain requirements
                  for achieving a particular gravity. The serving temperature
                  for your beer can be set at any value between 5ºC and 20ºC
                  (out to one decimal place).
                </p>
              </div>
              <div className="col-md-5">
                <div
                  style={{
                    paddingLeft: "13px"
                  }}
                >
                  <ControlLabel>Share recipe?</ControlLabel>
                  <Field
                    name="shared"
                    type="checkbox"
                    component="input"
                    style={{
                      display: "block",
                      boxShadow: "none",
                      borderRadius: "6px",
                      marginLeft: "-1px",
                      maxWidth: "233px"
                    }}
                  />
                </div>

                <Field
                  name="serving_temperature"
                  component={CustomField}
                  normalize={normalizeServingTemp}
                  type="number"
                  label="Serving temp. (ºC)"
                  smSize={5}
                  mdSize={12}
                  step="0.01"
                />
              </div>
            </div>
          </div>
          <div className="minibrew-container" style={{ marginTop: 0 }}>
            <h2 style={{ marginTop: 0 }}>Notes</h2>
            <div className="row">
              <Field
                name="private_note"
                component={CustomField}
                componentClass="textarea"
                label="Private note"
                smSize={12}
                style={{ minHeight: 150 }}
              />
              <Field
                name="public_note"
                component={CustomField}
                componentClass="textarea"
                label="Public note note"
                smSize={12}
                style={{ minHeight: 150 }}
              />
            </div>
          </div>
          <BrewWater
            batchSize={batchSize}
            totalWater={totalWater}
            preMixWater={preMixWater}
            kettleWater={kettleWater}
          />
          <FieldArray
            name="mashing"
            component={Mashing}
            change={change}
            validationEnabled={validationEnabled}
            efficiency={efficiency}
            disableAddStages={totalMashStages >= MAX_MASH_STAGES}
          />
          <FieldArray
            name="boiling"
            user={this.props.userInfo.user}
            component={Boiling}
            change={change}
            validationEnabled={validationEnabled}
          />
          <FieldArray
            name="fermenting"
            component={Fermentation}
            change={change}
            validationEnabled={validationEnabled}
            disableAddSteps={totalFermentationSteps >= MAX_FERMENTING_STEPS}
          />
          {error && (
            <div style={{ margin: "20px 15px" }}>
              {error.map((err, index) => (
                <Alert bsStyle="danger" key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "flex-row",
                      alignItems: "center"
                    }}
                  >
                    <i
                      className="ion-android-alert"
                      style={{ fontSize: "24px", marginRight: "15px" }}
                    />{" "}
                    <div style={{ fontFamily: "Gotham-Book" }}>
                      {err.message}
                    </div>
                  </div>
                </Alert>
              ))}
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <div style={{ margin: "0px 20px" }}>
                {!valid && (
                  <Error
                    error={
                      "Your recipe is currently not brewable, check the errors"
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className="button-row"
            style={{ margin: "20px 15px 80px", float: "right" }}
          >
            <ButtonWithLoad
              bsStyle="primary"
              bsSize="large"
              className="submit-btn"
              type="submit"
              isLoading={isSaving}
            >
              SAVE
            </ButtonWithLoad>
          </div>
        </Form>
      ))
    );
  }
}

const mapStateToProps = ({ form, routing, userInfo }) => {
  return { form, routing, userInfo };
};

const mapDispatchToProps = dispatch => ({
  touch: fields => dispatch(touch("recipe", fields))
});

Recipe = connect(
  mapStateToProps,
  mapDispatchToProps
)(Recipe);

export default (Recipe = reduxForm({
  form: "recipe",
  initialValues
})(Recipe));

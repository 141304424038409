import moment from "moment";

export const dateTimeLabel = timestamp => {
  if (!timestamp) {
    return "";
  }

  const inputDate = moment(timestamp);
  const today = moment();

  if (today.date() === inputDate.date()) {
    return "Today - " + inputDate.format("HH:mm");
  } else if (today.add(1, "days").date() === inputDate.date()) {
    return "Tomorrow - " + inputDate.format("HH:mm");
  } else {
    return inputDate.format("dddd, MMMM DD - HH:mm");
  }
};

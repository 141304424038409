import React from "react";
import { Grid, Row, Col } from "react-bootstrap";

import TitleBar from "@common/components/TitleBar/TitleBar";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

import "./privacy-policy.scss";

const PrivacyPolicy = () => (
  <div>
    <TitleBar title="PRIVACY" subtitle="Privacy policy" />
    <Grid>
      <Row className="privacy-policy">
        <Col sm={12}>
          <PrivacyPolicyContent />
        </Col>
      </Row>
    </Grid>
  </div>
);

export default PrivacyPolicy;

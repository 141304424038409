// source: status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

var useraction_pb = require("./useraction_pb.js");
goog.object.extend(proto, useraction_pb);
var recipe_pb = require("./recipe_pb.js");
goog.object.extend(proto, recipe_pb);
goog.exportSymbol("proto.ErrorType", null, global);
goog.exportSymbol("proto.MachineState", null, global);
goog.exportSymbol("proto.ProcessEvent", null, global);
goog.exportSymbol("proto.ProcessEvent.EventType", null, global);
goog.exportSymbol("proto.ProcessPhase", null, global);
goog.exportSymbol("proto.ProcessState", null, global);
goog.exportSymbol("proto.ProcessSummaryItem", null, global);
goog.exportSymbol("proto.ProcessType", null, global);
goog.exportSymbol("proto.Status", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProcessEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProcessEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProcessEvent.displayName = "proto.ProcessEvent";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProcessSummaryItem = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.ProcessSummaryItem.repeatedFields_,
    null
  );
};
goog.inherits(proto.ProcessSummaryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProcessSummaryItem.displayName = "proto.ProcessSummaryItem";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Status = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Status.repeatedFields_,
    null
  );
};
goog.inherits(proto.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Status.displayName = "proto.Status";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ProcessEvent.prototype.toObject = function(opt_includeInstance) {
    return proto.ProcessEvent.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ProcessEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.ProcessEvent.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        time: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        action: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProcessEvent}
 */
proto.ProcessEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProcessEvent();
  return proto.ProcessEvent.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProcessEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProcessEvent}
 */
proto.ProcessEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.ProcessEvent.EventType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setTime(value);
        break;
      case 3:
        var value = /** @type {!proto.UserAction} */ (reader.readEnum());
        msg.setAction(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProcessEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProcessEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProcessEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.ProcessEvent.EventType} */ (jspb.Message.getField(
    message,
    1
  ));
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(2, f);
  }
  f = /** @type {!proto.UserAction} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(3, f);
  }
};

/**
 * @enum {number}
 */
proto.ProcessEvent.EventType = {
  EVT_TYPE_UNDEF: 0,
  EVT_TYPE_USER_ACTION: 1,
  EVT_TYPE_INGREDIENT_INSERTION: 2
};

/**
 * optional EventType type = 1;
 * @return {!proto.ProcessEvent.EventType}
 */
proto.ProcessEvent.prototype.getType = function() {
  return /** @type {!proto.ProcessEvent.EventType} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ));
};

/**
 * @param {!proto.ProcessEvent.EventType} value
 * @return {!proto.ProcessEvent} returns this
 */
proto.ProcessEvent.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessEvent} returns this
 */
proto.ProcessEvent.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessEvent.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint32 time = 2;
 * @return {number}
 */
proto.ProcessEvent.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.ProcessEvent} returns this
 */
proto.ProcessEvent.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessEvent} returns this
 */
proto.ProcessEvent.prototype.clearTime = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessEvent.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional UserAction action = 3;
 * @return {!proto.UserAction}
 */
proto.ProcessEvent.prototype.getAction = function() {
  return /** @type {!proto.UserAction} */ (jspb.Message.getFieldWithDefault(
    this,
    3,
    0
  ));
};

/**
 * @param {!proto.UserAction} value
 * @return {!proto.ProcessEvent} returns this
 */
proto.ProcessEvent.prototype.setAction = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessEvent} returns this
 */
proto.ProcessEvent.prototype.clearAction = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessEvent.prototype.hasAction = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProcessSummaryItem.repeatedFields_ = [6];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ProcessSummaryItem.prototype.toObject = function(opt_includeInstance) {
    return proto.ProcessSummaryItem.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ProcessSummaryItem} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.ProcessSummaryItem.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        phase: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        state: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        duration: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        startTemperature:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null
            ? undefined
            : f,
        endTemperature:
          (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null
            ? undefined
            : f,
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.ProcessEvent.toObject,
          includeInstance
        ),
        fixedDuration:
          (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
        stepIndex: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProcessSummaryItem}
 */
proto.ProcessSummaryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProcessSummaryItem();
  return proto.ProcessSummaryItem.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProcessSummaryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProcessSummaryItem}
 */
proto.ProcessSummaryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 5:
        var value = /** @type {!proto.ProcessPhase} */ (reader.readEnum());
        msg.setPhase(value);
        break;
      case 1:
        var value = /** @type {!proto.ProcessState} */ (reader.readEnum());
        msg.setState(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setDuration(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setStartTemperature(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readFloat());
        msg.setEndTemperature(value);
        break;
      case 6:
        var value = new proto.ProcessEvent();
        reader.readMessage(
          value,
          proto.ProcessEvent.deserializeBinaryFromReader
        );
        msg.addEvents(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setFixedDuration(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setStepIndex(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProcessSummaryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProcessSummaryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProcessSummaryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessSummaryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.ProcessPhase} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(5, f);
  }
  f = /** @type {!proto.ProcessState} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(2, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(3, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(4, f);
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ProcessEvent.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(7, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint32(8, f);
  }
};

/**
 * optional ProcessPhase phase = 5;
 * @return {!proto.ProcessPhase}
 */
proto.ProcessSummaryItem.prototype.getPhase = function() {
  return /** @type {!proto.ProcessPhase} */ (jspb.Message.getFieldWithDefault(
    this,
    5,
    0
  ));
};

/**
 * @param {!proto.ProcessPhase} value
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.setPhase = function(value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.clearPhase = function() {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessSummaryItem.prototype.hasPhase = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional ProcessState state = 1;
 * @return {!proto.ProcessState}
 */
proto.ProcessSummaryItem.prototype.getState = function() {
  return /** @type {!proto.ProcessState} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ));
};

/**
 * @param {!proto.ProcessState} value
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.setState = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.clearState = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessSummaryItem.prototype.hasState = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint32 duration = 2;
 * @return {number}
 */
proto.ProcessSummaryItem.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.setDuration = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.clearDuration = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessSummaryItem.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional float start_temperature = 3;
 * @return {number}
 */
proto.ProcessSummaryItem.prototype.getStartTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    3,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.setStartTemperature = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.clearStartTemperature = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessSummaryItem.prototype.hasStartTemperature = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional float end_temperature = 4;
 * @return {number}
 */
proto.ProcessSummaryItem.prototype.getEndTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(
    this,
    4,
    0.0
  ));
};

/**
 * @param {number} value
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.setEndTemperature = function(value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.clearEndTemperature = function() {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessSummaryItem.prototype.hasEndTemperature = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * repeated ProcessEvent events = 6;
 * @return {!Array<!proto.ProcessEvent>}
 */
proto.ProcessSummaryItem.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.ProcessEvent>} */ jspb.Message.getRepeatedWrapperField(
    this,
    proto.ProcessEvent,
    6
  );
};

/**
 * @param {!Array<!proto.ProcessEvent>} value
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.ProcessEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProcessEvent}
 */
proto.ProcessSummaryItem.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    6,
    opt_value,
    proto.ProcessEvent,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};

/**
 * optional bool fixed_duration = 7;
 * @return {boolean}
 */
proto.ProcessSummaryItem.prototype.getFixedDuration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    7,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.setFixedDuration = function(value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.clearFixedDuration = function() {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessSummaryItem.prototype.hasFixedDuration = function() {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional uint32 step_index = 8;
 * @return {number}
 */
proto.ProcessSummaryItem.prototype.getStepIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.setStepIndex = function(value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.ProcessSummaryItem} returns this
 */
proto.ProcessSummaryItem.prototype.clearStepIndex = function() {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProcessSummaryItem.prototype.hasStepIndex = function() {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Status.repeatedFields_ = [5];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Status.prototype.toObject = function(opt_includeInstance) {
    return proto.Status.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Status} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Status.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        machState: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        procType: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        procState: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        timeInState:
          (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        timeInStateRemaining:
          (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
        errorTypesList:
          (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
        connectedPeerId:
          (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
        currentStepIndex:
          (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
        currentTempStepIndex:
          (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
        action: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
        ingredient:
          (f = msg.getIngredient()) &&
          recipe_pb.IngredientAddition.toObject(includeInstance, f)
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Status}
 */
proto.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Status();
  return proto.Status.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Status}
 */
proto.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.MachineState} */ (reader.readEnum());
        msg.setMachState(value);
        break;
      case 2:
        var value = /** @type {!proto.ProcessType} */ (reader.readEnum());
        msg.setProcType(value);
        break;
      case 3:
        var value = /** @type {!proto.ProcessState} */ (reader.readEnum());
        msg.setProcState(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setTimeInState(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setTimeInStateRemaining(value);
        break;
      case 5:
        var value = /** @type {!proto.ErrorType} */ (reader.readEnum());
        msg.addErrorTypes(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setConnectedPeerId(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCurrentStepIndex(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCurrentTempStepIndex(value);
        break;
      case 10:
        var value = /** @type {!proto.UserAction} */ (reader.readEnum());
        msg.setAction(value);
        break;
      case 11:
        var value = new recipe_pb.IngredientAddition();
        reader.readMessage(
          value,
          recipe_pb.IngredientAddition.deserializeBinaryFromReader
        );
        msg.setIngredient(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.MachineState} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(1, f);
  }
  f = /** @type {!proto.ProcessType} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(2, f);
  }
  f = /** @type {!proto.ProcessState} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(3, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(4, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeUint32(12, f);
  }
  f = message.getErrorTypesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(5, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(7, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint32(8, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(9, f);
  }
  f = /** @type {!proto.UserAction} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeEnum(10, f);
  }
  f = message.getIngredient();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      recipe_pb.IngredientAddition.serializeBinaryToWriter
    );
  }
};

/**
 * optional MachineState mach_state = 1;
 * @return {!proto.MachineState}
 */
proto.Status.prototype.getMachState = function() {
  return /** @type {!proto.MachineState} */ (jspb.Message.getFieldWithDefault(
    this,
    1,
    0
  ));
};

/**
 * @param {!proto.MachineState} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setMachState = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearMachState = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasMachState = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional ProcessType proc_type = 2;
 * @return {!proto.ProcessType}
 */
proto.Status.prototype.getProcType = function() {
  return /** @type {!proto.ProcessType} */ (jspb.Message.getFieldWithDefault(
    this,
    2,
    0
  ));
};

/**
 * @param {!proto.ProcessType} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setProcType = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearProcType = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasProcType = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional ProcessState proc_state = 3;
 * @return {!proto.ProcessState}
 */
proto.Status.prototype.getProcState = function() {
  return /** @type {!proto.ProcessState} */ (jspb.Message.getFieldWithDefault(
    this,
    3,
    0
  ));
};

/**
 * @param {!proto.ProcessState} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setProcState = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearProcState = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasProcState = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional uint64 time_in_state = 4;
 * @return {number}
 */
proto.Status.prototype.getTimeInState = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setTimeInState = function(value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearTimeInState = function() {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasTimeInState = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional uint32 time_in_state_remaining = 12;
 * @return {number}
 */
proto.Status.prototype.getTimeInStateRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};

/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setTimeInStateRemaining = function(value) {
  return jspb.Message.setField(this, 12, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearTimeInStateRemaining = function() {
  return jspb.Message.setField(this, 12, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasTimeInStateRemaining = function() {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * repeated ErrorType error_types = 5;
 * @return {!Array<!proto.ErrorType>}
 */
proto.Status.prototype.getErrorTypesList = function() {
  return /** @type {!Array<!proto.ErrorType>} */ (jspb.Message.getRepeatedField(
    this,
    5
  ));
};

/**
 * @param {!Array<!proto.ErrorType>} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setErrorTypesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};

/**
 * @param {!proto.ErrorType} value
 * @param {number=} opt_index
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.addErrorTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearErrorTypesList = function() {
  return this.setErrorTypesList([]);
};

/**
 * optional string connected_peer_id = 7;
 * @return {string}
 */
proto.Status.prototype.getConnectedPeerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setConnectedPeerId = function(value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearConnectedPeerId = function() {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasConnectedPeerId = function() {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional uint32 current_step_index = 8;
 * @return {number}
 */
proto.Status.prototype.getCurrentStepIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setCurrentStepIndex = function(value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearCurrentStepIndex = function() {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasCurrentStepIndex = function() {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional uint32 current_temp_step_index = 9;
 * @return {number}
 */
proto.Status.prototype.getCurrentTempStepIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/**
 * @param {number} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setCurrentTempStepIndex = function(value) {
  return jspb.Message.setField(this, 9, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearCurrentTempStepIndex = function() {
  return jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasCurrentTempStepIndex = function() {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional UserAction action = 10;
 * @return {!proto.UserAction}
 */
proto.Status.prototype.getAction = function() {
  return /** @type {!proto.UserAction} */ (jspb.Message.getFieldWithDefault(
    this,
    10,
    0
  ));
};

/**
 * @param {!proto.UserAction} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setAction = function(value) {
  return jspb.Message.setField(this, 10, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearAction = function() {
  return jspb.Message.setField(this, 10, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasAction = function() {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional IngredientAddition ingredient = 11;
 * @return {?proto.IngredientAddition}
 */
proto.Status.prototype.getIngredient = function() {
  return /** @type{?proto.IngredientAddition} */ jspb.Message.getWrapperField(
    this,
    recipe_pb.IngredientAddition,
    11
  );
};

/**
 * @param {?proto.IngredientAddition|undefined} value
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.setIngredient = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Status} returns this
 */
proto.Status.prototype.clearIngredient = function() {
  return this.setIngredient(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Status.prototype.hasIngredient = function() {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * @enum {number}
 */
proto.MachineState = {
  MACH_STATE_IDLE: 0,
  MACH_STATE_ACTIVE: 1,
  MACH_STATE_PAUSED: 2,
  MACH_STATE_UPDATING: 3
};

/**
 * @enum {number}
 */
proto.ProcessType = {
  PROC_IDLE: 0,
  PROC_BREWING: 1,
  PROC_CLEAN_MINIBREW: 2,
  PROC_CLEAN_KEG: 3,
  PROC_ACID_CLEAN_MINIBREW: 6,
  PROC_FERMENTATION: 4,
  PROC_SERVING: 5
};

/**
 * @enum {number}
 */
proto.ProcessPhase = {
  PHASE_NONE: 0,
  BREW_PREPARING: 1,
  BREW_MASHING: 2,
  BREW_BOILING: 3,
  BREW_CHILLING: 4,
  FERM_PREPARING: 12,
  FERM_PRIMARY: 5,
  FERM_SECONDARY: 6,
  FERM_CLARIFICATION: 7,
  FERM_CARBONATING: 13,
  SERV_SERVING: 8,
  CLEAN_MB_PREPARING: 9,
  CLEAN_MB_CLEANING: 10,
  CLEAN_MB_RINSING: 11,
  ACID_CLEAN_MB_SOAKING: 14,
  ACID_CLEAN_MB_CLEANING: 15,
  ACID_CLEAN_MB_RINSING: 16
};

/**
 * @enum {number}
 */
proto.ProcessState = {
  IDLE_STATE: 0,
  MANUAL_CONTROL_STATE: 5,
  PREPARE_RINSE_STATE: 6,
  PUMP_PRIMING_STATE: 10,
  CHECK_FLOW_STATE: 11,
  CHECK_WATER_STATE: 8,
  HEATUP_RINSE_STATE: 12,
  CLEAN_BALL_VALVE_STATE: 13,
  RINSE_BOILING_PATH_STATE: 14,
  RINSE_MASHING_PATH_STATE: 15,
  RINSE_COOL_STATE: 18,
  RINSE_DONE_STATE: 16,
  RINSE_FLOW_RECOVERY_STATE: 9,
  CHECK_KEG_STATE: 7,
  FILL_MACHINE_STATE: 17,
  MASHBED_HYDRATE_HEATING_STATE: 20,
  MASHBED_HYDRATE_FLOW_STATE: 21,
  MASHBED_HYDRATE_STATE: 22,
  MASHBED_HYDRATE_SETTLE_STATE: 23,
  MASH_IN_STATE: 24,
  MASHING_HEATUP_STATE: 30,
  MASHING_MAINTAIN_STATE: 31,
  MASHING_REST_STATE: 32,
  MASH_LINE_FLOW_RECOVERY_STATE: 33,
  SPARGING_STATE: 39,
  LAUTERING_STATE: 40,
  REPLACE_MASH_STATE: 43,
  BOILING_HEATUP_STATE: 50,
  BOILING_MAINTAIN_STATE: 51,
  SECONDARY_LAUTERING_STATE: 52,
  BOIL_LINE_FLOW_RECOVERY_STATE: 53,
  CONNECT_WATER_STATE: 59,
  COOL_WORT_STATE: 60,
  FILTER_COLD_CRASH_STATE: 61,
  WRITE_KEG_STATE: 65,
  BREWING_DONE_STATE: 70,
  BREWING_FAILED_STATE: 71,
  PITCH_COOLING_STATE: 74,
  PAIR_GRAVITY_SENSOR_STATE: 85,
  PREPARE_FERMENTATION_STATE: 75,
  FERMENTATION_TEMP_CONTROL_STATE: 80,
  PLACE_AIRLOCK_STATE: 76,
  REMOVE_AIRLOCK_STATE: 77,
  PLACE_TRUB_CONTAINER_STATE: 78,
  REMOVE_TRUB_STATE: 81,
  FERMENTATION_ADD_INGREDIENT_STATE: 82,
  FERMENTATION_REMOVE_INGREDIENT_STATE: 83,
  FERMENTATION_FAILED_STATE: 84,
  CARBONATING_STATE: 94,
  PREPARE_SERVING_STATE: 88,
  COOL_BEFORE_SERVING_STATE: 90,
  MOUNT_TAP_STATE: 91,
  SERVING_TEMP_CONTROL_STATE: 92,
  SERVING_FAILED_STATE: 93,
  PREPARE_CIP_STATE: 101,
  DRAIN_STATE: 102,
  CIP_HEATUP_STATE: 103,
  PLACE_CIP_ACCESORIES_STATE: 105,
  CIP_DONE_STATE: 108,
  CIP_FAILED_STATE: 109,
  CIRCULATE_BOILING_PATH_STATE: 111,
  CIRCULATE_MASHING_PATH_STATE: 112,
  RINSE_COUNTERFLOW_BOIL_STATE: 113,
  RINSE_COUNTERFLOW_MASHTUN_STATE: 114,
  CLEANING_FLOW_RECOVERY_STATE: 115,
  CIP_SOAK_STATE: 116,
  PREPARE_ACID_CLEAN_STATE: 117,
  ACID_SOAK_STATE: 118,
  ACID_CIRCULATE_BOILING_PATH_STATE: 119,
  CLEAN_FILTER_STATE: 120,
  ACID_CIRCULATE_MASHING_PATH_STATE: 121,
  ACID_RINSE_BOILING_PATH_STATE: 122,
  ACID_RINSE_MASHING_PATH_STATE: 123,
  ACID_CLEAN_DONE_STATE: 124,
  ACID_CLEAN_FAILED_STATE: 125,
  ACID_CLEAN_FLOW_RECOVERY_STATE: 126
};

/**
 * @enum {number}
 */
proto.ErrorType = {
  ERR_UNDEFINED: 0,
  ERR_IO_COMMUNICATION_FAILED: 9,
  ERR_IO_COMMUNICATION_ERROR: 116,
  ERR_RECIPE_INVALID: 10,
  ERR_NFC_NOT_CONNECTED: 11,
  ERR_NFC_READ_ERROR: 12,
  ERR_NFC_WRITE_ERROR: 17,
  ERR_PRESSURE_SENSOR_FAILED: 18,
  ERR_MEMORY_LOW: 13,
  ERR_STORAGE_LOW: 14,
  ERR_FILESYSTEM_CORRUPT: 15,
  ERR_I0_ENVIRONMENT_TEMP_READING_FAILED: 101,
  ERR_I0_LIQUID_TEMP_CONTROL_IN_READING_FAILED: 102,
  ERR_I0_LIQUID_TEMP_CONTROL_OUT_READING_FAILED: 103,
  ERR_I0_LIQUID_TEMP_CONTROL_FAILED: 104,
  ERR_I0_LIQUID_TEMP_CONTROL_THERMAL_PROTECTION: 105,
  ERR_I0_LIQUID_PUMP_CONTROL_FAILED: 106,
  ERR_I0_LIQUID_PUMP_RUNNING_DRY: 107,
  ERR_I0_LIQUID_PUMP_MALFUNCTIONING: 112,
  ERR_I0_LIQUID_PUMP_LOW_CURRENT_WARNING: 119,
  ERR_I0_LIQUID_PUMP_HIGH_CURRENT_WARNING: 120,
  ERR_IO_LIQUID_LOW_FLOW_WARNING: 117,
  ERR_IO_LIQUID_INSUFFICIENT_FLOW: 118,
  ERR_I0_MASH_TUN_VALVE_CONTROL_FAILED: 108,
  ERR_I0_BOILING_KETTLE_VALVE_CONTROL_FAILED: 109,
  ERR_I0_CAROUSEL_CONTROL_FAILED: 110,
  ERR_I0_INLET_VALVE_CONTROL_FAILED: 111,
  ERR_IO_LIQUID_TEMP_READING_FAILED: 113,
  ERR_IO_PELTIER_TEMP_READING_FAILED: 114,
  ERR_IO_AC_DETECT_FAILED: 115
};

goog.object.extend(exports, proto);

import React from "react";
import moment from "moment";

import "./panel-header.scss";

const PanelHeader = ({
  number,
  stageName,
  deviceName,
  timeRemaining,
  active,
  done = false,
  sessionDone
}) => {
  const daysLeft = moment.duration(timeRemaining, "seconds").asDays();
  const inDays = daysLeft >= 1;

  return (
    <div>
      <h3
        className={`panel-header ${
          !done && !active && !sessionDone ? "panel-header--done" : ""
        }`}
      >
        <span className="step">{number}</span>
        &nbsp;&nbsp;{stageName} {done && <i className="ion-checkmark-round" />}
        <span className="panel-header__device-name">
          {deviceName && active && `on ${deviceName}`}{" "}
        </span>
        {active && timeRemaining !== 0 && (
          <span>
            <span className="panel-header__time-value">
              {inDays && timeRemaining ? (
                <React.Fragment>
                  {daysLeft > 1
                    ? `${daysLeft.toFixed()} Days`
                    : `${daysLeft.toFixed()} Day`}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {moment.duration(timeRemaining, "seconds").format("H:mm:ss")}
                </React.Fragment>
              )}
            </span>
            <span className="panel-header__time-label">Time remaining </span>
          </span>
        )}
      </h3>
    </div>
  );
};

export default PanelHeader;

import React from "react";
import { Col } from "react-bootstrap";
import { srmToCss } from "../../../util/beercalc";
import { Link } from "react-router-dom";
import { routes } from "@common/constants/urls";
import "./recipe-list-item.scss";

const RecipeListItemSquare = ({ recipe }) => {
  return (
    <Col xs={12} sm={6} md={4}>
      <Link
        to={routes.community + `recipe/${recipe.id}`}
        style={{ textDecoration: "inherit", color: "inherit", width: "100%" }}
      >
        <div
          style={{
            backgroundColor: "white",
            minHeight: 245,
            marginBottom: 20,
            borderRadius: 6,
            width: "100%"
          }}
          className="list-item-square"
        >
          <svg
            style={{ height: 50, width: "100%", borderRadius: 6 }}
            src="data:image/svg+xml,%3Csvg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon
              fill={srmToCss(recipe.srm)}
              points="0,0 0,80 100,30 100,0"
            />
          </svg>
          <div
            style={{
              padding: "0 20px",
              marginTop: -46
            }}
            className="flex flex-col items-center justify-center"
          >
            {recipe.beer.brewer.image ? (
              <img
                style={{
                  width: 58,
                  height: 58,
                  borderRadius: 40,
                  backgroundColor: "white",
                  padding: 3
                }}
                src={recipe.beer.brewer.image}
                alt="brewer-image"
              />
            ) : (
              <div
                style={{
                  minWidth: 58,
                  height: 58,
                  borderRadius: 40,
                  backgroundColor: "white"
                }}
                className="flex justify-center items-center"
              >
                <span
                  className="ion-android-person"
                  style={{
                    fontSize: "30px"
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              padding: "0 20px"
            }}
            className="flex flex-col items-center"
          >
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "11px",
                textAlign: "center",
                width: "100%"
              }}
            >
              {recipe.beer_name}
              <div style={{ marginTop: -6 }}>
                <small>{recipe.beer.style.name}</small>
              </div>
              <div style={{ marginTop: -6 }}>
                {recipe.beer.brewer.name ? (
                  <small className="font-book">{recipe.beer.brewer.name}</small>
                ) : (
                  <small className="font-book">No name</small>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row" style={{ margin: "25px 0" }}>
            <div style={{ flex: 1 }} className="flex items-center flex-column">
              <div>{recipe.srm}</div>
              <div style={{ fontFamily: "Gotham-Book", fontSize: 12 }}>SRM</div>
            </div>
            <div style={{ flex: 1 }} className="flex items-center flex-column">
              <div>{recipe.ibu}</div>
              <div style={{ fontFamily: "Gotham-Book", fontSize: 12 }}>IBU</div>
            </div>
            <div style={{ flex: 1 }} className="flex items-center flex-column">
              <div>{recipe.abv}%</div>
              <div style={{ fontFamily: "Gotham-Book", fontSize: 12 }}>
                Abv.
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "gainsboro",
              height: 1,
              margin: "0 20px"
            }}
          />
          <div
            className="flex flex-row items-center"
            style={{
              height: 40,
              justifyContent: "space-between",
              padding: "0 20px"
            }}
          >
            {/* <p
              style={{ marginBottom: 8, marginTop: "0 !important" }}
              className={`recipe-version-item__${
                recipe.brewable ? "brewable" : "unbrewable"
              }`}
            >
              {recipe.brewable ? "Brewable" : "Unbrewable"}
            </p> */}
            <div className="flex justify-center items-center">
              <span style={{ marginRight: 5, marginTop: -3 }}>
                {recipe.favourite_amount}
              </span>
              <i
                className="icon ion-ios-heart"
                style={{ fontSize: 18, color: "#24262c" }}
              />
            </div>
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default RecipeListItemSquare;

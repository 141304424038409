import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import MonitorTemperature from "../../../../../monitor/pages/Monitor/Temperatures/Temperatures";
import { routes } from "@common/constants/urls";
import "./status-active.scss";

const StatusActive = ({ currentTemp, targetTemp, sessionId, dispatch }) => {
  return (
    <div
      onClick={() => dispatch(push(routes.monitoring + sessionId))}
      className="device__status--active"
    >
      <div className="device__temp">
        <MonitorTemperature current={currentTemp} target={targetTemp} />
      </div>
      <span className="ion-ios-arrow-right device__chevron" />
    </div>
  );
};

export default connect(
  undefined,
  undefined
)(StatusActive);

import { MQTT_ACTIONS } from "@common/constants/actionTypes";
import * as mqttTopics from "@common/constants/mqttTopics";
import notification_pb from "../../util/protobuf/notification_pb";
import log_pb from "../../util/protobuf/log_pb";
import { generateUUID } from "../util/UUID";

const initialState = {
  clientUUID: generateUUID(),
  topics: [],
  isConnecting: false,
  isConnected: false,
  errorMessage: "",
  notifications: [],
  notification: "",
  log: "",
  frequentLogResponse: false,
  processSummary: [],
  processSummaryDevice: "",
  ignoreSession: null
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case MQTT_ACTIONS.MQTT_CONNECT:
      return Object.assign({}, state, {
        isConnecting: true
      });
    case MQTT_ACTIONS.MQTT_CONNECT_SUCCESS:
      return Object.assign({}, state, {
        errorMessage: "",
        isConnecting: false,
        isConnected: true
      });
    case MQTT_ACTIONS.MQTT_CONNECT_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isConnecting: false,
        isConnected: false
      });
    case MQTT_ACTIONS.MQTT_DISCONNECT:
      return Object.assign({}, state, {
        topics: [],
        isConnected: false
      });
    case MQTT_ACTIONS.MQTT_RECEIVE: {
      let isNotification =
        action.topic.indexOf(mqttTopics.notificationTopic) !== -1;
      let isLog = action.topic.indexOf(mqttTopics.logsTopic) !== -1;
      let isLogRequestResponse =
        action.topic.indexOf(mqttTopics.logRequestResponseTopic) !== -1;
      let message;
      // TODO refactor to middleware
      if (isNotification) {
        // Deserialize protobuf message binary -> proto -> object
        // Have to wrap the Uint8Array action.message in a new Uint8Array
        // See: https://github.com/google/protobuf/issues/1319
        message = notification_pb.Notification.deserializeBinary(
          new Uint8Array(action.message)
        ).toObject();
        // console.log(message);

        return Object.assign({}, state, {
          notifications: [...state.notifications, message],
          notification: message
        });
      } else if (isLog) {
        message = log_pb.Logs.deserializeBinary(
          new Uint8Array(action.message)
        ).toObject();

        return Object.assign({}, state, {
          log: message
        });
      } else if (isLogRequestResponse) {
        message = log_pb.LogRequestResp.deserializeBinary(
          new Uint8Array(action.message)
        ).toObject();
        // console.log(message);

        return Object.assign({}, state, {
          frequentLogResponse: true,
          processSummary: message.processSummaryList,
          processSummaryDevice: action.topic.substr(
            mqttTopics.logRequestResponseTopic.length
          )
        });
      }

      return state;
    }
    case MQTT_ACTIONS.MQTT_SUBSCRIBE:
      if (state.isConnected)
        return Object.assign({}, state, {
          topics: [...state.topics, action.topic]
        });
      return state;
    case MQTT_ACTIONS.MQTT_UNSUBSCRIBE:
      return Object.assign({}, state, {
        topics: state.topics.filter(topic => action.topic !== topic)
      });
    case MQTT_ACTIONS.MQTT_PROCESS_SUMMARY:
      return Object.assign({}, state, {
        processSummary: action.processSummary
      });
    case MQTT_ACTIONS.MQTT_IGNORE_NOTIFICATIONS:
      return Object.assign({}, state, {
        ignoreSession: action.session
      });
    default:
      return state;
  }
}

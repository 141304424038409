import React from "react";
import "./status-updating.scss";

const StatusUpdating = () => {
  return (
    <div className="device__status--updating">
      <span
        className="ion-gear-a device__alert-icon spin-slow"
        style={{ float: "left", marginTop: "5px" }}
      />
      <div className="device__alert-text" style={{ float: "left" }}>
        {" "}
        Device is updating...{" "}
        <div style={{ fontFamily: "Gotham-Book" }}>
          <small>This can take up to 10 min</small>
        </div>
      </div>
    </div>
  );
};

export default StatusUpdating;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import moment from "moment";
import { Badge } from "react-bootstrap";
import "./brew-history-item.scss";

/**
 * <BrewHistoryItem session={session} onClick={function} />
 * @param {Object} props.session brewsession data
 * @param {function} props.onClick method for handling click on brew-history-item
 */
class BrewHistoryItem extends React.Component {
  navigateToMonitoring = () => {
    this.props.dispatch(push(`/brewery/monitor/${this.props.session.id}`));
  };

  sessionActive = () => {
    return this.props.session.status === 1;
  };

  render() {
    const { session } = this.props;

    return (
      <div className="brew-history-item" onClick={this.navigateToMonitoring}>
        <img
          src={
            this.props.userInfo.user.image || "http://via.placeholder.com/60x60"
          }
          className="brew-history-item__image"
          alt=""
        />
        <div className="brew-history-item__text">
          <h4 className="brew-history-item__brewer">
            {session.device.custom_name || "Minibrew"}
          </h4>
          <p className="brew-history-item__brew-date">
            Brew date:{" "}
            {moment
              .unix(this.props.session.brew_timestamp)
              .format("DD-MM-YYYY")}
          </p>
        </div>
        <div className="brew-history-item-chevron">
          <span className="ion-chevron-right" />
        </div>
        {this.sessionActive() && <Badge className="active-badge">Active</Badge>}
      </div>
    );
  }
}

BrewHistoryItem.propTypes = {
  session: PropTypes.object,
  onClick: PropTypes.func
};

const mapStateToProps = ({ userInfo }) => {
  return { userInfo };
};
export default connect(
  mapStateToProps,
  null
)(BrewHistoryItem);

import { BREWERY_ACTIONS as type } from "@common/constants/actionTypes";

const initialState = {
  list: null,
  isFetching: false,
  error: false,
  errorMessage: "",
  lastUpdate: Date.now()
};

export default function breweryReducer(state = initialState, action) {
  switch (action.type) {
    case type.GET_BREWERY:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: true,
        error: false
      });
    case type.GET_BREWERY_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: false,
        list: action.brewery,
        lastUpdate: Date.now()
      });
    case type.GET_BREWERY_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: false,
        error: true
      });
    default:
      return state;
  }
}

/* eslint-disable */

import {
  required,
  number,
  numberBetween
} from "../../../../util/formValidationFunctions";
import set from "lodash/set"; // Creates object paths while setting
import { VALIDATION } from "@common/constants/recipeConstants";

/*
GENERAL
  boiling duration > 0
  fermentation stage > 0
  mashing stage > 0
  mashing step > 0

RECIPE DETAILS
  water amount: 6-7L
  serving temp: 5-20 degrees celcius

MASHING
  sparge water: 0-1L (dependant on water amount)
    - if 6L water -> 1Liter sparge is possible, if 7L, Sparging is not possible)
  temperature: 0-80 degrees celcius / mash (tun can not be any hotter)
  max 120 minutes / duration step

FERMENTABLES
  SRM: 0 < 80
  Name is required if any of the rest fields is set

BOILING
  boil time: max 180 min

FERMENTING
  fermentation temp: 1 - 30 degrees celcius

OTHER INGREDIENTS
  Name is required if any of the rest fields is set

 */

const waterAmount = numberBetween(
  VALIDATION.MIN_WATER_AMOUNT,
  VALIDATION.MAX_WATER_AMOUNT
);

const servingTemperature = numberBetween(
  VALIDATION.MIN_SERVING_TEMP,
  VALIDATION.MAX_SERVING_TEMP
);

const mashingTemperature = numberBetween(
  VALIDATION.MIN_MASH_TEMP,
  VALIDATION.MAX_MASH_TEMP
);

const srmTemperature = numberBetween(VALIDATION.MIN_SRM, VALIDATION.MAX_SRM);

const fermentingTemperature = numberBetween(
  VALIDATION.MIN_FERM_TEMP,
  VALIDATION.MAX_FERM_TEMP
);

const mashingDuration = numberBetween(
  VALIDATION.MIN_MASH_STEP_DURATION,
  VALIDATION.MAX_MASH_STEP_DURATION
);

const boilingDuration = numberBetween(
  VALIDATION.MIN_BOIL_TIME,
  VALIDATION.MAX_BOIL_TIME
);

const fitMaxCapacity = (water, sparge) =>
  Number(water) + Number(sparge) > VALIDATION.MAX_WATER_AMOUNT
    ? `Water amount plus sparge water should not
     be higher than ${VALIDATION.MAX_WATER_AMOUNT}`
    : null;

const processErrors = (value, validators) =>
  validators.map(fn => fn(value)).join("");

const validate = values => {
  const errors = {};
  // Basic errors
  // [
  //   {
  //     inputName: "water_amount",
  //     validations: [number, waterAmount]
  //   },
  //   {
  //     inputName: "serving_temperature",
  //     validations: [number, servingTemperature]
  //   }
  // ].forEach(({ inputName, validations }) => {
  //   const errorString = processErrors(values[inputName], validations);
  //   if (errorString) errors[inputName] = errorString;
  // });

  // // FieldArray: Mashing specific errors
  // values.mashing.map((mashingStep, mashingStepIndex) => {
  //   mashingStep.ingredient_additions.map((ingredient, i) => {
  //     const { srm, amount, ingredient_name } = ingredient;
  //     const er = srmTemperature(srm);
  //     er &&
  //       set(
  //         errors,
  //         `mashing[${mashingStepIndex}].ingredient_additions[${i}].srm`,
  //         srmTemperature(srm)
  //       );

  //     // Setting amount or srm without selecting fermentable is error.
  //     !ingredient_name &&
  //       (srm || amount) &&
  //       set(
  //         errors,
  //         `mashing[${mashingStepIndex}].ingredient_additions[${i}].ingredient_name`,
  //         "You need to select a fermentable."
  //       );
  //   });

  //   mashingStep.steps.map(({ temperature, duration }, i) => {
  //     const erTemp = mashingTemperature(temperature);
  //     erTemp &&
  //       set(
  //         errors,
  //         `mashing[${mashingStepIndex}].steps[${i}].temperature`,
  //         erTemp
  //       );
  //     const erTime = mashingDuration(duration);
  //     erTime &&
  //       set(
  //         errors,
  //         `mashing[${mashingStepIndex}].steps[${i}].duration`,
  //         erTime
  //       );
  //   });

  //   const sparge = mashingStep.sparge_in_water;
  //   const erSparge =
  //     number(sparge) ||
  //     numberBetween(0, 1)(sparge) ||
  //     fitMaxCapacity(values.water_amount, sparge);
  //   erSparge &&
  //     set(errors, `mashing[${mashingStepIndex}].sparge_in_water`, erSparge);
  // });

  // // FieldArray: Boiling specific errors
  // const boiling = values.boiling[0];
  // const erBoilingTime = boilingDuration(boiling.duration);
  // erBoilingTime && set(errors, `boiling[0].duration`, erBoilingTime);
  // boiling.hops.map((ingredient, i) => {
  //   const { duration, amount, ingredient_name, alpha_acid } = ingredient;
  //   !ingredient_name &&
  //     (duration || amount || alpha_acid) &&
  //     set(
  //       errors,
  //       `boiling[0].hops[${i}].ingredient_name`,
  //       "You need to select a hop."
  //     );
  // });
  // boiling.other_ingredients.map((ingredient, i) => {
  //   const { duration, amount, ingredient_name } = ingredient;
  //   !ingredient_name &&
  //     (duration || amount) &&
  //     set(
  //       errors,
  //       `boiling[0].other_ingredients[${i}].ingredient_name`,
  //       "You need to select an ingredient."
  //     );
  // });

  // // FieldArray: Fermenting specific errors
  // values.fermenting.map((fermentingStep, fermentingStepIndex) => {
  //   fermentingStep.steps.map(({ temperature }, i) => {
  //     const er = fermentingTemperature(temperature);
  //     er &&
  //       set(
  //         errors,
  //         `fermenting[${fermentingStepIndex}].steps[${i}].temperature`,
  //         er
  //       );
  //   });
  // });

  // values.while_fermenting.hops.map((ingredient, i) => {
  //   const { duration, amount, ingredient_name, alpha_acid } = ingredient;
  //   !ingredient_name &&
  //     (duration || amount || alpha_acid) &&
  //     set(
  //       errors,
  //       `while_fermenting.hops[${i}].ingredient_name`,
  //       "You need to select a hop."
  //     );
  // });

  // values.while_fermenting.other_ingredients.map((ingredient, i) => {
  //   const { duration, amount, ingredient_name } = ingredient;
  //   !ingredient_name &&
  //     (duration || amount) &&
  //     set(
  //       errors,
  //       `while_fermenting.other_ingredients[${i}].ingredient_name`,
  //       "You need to select an ingredient."
  //     );
  // });

  return errors;
};

export const warn = ({ boiling, mashing, fermenting }) => {
  const warnings = {};

  // const mashingStepWarnText =
  //   "The mashing stage needs to have at least the first step completed.";

  // mashing.forEach((stage, index) => {
  //   const mashingStepOne = stage.steps[0];
  //   const mashingTimeOk = mashingStepOne.duration > 0;
  //   const mashingTempOk = mashingStepOne.temperature > 0;

  //   !mashingTimeOk &&
  //     set(warnings, `mashing[${index}].steps[0].duration`, mashingStepWarnText);
  //   !mashingTempOk &&
  //     set(
  //       warnings,
  //       `mashing[${index}].steps[0].temperature`,
  //       mashingStepWarnText
  //     );
  // });

  // const boilingStepOne = boiling[0];
  // const boilingTimeOk = boilingStepOne.duration > 0;

  // !boilingTimeOk &&
  //   set(
  //     warnings,
  //     "boiling[0].duration",
  //     "Needs to have a boiling duration of more than 1 minute."
  //   );

  // const fermentingWarnText = "Cannot be empty";

  // fermenting.forEach((stage, stageIndex) => {
  //   stage.steps.forEach((step, index) => {
  //     const stepDurationOk = step.duration !== null;
  //     const stepTempOk = step.temperature !== null;

  //     !stepDurationOk &&
  //       set(
  //         warnings,
  //         `fermenting[${stageIndex}].steps[${index}].duration`,
  //         fermentingWarnText
  //       );

  //     !stepTempOk &&
  //       set(
  //         warnings,
  //         `fermenting[${stageIndex}].steps[${index}].temperature`,
  //         fermentingWarnText
  //       );
  //   });
  // });

  return warnings;
};

export default validate;

import { BREWERY_ACTIONS } from "@common/constants/actionTypes";
import * as breweryservice from "@brewery/services/breweryService";

const getBrewery = () => {
  return { type: BREWERY_ACTIONS.GET_BREWERY };
};

const getBrewerySuccess = brewery => {
  return { type: BREWERY_ACTIONS.GET_BREWERY_SUCCESS, brewery };
};

const getBreweryError = message => {
  return { type: BREWERY_ACTIONS.GET_BREWERY_ERROR, message };
};

export const fetchBrewery = () => {
  return dispatch => {
    dispatch(getBrewery());
    breweryservice
      .getBreweryInformation()
      .then(response => {
        dispatch(getBrewerySuccess(response));
      })
      .catch(() => {
        dispatch(getBreweryError("Fetching brewery info failed"));
      });
  };
};

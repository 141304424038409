import React from "react";
import RootCloseWrapper from "react-overlays/lib/RootCloseWrapper";
import { FormControl, MenuItem } from "react-bootstrap";

class TypeAheadMenu extends React.Component {
  state = {
    value: null,
    selected: 0
  };

  componentWillReceiveProps() {
    setTimeout(() => {
      if (this.input) {
        this.input.focus();
      }
    }, 100);
  }

  componentWillUnmount() {
    window.onkeydown = null;
  }

  handleChange = e => {
    this.setState({
      value: e.target.value.toLowerCase(),
      selected: 0
    });
  };

  triggerRootCloseEvent = event => {
    this.props.onClose(event, { source: "rootClose" });
  };

  selectOption = option => {
    this.setState({
      selected: option
    });
  };

  handleKeyDown = e => {
    const optionsLength = this.props.options.length;
    if (e.keyCode == 40) {
      if (this.state.selected < optionsLength) {
        this.selectOption(this.state.selected + 1);
      }
    } else if (e.keyCode == 38) {
      if (this.state.selected > 0) {
        this.selectOption(this.state.selected - 1);
      }
    } else if (e.keyCode == 13) {
      e.preventDefault();
      const filtered = this.props.options.filter(
        opt =>
          !this.state.value || opt.name.toLowerCase().includes(this.state.value)
      );
      this.props.handleClick(null, filtered[this.state.selected]);
    }
  };

  render() {
    const { options = [], style, "aria-labelledby": labeledBy } = this.props;
    const { value } = this.state;
    return (
      <RootCloseWrapper
        event="click"
        disabled={!this.props.open}
        onRootClose={this.triggerRootCloseEvent}
      >
        <div
          style={style}
          className="dropdown-menu typeahead-dropdown"
          aria-labelledby={labeledBy}
        >
          <FormControl
            onkeydown={this.handleKeyDown}
            inputRef={c => {
              this.input = c;
            }}
            onKeyDown={this.handleKeyDown}
            className=""
            placeholder="Type to filter..."
            onChange={this.handleChange}
            value={value ? value : ""}
          />
          {options
            .filter(opt => !value || opt.name.toLowerCase().includes(value))
            .map((option, index) => (
              <MenuItem
                active={this.state.selected === index}
                eventKey={index + 1}
                key={option.id}
                onMouseDown={e => this.props.handleClick(e, option)}
              >
                {option.available && (
                  <span>
                    {" "}
                    <img
                      src={require("@assets/images/MB_logo_color.png")}
                      alt="mb"
                      style={{
                        width: "18px",
                        marginTop: "-2px",
                        marginRight: "3px"
                      }}
                    />{" "}
                    {option.name}
                  </span>
                )}
                {!option.available && (
                  <span style={{ fontFamily: "Gotham-light" }}>
                    <img
                      src={this.props.user.image}
                      className="img-circle"
                      style={{
                        width: "18px",
                        height: "18px",
                        marginTop: "-2px",
                        marginRight: "3px"
                      }}
                    />{" "}
                    {option.name}
                  </span>
                )}
              </MenuItem>
            ))}
          <MenuItem eventKey={0} onClick={this.props.showIngredientModal}>
            + Add ingredient
          </MenuItem>
        </div>
      </RootCloseWrapper>
    );
  }
}

export default TypeAheadMenu;

import React, { Component } from "react";
import { connect } from "react-redux";
import { routes } from "@common/constants/urls";
import { push } from "react-router-redux";
import { FormGroup, FormControl, Alert, Checkbox, Col } from "react-bootstrap";
import * as authActions from "@auth/actions/authenticationActions";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import { registerUser } from "@auth/services/authenticationService";
import "./register-page.scss";
import Stepper from "react-stepper-horizontal";

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 128;

class RegisterPage extends Component {
  state = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordCheck: "",
    termsAccepted: false,
    errorMessage: null,
    passwordCorrect: false
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "password") {
      this.validatePassword(e.target.value);
    }
    if (e.target.name === "email") {
      this.validateEmail(e.target.value);
    }
  };

  validatePassword = password => {
    const passwordCorrect =
      password.length > PASSWORD_MIN_LENGTH &&
      password.length <= PASSWORD_MAX_LENGTH;
    this.setState({ passwordCorrect });
  };

  validateEmail = email => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({ emailValid: emailRegex.test(email) });
  };

  handleToggle = () => {
    this.setState(prevState => {
      return this.setState({ termsAccepted: !prevState.termsAccepted });
    });
  };

  authenticate = event => {
    event.preventDefault();
    this.setState({ errorMessage: null });
    const customHeaders = {
      Authorization: "TOKEN " + process.env.API_TOKEN,
      "Content-Type": "multipart/form-data",
      client: "Breweryportal",
      version: process.env.VERSION || 0
    };

    const body = {
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      email: this.state.email,
      password: this.state.password,
      terms_conditions_accepted: true
    };

    registerUser(body, customHeaders)
      .then(createdUser => {
        analyticsService.track(ANALYTICS_TYPES.SIGN_UP, {
          UserID: createdUser.id
        });
        analyticsService.track(ANALYTICS_TYPES.ACCEPTED_TERMS, {
          UserID: createdUser.id
        });

        const customHeaders = {
          "Content-Type": "application/json",
          Authorization: "TOKEN " + process.env.API_TOKEN,
          client: "Breweryportal",
          version: process.env.VERSION || 0
        };

        const credentials = {
          email: this.state.email,
          password: this.state.password
        };

        this.props.authenticateUser(credentials, customHeaders);
      })
      .catch(errorPromise => {
        errorPromise.then(error =>
          this.setState({ errorMessage: error.error_message })
        );
      });
  };

  render() {
    const { auth } = this.props;
    const {
      errorMessage,
      firstname,
      lastname,
      email,
      password,
      passwordCheck,
      passwordCorrect,
      termsAccepted,
      emailValid
    } = this.state;
    return (
      <div className="white-bg">
        <div className="stepper-container stepper-container-register">
          <Stepper
            steps={[
              {
                title: "Register"
              },
              { title: "Select Membership" },
              { title: "Payment" }
            ]}
            activeStep={0}
            activeColor={"rgb(253, 204, 0)"}
            defaultColor="rgba(253, 204, 0, 0.44)"
            completeColor="rgb(253, 204, 0)"
            defaultBorderColor="#000"
            size={40}
            completeBarColor="rgb(253, 204, 0)"
            circleFontColor="rgb(117, 117, 117)"
          />
        </div>
        <main className="container-fluid content--auth center white-bg">
          <section style={{ textAlign: "center", color: "#000" }}>
            <div>
              <h1 className="register__title hidden-xs text-black  ">
                Create a password to get access to the MiniBrew {"\n"}Pro
                brewing community & custom recipes
              </h1>
              <h2 className="register__title text-black show-xs">
                Create an Account
              </h2>
              <h3
                style={{ marginBottom: 0 }}
                className="show-xs register__subtitle"
              >
                <span
                  className="ion-checkmark-round"
                  style={{ paddingRight: 10, color: "#fdcc00" }}
                />
                Brew any recipe you want
              </h3>
              <h3 className="show-xs register__subtitle">
                <span
                  className="ion-checkmark-round"
                  style={{ paddingRight: 10, color: "#fdcc00" }}
                />
                Share your recipes with the community
              </h3>
            </div>

            <form horizontal onSubmit={this.authenticate}>
              <FormGroup className="signin__form">
                <FormGroup style={{ marginBottom: 0 }}>
                  <Col className={"form__col--name form__col--left"} sm={5}>
                    <FormControl
                      name="firstname"
                      type="text"
                      value={firstname}
                      placeholder="First name"
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                  <Col sm={5} className="form__col--name">
                    <FormControl
                      name="lastname"
                      type="text"
                      value={lastname}
                      placeholder="Last name"
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormControl
                  name="email"
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={this.handleChange}
                  required
                />
                <FormControl
                  name="password"
                  type="password"
                  value={this.state.password}
                  placeholder="Password"
                  onChange={this.handleChange}
                  required
                />
                <FormControl
                  name="passwordCheck"
                  type="password"
                  value={this.state.passwordCheck}
                  placeholder="Confirm Password"
                  onChange={this.handleChange}
                  required
                />
                <FormControl.Feedback />
                <Checkbox
                  className="checkbox-terms text-black"
                  checked={this.state.termsAccepted}
                  name="termsAccepted"
                  onChange={this.handleToggle}
                >
                  Yes, I understand and accept the MiniBrew{" "}
                  <a className="sign-up__link" href={routes.tc} target="_blank">
                    [Terms of Service]
                  </a>{" "}
                  and agree to receive MiniBrew recipe suggestion emails.
                </Checkbox>
                <ButtonWithLoad
                  isLoading={auth.isAuthenticating}
                  bsStyle="primary"
                  type="submit"
                  className="signup__form__button "
                  disabled={
                    !termsAccepted ||
                    password !== passwordCheck ||
                    !passwordCorrect ||
                    !emailValid
                  }
                >
                  Next
                </ButtonWithLoad>
              </FormGroup>
            </form>
            <div style={{ height: "50px" }} />
            {errorMessage && (
              <Alert
                bsStyle="danger"
                onDismiss={() => this.setState({ errorMessage: null })}
              >
                {errorMessage}
              </Alert>
            )}
            {password &&
              passwordCheck &&
              password !== passwordCheck &&
              termsAccepted && (
                <Alert bsStyle="danger">Passwords don't match</Alert>
              )}
            {termsAccepted && !passwordCorrect && (
              <Alert bsStyle="danger">
                Password length must be between {PASSWORD_MIN_LENGTH} and{" "}
                {PASSWORD_MAX_LENGTH} characters
              </Alert>
            )}
            {termsAccepted && !emailValid && (
              <Alert bsStyle="danger">Please enter a valid email</Alert>
            )}
          </section>
        </main>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticateUser: (credentials, headers) =>
      dispatch(authActions.authenticateUser(credentials, headers)),
    goToTC: state => dispatch(push({ pathname: routes.tc, state: state }))
  };
}
function mapStateToProps({ auth, userInfo }) {
  return {
    auth,
    userInfo
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);

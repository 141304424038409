import React from "react";
import "./status-idle-keg.scss";

const StatusIdle = () => {
  return (
    <div className="device__status--keg-idle">
      <span className="ion-alert device__alert-icon" />
      <span>Keg lost it's state, please contact support</span>
    </div>
  );
};

export default StatusIdle;

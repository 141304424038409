import React from "react";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import * as deviceService from "@common/services/deviceService";

class EditDeviceModal extends React.Component {
  state = {
    value: "",
    isSending: false,
    error: false
  };

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  changeDeviceName = () => {
    this.setState({ isSending: true, error: false });
    deviceService
      .editDevice(this.props.serialNumber, {
        custom_name: this.state.value
      })
      .then(() => this.handleClose())
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => this.setState({ isSending: false }));
  };

  handleClose = () => {
    this.setState({ value: "", isSending: false, error: false });
    this.props.handleClose();
  };

  render() {
    const { show } = this.props;
    const { isSending, error } = this.state;

    return (
      <Modal show={show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>EDIT DEVICE</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup controlId="editDevice">
              <ControlLabel>Device name</ControlLabel>
              <FormControl
                type="text"
                value={this.state.value}
                placeholder="Enter text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Form>
          {isSending && <div>Changing name...</div>}
          {error && <div>Error!</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>Close</Button>
          <Button onClick={this.changeDeviceName} bsStyle="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditDeviceModal;

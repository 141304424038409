import { get } from "@common/services/request";
import { apiEndpoints as api } from "@common/constants/urls";

const endpoint = (id, state) => `${api.sessions}${id}/logs/${state}/`;

export const getHistoricBrewing = sessionId =>
  get(endpoint(sessionId, "brewing"));

export const getHistoricFermenting = sessionId =>
  get(endpoint(sessionId, "fermenting"));

export const getHistoricServing = sessionId =>
  get(endpoint(sessionId, "serving"));

export const getHistoricCIP = sessionId => get(endpoint(sessionId, "cleaning"));

import { INGREDIENT_ACTIONS } from "@common/constants/actionTypes";
import * as ingredientService from "@beers/services/ingredientService";

const getHops = () => {
  return { type: INGREDIENT_ACTIONS.GET_HOP };
};

const getHopsSuccess = hops => {
  return { type: INGREDIENT_ACTIONS.GET_HOP_SUCCESS, hops };
};

const getHopsError = message => {
  return { type: INGREDIENT_ACTIONS.GET_HOP_ERROR, message };
};

export const fetchHops = () => {
  return dispatch => {
    dispatch(getHops());
    ingredientService
      .getHops()
      .then(response => dispatch(getHopsSuccess(response)))
      .catch(() => dispatch(getHopsError("Fetching hops failed")));
  };
};

const getFermentables = () => {
  return { type: INGREDIENT_ACTIONS.GET_FERMENTABLE };
};

const getFermentablesSuccess = fermentables => {
  return { type: INGREDIENT_ACTIONS.GET_FERMENTABLE_SUCCESS, fermentables };
};

const getFermentablesError = message => {
  return { type: INGREDIENT_ACTIONS.GET_FERMENTABLE_ERROR, message };
};

export const fetchFermentables = () => {
  return dispatch => {
    dispatch(getFermentables());
    ingredientService
      .getFermentables()
      .then(response => dispatch(getFermentablesSuccess(response)))
      .catch(() =>
        dispatch(getFermentablesError("Fetching fermentables failed"))
      );
  };
};

const getYeasts = () => {
  return { type: INGREDIENT_ACTIONS.GET_YEAST };
};

const getYeastsSuccess = yeasts => {
  return { type: INGREDIENT_ACTIONS.GET_YEAST_SUCCESS, yeasts };
};

const getYeastsError = message => {
  return { type: INGREDIENT_ACTIONS.GET_YEAST_ERROR, message };
};

export const fetchYeasts = () => {
  return dispatch => {
    dispatch(getYeasts());
    ingredientService
      .getYeasts()
      .then(response => dispatch(getYeastsSuccess(response)))
      .catch(() => dispatch(getYeastsError("Fetching yeasts failed")));
  };
};

const getAdjuncts = () => {
  return { type: INGREDIENT_ACTIONS.GET_ADJUNCT };
};

const getAdjunctsSuccess = adjuncts => {
  return { type: INGREDIENT_ACTIONS.GET_ADJUNCT_SUCCESS, adjuncts };
};

const getAdjunctsError = message => {
  return { type: INGREDIENT_ACTIONS.GET_ADJUNCT_ERROR, message };
};

export const fetchAdjuncts = () => {
  return dispatch => {
    dispatch(getAdjuncts());
    ingredientService
      .getAdjuncts()
      .then(response => dispatch(getAdjunctsSuccess(response)))
      .catch(() => dispatch(getAdjunctsError("Fetching adjuncts failed")));
  };
};

const getLaboratories = () => {
  return { type: INGREDIENT_ACTIONS.GET_LABORATORIES };
};

const getLaboratoriesSuccess = laboratories => {
  return { type: INGREDIENT_ACTIONS.GET_LABORATORIES_SUCCESS, laboratories };
};

const getLaboratoriesError = message => {
  return { type: INGREDIENT_ACTIONS.GET_LABORATORIES_ERROR, message };
};

export const fetchLaboratories = () => {
  return dispatch => {
    dispatch(getLaboratories());
    ingredientService
      .getLaboratories()
      .then(response => dispatch(getLaboratoriesSuccess(response)))
      .catch(() =>
        dispatch(getLaboratoriesError("Fetching laboratories failed"))
      );
  };
};

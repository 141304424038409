import * as recipeConstants from "@common/constants/recipeConstants";
import numeral from "numeral";
import get from "lodash/get";

export const normalizeNumber = (preDecimalDigits, postDecimalDigits) => {
  return value => {
    if (value < 0) {
      return 0;
    }

    let numbers = value.split(".");
    let preDecimal = numbers[0];
    let postDecimal = numbers[1] ? numbers[1] : [];

    if (
      preDecimal.length > preDecimalDigits ||
      postDecimal.length > postDecimalDigits
    ) {
      return;
    }

    return value && Math.abs(parseFloat(numeral(value).format("0.00")));
  };
};

export const getFermentables = mashStage =>
  !mashStage || !mashStage.ingredient_additions
    ? []
    : mashStage.ingredient_additions.filter(
        ing => ing.ingredient_type === recipeConstants.ING_FERMENTABLE
      );

// only take first step for now, because most fermentables are added here
export const getMashingSteps = recipe =>
  recipe.mashing.length ? recipe.mashing[0].steps : [];

export const getBoilingSteps = recipe =>
  (recipe.boiling[0].steps.length &&
    recipe.boiling[0].steps[0].ingredient_additions) ||
  [];

// Returns all the steps belonging to fermentation stages, ingredients included.
export const getFermentationSteps = recipe => {
  const steps = [];
  const stepIngredients = {};
  const ingredients = recipe.while_fermenting.hops.concat(
    recipe.while_fermenting.other_ingredients
  );

  for (let i = 0; i < ingredients.length; i++) {
    const [stage, step] = ingredients[i].addition_step.split("-");
    if (!stepIngredients[stage]) stepIngredients[stage] = [];
    if (!stepIngredients[stage][step])
      stepIngredients[stage][step] = [ingredients[i]];
    else stepIngredients[stage][step].push(ingredients[i]);
  }

  recipe.fermenting.forEach(stage => {
    if (stage.yeast.length) {
      // Check if there already is an ingredient in the first step of the stage
      // if not creaete empty stage and step and assign yeast
      if (!stepIngredients[stage.order]) {
        stepIngredients[stage.order] = [];
        stepIngredients[stage.order][0] = [stage.yeast[0]];
      } else {
        if (stepIngredients[stage.order][0]) {
          stepIngredients[stage.order][0].unshift(stage.yeast[0]);
        } else {
          stepIngredients[stage.order][0] = [stage.yeast[0]];
        }
      }
    }
  });

  recipe.fermenting
    .sort((x, y) => x.order - y.order)
    .forEach((stage, stageIndex) => {
      stage.steps.forEach((step, stepIndex) => {
        step.stageIndex = stepIndex;
        step.pressure_relief = stage.pressure_relief;
        step.stageName =
          recipeConstants.FERMENTATION_STAGE_TYPES[
            stage.fermentation_stage_type
          ];
        step.stageStepsAmount = stage.steps.length;
        step.ingredients = stepIngredients[stageIndex]
          ? stepIngredients[stageIndex][stepIndex]
          : null;

        steps.push(step);
      });
    });
  return steps;
};

export const removeNullIngredients = originalRecipe => {
  const recipe = JSON.parse(JSON.stringify(originalRecipe));
  recipe.mashing.forEach(
    stage =>
      (stage.ingredient_additions = stage.ingredient_additions.filter(
        ingredient => ingredient.ingredient_id
      ))
  );
  recipe.boiling[0].hops = recipe.boiling[0].hops.filter(
    hop => hop.ingredient_id
  );
  recipe.boiling[0].other_ingredients = recipe.boiling[0].other_ingredients.filter(
    hop => hop.ingredient_id
  );
  recipe.fermenting.forEach(stage => {
    if (stage.yeast && stage.yeast[0] && !stage.yeast[0].ingredient_id)
      stage.yeast = [];
  });
  recipe.while_fermenting.hops = recipe.while_fermenting.hops.filter(
    hop => hop.ingredient_id
  );
  recipe.while_fermenting.other_ingredients = recipe.while_fermenting.other_ingredients.filter(
    hop => hop.ingredient_id
  );

  const deepNormalizeEmptyToNull = obj => {
    for (let prop in obj) {
      if (obj[prop] === Object(obj[prop])) deepNormalizeEmptyToNull(obj[prop]);
      else obj[prop] = obj[prop] === "" ? null : obj[prop];
    }
  };
  deepNormalizeEmptyToNull(recipe);
  recipe.public_note === null ? (recipe.public_note = "") : null;
  recipe.private_note === null ? (recipe.private_note = "") : null;
  return recipe;
};

export const fermentable = () => ({
  ingredient_id: null,
  ingredient_name: null,
  ingredient_type: "FERM",
  amount: 0,
  duration: 0,
  srm: null
});

export const yeast = () => ({
  ingredient_name: null,
  ingredient_id: null,
  ingredient_type: "YEAST",
  amount: 0
});

const hasFermentables = ingredients =>
  !!ingredients.length &&
  !!ingredients.filter(ing => ing.ingredient_type === "FERM").length;

export const validateIngredients = originalRecipe => {
  const recipe = JSON.parse(JSON.stringify(originalRecipe));

  // Ensure 1 fermentable at mashing stages;
  recipe.mashing.forEach(stage => {
    if (!hasFermentables(stage.ingredient_additions))
      stage.ingredient_additions.push(fermentable());
  });

  // Ensure yeast at every fermentation stage where fermentation_stage_type === "PRIM"
  recipe.fermenting.forEach(stage => {
    if (stage.fermentation_stage_type === "PRIM" && !stage.yeast.length)
      stage.yeast.push(yeast());
  });

  return recipe;
};

export const createInputStep = value => {
  // eslint-disable-next-line no-unused-vars
  const [_, after] = String(value).split(".");
  const afterLength = (after && after.length) || 0;
  let step = "1";
  if (afterLength != 0) {
    const padding = "".padStart(afterLength - 1, 0);
    step = "0." + padding + "1";
  }

  return {
    step
  };
};

export const countFermentationSteps = recipe => {
  const fermenting = get(recipe, "values.fermenting", null);
  const while_fermenting = get(recipe, "values.while_fermenting", null);
  let count = 0;
  if (fermenting) {
    fermenting.forEach(stage => {
      count += get(stage, "yeast.length", 0) + get(stage, "steps.length", 0);
    });
  }

  if (while_fermenting) {
    count +=
      get(while_fermenting, "other_ingredients.length", 0) +
      get(while_fermenting, "hops.length", 0);
  }

  return count;
};

export const countMashStages = recipe => {
  return recipe.values.mashing && recipe.values.mashing.length;
};

import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { toFixed } from "@common/util/format";
/*eslint-disable */

const BrewWater = ({ batchSize, preMixWater, totalWater, kettleWater }) => {
  const batchSizeDescription =
    "Currently the MiniBrew Portal only allows you to create 5.5L batches.";
  const totalWaterDescription =
    "This is the total amount of drinkable water that will be added to the beer during mashing preparation, and brewers will need for brewing.";
  const kettleWaterDescription =
    "This is the amount of water that will be added to the Keg during mash preparation. It is equal to the Total Water minus the Pre-Mix Water.";
  const preMixWaterDescription =
    "This is the amount of water that will be mixed into the dry grains, it is equal to 1 L per 1.6 kg of grains.";
  const popOver = content => (
    <Popover id="popover-trigger-hover-focus">{content}</Popover>
  );
  const Trigger = ({ children, content }) => (
    <OverlayTrigger
      trigger={["hover", "focus", "click"]}
      placement="bottom"
      overlay={popOver(content)}
    >
      {children}
    </OverlayTrigger>
  );
  return (
    <div className="minibrew-container">
      <h2
        style={{
          fontWeight: "normal",
          fontFamily: "Gotham-Bold",
          marginTop: "3px"
        }}
      >
        Brew water
      </h2>

      <div className="row">
        <div className="col-md-6 col-sm-12">
          <p style={{ fontWeight: "normal", fontFamily: "Gotham-Book" }}>
            These values are calculated automatically based on the fermentables
            you include in your mash stage(s).
          </p>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="col-md-8 col-xs-6">
            <Trigger content={batchSizeDescription}>
              <p>
                Batch Size <span className="ion-help-circled" />
              </p>
            </Trigger>
            <Trigger content={kettleWaterDescription}>
              <p>
                Kettle Water Addition <span className="ion-help-circled" />
              </p>
            </Trigger>
            <Trigger content={preMixWaterDescription}>
              <p>
                Pre-Mix Water Addition <span className="ion-help-circled" />
              </p>
            </Trigger>
            <Trigger content={totalWaterDescription}>
              <p>
                Total water <span className="ion-help-circled" />
              </p>
            </Trigger>
          </div>
          <div className="col-md-4 col-xs-6">
            <p>{toFixed(batchSize - 0.5)} L</p>
            <p>{toFixed(kettleWater)} L</p>
            <p>{toFixed(preMixWater)} L</p>
            <p>{toFixed(totalWater)} L</p>
          </div>
        </div>
      </div>
    </div>
  );
};
/*eslint-enable */
export default BrewWater;

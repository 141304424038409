import React from "react";
import { Modal, Button } from "react-bootstrap";

const SupportAcidModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center" }}>
          Requires Maintenance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontFamily: "Gotham-Book" }}>
          Your MiniBrew requires a deep clean procedure before its next brew.
          Please contact our customer support team as soon as possible to
          arrange maintenance.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
        <a
          href="https://minibrew.zendesk.com/hc/en-us/requests/new"
          target="_blank"
          className="btn btn-primary"
        >
          Go
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default SupportAcidModal;

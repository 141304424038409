import { INGREDIENT_ACTIONS as type } from "@common/constants/actionTypes";

const initialState = {
  hops: [],
  fermentables: [],
  yeasts: [],
  adjuncts: [],
  laboratories: [],
  isFetching: [],
  error: false,
  errorMessage: ""
};

export default function ingredientReducer(state = initialState, action) {
  switch (action.type) {
    case type.GET_HOP:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: [...state.isFetching, "Hop"],
        error: false
      });
    case type.GET_HOP_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: state.isFetching.filter(item => item !== "Hop"),
        hops: action.hops
      });
    case type.GET_HOP_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: state.isFetching.filter(item => item !== "Hop"),
        error: true
      });
    case type.GET_FERMENTABLE:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: [...state.isFetching, "Ferm"],
        error: false
      });
    case type.GET_FERMENTABLE_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: state.isFetching.filter(item => item !== "Ferm"),
        fermentables: action.fermentables
      });
    case type.GET_FERMENTABLE_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: state.isFetching.filter(item => item !== "Ferm"),
        error: true
      });
    case type.GET_YEAST:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: [...state.isFetching, "Yeast"],
        error: false
      });
    case type.GET_YEAST_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: state.isFetching.filter(item => item !== "Yeast"),
        yeasts: action.yeasts
      });
    case type.GET_YEAST_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: state.isFetching.filter(item => item !== "Yeast"),
        error: true
      });
    case type.GET_ADJUNCT:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: [...state.isFetching, "Adjunct"],
        error: false
      });
    case type.GET_ADJUNCT_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: state.isFetching.filter(item => item !== "Adjunct"),
        adjuncts: action.adjuncts
      });
    case type.GET_ADJUNCT_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: state.isFetching.filter(item => item !== "Adjunct"),
        error: true
      });
    case type.GET_LABORATORIES:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: [...state.isFetching, "Lab"],
        error: false
      });
    case type.GET_LABORATORIES_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: state.isFetching.filter(item => item !== "Lab"),
        laboratories: action.laboratories
      });
    case type.GET_LABORATORIES_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: state.isFetching.filter(item => item !== "Lab"),
        error: true
      });
    default:
      return state;
  }
}

export const ACCESS_TYPES = {
  BP_CRAFT: 0,
  BP_PRO: 1,
  BP_PARTNER: 2,
  BP_RESEARCHER: 3
};

export const ALLOWED_TO_ACCESS = [
  ACCESS_TYPES.BP_PRO,
  ACCESS_TYPES.BP_PARTNER,
  ACCESS_TYPES.BP_RESEARCHER
];

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { Button, Row, Col, ControlLabel } from "react-bootstrap";

import CustomField from "../../../CustomField/CustomField";
import { getSteps } from "../FermUtils";
import TypeAhead from "../../TypeAhead/TypeAhead";
import { INGREDIENT_ENDPOINTS as ENDPOINTS } from "@common/constants/recipeConstants";
import { normalizeNumber } from "../../../../../../util/recipeHelper";

const normalizeAmount = normalizeNumber(3, 2);
const normalizeDuration = normalizeNumber(2, 1);
const INGREDIENT_TYPES = {
  FERM: "Fermentable",
  ADJ: "Adjunct"
};

class FermIngredients extends Component {
  componentWillUnmount() {
    const { fields } = this.props;
    fields.removeAll();
  }

  resetIngredient(index) {
    const { change } = this.props;
    change(`while_fermenting.other_ingredients[${index}].ingredient_id`, null);
    change(
      `while_fermenting.other_ingredients[${index}].ingredient_name`,
      null
    );
  }

  render() {
    const { fields, steps, change, disableAdd } = this.props;

    return (
      <div style={{ marginTop: "20px" }}>
        <h3>Other ingredients</h3>
        <Button
          type="button"
          onClick={() =>
            fields.push({
              ingredient_id: null,
              ingredient_name: null,
              ingredient_type: "FERM",
              addition_step: steps.length ? `${steps[0].stageIndex}-0` : null,
              duration: null,
              amount: null
            })
          }
          className="AddButton"
          disabled={disableAdd}
        >
          <i className="icon ion-plus" /> INGREDIENT
        </Button>

        {!!fields.length && (
          <div>
            {fields.map((ingredient, index) => (
              <Row key={index} className="list-item">
                <Col sm={2}>
                  {!index && <ControlLabel>Type</ControlLabel>}
                  <div>
                    <Field
                      name={`${ingredient}.ingredient_type`}
                      component="select"
                      className="form-control"
                      onChange={() => this.resetIngredient(index)}
                    >
                      {Object.keys(INGREDIENT_TYPES).map(key => (
                        <option value={key} key={key}>
                          {INGREDIENT_TYPES[key]}
                        </option>
                      ))}
                    </Field>
                  </div>
                </Col>
                <Field
                  name={`${ingredient}.ingredient_name`}
                  component={TypeAhead}
                  forceValidation
                  label="Name"
                  rowIndex={index}
                  change={change}
                  location={`while_fermenting.other_ingredients[${index}]`}
                  endpoint={`/${ENDPOINTS[fields.get(index).ingredient_type]}/`}
                  attrMap={{
                    id: "ingredient_id",
                    dry_yield: "ingredient_dry_yield",
                    moisture: "ingredient_moisture"
                  }}
                />
                <Field
                  name={`${ingredient}.amount`}
                  type="number"
                  component={CustomField}
                  label="Grams"
                  rowIndex={index}
                  normalize={normalizeAmount}
                  step="0.01"
                />
                <Col sm={3}>
                  {!index && <ControlLabel>When?</ControlLabel>}
                  <div>
                    <Field
                      name={`${ingredient}.addition_step`}
                      component="select"
                      className="form-control"
                    >
                      {(steps.length &&
                        steps.map((step, index) => (
                          <option
                            value={`${step.stageIndex}-${step.index}`}
                            key={index}
                          >
                            {`Stage ${step.stageIndex + 1} (${
                              step.stageLabel
                            }) step ${step.index + 1}`}
                          </option>
                        ))) || <option>No secondary stages</option>}
                    </Field>
                  </div>
                </Col>
                <Field
                  name={`${ingredient}.duration`}
                  type="number"
                  component={CustomField}
                  label="Days"
                  rowIndex={index}
                  normalize={normalizeDuration}
                  step="0.1"
                />
                <Col sm={1}>
                  <Button
                    className="remove-btn"
                    style={{
                      marginTop: index ? "4px" : "31px"
                    }}
                    type="button"
                    title="Remove ingredient"
                    onClick={() => fields.remove(index)}
                  >
                    <i className="icon ion-close" />
                  </Button>
                </Col>
              </Row>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  steps: getSteps(state)
});

export default connect(mapStateToProps)(FermIngredients);

import React from "react";

import Titlebar from "@common/components/TitleBar/TitleBar";
import "./dashboard-page.scss";

const DashboardPage = () => {
  return (
    <div>
      <Titlebar title={"DASHBOARD"} subtitle={"Overview"} />
      <div className="container-fluid dashboard">
        <div className="row">
          <div className="dashboard__brews col-xs-12 col-sm-8" />
          <div className="dashboard__sales col-xs-12 col-sm-4" />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import RouteAnalytics from "@common/components/RouteAnalytics/RouteAnalytics";
import App from "./App";

// Root is used for providing the rest of the application with the redux store
// it also adds the router history to the redux store
export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <RouteAnalytics>
            <App />
          </RouteAnalytics>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

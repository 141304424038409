import React, { useRef, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts";
import darktheme from "./dark-theme";

const Chart = ({ option, active, open }) => {
  const echarts_react = useRef(null);
  echarts.registerTheme("active", darktheme);

  useEffect(() => {
    let echarts_instance = echarts_react.current.getEchartsInstance();

    window.onresize = () => {
      if (echarts_instance) {
        echarts_instance.resize();
      }
    };

    return () => {
      window.onresize = null;
    };
  }, [active]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        let echarts_instance = echarts_react.current.getEchartsInstance();
        if (echarts_instance) {
          echarts_instance.resize();
        }
      }, 300);
    }
  }, [open]);

  // When mouseover don't point the axisPointer to latest log
  const [manualAxisPointer, setManualAxisPointer] = useState(false);
  const onEvents = {
    mouseEnter: () => {
      setManualAxisPointer(true);
    },
    mouseLeave: () => {
      setManualAxisPointer(false);
    }
  };
  if (manualAxisPointer) {
    delete option.xAxis[0].axisPointer.value;
  }

  return (
    <div onMouseEnter={onEvents.mouseEnter} onMouseLeave={onEvents.mouseLeave}>
      <ReactEcharts
        ref={echarts_react}
        option={option ? option : {}}
        style={{ height: 400 }}
        theme={active ? "active" : null}
      />
    </div>
  );
};

export default Chart;

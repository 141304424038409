// source: notification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

var command_pb = require("./command_pb.js");
goog.object.extend(proto, command_pb);
var status_pb = require("./status_pb.js");
goog.object.extend(proto, status_pb);
goog.exportSymbol("proto.Notification", null, global);
goog.exportSymbol("proto.Notification.ErrorType", null, global);
goog.exportSymbol("proto.NotificationResp", null, global);
goog.exportSymbol("proto.NotificationType", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Notification = function(opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.Notification.repeatedFields_,
    null
  );
};
goog.inherits(proto.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Notification.displayName = "proto.Notification";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NotificationResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NotificationResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NotificationResp.displayName = "proto.NotificationResp";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Notification.repeatedFields_ = [9];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Notification.prototype.toObject = function(opt_includeInstance) {
    return proto.Notification.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Notification} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Notification.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        seq: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        type: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        session: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        device: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
        command: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        commandSeq: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
        error: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
        message: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
        processSummaryList: jspb.Message.toObjectList(
          msg.getProcessSummaryList(),
          status_pb.ProcessSummaryItem.toObject,
          includeInstance
        )
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Notification}
 */
proto.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Notification();
  return proto.Notification.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Notification}
 */
proto.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeq(value);
        break;
      case 2:
        var value = /** @type {!proto.NotificationType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSession(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDevice(value);
        break;
      case 5:
        var value = /** @type {!proto.CommandType} */ (reader.readEnum());
        msg.setCommand(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCommandSeq(value);
        break;
      case 7:
        var value = /** @type {!proto.Notification.ErrorType} */ (reader.readEnum());
        msg.setError(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setMessage(value);
        break;
      case 9:
        var value = new status_pb.ProcessSummaryItem();
        reader.readMessage(
          value,
          status_pb.ProcessSummaryItem.deserializeBinaryFromReader
        );
        msg.addProcessSummary(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = /** @type {!proto.NotificationType} */ (jspb.Message.getField(
    message,
    2
  ));
  if (f != null) {
    writer.writeEnum(2, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(3, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(4, f);
  }
  f = /** @type {!proto.CommandType} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(5, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(6, f);
  }
  f = /** @type {!proto.Notification.ErrorType} */ (jspb.Message.getField(
    message,
    7
  ));
  if (f != null) {
    writer.writeEnum(7, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(8, f);
  }
  f = message.getProcessSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      status_pb.ProcessSummaryItem.serializeBinaryToWriter
    );
  }
};

/**
 * @enum {number}
 */
proto.Notification.ErrorType = {
  NO_ERROR: 0,
  COMMAND_FAILED: 1,
  CONNECTION_LOST: 2,
  SYSTEM_ERROR: 3,
  FW_UPDATE_ERROR: 4
};

/**
 * optional uint32 seq = 1;
 * @return {number}
 */
proto.Notification.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional NotificationType type = 2;
 * @return {!proto.NotificationType}
 */
proto.Notification.prototype.getType = function() {
  return /** @type {!proto.NotificationType} */ (jspb.Message.getFieldWithDefault(
    this,
    2,
    0
  ));
};

/**
 * @param {!proto.NotificationType} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setType = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearType = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional uint32 session = 3;
 * @return {number}
 */
proto.Notification.prototype.getSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setSession = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearSession = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasSession = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string device = 4;
 * @return {string}
 */
proto.Notification.prototype.getDevice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setDevice = function(value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearDevice = function() {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional CommandType command = 5;
 * @return {!proto.CommandType}
 */
proto.Notification.prototype.getCommand = function() {
  return /** @type {!proto.CommandType} */ (jspb.Message.getFieldWithDefault(
    this,
    5,
    0
  ));
};

/**
 * @param {!proto.CommandType} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setCommand = function(value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearCommand = function() {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasCommand = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional uint32 command_seq = 6;
 * @return {number}
 */
proto.Notification.prototype.getCommandSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setCommandSeq = function(value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearCommandSeq = function() {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasCommandSeq = function() {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional ErrorType error = 7;
 * @return {!proto.Notification.ErrorType}
 */
proto.Notification.prototype.getError = function() {
  return /** @type {!proto.Notification.ErrorType} */ (jspb.Message.getFieldWithDefault(
    this,
    7,
    0
  ));
};

/**
 * @param {!proto.Notification.ErrorType} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setError = function(value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearError = function() {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasError = function() {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional string message = 8;
 * @return {string}
 */
proto.Notification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setMessage = function(value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearMessage = function() {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Notification.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * repeated ProcessSummaryItem process_summary = 9;
 * @return {!Array<!proto.ProcessSummaryItem>}
 */
proto.Notification.prototype.getProcessSummaryList = function() {
  return /** @type{!Array<!proto.ProcessSummaryItem>} */ jspb.Message.getRepeatedWrapperField(
    this,
    status_pb.ProcessSummaryItem,
    9
  );
};

/**
 * @param {!Array<!proto.ProcessSummaryItem>} value
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.setProcessSummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};

/**
 * @param {!proto.ProcessSummaryItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProcessSummaryItem}
 */
proto.Notification.prototype.addProcessSummary = function(
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    9,
    opt_value,
    proto.ProcessSummaryItem,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Notification} returns this
 */
proto.Notification.prototype.clearProcessSummaryList = function() {
  return this.setProcessSummaryList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.NotificationResp.prototype.toObject = function(opt_includeInstance) {
    return proto.NotificationResp.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.NotificationResp} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.NotificationResp.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        seq: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NotificationResp}
 */
proto.NotificationResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NotificationResp();
  return proto.NotificationResp.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NotificationResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NotificationResp}
 */
proto.NotificationResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeq(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NotificationResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NotificationResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NotificationResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NotificationResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
};

/**
 * optional uint32 seq = 1;
 * @return {number}
 */
proto.NotificationResp.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.NotificationResp} returns this
 */
proto.NotificationResp.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.NotificationResp} returns this
 */
proto.NotificationResp.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NotificationResp.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * @enum {number}
 */
proto.NotificationType = {
  NOT_PROCESS_STATE_CHANGED: 0,
  NOT_COMMAND_EXECUTED: 1,
  NOT_ERROR: 2,
  NOT_USER_ACTION_REQUIRED: 3,
  NOT_INGREDIENT_ADDED: 4,
  NOT_DEVICE_CONNECTED: 5,
  NOT_DEVICE_DISCONNECTED: 6,
  NOT_PROCESS_ESTIMATION_CHANGED: 7,
  NOT_SESSION_STATE_CHANGED: 8,
  NOT_UPDATE_COMPLETE: 9
};

goog.object.extend(exports, proto);

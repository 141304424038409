// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
import * as types from '@common/constants/actionTypes';

const initialState = {
  jwtToken: localStorage.getItem('token'),
  isAuthenticating: false,
  errorMessage: ''
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTHENTICATE_USER:
      return Object.assign({}, state, {
        errorMessage: '',
        isAuthenticating: true
      });
    case types.AUTHENTICATE_USER_SUCCESS:
      return Object.assign({}, state, {
        errorMessage: '',
        isAuthenticating: false,
        jwtToken: action.token
      });
    case types.AUTHENTICATE_USER_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isAuthenticating: false,
      });
    case types.SIGN_OUT:
      return Object.assign({}, state, {
        jwtToken: '',
      });
    default:
      return state;
  }
}
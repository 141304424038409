// source: command.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require("google-protobuf");
var goog = jspb;
var global = Function("return this")();

var recipe_pb = require("./recipe_pb.js");
goog.object.extend(proto, recipe_pb);
var status_pb = require("./status_pb.js");
goog.object.extend(proto, status_pb);
var useraction_pb = require("./useraction_pb.js");
goog.object.extend(proto, useraction_pb);
goog.exportSymbol("proto.Command", null, global);
goog.exportSymbol("proto.CommandResp", null, global);
goog.exportSymbol("proto.CommandResp.ErrorType", null, global);
goog.exportSymbol("proto.CommandType", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Command.displayName = "proto.Command";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CommandResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CommandResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CommandResp.displayName = "proto.CommandResp";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.Command.prototype.toObject = function(opt_includeInstance) {
    return proto.Command.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.Command} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.Command.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        seq: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        type: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
        processtype:
          (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        session: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
        recipe:
          (f = msg.getRecipe()) &&
          recipe_pb.BrewRecipe.toObject(includeInstance, f),
        fermentationTimeSec:
          (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
        loggingFrequency:
          (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
        firmwareBinarySizeBytes:
          (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
        resumeAction:
          (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
        firmwareBinaryUrl:
          (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
        firmwareBinaryToken:
          (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
        minimumEspVersion:
          (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
        minimumKegIoVersion:
          (f = jspb.Message.getField(msg, 14)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Command}
 */
proto.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Command();
  return proto.Command.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Command}
 */
proto.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeq(value);
        break;
      case 2:
        var value = /** @type {!proto.CommandType} */ (reader.readEnum());
        msg.setType(value);
        break;
      case 3:
        var value = /** @type {!proto.ProcessType} */ (reader.readEnum());
        msg.setProcesstype(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSession(value);
        break;
      case 4:
        var value = new recipe_pb.BrewRecipe();
        reader.readMessage(
          value,
          recipe_pb.BrewRecipe.deserializeBinaryFromReader
        );
        msg.setRecipe(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setFermentationTimeSec(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setLoggingFrequency(value);
        break;
      case 9:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setFirmwareBinarySizeBytes(value);
        break;
      case 10:
        var value = /** @type {!proto.UserAction} */ (reader.readEnum());
        msg.setResumeAction(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirmwareBinaryUrl(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setFirmwareBinaryToken(value);
        break;
      case 13:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setMinimumEspVersion(value);
        break;
      case 14:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setMinimumKegIoVersion(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = /** @type {!proto.CommandType} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(2, f);
  }
  f = /** @type {!proto.ProcessType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(3, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint32(8, f);
  }
  f = message.getRecipe();
  if (f != null) {
    writer.writeMessage(4, f, recipe_pb.BrewRecipe.serializeBinaryToWriter);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(6, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint32(7, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(9, f);
  }
  f = /** @type {!proto.UserAction} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeEnum(10, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(11, f);
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(12, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeUint32(13, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeUint32(14, f);
  }
};

/**
 * optional uint32 seq = 1;
 * @return {number}
 */
proto.Command.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional CommandType type = 2;
 * @return {!proto.CommandType}
 */
proto.Command.prototype.getType = function() {
  return /** @type {!proto.CommandType} */ (jspb.Message.getFieldWithDefault(
    this,
    2,
    0
  ));
};

/**
 * @param {!proto.CommandType} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setType = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearType = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional ProcessType processType = 3;
 * @return {!proto.ProcessType}
 */
proto.Command.prototype.getProcesstype = function() {
  return /** @type {!proto.ProcessType} */ (jspb.Message.getFieldWithDefault(
    this,
    3,
    0
  ));
};

/**
 * @param {!proto.ProcessType} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setProcesstype = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearProcesstype = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasProcesstype = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional uint32 session = 8;
 * @return {number}
 */
proto.Command.prototype.getSession = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setSession = function(value) {
  return jspb.Message.setField(this, 8, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearSession = function() {
  return jspb.Message.setField(this, 8, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasSession = function() {
  return jspb.Message.getField(this, 8) != null;
};

/**
 * optional BrewRecipe recipe = 4;
 * @return {?proto.BrewRecipe}
 */
proto.Command.prototype.getRecipe = function() {
  return /** @type{?proto.BrewRecipe} */ jspb.Message.getWrapperField(
    this,
    recipe_pb.BrewRecipe,
    4
  );
};

/**
 * @param {?proto.BrewRecipe|undefined} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setRecipe = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearRecipe = function() {
  return this.setRecipe(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasRecipe = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional uint32 fermentation_time_sec = 6;
 * @return {number}
 */
proto.Command.prototype.getFermentationTimeSec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setFermentationTimeSec = function(value) {
  return jspb.Message.setField(this, 6, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearFermentationTimeSec = function() {
  return jspb.Message.setField(this, 6, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasFermentationTimeSec = function() {
  return jspb.Message.getField(this, 6) != null;
};

/**
 * optional uint32 logging_frequency = 7;
 * @return {number}
 */
proto.Command.prototype.getLoggingFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setLoggingFrequency = function(value) {
  return jspb.Message.setField(this, 7, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearLoggingFrequency = function() {
  return jspb.Message.setField(this, 7, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasLoggingFrequency = function() {
  return jspb.Message.getField(this, 7) != null;
};

/**
 * optional uint32 firmware_binary_size_bytes = 9;
 * @return {number}
 */
proto.Command.prototype.getFirmwareBinarySizeBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/**
 * @param {number} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setFirmwareBinarySizeBytes = function(value) {
  return jspb.Message.setField(this, 9, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearFirmwareBinarySizeBytes = function() {
  return jspb.Message.setField(this, 9, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasFirmwareBinarySizeBytes = function() {
  return jspb.Message.getField(this, 9) != null;
};

/**
 * optional UserAction resume_action = 10;
 * @return {!proto.UserAction}
 */
proto.Command.prototype.getResumeAction = function() {
  return /** @type {!proto.UserAction} */ (jspb.Message.getFieldWithDefault(
    this,
    10,
    0
  ));
};

/**
 * @param {!proto.UserAction} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setResumeAction = function(value) {
  return jspb.Message.setField(this, 10, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearResumeAction = function() {
  return jspb.Message.setField(this, 10, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasResumeAction = function() {
  return jspb.Message.getField(this, 10) != null;
};

/**
 * optional string firmware_binary_url = 11;
 * @return {string}
 */
proto.Command.prototype.getFirmwareBinaryUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};

/**
 * @param {string} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setFirmwareBinaryUrl = function(value) {
  return jspb.Message.setField(this, 11, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearFirmwareBinaryUrl = function() {
  return jspb.Message.setField(this, 11, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasFirmwareBinaryUrl = function() {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional string firmware_binary_token = 12;
 * @return {string}
 */
proto.Command.prototype.getFirmwareBinaryToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};

/**
 * @param {string} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setFirmwareBinaryToken = function(value) {
  return jspb.Message.setField(this, 12, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearFirmwareBinaryToken = function() {
  return jspb.Message.setField(this, 12, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasFirmwareBinaryToken = function() {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * optional uint32 minimum_esp_version = 13;
 * @return {number}
 */
proto.Command.prototype.getMinimumEspVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setMinimumEspVersion = function(value) {
  return jspb.Message.setField(this, 13, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearMinimumEspVersion = function() {
  return jspb.Message.setField(this, 13, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasMinimumEspVersion = function() {
  return jspb.Message.getField(this, 13) != null;
};

/**
 * optional uint32 minimum_keg_io_version = 14;
 * @return {number}
 */
proto.Command.prototype.getMinimumKegIoVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};

/**
 * @param {number} value
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.setMinimumKegIoVersion = function(value) {
  return jspb.Message.setField(this, 14, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.Command} returns this
 */
proto.Command.prototype.clearMinimumKegIoVersion = function() {
  return jspb.Message.setField(this, 14, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Command.prototype.hasMinimumKegIoVersion = function() {
  return jspb.Message.getField(this, 14) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CommandResp.prototype.toObject = function(opt_includeInstance) {
    return proto.CommandResp.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CommandResp} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.CommandResp.toObject = function(includeInstance, msg) {
    var f,
      obj = {
        seq: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
        timestampMs:
          (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
        success:
          (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
        errortype: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
        blockSize: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CommandResp}
 */
proto.CommandResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CommandResp();
  return proto.CommandResp.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CommandResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CommandResp}
 */
proto.CommandResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setSeq(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setTimestampMs(value);
        break;
      case 2:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      case 3:
        var value = /** @type {!proto.CommandResp.ErrorType} */ (reader.readEnum());
        msg.setErrortype(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setBlockSize(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CommandResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CommandResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CommandResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CommandResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(1, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(5, f);
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(2, f);
  }
  f = /** @type {!proto.CommandResp.ErrorType} */ (jspb.Message.getField(
    message,
    3
  ));
  if (f != null) {
    writer.writeEnum(3, f);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(4, f);
  }
};

/**
 * @enum {number}
 */
proto.CommandResp.ErrorType = {
  NO_ERROR: 0,
  UNSUPPORTED_COMMAND: 1,
  WRONG_STATE: 2,
  EXECUTION_FAILED: 3,
  DELIVERY_FAILED: 4
};

/**
 * optional uint32 seq = 1;
 * @return {number}
 */
proto.CommandResp.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 1, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 1, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CommandResp.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional uint64 timestamp_ms = 5;
 * @return {number}
 */
proto.CommandResp.prototype.getTimestampMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.setTimestampMs = function(value) {
  return jspb.Message.setField(this, 5, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.clearTimestampMs = function() {
  return jspb.Message.setField(this, 5, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CommandResp.prototype.hasTimestampMs = function() {
  return jspb.Message.getField(this, 5) != null;
};

/**
 * optional bool success = 2;
 * @return {boolean}
 */
proto.CommandResp.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(
    this,
    2,
    false
  ));
};

/**
 * @param {boolean} value
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.setSuccess = function(value) {
  return jspb.Message.setField(this, 2, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.clearSuccess = function() {
  return jspb.Message.setField(this, 2, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CommandResp.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional ErrorType errorType = 3;
 * @return {!proto.CommandResp.ErrorType}
 */
proto.CommandResp.prototype.getErrortype = function() {
  return /** @type {!proto.CommandResp.ErrorType} */ (jspb.Message.getFieldWithDefault(
    this,
    3,
    0
  ));
};

/**
 * @param {!proto.CommandResp.ErrorType} value
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.setErrortype = function(value) {
  return jspb.Message.setField(this, 3, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.clearErrortype = function() {
  return jspb.Message.setField(this, 3, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CommandResp.prototype.hasErrortype = function() {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional uint32 block_size = 4;
 * @return {number}
 */
proto.CommandResp.prototype.getBlockSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.setBlockSize = function(value) {
  return jspb.Message.setField(this, 4, value);
};

/**
 * Clears the field making it undefined.
 * @return {!proto.CommandResp} returns this
 */
proto.CommandResp.prototype.clearBlockSize = function() {
  return jspb.Message.setField(this, 4, undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CommandResp.prototype.hasBlockSize = function() {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * @enum {number}
 */
proto.CommandType = {
  CMD_START_PROCESS: 0,
  CMD_STOP_PROCESS: 1,
  CMD_PAUSE_PROCESS: 2,
  CMD_RESUME_PROCESS: 3,
  CMD_RESUME_FERMENTATION: 4,
  CMD_START_FREQUENT_LOGGING: 5,
  CMD_UPDATE_RECIPE: 6,
  CMD_UPDATE_ESP: 7,
  CMD_UPDATE_IO: 8
};

goog.object.extend(exports, proto);

import React, { useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Modal, Button, Alert } from "react-bootstrap";
import * as sessionService from "@brewery/services/sessionService";
import { CLEANING_TYPE } from "@common/constants/sessionConstants";
import mqttService from "../../../../monitor/mqttService";
import mqttUtil from "../../../../monitor/mqttUtil";

const CleaningModal = props => {
  const [waitingForNotification, setWaitingForNotification] = useState(false);
  const [error, setError] = useState(false);
  let resumingSequence = null;
  let intervalIdCleaning = null;

  const startCleaning = () => {
    const subscription = listenForCommandResponse();

    const cleanFunc =
      props.type == CLEANING_TYPE.NORMAL
        ? sessionService.startCleaningSession
        : sessionService.startAcidCleaningSession;

    cleanFunc(props.deviceUUID)
      .then(response => {
        if (response.error <= 0) {
          throw Error(response.message);
        } else {
          setWaitingForNotification(true);
          resumingSequence = response.seq;
          intervalIdCleaning = setTimeout(() => {
            setWaitingForNotification(false);
            props.handleClose();
          }, 8000);
        }
      })
      .catch(() => {
        setError(true);
        subscription.unsubscribe();
      });
  };

  const listenForCommandResponse = () => {
    const subscription = mqttService.notification$.subscribe(notification => {
      if (notification.commandSeq === resumingSequence) {
        if (mqttUtil.isExecuted(notification)) {
          clearInterval(intervalIdCleaning);
          setWaitingForNotification(false);
          props.handleClose();
          props.dispatch(push(`/brewery/monitor/${notification.session}`));
          subscription.unsubscribe();
        } else if (mqttUtil.hasError(notification)) {
          setError(true);
          setWaitingForNotification(false);
          subscription.unsubscribe();
        }
      }
    });
    return subscription;
  };
  const { show, handleClose } = props;
  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center" }}>
          {props.type == CLEANING_TYPE.ACID ? "Deep Clean" : "Cleaning"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span style={{ fontFamily: "Gotham-Book" }}>
          {props.type == CLEANING_TYPE.ACID
            ? "Your MiniBrew requires a deep clean procedure before brewing or storage. This procedure will improve performance and extend the life of your MiniBrew Base. The good news is that you can do this yourself!"
            : "This procedure will take you through the steps to clean your MiniBrew. If you don't need to clean your Mash Tun and other parts, you can skip through those steps to begin the automated clean-in-place process."}
        </span>
        {error && (
          <Alert bsStyle="danger">
            <span className="ion-alert-circled">
              {" "}
              Something happened, try again later
            </span>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        {waitingForNotification && (
          <span
            className="start-brew-loading__icon ion-load-c spin"
            style={{ float: "left", marginTop: "6px" }}
          >
            {" "}
            Starting session...
          </span>
        )}
        <Button onClick={() => handleClose()}>Close</Button>
        <Button onClick={startCleaning} bsStyle="primary">
          Start
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapStateToProps({ mqtt }) {
  return { mqtt };
}

export default connect(
  mapStateToProps,
  undefined
)(CleaningModal);

import { BEER_ACTIONS as type } from "@common/constants/actionTypes";

const initialState = {
  list: null,
  isFetching: false,
  error: false,
  errorMessage: ""
};

export default function beerReducer(state = initialState, action) {
  switch (action.type) {
    case type.GET_BEERS:
      return Object.assign({}, state, {
        errorMessage: "",
        isFetching: true,
        error: false
      });
    case type.GET_BEERS_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        errorMessage: "",
        isFetching: false,
        list: action.beers
      });
    case type.GET_BEERS_ERROR:
      return Object.assign({}, state, {
        errorMessage: action.message,
        isFetching: false,
        error: true
      });
    default:
      return state;
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as authActions from "@auth/actions/authenticationActions";
import PropTypes from "prop-types";
import { FormGroup, FormControl, Alert } from "react-bootstrap";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import { ALLOWED_TO_ACCESS } from "@common/constants/accessTypes";
import "./signin-page.scss";
import { routes } from "@common/constants/urls";

class SignInPage extends Component {
  state = {
    email: "",
    password: ""
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  authenticate = event => {
    event.preventDefault();

    const customHeaders = {
      "Content-Type": "application/json",
      Authorization: "TOKEN " + process.env.API_TOKEN,
      client: "Breweryportal",
      version: process.env.VERSION || 0
    };

    const credentials = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.authenticateUser(credentials, customHeaders);
  };

  render() {
    const { auth, userInfo } = this.props;

    let errorMessage = null;
    if (auth.errorMessage) {
      errorMessage = auth.errorMessage;
    } else if (
      userInfo.user &&
      !ALLOWED_TO_ACCESS.includes(!userInfo.user.access_type)
    ) {
      errorMessage = "No rights to access the breweryportal";
    }

    return (
      <main className="container-fluid content--auth center auth-bg">
        <meta
          name="viewport"
          content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0"
        />
        <section
          className="col-md-4 col-sm-7 col-xs-12 "
          style={{ textAlign: "center", marginTop: "7%" }}
        >
          <div className="mobile--content">
            <h1 className="signin__title">WELCOME</h1>
          </div>
          <h4 className="white">
            CREATE YOUR OWN RECIPES & ACCESS
            <br />
            COMMUNITY RECIPES
          </h4>

          <form onSubmit={this.authenticate}>
            <FormGroup className="signin__form">
              <FormControl
                name="email"
                type="email"
                value={this.state.email}
                placeholder="Enter your email"
                onChange={this.handleChange}
                required
              />
              <FormControl
                name="password"
                type="password"
                value={this.state.password}
                placeholder="Enter your password"
                onChange={this.handleChange}
                required
              />
              <FormControl.Feedback />
              <Link to={routes.resetPassword} className="signin__form__forgot">
                Forgot password?
              </Link>
              <ButtonWithLoad
                isLoading={auth.isAuthenticating}
                bsStyle="primary"
                type="submit"
                className="signin__form__button"
              >
                Sign in
              </ButtonWithLoad>
            </FormGroup>
          </form>
          {errorMessage && (
            <Alert
              bsStyle="danger"
              className="signin__form__clear signin__alert"
            >
              {errorMessage}
            </Alert>
          )}
        </section>
      </main>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticateUser: (credentials, headers) =>
      dispatch(authActions.authenticateUser(credentials, headers))
  };
}

function mapStateToProps({ auth, userInfo }) {
  return {
    auth,
    userInfo
  };
}

SignInPage.propTypes = {
  authenticateUser: PropTypes.func,
  auth: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInPage);

import React from "react";
import PropTypes from "prop-types";

import CollapsablePanel from "@common/components/Panel/Panel";
import GenericTable from "@common/components/Table/Table";
import * as recipeHelper from "../../../../util/recipeHelper";

const RecipeDetailMashing = ({ header, currentRecipe }) => (
  <CollapsablePanel header={header} initialOpen={true}>
    {currentRecipe.mashing.map((stage, index) => (
      <React.Fragment key={index}>
        {currentRecipe.mashing.length > 1 && <h2>Mashing Stage {index + 1}</h2>}
        <div
          style={
            currentRecipe.mashing.length > 1
              ? {
                  backgroundColor: "ghostwhite",
                  padding: "3px 10px",
                  borderRadius: "5px",
                  marginBottom: "15px"
                }
              : {}
          }
        >
          <h3 style={{ marginBottom: "0px" }}>Fermentables</h3>
          <GenericTable
            headers={["", "Amount (gr)", "SRM"]}
            data={recipeHelper.getFermentables(stage)}
            filter={["ingredient_name", "amount", "srm"]}
          />
          <h3 style={{ marginBottom: "0px" }}>Mashing scheme</h3>
          <GenericTable
            headers={["", "Start temp. in °C", "Duration in min."]}
            data={stage.steps}
            filter={["index", "temperature", "duration"]}
            key={stage.order}
          />
        </div>
      </React.Fragment>
    ))}
  </CollapsablePanel>
);

RecipeDetailMashing.propTypes = {
  currentRecipe: PropTypes.object,
  header: PropTypes.object
};

export default RecipeDetailMashing;

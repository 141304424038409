import React, { useEffect, useState } from "react";
import TitleBar from "@common/components/TitleBar/TitleBar";
import { searchSharedRecipe } from "@beers/services/recipeService";
import Spinner from "@common/components/Spinner/Spinner";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import { get } from "@common/services/request";
import { FormGroup, FormControl, Row } from "react-bootstrap";
import ContentWrapper from "@common/components/ContentWrapper/ContentWrapper";
import styled from "styled-components";
import RecipeListItemSquare from "./RecipeListItemSquare";

const Padding = styled.div`
  padding: 20px 0px;
`;
const loadStates = {
  loading: 0,
  success: 1,
  fail: 2
};

const paginationLoadStates = {
  idle: 0,
  loading: 1,
  fail: 3
};

let nextRecipes = null;

const SharedRecipes = () => {
  const [loadState, setLoadState] = useState(loadStates.loading);
  const [paginationLoadState, setPaginationLoadState] = useState(
    paginationLoadStates.idle
  );
  const [recipes, setRecipes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [retryCount, setRetryCount] = useState(0);
  const loadMore = () => {
    setPaginationLoadState(paginationLoadStates.loading);
    const url = nextRecipes.split(".io/")[1];
    get(url)
      .then(response => {
        nextRecipes = response.next;
        setRecipes(prevState => [...prevState, ...response.results]);
        setPaginationLoadState(paginationLoadStates.idle);
      })
      .catch(() => {
        setPaginationLoadState(paginationLoadStates.fail);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      function searchRecipe() {
        setLoadState(loadStates.loading);
        searchSharedRecipe(searchTerm)
          .then(response => {
            setRecipes(response.results);
            nextRecipes = response.next;
            setLoadState(loadStates.success);
          })
          .catch(() => {
            setLoadState(loadStates.fail);
          });
      }
      searchRecipe();
    }, 500);

    return () => timer && clearTimeout(timer);
  }, [searchTerm, retryCount]);

  const handleChange = e => {
    setSearchTerm(e.target.value);
  };

  const loadedAll = nextRecipes === null;
  const isLoading = paginationLoadState === paginationLoadStates.loading;
  return (
    <>
      <TitleBar title="COMMUNITY" subtitle="Recipes shared by the community" />
      <div className="recipe__searchbar">
        <div className="col-lg-8 col-lg-offset-2 col-md-12">
          <FormGroup
            controlId="signinForm"
            className="recipe__searchbar__input"
          >
            <FormControl
              name="searchbar"
              type="text"
              value={searchTerm}
              placeholder="Search for beer or beerstyle"
              onChange={handleChange}
              autoFocus={true}
            />
          </FormGroup>
        </div>
      </div>
      <ContentWrapper>
        <Padding>
          {loadState === loadStates.loading ? (
            <Spinner />
          ) : loadState === loadStates.success ? (
            <div className="flex flex-column items-center">
              {/* {recipes.map(recipe => (
                <RecipeListItem key={recipe.id} recipe={recipe} />
              ))} */}
              <Row style={{ width: "100%" }}>
                {recipes.map(recipe => (
                  <RecipeListItemSquare key={recipe.id} recipe={recipe} />
                ))}
              </Row>
              {recipes.length === 0 && <div>No recipes found</div>}
              <Padding>
                <ButtonWithLoad
                  isLoading={isLoading}
                  disabled={loadedAll}
                  onClick={loadMore}
                >
                  {loadedAll ? "No more recipes" : "Show more"}
                </ButtonWithLoad>
              </Padding>
            </div>
          ) : loadState === loadStates.fail ? (
            <div className="flex flex-column items-center">
              <span>Something went wrong</span>
              <ButtonWithLoad onClick={() => setRetryCount(prev => prev + 1)}>
                Retry
              </ButtonWithLoad>
            </div>
          ) : null}
        </Padding>
      </ContentWrapper>
    </>
  );
};

export default SharedRecipes;

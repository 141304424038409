import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import * as ingredientService from "@beers/services/ingredientService";

import "./custom-ingredient.scss";

class DeletionModal extends Component {
  state = {
    error: null,
    isSaving: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState({ error: null });
    }
  }
  handleDelete = (ingredient, type) => {
    // do delete
    const httpFunction = this.getFunction(type);
    this.setState({ error: null, isSaving: true });
    const setError = err => this.setState({ error: err, isSaving: false });
    const setSuccess = () => this.setState({ error: null, isSaving: false });
    httpFunction(ingredient.id)
      .then(() => {
        this.props.onHide();
        this.props.getAllIngredients();
        setSuccess();
      })
      .catch(setError);
  };

  getFunction = type => {
    switch (type) {
      case "Hop":
        return ingredientService.deleteHops;
      case "Fermentable":
        return ingredientService.deleteFermentable;
      case "Yeast":
        return ingredientService.deleteYeast;
      case "Adjunct":
        return ingredientService.deleteAdjunct;
    }
  };

  render() {
    const { show, onHide, ingredient, type } = this.props;

    return (
      <Modal show={show} className="custom-ingredient-modal" onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            ARE YOU SURE?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will not be able to select this ingredient again when creating or
          editing recipes.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Cancel</Button>
          <Button
            onClick={() => this.handleDelete(ingredient, type)}
            bsStyle="danger"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = ({ ingredients }) => {
  return { ingredients };
};

export default connect(
  mapStateToProps,
  null
)(DeletionModal);

import React from "react";
import PropTypes from "prop-types";
import { Pager } from "react-bootstrap";
import BrewHistoryItem from "./BrewHistoryItem";
import "./brew-history.scss";

/**
 * <BrewHistory sessions={sessions} onClick={function} />
 * @param {Array} this.props.sessions array of brewsession objects
 * @param {function} this.props.onClick function for handling click on a brew-history-item
 */
class BrewHistory extends React.Component {
  state = {
    currentPage: 0
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.sessions !== this.props.sessions) {
      this.setState({ currentPage: 0 });
    }
  }

  paginatedSessions = pageIndex => {
    if (pageIndex * 6 < this.props.sessions.length) {
      return this.props.sessions.slice(pageIndex * 6, pageIndex * 6 + 6);
    }
    return [];
  };

  incrementPage = () => {
    this.setState(prevState => {
      return {
        currentPage: prevState.currentPage + 1
      };
    });
  };

  decrementPage = () => {
    this.setState(prevState => {
      return {
        currentPage: prevState.currentPage - 1
      };
    });
  };

  nextDisabled = () => {
    return this.paginatedSessions(this.state.currentPage + 1).length === 0;
  };

  render() {
    const { sessions } = this.props;
    const { currentPage } = this.state;
    return (
      <div>
        <div className="brew-history">
          {sessions.length > 0 ? (
            this.paginatedSessions(currentPage).map((session, index) => (
              <BrewHistoryItem key={index} session={session} />
            ))
          ) : (
            <div className="brew-history__no-sessions">
              <img
                src={require("../../../assets/images/beer.svg")}
                style={{ width: "30%" }}
                alt=""
              />
              <h2>You didn't brew this recipe yet</h2>
              <p className="normal-text">
                View your history here once you have brewed this recipe
              </p>
            </div>
          )}
        </div>
        {sessions.length > 6 && (
          <Pager>
            <Pager.Item disabled={!currentPage} onClick={this.decrementPage}>
              Previous
            </Pager.Item>{" "}
            <Pager.Item
              disabled={this.nextDisabled()}
              onClick={this.incrementPage}
            >
              Next
            </Pager.Item>
          </Pager>
        )}
      </div>
    );
  }
}

BrewHistory.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func
};

export default BrewHistory;

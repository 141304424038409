import React, { Component } from "react";
import { Row, Well, ControlLabel } from "react-bootstrap";
import { Field, FieldArray } from "redux-form";

import Ingredients from "./Ingredients/Ingredients";
import Hops from "./Hops/Hops";
import CustomField from "../../CustomField/CustomField";
import {
  required,
  numberBetween
} from "../../../../../util/formValidationFunctions";
import { VALIDATION } from "@common/constants/recipeConstants";
import { normalizeNumber } from "../../../../../util/recipeHelper";
import ErrorList from "../ErrorList";
import { hasAccess, betaFeature } from "@common/util/access";
const HOP_FILTER = { standard: 0, bypass: 1 };

const normalizeBoilTime = normalizeNumber(3, 0);
const boilingTime = numberBetween(
  VALIDATION.MIN_BOIL_TIME,
  VALIDATION.MAX_BOIL_TIME
);

class Boiling extends Component {
  constructor(props) {
    super(props);
    const { other_ingredients, hops } = props.fields.get(0);
    this.state = {
      amount: other_ingredients.length + hops.length
    };
  }

  increaseAmount = () => this.setState({ amount: this.state.amount + 1 });
  decreaseAmount = () => this.setState({ amount: this.state.amount - 1 });
  addHop = fields => () => {
    this.increaseAmount();
    fields.push({
      ingredient_id: null,
      ingredient_name: null,
      ingredient_type: "HOP",
      temperature: null,
      duration: null,
      amount: null,
      alpha_acid: null
    });
  };
  addIngredient = fields => () => {
    this.increaseAmount();
    fields.push({
      ingredient_id: null,
      ingredient_name: null,
      ingredient_type: "FERM",
      amount: null,
      duration: null
    });
  };
  removeItem = (fields, index) => () => {
    this.decreaseAmount();
    fields.remove(index);
  };

  render() {
    const {
      fields,
      change,
      validationEnabled,
      meta: { error }
    } = this.props;
    return (
      <div className="minibrew-container" data-name="boiling">
        <h2>Boiling</h2>
        <p className="instruction">
          Each Hop addition and Other Ingredient addition in the boiling
          schedule represents a single carousel location. The MiniBrew currently
          has six carousel locations. Additions are time based, if you would
          like to add multiple hops of different types at the same time, please
          use the standard “Multiple Hops” ingredient (or a custom ingredient
          with more specific naming) for the name and attempt to calculate the
          average Alpha Acid for all types of hops included in that carousel
          location by weight. For whirl-pooling please set your hop addition’s
          duration to zero minutes.
        </p>
        {fields.map((stage, index) => (
          <Well key={index} className="Stage">
            <Row>
              <Field
                name={`${stage}.duration`}
                component={CustomField}
                label="Boil time (min)"
                placeholder="0"
                value={0}
                smSize={3}
                type="number"
                validate={
                  (validationEnabled && [required, boilingTime]) || null
                }
                normalize={normalizeBoilTime}
              />
              {hasAccess(betaFeature.hopFilter, this.props.user) && (
                <div className="col-md-3">
                  <ControlLabel>
                    <span>Hop Filter</span>
                  </ControlLabel>
                  <Field
                    name="hop_filter"
                    component="select"
                    label="Hop filter"
                    parse={value => Number(value)}
                    className="form-control"
                    smSize={3}
                  >
                    <option value={HOP_FILTER.standard}>Standard</option>
                    <option value={HOP_FILTER.bypass}>Bypass</option>
                  </Field>
                </div>
              )}
            </Row>
            <FieldArray
              name={`${stage}.hops`}
              component={Hops}
              change={change}
              stageIndex={index}
              amount={this.state.amount}
              addItem={this.addHop}
              removeItem={this.removeItem}
            />
            {/* {this.state.hopAmountError && (
              <Alert bsStyle="danger">No more than 60 gram hops total</Alert>
            )} */}
            <FieldArray
              name={`${stage}.other_ingredients`}
              component={Ingredients}
              change={change}
              rerenderOnEveryChange={true}
              amount={this.state.amount}
              addItem={this.addIngredient}
              removeItem={this.removeItem}
            />
          </Well>
        ))}
        <ErrorList error={error} />
      </div>
    );
  }
}

export default Boiling;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Table } from "react-bootstrap";
import TitleBar from "@common/components/TitleBar/TitleBar";
import Spinner from "@common/components/Spinner/Spinner";
import CustomIngredientModal from "./CustomIngredientModal";
import * as ingredientActions from "@beers/actions/ingredientActions";
import DeletionModal from "./DeletionModal";

class CustomIngredientList extends Component {
  state = {
    ingredients: [],
    showCustomIngredientModal: false,
    showDeletionModal: false,
    selectedIngredient: null,
    activeTabKey: 1,
    loading: true,
    callsInitiated: false,
    selectedType: null
  };

  componentDidMount() {
    this.getAllIngredients();
  }

  getAllIngredients = () => {
    // Can be easily solved with a backend fix, adding the ingredient type in the response
    this.props.fetchAdjuncts();
    this.props.fetchFermentables();
    this.props.fetchHops();
    this.props.fetchYeasts();
    this.props.fetchLaboratories();
    this.setState({ callsInitiated: true });
  };

  toggleModal = () => {
    if (this.state.showCustomIngredientModal) {
      this.setState({ selectedIngredient: null, activeTabKey: 1 });
    }
    this.setState(prevState => {
      return {
        showCustomIngredientModal: !prevState.showCustomIngredientModal
      };
    });
  };
  toggleDeletionModal = (ingredient, type) => {
    this.setState(prevState => {
      return {
        selectedType: type,
        selectedIngredient: ingredient,
        showDeletionModal: !prevState.showDeletionModal
      };
    });
  };

  toggleDeletionModal = (ingredient, type) => {
    this.setState(prevState => {
      return {
        selectedType: type,
        selectedIngredient: ingredient,
        showDeletionModal: !prevState.showDeletionModal
      };
    });
  };

  toggleModalWithIngredient = (ingredient, type) => {
    let newActiveTabKey = null;
    switch (type) {
      case "Hop":
        newActiveTabKey = 1;
        break;
      case "Fermentable":
        newActiveTabKey = 2;
        break;
      case "Yeast":
        newActiveTabKey = 3;
        break;
      case "Adjunct":
        newActiveTabKey = 4;
        break;
      default:
        newActiveTabKey = 1;
        break;
    }
    const newIngredient = { ...ingredient };
    newIngredient.type = type;

    this.setState({
      selectedIngredient: newIngredient,
      showCustomIngredientModal: true,
      activeTabKey: newActiveTabKey
    });
  };

  setActiveKey = key => {
    this.setState({
      activeTabKey: key
    });
  };

  render() {
    const {
      showCustomIngredientModal,
      activeTabKey,
      selectedIngredient,
      callsInitiated,
      showDeletionModal,
      selectedType
    } = this.state;

    let loading = this.props.ingredients.isFetching.length > 0;
    const deleteButton = (
      <span className="delete-ingredient">
        <p
          style={{
            fontWeight: 400,
            padding: 0,
            margin: 0,
            fontFamily: "Gotham-Book",
            height: "74%"
          }}
        >
          x
        </p>
      </span>
    );

    const equalWidth = { width: "25%" };
    const ingredientFilter = value => {
      if (!value.available && value.is_active) {
        return value;
      } else {
        return "";
      }
    };
    return (
      <div>
        <TitleBar title="MY INGREDIENTS">
          <Button
            bsStyle="primary"
            style={{ position: "absolute", right: "15px" }}
            onClick={this.toggleModal}
          >
            NEW INGREDIENT
          </Button>
        </TitleBar>
        <div className="col-xs-12 custom-ingredient-container">
          <div
            className="col-xs-12 col-md-8 col-md-offset-2"
            style={{ paddingTop: "20px" }}
          >
            {!loading && callsInitiated && (
              <Table className="ingredients-table" responsive>
                <thead>
                  <tr>
                    <th style={equalWidth}>Name</th>
                    <th style={equalWidth}>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.ingredients.hops.results
                    .filter(ingredientFilter)
                    .map(ing => (
                      <tr key={ing.id + ing.name}>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Hop")
                          }
                          style={equalWidth}
                        >
                          {ing.name}
                        </td>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Hop")
                          }
                          style={equalWidth}
                        >
                          Hop
                        </td>
                        <td style={{ width: "4%", paddingRight: "0px" }}>
                          <div
                            className="delete-ingredient"
                            onClick={() => this.toggleDeletionModal(ing, "Hop")}
                          >
                            {deleteButton}
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Hop")
                          }
                        />
                      </tr>
                    ))}
                  {this.props.ingredients.fermentables.results
                    .filter(ingredientFilter)
                    .map(ing => (
                      <tr key={ing.id + ing.name}>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Fermentable")
                          }
                          style={equalWidth}
                        >
                          {ing.name}
                        </td>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Fermentable")
                          }
                          style={equalWidth}
                        >
                          Fermentable
                        </td>
                        <td style={{ width: "4%" }}>
                          <div
                            className="delete-ingredient"
                            onClick={() =>
                              this.toggleDeletionModal(ing, "Fermentable")
                            }
                          >
                            {deleteButton}
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Fermentable")
                          }
                        />
                      </tr>
                    ))}
                  {this.props.ingredients.yeasts.results
                    .filter(ingredientFilter)
                    .map(ing => (
                      <tr key={ing.id + ing.name}>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Yeast")
                          }
                          style={equalWidth}
                        >
                          {ing.name}
                        </td>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Yeast")
                          }
                          style={equalWidth}
                        >
                          Yeast
                        </td>
                        <td style={{ width: "4%" }}>
                          <div
                            className="delete-ingredient"
                            onClick={() =>
                              this.toggleDeletionModal(ing, "Yeast")
                            }
                          >
                            {deleteButton}
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Yeast")
                          }
                        />
                      </tr>
                    ))}
                  {this.props.ingredients.adjuncts.results
                    .filter(ingredientFilter)
                    .map(ing => (
                      <tr key={ing.id + ing.name}>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Adjunct")
                          }
                          style={equalWidth}
                        >
                          {ing.name}
                        </td>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Adjunct")
                          }
                          style={equalWidth}
                        >
                          Adjunct
                        </td>
                        <td style={{ width: "5%" }}>
                          <div
                            className="delete-ingredient"
                            onClick={() =>
                              this.toggleDeletionModal(ing, "Adjunct")
                            }
                          >
                            {deleteButton}
                          </div>
                        </td>
                        <td
                          onClick={() =>
                            this.toggleModalWithIngredient(ing, "Adjunct")
                          }
                        />
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
            <div className="clearfix" />
          </div>
        </div>
        {loading && <Spinner className="center-spinner" />}
        <CustomIngredientModal
          show={showCustomIngredientModal}
          onHide={this.toggleModal}
          activeTabKey={activeTabKey}
          setActiveKey={this.setActiveKey}
          ingredient={selectedIngredient}
          getAllIngredients={this.getAllIngredients}
        />
        <DeletionModal
          show={showDeletionModal}
          onHide={this.toggleDeletionModal}
          ingredient={selectedIngredient}
          type={selectedType}
          getAllIngredients={this.getAllIngredients}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ ingredients }) => {
  return { ingredients };
};

const mapDispatchToProps = dispatch => ({
  fetchHops: () => dispatch(ingredientActions.fetchHops()),
  fetchFermentables: () => dispatch(ingredientActions.fetchFermentables()),
  fetchYeasts: () => dispatch(ingredientActions.fetchYeasts()),
  fetchAdjuncts: () => dispatch(ingredientActions.fetchAdjuncts()),
  fetchLaboratories: () => dispatch(ingredientActions.fetchLaboratories())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomIngredientList);

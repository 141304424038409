import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";

class ProfileDropdownToggle extends React.Component {
  state = {};

  handleClick = e => {
    e.preventDefault();
    this.props.onClick(e);
    analyticsService.track(ANALYTICS_TYPES.OPEN_MENU, {
      Menu: "Profile Menu",
      Screen: this.props.history.location.pathname
    });
  };

  render() {
    return <div onClick={this.handleClick}>{this.props.children}</div>;
  }
}

function mapStateToProps({ userInfo }) {
  return {
    userInfo
  };
}

ProfileDropdownToggle.propTypes = {
  userInfo: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.object
};
const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    undefined
  )
);
export default enhance(ProfileDropdownToggle);

import { get, patch, post, del } from "@common/services/request";
import { apiEndpoints as api } from "@common/constants/urls";
import {
  MACH_CONN_STATUS,
  MACH_STATES
} from "@common/constants/sessionConstants";

export function getDevices() {
  return get(api.devices);
}

export function getReadyToBrewDevices() {
  let promise = new Promise(resolve => {
    get(api.devices).then(response => {
      resolve(
        response.filter(
          device =>
            device.connection_status ===
              MACH_CONN_STATUS.MACH_CONN_STATUS_ONLINE &&
            device.process_type === MACH_STATES.MACH_STATE_IDLE &&
            device.user_action === 0 &&
            device.device_type === 0
        )
      );
    });
  });
  return promise;
}

export function editDevice(serialNumber, data) {
  return patch(`${api.devices}${serialNumber}/`, data);
}

export const addDevice = serialNumber => {
  return post(api.devices, { serial_number: serialNumber });
};

export const unregisterDevice = serialNumber => {
  return del(`${api.devices}${serialNumber}/`);
};

import * as wifiActions from "@common/actions/wifiActions";

export const setUpWifiListeners = dispatch => {
  window.addEventListener("online", () => {
    dispatch(wifiActions.wifiIsOnline());
  });
  window.addEventListener("offline", () => {
    dispatch(wifiActions.wifiIsOffline());
  });
};

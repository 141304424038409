import React from "react";
import { Dropdown } from "react-bootstrap";

import BeerActionMenu from "./BeerActionMenu";
import BeerActionToggle from "./BeerActionToggle";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import { withRouter } from "react-router-dom";

/**
 * Custom dropdown, see: https://react-bootstrap.github.io/components.html#btn-dropdowns-custom
 */
const menuName = "Beerlist Menu";
const BeerActionDropdown = ({ editBeer, deleteBeer, history }) => {
  const handleSelect = (openModal, name) => {
    openModal();
    analyticsService.track(ANALYTICS_TYPES.OPEN_MENU_ITEM, {
      Menu: menuName,
      MenuItem: name,
      Screen: history.location.pathname
    });
  };
  const handleToggle = isOpen => {
    if (isOpen) {
      analyticsService.track(ANALYTICS_TYPES.OPEN_MENU, {
        Menu: menuName,
        Screen: history.location.pathname
      });
    }
  };
  return (
    <Dropdown id="dropdown-custom-menu" onToggle={handleToggle}>
      <BeerActionToggle bsRole="toggle">
        <button className="beertable__button">
          <span className="ion-ios-more pointer" style={{ fontSize: "18px" }} />
        </button>
      </BeerActionToggle>
      <BeerActionMenu
        editBeer={() => handleSelect(editBeer, "Edit Beer")}
        deleteBeer={() => handleSelect(deleteBeer, "Delete Beer")}
        bsRole="menu"
        onSelect={handleSelect}
      />
    </Dropdown>
  );
};

export default withRouter(BeerActionDropdown);

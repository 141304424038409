import { MQTT_ACTIONS } from "@common/constants/actionTypes";

import {
  logRequestResponseTopic,
  logRequestTopic,
  logsTopic,
  notificationTopic
} from "@common/constants/mqttTopics";
import log_pb from "../../util/protobuf/log_pb";

// Connect to the MQTT Broker
export const connectToMQTT = credentials => ({
  type: MQTT_ACTIONS.MQTT_CONNECT,
  credentials
});

// Subscribe to a channel
export const subscribeTo = (topic, UUID) => ({
  type: MQTT_ACTIONS.MQTT_SUBSCRIBE,
  topic: topic + UUID
});
// Publish to a channel
export const publishTo = (topic, UUID, message) => ({
  type: MQTT_ACTIONS.MQTT_PUBLISH,
  topic: topic + UUID,
  message
});

// Request frequent log messages from the MiniBrew device
export const requestFrequentLog = (deviceMACAddress, clientUUID) => {
  let logRequest = new log_pb.LogRequest();
  logRequest.setDeviceId(clientUUID);
  logRequest.setStartOrStop(true); // true = start sending logs, false = stop sending logs
  logRequest.setDumpHistory(true);
  logRequest.setExtendedLog(false);
  const bytes = logRequest.serializeBinary();

  return {
    type: MQTT_ACTIONS.MQTT_PUBLISH,
    topic: logRequestTopic + deviceMACAddress,
    message: bytes
  };
};

export const stopFrequentLog = (deviceMACAddress, clientUUID) => {
  let logRequest = new log_pb.LogRequest();
  logRequest.setDeviceId(clientUUID);
  logRequest.setStartOrStop(false); // true = start sending logs, false = stop sending logs
  logRequest.setDumpHistory(true);
  logRequest.setExtendedLog(false);
  const bytes = logRequest.serializeBinary();

  return {
    type: MQTT_ACTIONS.MQTT_PUBLISH,
    topic: logRequestTopic + deviceMACAddress,
    message: bytes
  };
};

// Subscribe to the notifications channel
export const subscribeNotifications = userUUID => ({
  type: MQTT_ACTIONS.MQTT_SUBSCRIBE,
  topic: notificationTopic + userUUID
});

// Frequent log channel where the MiniBrew device sends
// all of it's frequent log messages
export const subscribeFrequentLog = deviceMACAddress => ({
  type: MQTT_ACTIONS.MQTT_SUBSCRIBE,
  topic: logsTopic + deviceMACAddress
});

// Channel to receive response from the MiniBrew device
// if the frequentlog request has been approved
export const subscribeFrequentLogResp = deviceMACAddress => ({
  type: MQTT_ACTIONS.MQTT_SUBSCRIBE,
  topic: logRequestResponseTopic + deviceMACAddress
});

export const setProcessSummary = processSummary => ({
  type: MQTT_ACTIONS.MQTT_PROCESS_SUMMARY,
  processSummary: processSummary
});

export const unsubscribeFrequentLog = deviceMACAddress => ({
  type: MQTT_ACTIONS.MQTT_UNSUBSCRIBE,
  topic: logsTopic + deviceMACAddress
});

// Channel to receive response from the MiniBrew device
// if the frequentlog request has been approved
export const unsubscribeFrequentLogResp = deviceMACAddress => ({
  type: MQTT_ACTIONS.MQTT_UNSUBSCRIBE,
  topic: logRequestResponseTopic + deviceMACAddress
});

export const ignoreNotifications = sessionID => ({
  type: MQTT_ACTIONS.MQTT_IGNORE_NOTIFICATIONS,
  session: sessionID
});

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import UserActionsModal from "../ModalManager/Modals/UserActionsModal";
import UserActionBar from "../UserActionBar/UserActionBar";
import UserActionDropdown from "../UserActionDropdown/UserActionDropdown";
import * as userActionService from "@brewery/services/userActionService";
import * as sessionService from "@brewery/services/sessionService";
import { routes } from "@common/constants/urls";
import PROCESS_STATES from "@common/constants/processStates";
import mqttService from "../../../mqttService";
import mqttUtil from "../../../mqttUtil";
import { dateTimeLabel } from "@util/formatTime";
import { getDevices } from "@common/services/deviceService";

const UserActionManager = ({ active, session, dispatch, lastFrequentLog }) => {
  const [userActionID, setUserActionID] = useState();
  const [userAction, setUserAction] = useState();
  const [nextUserAction, setNextUserAction] = useState(null);
  const [prevUserAction, setPrevUserAction] = useState(null);
  const [showUserActionModal, setShowUserActionModal] = useState(false);
  const [showNextUserActionModal, setShowNextUserActionModal] = useState(false);
  const [showPrevUserActionModal, setShowPrevUserActionModal] = useState(false);
  const [isResuming, setIsResuming] = useState(false);
  const [resumingError, setResumingError] = useState();
  const [lastLog, setLastLog] = useState(null);
  const [supressing, setSurpressing] = useState(false);

  let lastUserAction = null;
  let lastNextUserAction = null;
  let lastPrevUserAction = null;
  let resumingSequence = null;

  useEffect(() => {
    let subscription = null;
    if (active) {
      subscription = mqttService.frequentLog$.subscribe(frequentlog => {
        setLastLog(frequentlog);
        const nextAction = frequentlog.logsList[0].nextUserAction;
        const prevAction = frequentlog.logsList[0].previousUserAction;

        // Handle Useraction
        if (supressing) {
          setUserAction(null);
          setUserActionID(null);
        } else if (mqttUtil.hasNewUseraction(frequentlog, lastUserAction)) {
          const userActionFL = frequentlog.logsList[0].currentStatus.action;
          fetchUserActionInformation(userActionFL, session.id);
          fetchPrevAndNextUserActions(nextAction, prevAction);
          setUserActionID(userActionFL);
          lastUserAction = userActionFL;
        } else if (mqttUtil.hasNoUseraction(frequentlog)) {
          setUserActionID(null);
          lastUserAction = null;
        }

        if (nextAction && nextAction !== lastNextUserAction) {
          lastNextUserAction = nextAction;
          fetchUserActionInformation(
            nextAction,
            session.id,
            useractionOrder.next
          );
        }
        if (prevAction && prevAction !== lastPrevUserAction) {
          lastPrevUserAction = prevAction;
          fetchUserActionInformation(
            prevAction,
            session.id,
            useractionOrder.previous
          );
        }
        if (
          mqttUtil.hasNoNewNextUseraction(frequentlog) &&
          lastNextUserAction !== null
        ) {
          setNextUserAction(null);
        }
        if (
          mqttUtil.hasNoNewPreviousUseraction(frequentlog) &&
          lastPrevUserAction !== null
        ) {
          setPrevUserAction(null);
        }
      });
    }
    return () => {
      setUserActionID(null);
      setUserAction(null);
      setShowUserActionModal(null);
      setResumingError(null);
      setLastLog(null);
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [active, supressing]);

  const fetchPrevAndNextUserActions = (nextAction, prevAction) => {
    fetchUserActionInformation(nextAction, session.id, useractionOrder.next);
    fetchUserActionInformation(
      prevAction,
      session.id,
      useractionOrder.previous
    );
  };
  const useractionOrder = {
    current: "current",
    next: "next",
    previous: "previous"
  };

  const fetchUserActionInformation = (
    userActionID,
    sessionID,
    order = useractionOrder.current
  ) => {
    if (!userActionID) return;
    userActionService
      .getUserActionById(userActionID, sessionID)
      .then(response => {
        if (order === useractionOrder.current) {
          setUserAction(response);
        } else if (order === useractionOrder.next) {
          setNextUserAction(response);
        } else if (order === useractionOrder.previous) {
          setPrevUserAction(response);
        }
      })
      .catch(() => {
        setUserAction(null);
      });
  };

  const temporarySupressUseractions = () => {
    setSurpressing(true);
    setTimeout(() => setSurpressing(false), 8000);
  };

  const resumeSession = sessionID => {
    temporarySupressUseractions();
    const subscription = listenForCommandResponse();
    setResumingError(null);
    sessionService
      .resumeSession(sessionID, userActionID)
      .then(response => {
        setIsResuming(true);
        resumingSequence = response.seq;
      })
      .catch(() => {
        setResumingError("Resuming your session failed!");
        subscription.unsubscribe();
      });
  };

  const stopSession = () => {
    setResumingError(null);
    sessionService
      .deleteSession(session.id)
      .then(() => {
        dispatch(push(routes.brewery));
      })
      .catch(() => {
        setResumingError("Stopping your session failed!");
      });
  };

  const listenForCommandResponse = () => {
    const notificationSubscription = mqttService.notification$.subscribe(
      notification => {
        if (resumingSequence && resumingSequence === notification.commandSeq) {
          if (mqttUtil.hasError(notification)) {
            setResumingError("Resuming your session failed");
            notificationSubscription.unsubscribe();
          } else if (mqttUtil.isExecuted(notification)) {
            setShowUserActionModal(false);
            setResumingError(null);
            setIsResuming(false);
            resumingSequence = null;
            notificationSubscription.unsubscribe();
          }
        }
      }
    );
    return notificationSubscription;
  };

  const { serial_number: serialNumber } = session.device;
  const [currentDevice, setDevice] = useState(null);
  useEffect(() => {
    getDevices().then(devices => {
      const [device] = devices.filter(d => d.serial_number === serialNumber);
      if (device) {
        setDevice(device);
      }
    });
  }, [serialNumber]);

  const initialValues = {
    timeTillNextUserAction: 0,
    currentStatus: 0
  };
  const { timeTillNextUserAction, currentStatus } = lastFrequentLog
    ? lastFrequentLog.logsList[0]
    : initialValues;

  const { CIP_DONE_STATE, CIP_FAILED_STATE } = PROCESS_STATES;
  const processState = lastLog && lastLog.logsList[0].currentStatus.procState;
  const shouldStopSession =
    [CIP_DONE_STATE, CIP_FAILED_STATE].includes(processState) ||
    userActionID === 38; //Useraction = clean keg
  const nextUAPresent = nextUserAction !== null;
  const prevUAPresent = prevUserAction !== null;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "12px"
        }}
      >
        {active && (
          <UserActionDropdown
            showNext={() => setShowNextUserActionModal(true)}
            showPrev={() => setShowPrevUserActionModal(true)}
            nextUAPresent={nextUAPresent}
            prevUAPresent={prevUAPresent}
            enabled={nextUserAction || prevUserAction}
          />
        )}
      </div>
      {userActionID && (
        <UserActionBar
          device={session.device}
          handleClick={() => setShowUserActionModal(true)}
        />
      )}
      {timeTillNextUserAction && !currentStatus.action ? (
        <Banner
          date={makeEndDate(timeTillNextUserAction)}
          image={currentDevice && currentDevice.image}
        />
      ) : null}

      <UserActionsModal
        show={showUserActionModal}
        handleClose={() =>
          setShowUserActionModal(false) && setResumingError(null)
        }
        userAction={userAction}
        resumeSession={() => resumeSession(session.id)}
        stopSession={shouldStopSession && stopSession}
        resumeError={resumingError}
        isResuming={isResuming}
        isBrewpack={session.is_brewpack}
        preview={false}
      />
      <UserActionsModal
        show={showNextUserActionModal}
        handleClose={() => setShowNextUserActionModal(false)}
        userAction={nextUserAction}
        isBrewpack={session.is_brewpack}
        preview={true}
        type="Next"
      />
      <UserActionsModal
        show={showPrevUserActionModal}
        handleClose={() => setShowPrevUserActionModal(false)}
        userAction={prevUserAction}
        isBrewpack={session.is_brewpack}
        preview={true}
        type="Previous"
      />
    </div>
  );
};

const Banner = ({ date, image }) => {
  const dateLabel = dateTimeLabel(date);
  return (
    <div className="ChartHeaderBanner">
      {image && <img src={image} className="ChartHeaderBanner__avatar" />}
      <div className="ChartHeaderBanner__text">
        <div className="ChartHeaderBanner__label">your action needed:</div>
        <div className="ChartHeaderBanner__date">{dateLabel}</div>
      </div>
    </div>
  );
};

const makeEndDate = time => new Date(Date.now() + time * 1000);

export default connect(
  null,
  null
)(UserActionManager);

const isNumber = value => !isNaN(Number(value));

export const required = value => ((!value && value !== 0) ? "Required" : null);

export const chooseDropdownOption = value =>
  !value ? "Please choose an option from the dropdown" : null;

export const number = value =>
  (value || value === 0) && !isNumber(value) ? "Must be a number" : null;

export const numberBiggerThanZero = value => (
  (isNumber(value) && value <= 0)
    ? "Must be bigger than zero."
    : null
);

export const numberBetween = (min, max) => value =>
  (value || value === 0) && isNumber(value) && (value < min || value > max)
    ? `Must be a number between ${min} and ${max}`
    : null;

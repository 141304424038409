import React, { useState, useEffect } from "react";
import mqttService from "../../../mqttService";
import mqttUtil from "../../../mqttUtil";
import * as sessionService from "@brewery/services/sessionService";
import analyticsService, {
  ANALYTICS_TYPES
} from "@common/services/analyticsService";
import "./UpdateTargetTempSlider.scss";

const UpdateTargetTempSlider = ({ targetTemp, session }) => {
  const [rangeSliderValue, setRangeSlideValue] = useState(null);
  const [
    waitingForTargetTempConfirmation,
    setWaitingForTargetTempConfirmation
  ] = useState(false);
  let resumingSequence = null;
  let timeoutId = null;

  const handleRangeSliderChange = event => {
    setRangeSlideValue(event.target.value);
  };

  const handleDragStop = event => {
    setWaitingForTargetTempConfirmation(true);
    updateTargetTemp(event.target.value);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  });

  const updateTargetTemp = value => {
    const subscription = listenForCommandResponse();

    // Command type 6 is CMD_UPDATE_RECIPE
    const data = { command_type: 6, serving_temperature: value };
    sessionService
      .updateSession(session.id, data)
      .then(response => {
        analyticsService.track(ANALYTICS_TYPES.SET_SERVING_TEMP, {
          SessionID: session.id,
          Value: value
        });
        resumingSequence = response.seq;
        timeoutId = setTimeout(() => {
          setRangeSlideValue(null);
          setWaitingForTargetTempConfirmation(false);
        }, 15000);
      })
      .catch(() => subscription.unsubscribe());
  };

  const listenForCommandResponse = () => {
    const notificationSubscription = mqttService.notification$.subscribe(
      notification => {
        if (resumingSequence && resumingSequence === notification.commandSeq) {
          if (mqttUtil.hasError(notification)) {
            notificationSubscription.unsubscribe();
            setWaitingForTargetTempConfirmation(false);
          } else if (mqttUtil.isExecuted(notification)) {
            resumingSequence = null;
            setWaitingForTargetTempConfirmation(false);
            notificationSubscription.unsubscribe();
          }
        }
      }
    );
    return notificationSubscription;
  };

  return (
    <div className="slidecontainer">
      <input
        type="range"
        min="5"
        max="35"
        value={rangeSliderValue ? rangeSliderValue : targetTemp}
        onChange={handleRangeSliderChange}
        onMouseUp={handleDragStop}
        className="slider"
        id="myRange"
      />
      <small>
        {waitingForTargetTempConfirmation
          ? "Setting new target..."
          : rangeSliderValue
          ? `Set target: ${rangeSliderValue}°C`
          : "Set new target"}
      </small>
    </div>
  );
};

export default UpdateTargetTempSlider;

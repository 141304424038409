import {
  FERMENTABLE_TYPE,
  ING_FERMENTABLE
} from "@common/constants/recipeConstants";

const WATER_GRAIN_MASH_IN_RATE = 0.000625;
const WATER_RETENTION_MULTIPLIER = 0.000834540444502095;
const DEFAULT_BATCH_SIZE = 6;

const kgToLbs = kg => kg * 2.205;
const literToGallon = liter => liter * 0.26417;

const calculateSrm = fermentables => {
  let totalMcu = 0;
  fermentables.forEach(fermentable => {
    const amount = fermentable.amount || 0;
    const ingSrm = fermentable.srm || 0;
    const weightKg = amount / 1000;
    const mcu = (kgToLbs(weightKg) * ingSrm) / literToGallon(6);
    totalMcu += mcu;
  });
  const srm = 1.4922 * totalMcu ** 0.6859;

  if (srm > 40) {
    return 40;
  } else if (isNaN(srm)) return 0;
  return Math.ceil(srm);
};

const getRecipeAttenuation = recipe =>
  recipe.fermenting[0].yeast[0].ingredient_attenuation || 0;

const calculateIbu = (recipe, og, hops) => {
  const maxUtilValue = 4.15;
  let totalIbu = 0;
  hops.forEach(hop => {
    const duration = hop.duration || 0;
    const alphaAcid = hop.alpha_acid || 0;
    const amount = hop.amount || 0;
    const bitterness =
      1.65 *
      Math.pow(0.000125, og) *
      ((1 - Math.pow(Math.E, -0.04 * duration)) / maxUtilValue) *
      ((alphaAcid * amount * 1000) / 6) *
      100;
    totalIbu += bitterness;
  });

  return og ? totalIbu.toFixed(2) : 0;
};

const calculateKcal = abv => Math.ceil(abv * 30);

const calculateAbv = (og, fg) =>
  (((76.08 * (og - fg)) / (1.775 - og)) * (fg / 0.794)).toFixed(2);

const calculateEfficiencies = mashingStages => {
  let efficiencies = {};
  mashingStages.forEach((stage, index) => {
    efficiencies[index] = 0;
    stage.ingredient_additions.forEach(ingredient => {
      if (ingredient.fermentable_type === FERMENTABLE_TYPE.GRAIN) {
        efficiencies[index] += +ingredient.amount;
      }
    });

    if (efficiencies[index] > 0) {
      efficiencies[index] = -0.000133 * efficiencies[index] + 0.855;
    } else {
      delete efficiencies[index];
    }
  });
  return efficiencies;
};

const calculateTotalEfficiency = efficiencies => {
  let efficiencySum = 0;
  if (efficiencies) {
    for (let key in efficiencies) {
      efficiencySum += efficiencies[key];
    }
    return efficiencySum / Object.values(efficiencies).length;
  }
  return efficiencySum;
};

export const calculateBeerProfile = form => {
  if (!form || !form.values) return;
  const recipe = form.values;
  const fermentables = [];
  recipe.mashing.forEach(stage => {
    stage.ingredient_additions.forEach(fermentable => {
      if (
        fermentable.amount !== null &&
        fermentable.amount !== "" &&
        fermentable.ingredient_id
      ) {
        fermentables.push(fermentable);
      }
    });
  });

  recipe.boiling.forEach(step => {
    step.other_ingredients.forEach(ingredient => {
      if (ingredient.ingredient_type === ING_FERMENTABLE) {
        fermentables.push(ingredient);
      }
    });
  });

  if (!fermentables.length || !recipe.while_fermenting) return;

  const hops =
    (recipe.boiling[0] &&
      recipe.boiling[0].hops &&
      recipe.while_fermenting &&
      recipe.while_fermenting.hops &&
      recipe.boiling[0].hops) ||
    [];

  const efficiencies = calculateEfficiencies(recipe.mashing);
  const totalEfficiency = calculateTotalEfficiency(efficiencies);
  const srm = calculateSrm(fermentables);
  const og = calculateOg(totalEfficiency, fermentables);
  const attenuation = getRecipeAttenuation(recipe);
  const fg = calculateFg(og, attenuation);
  const ibu = calculateIbu(recipe, og, hops);
  const abv = calculateAbv(og, fg);
  const kcal = calculateKcal(abv);

  return [{ srm, og, fg, ibu, abv, kcal }, totalEfficiency];
};

export const calculateTotalMashInWater = totalMashWeight => {
  return totalMashWeight * WATER_GRAIN_MASH_IN_RATE;
};

export const calculateMashInWater = mashStage => {
  let mashWeight = 0;

  mashStage.ingredient_additions.forEach(ingredient => {
    if (ingredient.fermentable_type === FERMENTABLE_TYPE.GRAIN) {
      mashWeight += +ingredient.amount;
    }
  });

  return mashWeight * WATER_GRAIN_MASH_IN_RATE;
};

export const calculateTotalWater = totalMashWeight => {
  return totalMashWeight * WATER_RETENTION_MULTIPLIER + DEFAULT_BATCH_SIZE;
};

export const calculateKettleWater = (totalMashInWater, totalWater) => {
  return totalWater - totalMashInWater;
};

const calculateGravityUnitsForFermentable = (
  fermentable,
  brewHouseEfficiency
) => {
  const extractPotentialMultiplier = 0.04621;
  const extractPotential =
    ((
      1 +
      (fermentable.ingredient_dry_yield / 100) *
        (1 - fermentable.ingredient_moisture / 100) *
        extractPotentialMultiplier
    ).toFixed(3) -
      1) *
    1000;

  const gramsToGUMultiplier = 0.00220462262;
  const gravityUnitsFermentable =
    fermentable.amount *
    gramsToGUMultiplier *
    brewHouseEfficiency *
    extractPotential;

  return gravityUnitsFermentable;
};

const calculateOg = (brewHouseEfficiency, fermentables) => {
  let totalGU = 0;
  fermentables.forEach(fermentable => {
    totalGU += calculateGravityUnitsForFermentable(
      fermentable,
      brewHouseEfficiency
    );
  });
  const litersToGallonsConverter = 3.785411784;

  const og =
    1 + (totalGU / (DEFAULT_BATCH_SIZE / litersToGallonsConverter)) * 0.001;
  return og ? og : null;
};

const calculateFg = (og, attenuation) => {
  const fg = 1 + ((og * 1000 - 1000) * (1 - attenuation / 100)) / 1000;
  return og ? fg : null;
};

import { updateRecipe } from "@beers/services/recipeService";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, Button, FormGroup, FormControl } from "react-bootstrap";

const NotesModal = ({
  show,
  closeNotesModal,
  type,
  recipe,
  refetchRecipes
}) => {
  const [recipeCopy, setRecipeCopy] = useState();
  useEffect(() => {
    setRecipeCopy(recipe);
  }, [recipe]);

  const handleChange = e => {
    const updatedRecipe = JSON.parse(JSON.stringify(recipeCopy));
    updatedRecipe[type] = e.target.value;
    setRecipeCopy(updatedRecipe);
  };

  const updateNote = () => {
    updateRecipe(recipeCopy)
      .then(refetchRecipes)
      .then(closeNotesModal)
      .catch(closeNotesModal);
  };

  if (!recipeCopy) return null;
  return (
    <Modal style={{ textAlign: "center" }} show={show} onHide={closeNotesModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type == "private_note" ? "PRIVATE NOTES" : "PUBLIC NOTES"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type == "private_note" ? (
          <span style={{ fontFamily: "gotham-book" }}>
            These notes will be visible only to you
          </span>
        ) : (
          <span style={{ fontFamily: "gotham-book" }}>
            These notes will be visible to other users if you decide to share
            this recipe
          </span>
        )}
        <FormGroup>
          <FormControl
            style={{ minHeight: 200 }}
            componentClass="textarea"
            value={recipeCopy[type]}
            onChange={handleChange}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="primary" onClick={updateNote}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotesModal;

import React, { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import UserActionVideo from "./UserActionVideo";

// UserActionCarousel
const UserActionCarousel = ({
  userAction,
  handleSelect,
  index,
  direction,
  handleImageLoaded
}) => {
  useEffect(() => {
    userAction.action_steps.sort((a, b) => a.order - b.order);
    if (userAction.action_steps[index].video) {
      handleImageLoaded();
    }
  }, [userAction]);

  return (
    <div>
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={handleSelect}
        controls={false}
      >
        {userAction.action_steps
          .sort((a, b) => a.order - b.order)
          .map((action, actionIndex) => (
            <Carousel.Item key={action.order}>
              {action.video ? (
                <UserActionVideo
                  index={index}
                  action={action}
                  actionIndex={actionIndex}
                />
              ) : action.image ? (
                <img
                  width={900}
                  height={500}
                  alt="900x500"
                  src={action.image}
                  onLoad={handleImageLoaded}
                />
              ) : (
                <img
                  src="http://via.placeholder.com/900x500"
                  alt="placeholder"
                  onLoad={handleImageLoaded}
                />
              )}
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
};

export default UserActionCarousel;

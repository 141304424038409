import React from "react";
import { Field } from "redux-form";
import { Button, Row, Col, FormControl, ControlLabel } from "react-bootstrap";

import CustomField from "../../../CustomField/CustomField";
import TypeAhead from "../../TypeAhead/TypeAhead";
import { apiEndpoints as api } from "@common/constants/urls";
import { PROFILE_PROPS } from "@common/constants/recipeConstants";
import {
  fermentable as fermentableFactory,
  normalizeNumber
} from "../../../../../../util/recipeHelper";

const normalizeAmount = normalizeNumber(4, 2);
const normalizeSRM = normalizeNumber(3, 2);

const fermentable = ({ fields, change, stageIndex }) => {
  const calcPercentage = amount => {
    let total = 0;
    for (let i = 0; i < fields.length; i++) {
      total += fields.get(i) ? +fields.get(i).amount : 0;
    }
    let percentage = Math.round((amount / total) * 1000) / 10;
    return isNaN(percentage) ? 0 : percentage;
  };

  return (
    <div>
      <h3 style={{ display: "inline-block" }}>Fermentables</h3>
      <Button
        bsSize="xsmall"
        className="AddButton"
        type="button"
        onClick={() => fields.push(fermentableFactory())}
      >
        <i className="icon ion-plus" /> FERMENTABLE
      </Button>

      {!fields.length ? null : (
        <div>
          {fields.map((fermentable, index) => (
            <Row key={index} className="list-item">
              <Field
                name={`${fermentable}.ingredient_name`}
                component={TypeAhead}
                forceValidation
                label="Name"
                rowIndex={index}
                change={change}
                location={`mashing[${stageIndex}].ingredient_additions[${index}]`}
                endpoint={api.fermentables}
                attrMap={{
                  id: "ingredient_id",
                  dry_yield: "ingredient_dry_yield",
                  moisture: "ingredient_moisture",
                  avg_srm: "srm",
                  fermentable_type: "fermentable_type"
                }}
                smSize={3}
              />
              <Field
                name={`${fermentable}.amount`}
                component={CustomField}
                type="number"
                label="Amount (gr)"
                rowIndex={index}
                smSize={3}
                normalize={normalizeAmount}
                step="0.01"
              />
              <Col sm={2}>
                {!index && <ControlLabel>Percentage</ControlLabel>}
                <FormControl
                  value={calcPercentage(fields.get(index).amount) + "%"}
                  type="text"
                  placeholder={0}
                  readOnly={true}
                />
              </Col>
              <Field
                name={`${fermentable}.srm`}
                type="number"
                component={CustomField}
                label="SRM"
                rowIndex={index}
                smSize={2}
                overlay={PROFILE_PROPS.SRM}
                normalize={normalizeSRM}
                step="0.01"
              />
              {!index ? null : (
                <Button
                  className="remove-btn"
                  type="button"
                  title="Remove ingredient"
                  onClick={() => fields.remove(index)}
                >
                  <i className="icon ion-close" />
                </Button>
              )}
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

export default fermentable;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormControl, Alert, Col } from "react-bootstrap";

import "./ChangePassword.scss";
import { post } from "@common/services/request";
import { routes, apiEndpoints as api } from "@common/constants/urls";
import Container from "@common/components/Container/Container";
import CustomAlert from "./CustomAlert/CustomAlert";
import errorMessages from "@common/constants/errorMessages";
// import ConfirmationModal from "@common/components/ConfirmationModal/ConfirmationModal";
import CustomWrapper from "./CustomWrapper/CustomWrapper";
import { isPasswordCorrectLength } from "@common/services/password";
import Navigation from "@common/components/Navigation/Navigation";
import { patchUserInformation } from "@profile/actions/userActions";
import { signOut } from "@auth/actions/authenticationActions";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";

class ChangePassword extends Component {
  state = {
    oldPw: "",
    newPw: "",
    repNewPw: "",
    netError: false,
    isPasswordWrongLength: false,
    isPasswordMissing: false,
    confirmationMismatch: false,
    isLoading: false,
    wrongPassword: false,
    isConfirmationModalVisible: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const { oldPw, newPw, repNewPw } = this.state;
    this.setState({
      netError: false,
      wrongPassword: false,
      isPasswordWrongLength: false
    });

    if (!oldPw.length || !newPw.length || !repNewPw.length) {
      this.setState({ isPasswordMissing: true });
      return;
    } else this.setState({ isPasswordMissing: false });

    if (
      !isPasswordCorrectLength(oldPw) ||
      !isPasswordCorrectLength(newPw) ||
      !isPasswordCorrectLength(repNewPw)
    ) {
      this.setState({ isPasswordWrongLength: true });
      return;
    } else this.setState({ isPasswordWrongLength: false });

    if (newPw !== repNewPw) {
      this.setState({ confirmationMismatch: true });
      return;
    } else this.setState({ confirmationMismatch: false });

    this.setState({ isLoading: true });

    post(api.changePw, {
      old_password: oldPw,
      new_password: newPw
    })
      .then(response => {
        localStorage.setItem("token", response.token);
        localStorage.setItem("exp", response.exp);
        if (this.props.isFirstChange)
          this.props.patchUserInformation({
            default_password_changed: true
          });
        this.setState({ isLoading: false, isConfirmationModalVisible: true });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        if (err.then)
          err.then(body =>
            this.setState({
              wrongPassword: !!body.old_password,
              netError: false
            })
          );
        else this.setState({ netError: true, wrongPassword: false });
      });
  };

  changeHandler = e => this.setState({ [e.target.name]: e.target.value });

  toggleModal = () => {
    this.setState({
      isConfirmationModalVisible: !this.state.isConfirmationModalVisible
    });
  };
  render() {
    return (
      <div>
        {this.props.isFirstChange && <Navigation />}
        <div
          className={
            this.props.isFirstChange
              ? "portal-main change-password"
              : "change-password"
          }
        >
          <Container>
            <CustomWrapper>
              <h2>{this.props.title || "Change password"}</h2>
            </CustomWrapper>
            <Form horizontal onSubmit={e => this.handleSubmit(e)}>
              <CustomWrapper>
                <FormControl
                  name="oldPw"
                  type="password"
                  placeholder="Current password"
                  onChange={e => this.changeHandler(e)}
                  value={this.state.oldPw}
                />
              </CustomWrapper>
              <CustomWrapper>
                <FormControl
                  name="newPw"
                  type="password"
                  placeholder="New password"
                  onChange={e => this.changeHandler(e)}
                  value={this.state.newPw}
                />
              </CustomWrapper>
              <CustomWrapper>
                <FormControl
                  name="repNewPw"
                  type="password"
                  placeholder="Confirm new password"
                  onChange={e => this.changeHandler(e)}
                  value={this.state.repNewPw}
                />
              </CustomWrapper>
              {this.state.isPasswordMissing && (
                <CustomAlert msg={errorMessages.missingPassword} />
              )}
              {this.state.isPasswordWrongLength && (
                <CustomAlert msg={errorMessages.passwordLength} />
              )}
              {this.state.wrongPassword && (
                <CustomAlert msg={errorMessages.wrongPassword} />
              )}
              {this.state.netError && (
                <CustomAlert msg={errorMessages.network} />
              )}
              {this.state.confirmationMismatch && (
                <CustomAlert msg={errorMessages.passwordConfirmationMismatch} />
              )}
              {this.state.isLoading && (
                <CustomWrapper>
                  <span className="ion-load-c spin"> Changing password...</span>
                </CustomWrapper>
              )}
              {this.state.isConfirmationModalVisible && (
                <Col sm={12}>
                  <Alert bsStyle="success">
                    <span className="checkmark-circle">
                      Password updated successfully.
                    </span>
                  </Alert>
                </Col>
              )}
              <CustomWrapper>
                <ButtonWithLoad
                  type="submit"
                  bsStyle="primary"
                  style={{ float: "right" }}
                  isLoading={this.state.isLoading}
                >
                  CHANGE
                </ButtonWithLoad>
                <Button
                  onClick={() => {
                    this.props.signOut();
                    this.props.history.push(routes.profile);
                  }}
                  style={{ float: "right" }}
                >
                  CANCEL
                </Button>
              </CustomWrapper>

              {/* <ConfirmationModal
                show={this.state.isConfirmationModalVisible}
                hide={this.toggleModal}
                title="CHANGED PASSWORD"
                msg="Your password has been successfully changed."
                noCancel
                action={() =>
                  this.props.history.push(
                    this.props.nextRoute || routes.profile
                  )
                }
                confirmLabel="OK"
                confirmStyle="primary"
              /> */}
            </Form>
          </Container>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { patchUserInformation, signOut }
)(ChangePassword);

import React from "react";
import { Modal, Button } from "react-bootstrap";
import WifiContent from "./WifiContent";

class WifiModal extends React.Component {
  render() {
    const { show, handleClose } = this.props;
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>
            SET UP Wi-Fi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WifiContent />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WifiModal;
